import { createReducer } from '@reduxjs/toolkit';
import { setApproveCallAttempt, setApproveCallTxHash, setPermitCallAttempt, setPermitCallData } from './approval.actions';

export interface ApprovalStateByApproveCall {
  // Did try approval (the approval tx confirm window is opened)
  attempt?: boolean;
  // Hash of the sent transaction.
  txHash?: string;
}

export interface ApprovalStateByPermitCall {
  // Did try approval (the approval sign window is opened)
  attempt?: boolean;
  // Approved token address.
  token?: string;
  // The account of the user who issued the permissions.
  account?: string;
  // Permit method call data
  callData?: string;
  // The index of current record (The order of adding to the store).
  index: number;
}

export interface ApprovalState {
  byApproveCall: { [id: string]: ApprovalStateByApproveCall };
  byPermitCall: { [id: string]: ApprovalStateByPermitCall };
  byPermitCallLastIndex: number;
}

export const initialState: ApprovalState = {
  byApproveCall: {},
  byPermitCall: {},
  byPermitCallLastIndex: 0,
};

export default createReducer(initialState, builder => {
  builder
    .addCase(setApproveCallAttempt, (state, { payload: { id, attempt } }) => {
      if (attempt) {
        state.byApproveCall[id] = {
          ...state.byApproveCall[id],
          attempt: true,
        };
      } else if (state.byApproveCall[id]?.txHash) {
        state.byApproveCall[id] = {
          ...state.byApproveCall[id],
          attempt: false,
        };
      } else {
        Reflect.deleteProperty(state.byApproveCall, id);
      }

      return state;
    })
    .addCase(setApproveCallTxHash, (state, { payload: { id, txHash } }) => {
      if (txHash) {
        state.byApproveCall[id] = {
          ...state.byApproveCall[id],
          txHash: txHash,
        };
      } else if (state.byApproveCall[id]?.attempt) {
        Reflect.deleteProperty(state.byApproveCall[id], 'txHash');
      } else {
        Reflect.deleteProperty(state.byApproveCall, id);
      }

      return state;
    })
    .addCase(setPermitCallAttempt, (state, { payload: { id, attempt } }) => {
      if (attempt) {
        const index = state.byPermitCall[id] ? state.byPermitCall[id].index : ++state.byPermitCallLastIndex;

        state.byPermitCall[id] = {
          ...state.byPermitCall[id],
          attempt: true,
          index,
        };
      } else if (state.byPermitCall[id]?.callData) {
        state.byPermitCall[id] = {
          ...state.byPermitCall[id],
          attempt: false,
        };
      } else {
        Reflect.deleteProperty(state.byPermitCall, id);
      }

      return state;
    })
    .addCase(setPermitCallData, (state, { payload: { id, callData, account, token } }) => {
      if (callData) {
        const index = state.byPermitCall[id] ? state.byPermitCall[id].index : ++state.byPermitCallLastIndex;

        state.byPermitCall[id] = {
          ...state.byPermitCall[id],
          token,
          account,
          callData,
          index,
        };
      } else if (state.byPermitCall[id]?.attempt) {
        Reflect.deleteProperty(state.byPermitCall[id], 'callData');
        Reflect.deleteProperty(state.byPermitCall[id], 'token');
        Reflect.deleteProperty(state.byPermitCall[id], 'account');
      } else {
        Reflect.deleteProperty(state.byPermitCall, id);
      }

      return state;
    });
});
