import { CurrencyAmount, toCurrencyAmount, Trade0xSwap, TradeType } from '@plasma/plasmaswap-sdk';
import Big from 'big.js';
import { useMemo } from 'react';
import { useSlippageTolerance } from '../../state/user/user.hooks';

/**
 * Max amount of tokens or ETH to be sold, without fee.
 * @param trade
 */
export function useTrade0xSwapMaxSellAmount(trade: Trade0xSwap | undefined | null): CurrencyAmount | undefined {
  const [allowedSlippage] = useSlippageTolerance();

  return useMemo(() => {
    if (!trade || !trade.tx) {
      return undefined;
    }
    if (trade.inputAmount.currency.isNative) {
      return toCurrencyAmount(trade.inputAmount.currency, trade.tx.value ? trade.tx.value.toString() : '0');
    }

    const inputAmount = trade.inputAmount.subtract(trade.feeAmount as any);
    if (trade.tradeType === TradeType.EXACT_INPUT) {
      return inputAmount;
    }

    const multiplier = Big(allowedSlippage).div(10000).add(1).toString();
    const rawMaxInputAmount = Big(inputAmount.raw.toString()).times(multiplier).toFixed(0, 0);

    return toCurrencyAmount(trade.inputAmount.currency, rawMaxInputAmount);
  }, [allowedSlippage, trade]);
}
