export enum ApprovalState {
  /**
   * The condition is unknown. There is not enough data to determine the state.
   */
  UNKNOWN = 'UNKNOWN',
  /**
   * Insufficient funds for the transaction
   */
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  /**
   * Loading, how much is allowed to spend
   */
  LOADING = 'LOADING',
  /**
   * The amount to approve is less than allowed for the spender
   */
  NOT_APPROVED = 'NOT_APPROVED',
  /**
   * The issuance of an approval is not required
   */
  APPROVED = 'APPROVED',
  /**
   * Issuing permissions in the process (Pending TX)
   */
  TX_PENDING = 'TX_PENDING',
  /**
   * The approval transaction was completed successfully (Success TX)
   */
  TX_SUCCESS = 'TX_SUCCESS',
  /**
   * Waiting for signing from the user (For the EIP712 tokens support).
   */
  SIGNING = 'SIGNING',
  /**
   * Approval signed (For the EIP712 tokens support).
   */
  SIGNED = 'SIGNED',
}
