import { Trade0xLiquiditySource } from '@plasma/plasmaswap-sdk';
import { createAction } from '@reduxjs/toolkit';
import { SerializedToken } from '../../types';
import { WidgetTab } from '../../types/widget-tabs';

export const updateCurrency = createAction<string>('user/updateCurrency');
export const updateExchangeRates = createAction<{ [key: string]: string }>('user/updateExchangeRates');
export const changeTheme = createAction<boolean>('user/changeTheme');
export const setExclude0xLiquiditySources = createAction<Trade0xLiquiditySource[]>('user/setExclude0xLiquiditySources');
export const updateSlippageTolerance = createAction<number>('user/updateSlippageTolerance');
export const updateTransactionExpiry = createAction<number>('user/updateTransactionExpiry');
export const removeSerializedToken = createAction<{ chainId: number; address: string }>('user/removeSerializedToken');
export const addSerializedToken = createAction<SerializedToken>('user/addSerializedToken');
export const setActiveTab = createAction<WidgetTab>('user/setActiveTab');
export const updateReferrals = createAction<string[] | undefined>('user/updateReferrals');
export const updateUsedWallet = createAction<string>('user/updateUsedWallet');
