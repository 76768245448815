import { Trade0xLiquiditySource } from '@plasma/plasmaswap-sdk';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ZERO_EX_SWAP_SOURCE_NAME } from '../../constants';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import { useSupported0xLiquiditySources } from '../../hooks/use-supported-0x-liquidity-sources';
import { useExclude0xLiquiditySources } from '../../state/user/user.hooks';
import { ToggleSwitch } from '../toggle-switch/toggle-switch';
import { LiquiditySources } from './_liquidity-sources';
import { SlippageTolerance } from './_slippage-tolerance';
import { TransactionExpiry } from './_transaction-expiry';
import packageJson from '../../../package.json';
import './advanced-pool-settings.scss';
import { useTranslation } from 'react-i18next';

export interface AdvancedPoolSettingsProps {
  show0xLiquiditySources?: boolean;
}

export const AdvancedPoolSettings: FC<AdvancedPoolSettingsProps> = props => {
  const { show0xLiquiditySources } = props;
  const settingsBlockRef = useRef<HTMLDivElement>();
  const [open, setOpen] = useState<boolean>(false);
  const [view, setView] = useState<'settings' | 'sources'>('settings');
  const { t } = useTranslation();
  // Close settings, when user to clicked outside area.
  useOnClickOutside(settingsBlockRef, open ? () => setOpen(false) : undefined);

  const [search, setSearch] = useState('');
  const allLiquiditySources = useSupported0xLiquiditySources();
  const [excludedSources, setExcludedSources] = useExclude0xLiquiditySources();

  const enableAll = useMemo(() => !excludedSources.length, [excludedSources.length]);

  const liquiditySourcesList = useMemo(() => {
    return allLiquiditySources
      .map(source => {
        return {
          id: source,
          name: ZERO_EX_SWAP_SOURCE_NAME[source],
          icon: `${packageJson.homepage || '/'}images/liquidity-sources/${source.toLowerCase().replace('_', '-')}.svg`,
          enabled: !excludedSources.includes(source),
        };
      })
      .filter(source => {
        const regex = new RegExp(search, 'ig');
        return regex.test(source.name);
      });
  }, [allLiquiditySources, excludedSources, search]);

  const toggleSource = useCallback(
    (source, newState) => {
      let newExcludedSources: Trade0xLiquiditySource[];
      if (newState) {
        newExcludedSources = excludedSources.filter(i => i !== source.id);
      } else {
        newExcludedSources = Array.from(new Set(([] as Trade0xLiquiditySource[]).concat(excludedSources, [source.id])));
      }
      setExcludedSources(newExcludedSources);
    },
    [setExcludedSources, excludedSources],
  );

  const setEnableAll = useCallback(() => {
    if (!enableAll) {
      setExcludedSources([]);
    }
  }, [enableAll, setExcludedSources]);

  useEffect(() => {
    if (!open) {
      setView('settings');
    }
  }, [open]);

  return (
    <div className="advanced-pool-settings" ref={settingsBlockRef as any}>
      <button onClick={() => setOpen(!open)} id="open-settings-dialog-button" className={`btn btn-icon-rect btn-foreground-white ${open ? 'active' : ''}`}>
        <i className="pi pi-settings" />
      </button>
      {open &&
        (view === 'settings' ? (
          <div className="settings-popup">
            <h6 className="block-label">
              <span>{t('advanced_settings')}</span>
            </h6>
            <div className="transaction-settings">
              <SlippageTolerance />
              <TransactionExpiry />
            </div>
            {show0xLiquiditySources ? <LiquiditySources onSetView={() => setView('sources')} /> : null}
          </div>
        ) : (
          <div className="sources-popup">
            <div className="header">
              <button className="btn btn-close" onClick={() => setView('settings')}>
                <i className="pi pi-back" />
              </button>

              <div className="caption">
                <span>{t('liquidity_sources')}</span>
              </div>

              <button className="btn btn-close" onClick={() => setOpen(false)}>
                <i className="pi pi-close" />
              </button>
            </div>

            <div className="search">
              <i className="pi pi-search icon" />

              <input type="text" className="search-input" placeholder={t('find_protocol_by_name')} onChange={e => setSearch(e.target.value)} value={search} />
            </div>

            <div className="liquidity-sources">
              <div className="source-item">
                <div className="name">
                  <span>{t('enable_all_sources')}</span>
                </div>

                <div className="switch-block">
                  <ToggleSwitch value={enableAll} onChange={setEnableAll} />
                </div>
              </div>
              {liquiditySourcesList.map((source, index) => {
                return (
                  <div className="source-item" key={index}>
                    <div className="icon">
                      <img src={source.icon} alt={source.name} />
                    </div>
                    <div className="name">
                      <span>{source.name}</span>
                    </div>

                    <div className="switch-block">
                      <ToggleSwitch value={source.enabled} onChange={value => toggleSource(source, value)} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
    </div>
  );
};
