import { Currency, Token } from '@plasma/plasmaswap-sdk';
import { TOKEN_SYMBOL_MAP } from '../constants';

export function formatTokenSymbol(token: Token | Currency | null | undefined): string {
  if (!token) {
    return '';
  }

  if (token instanceof Token && TOKEN_SYMBOL_MAP[token.address.toLowerCase()]) {
    return TOKEN_SYMBOL_MAP[token.address.toLowerCase()];
  }
  const symbol = token.symbol?.toUpperCase() || '';
  if (symbol.length > 20) {
    return `${symbol.slice(0, 4)}...${symbol.slice(symbol.length - 5, symbol.length)}`;
  }
  return symbol;
}
