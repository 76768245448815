import { Token, TokenAmount } from '@plasma/plasmaswap-sdk';
import { useMemo } from 'react';
import { useTokenContract } from './use-contract';
import { useSingleCallResult } from '../state/multicall/multicall.hooks';

export function useTokenAllowance(token?: Token, owner?: string, spender?: string): TokenAmount | undefined | null {
  const contract = useTokenContract(token?.address, false);
  const inputs = useMemo(() => [owner, spender], [owner, spender]);

  const callState = useSingleCallResult(contract, 'allowance', inputs);

  return useMemo(() => {
    if (!token || !callState || callState.error) {
      return undefined;
    }
    if (callState.loading) {
      return null;
    }
    return callState.result ? new TokenAmount(token, callState.result.toString()) : undefined;
  }, [token, callState]);
}
