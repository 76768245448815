import { ChainId } from '@plasma/plasmaswap-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Connector, useConnect } from 'wagmi';
import { CONNECTOR_ICONS } from '../../constants';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';

export interface ConnectWalletProps {
  onConnect: (connector: Connector) => void;
}

export function ConnectWallet({ onConnect }: ConnectWalletProps): JSX.Element {
  const { t } = useTranslation();
  const { chainId } = useActiveWeb3React();
  const { activeConnector, connectors: allConnectors } = useConnect();

  const selectedChainId = chainId || ChainId.MAINNET;

  return (
    <div className="connect-wallet">
      <h2 className="title">{t('connect_wallet')}</h2>
      <div className="disclaimer">
        <span>{t('disclaimer')}</span>
      </div>
      <div className="wallet-selector">
        {allConnectors.map((connector, index) => {
          const isDisabled = activeConnector?.id === 'gnosis' || !connector.ready || !connector.chains.some(({ id }) => id === selectedChainId);

          return (
            <div key={index} className="connector-button">
              <button type="button" className="btn btn-connector" onClick={() => onConnect(connector)} disabled={isDisabled}>
                <span className="logo">
                  <img src={CONNECTOR_ICONS[connector.id]} />
                  <i className="checked pi pi-check" />
                </span>
                <span className="title">{connector.name}</span>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}
