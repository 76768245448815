const URL = 'https://api.coingecko.com/api/v3';

export interface CoingeckoTokenPrices {
  [addressOrId: string]: {
    usd: number;
  };
}

/**
 * Get Coingecko currency price by coingecko id
 * @param coingeckoIds
 * @param abort
 */
export const getCoingeckoCurrencyPrices = async (coingeckoIds: string[], abort: AbortSignal): Promise<CoingeckoTokenPrices> => {
  const url = `${URL}/simple/price?ids=${coingeckoIds.join(',')}&vs_currencies=usd`;

  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    signal: abort,
  });

  if (!res.ok) {
    throw new Error(`Failed to fetch price list data from coingecko API`);
  }
  return res.json();
};
