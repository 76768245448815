import { TokenTag } from '../types';

export const TOKENS_ITEMS_PER_PAGE = 20;

export const TOKENS_TAGS: { [id: string]: Omit<TokenTag, 'id'> } = {
  defi: {
    name: 'DeFi Market',
    description: 'Decentralized Finance protocols',
  },
  aavev2: {
    name: 'Aave V2',
    description: 'Aave interest bearing tokens',
  },
  comp: {
    name: 'Compound',
    description: 'Compound protocol balance token',
  },
  nft: {
    name: 'NFT',
    description: 'Non fungible token',
  },
  indexes: {
    name: 'DeFi Indexes',
    description: 'Tokenized baskets of high quality DeFi projects',
  },
  stable: {
    name: 'Stablecoin',
    description: 'Stablecoins may be pegged to a currency like the US dollar or to a commoditys price such as gold',
  },
  alliance: {
    name: 'Plasma Alliance',
    description: 'Plasma Alliance of ambitious projects and game changing DeFi protocols',
  },
  dex: {
    name: 'DEX Tokens',
    description: 'Tokens from AMM protocols',
  },
  wrap: {
    name: 'Wrapped',
    description: 'Wrapped tokens pegged to the value of cryptocurrency from another chain',
  },
  eth2: {
    name: 'Eth 2 Staking',
    description: 'Eth 2 Staking Tokens',
  },
  pools: {
    name: 'Pools',
    description: 'Earn trading fees by providing liquidity in a single transaction',
  },
  synths: {
    name: 'Synthetix',
    description: 'Synthetic cryptocurrency by Synthetix protocol',
  },
  stock: {
    name: 'Stock',
    description: 'Synthetic stocks by Mirror protocol',
  },
  play: {
    name: 'Play To Earn',
    description: 'Play to earn games rely on blockchain technology, including in the form of NFTs, as the foundation for value creation',
  },
  synth: {
    name: 'Synth',
    description: 'A synthetic asset within the Synthetix protocol which can at any time be exchanged in its entirety into any other synth within Synthetix.',
  },
  index: {
    name: 'Index Synth',
    description:
      'Tokens that are compromised of a basket of underlying assets determined by a set number of units of each. These units are are based on a marketcap weighting of each asset.',
  },
  stablecoin: {
    name: 'Stablecoin',
    description: 'Tokens that are fixed to an external asset',
  },
  atokenv1: {
    name: 'aToken V1',
    description: 'Tokens that earn interest on the Aave Protocol V1',
  },
  atokenv2: {
    name: 'aToken V2',
    description: 'Tokens that earn interest on the Aave Protocol V2',
  },
  stktoken: {
    name: 'Staked Token',
    description: 'Tokens that earn interest as they are staked in the Aave Safety Module',
  },
  unilpv1: {
    name: 'Uniswap v1 LP token',
    description: 'Tokens that represents an LPs stake in a Uniswap pool',
  },
};
