import { parseBytes32String } from '@ethersproject/strings';
import { ChainId, Currency, NATIVE, Token } from '@plasma/plasmaswap-sdk';
import { useMemo } from 'react';
import { BLACKLISTED_TOKENS, NATIVE_ADDRESSES_LIST, TOKENS } from '../constants';
import { ERC20_BYTES32_INTERFACE, ERC20_INTERFACE } from '../constants/abis/erc20';
import { usePlasmaMarketTokenList, useSearchFavoritesTokens } from '../state/lists/lists.hooks';
import { NEVER_RELOAD } from '../state/multicall/multicall.helpers';
import { useMultipleContractSingleData, useSingleCallResult } from '../state/multicall/multicall.hooks';
import { isAddress } from '../utils';
import { useBytes32TokenContract, useTokenContract } from './use-contract';
import { useActiveWeb3React } from './web3/use-active-web3-react';

// parse a name or symbol from a token response
const BYTES32_REGEX = /^0x[a-fA-F0-9]{64}$/;

const LOWER_NATIVE_ADDRESSES: string[] = NATIVE_ADDRESSES_LIST.map(i => i.toLowerCase());

function parseStringOrBytes32(str: string | undefined, bytes32: string | undefined, defaultValue: string): string {
  if (str && str.length > 0) {
    return str;
  } else if (bytes32 && BYTES32_REGEX.test(bytes32)) {
    try {
      return parseBytes32String(bytes32);
    } catch (e) {}
  }
  return defaultValue;
}

export function useDefaultTokens(): { [address: string]: Token } {
  const { chainId } = useActiveWeb3React();

  return useMemo(() => {
    if (!chainId) {
      return {};
    }

    const tokens: { [address: string]: Token } = {};
    Object.values(TOKENS[chainId]).forEach(token => {
      tokens[token.address] = token;
    });
    return tokens;
  }, [chainId]);
}

// Undefined if invalid or does not exist
// null if loading
// otherwise returns the token
export function useToken(tokenAddress?: string): Token | undefined | null {
  const { chainId } = useActiveWeb3React();
  const userTokens = useSearchFavoritesTokens();
  const [marketTokensLoading, marketTokens] = usePlasmaMarketTokenList();

  const address = useMemo(() => {
    const address = isAddress(tokenAddress);
    if (!chainId || !address || BLACKLISTED_TOKENS[chainId].includes(address)) {
      return undefined;
    }
    return address;
  }, [tokenAddress, chainId]);

  const token: Token | undefined = useMemo(() => {
    if (!address) {
      return undefined;
    }
    return marketTokens[address] || userTokens[address];
  }, [address, marketTokens, userTokens]);
  const tokenContract = useTokenContract(marketTokensLoading || token ? undefined : address, false);
  const tokenContractBytes32 = useBytes32TokenContract(marketTokensLoading || token ? undefined : address, false);

  const tokenName = useSingleCallResult(tokenContract, 'name', undefined, NEVER_RELOAD);
  const tokenNameBytes32 = useSingleCallResult(tokenContractBytes32, 'name', undefined, NEVER_RELOAD);
  const symbol = useSingleCallResult(tokenContract, 'symbol', undefined, NEVER_RELOAD);
  const symbolBytes32 = useSingleCallResult(tokenContractBytes32, 'symbol', undefined, NEVER_RELOAD);
  const decimals = useSingleCallResult(tokenContract, 'decimals', undefined, NEVER_RELOAD);

  return useMemo(() => {
    if (marketTokensLoading) {
      return null;
    }
    if (token) {
      return token;
    }
    if (!chainId || !address) {
      return undefined;
    }
    if (decimals.loading || symbol.loading || tokenName.loading) {
      return null;
    }
    if (decimals.result) {
      return new Token(
        chainId,
        address,
        decimals.result[0],
        parseStringOrBytes32(symbol.result?.[0], symbolBytes32.result?.[0], 'UNKNOWN'),
        parseStringOrBytes32(tokenName.result?.[0], tokenNameBytes32.result?.[0], 'Unknown Token'),
      );
    }
    return undefined;
  }, [
    address,
    chainId,
    decimals.loading,
    decimals.result,
    symbol.loading,
    symbol.result,
    symbolBytes32.result,
    token,
    tokenName.loading,
    tokenName.result,
    tokenNameBytes32.result,
    marketTokensLoading,
  ]);
}

export function useTokens(addresses: (string | undefined)[]): (Token | undefined | null)[] {
  const { chainId } = useActiveWeb3React();
  const userTokens = useSearchFavoritesTokens();
  const [marketTokensLoading, marketTokens] = usePlasmaMarketTokenList();

  const addressesValid = useMemo(() => {
    if (!chainId) {
      return [];
    }
    return addresses.map(address => {
      const checksummed = isAddress(address);
      if (!checksummed || BLACKLISTED_TOKENS[chainId].includes(checksummed)) {
        return undefined;
      }
      return checksummed;
    });
  }, [addresses, chainId]);

  const addressesToGet: string[] = useMemo(() => {
    if (marketTokensLoading) {
      return [];
    }
    return addressesValid.filter(address => address && !marketTokens[address] && !userTokens[address]) as string[];
  }, [addressesValid, marketTokens, marketTokensLoading, userTokens]);

  const namesResult = useMultipleContractSingleData(addressesToGet, ERC20_INTERFACE, 'name', undefined, NEVER_RELOAD);
  const namesBytes32Result = useMultipleContractSingleData(addressesToGet, ERC20_BYTES32_INTERFACE, 'name', undefined, NEVER_RELOAD);
  const symbolsResult = useMultipleContractSingleData(addressesToGet, ERC20_INTERFACE, 'symbol', undefined, NEVER_RELOAD);
  const symbolsBytes32Result = useMultipleContractSingleData(addressesToGet, ERC20_BYTES32_INTERFACE, 'symbol', undefined, NEVER_RELOAD);
  const decimalsResult = useMultipleContractSingleData(addressesToGet, ERC20_INTERFACE, 'decimals', undefined, NEVER_RELOAD);

  return useMemo(() => {
    return addressesValid.map((address: string | undefined) => {
      if (marketTokensLoading) {
        return null;
      }
      if (!chainId || !address) {
        return undefined;
      }

      if (marketTokens[address]) {
        return marketTokens[address];
      }

      if (userTokens[address]) {
        return userTokens[address];
      }

      const index = addressesToGet.findIndex(i => i === address);
      if (index === -1) {
        return undefined;
      }

      if (decimalsResult[index].loading || symbolsResult[index].loading || namesResult[index].loading) {
        return null;
      }

      const decimals = decimalsResult[index].result?.[0];
      const symbol = symbolsResult[index].result?.[0];
      const symbolBytes32 = symbolsBytes32Result[index].result?.[0];
      const name = namesResult[index].result?.[0];
      const nameBytes32 = namesBytes32Result[index].result?.[0];

      if (decimalsResult[index].result?.[0]) {
        return new Token(chainId, address, decimals, parseStringOrBytes32(symbol, symbolBytes32, 'UNKNOWN'), parseStringOrBytes32(name, nameBytes32, 'Unknown Token'));
      }
      return undefined;
    });
  }, [
    addressesToGet,
    addressesValid,
    chainId,
    decimalsResult,
    marketTokens,
    marketTokensLoading,
    namesBytes32Result,
    namesResult,
    symbolsBytes32Result,
    symbolsResult,
    userTokens,
  ]);
}

export function useCurrency(currencyId: string | undefined, chainId?: ChainId): Currency | null | undefined {
  const { chainId: activeChainId } = useActiveWeb3React();
  chainId = chainId || activeChainId;
  const isNATIVE = currencyId && (currencyId.toUpperCase() === 'NATIVE' || LOWER_NATIVE_ADDRESSES.includes(currencyId.toLowerCase()));
  const token = useToken(isNATIVE ? undefined : currencyId);

  return isNATIVE && chainId ? NATIVE[chainId] : token;
}
