import { LIQUIDITY_TOKEN_NAME } from '@plasma/plasmaswap-sdk';
import { createReducer } from '@reduxjs/toolkit';
import Big from 'big.js';
import { NATIVE_ADDRESS } from '../../constants';
import { PORTFOLIO_CHAIN_ID } from '../../constants/portfolio';
import { PlasmaTokenInfo, UnmarshalSupportedChainId } from '../../types';
import { isAddress } from '../../utils';
import { updateVersion } from '../global/global.actions';
import { fetchPortfolioAssets } from './portfolio-assets.actions';

export interface PortfolioAccountAssetsByChainId {
  loading: boolean;
  items: PlasmaTokenInfo[];
}

export interface PortfolioAssetsState {
  totals: {
    [account: string]: {
      accountTotal: string;
      totalByChain: {
        [chainId in UnmarshalSupportedChainId]?: string;
      };
    };
  };
  assets: {
    [account in string]?: PlasmaTokenInfo[];
  };
  loading: boolean;
}

export const initialState: PortfolioAssetsState = {
  assets: {},
  totals: {},
  loading: false,
};

export default createReducer(initialState, builder => {
  return builder
    .addCase(updateVersion, state => {
      if (!state.assets) {
        state.assets = initialState.assets;
      }
      return state;
    })
    .addCase(fetchPortfolioAssets.pending, state => {
      state.loading = true;
    })
    .addCase(fetchPortfolioAssets.resolved, (state, { payload: { account, items } }) => {
      const lpTokens = Object.values(LIQUIDITY_TOKEN_NAME);
      const assetsItemsMap: { [key: string]: PlasmaTokenInfo } = {};
      let totalByChain: { [chainId in UnmarshalSupportedChainId]?: string } = {};
      let accountTotal = Big(0);

      items.forEach(item => {
        const itemAddress = isAddress(item.id);
        const address = itemAddress ? itemAddress : item.id ? NATIVE_ADDRESS[item.id] : undefined;

        const chainId = PORTFOLIO_CHAIN_ID[item.chain];
        if (!chainId || !address || address in assetsItemsMap || !item.symbol || !item.name || lpTokens.includes(item.name) || !item.amount) {
          return;
        }
        const usdQuote = Big(item.amount || 0)
          .mul(item.price || 0)
          .toString();
        accountTotal = accountTotal.add(usdQuote);

        assetsItemsMap[address] = {
          address,
          chainId,
          decimals: item.decimals,
          exactBalance: Big(item.amount).toString(),
          symbol: item.symbol,
          name: item.name,
          logoURI: item.logo_url || undefined,
          usdQuote,
          usdRate: item.price ? Big(item.price).toString() : undefined,
          isCore: item.is_core,
        };
        totalByChain = {
          ...totalByChain,
          [chainId]: Big(totalByChain[chainId] || 0)
            .add(usdQuote)
            .toString(),
        };
      });

      state.totals[account] = {
        accountTotal: accountTotal.toString(),
        totalByChain,
      };

      state.assets[account] = Object.values(assetsItemsMap);

      state.loading = false;
    })
    .addCase(fetchPortfolioAssets.rejected, state => {
      state.loading = false;
    });
});
