import { Token } from '@plasma/plasmaswap-sdk';
import { useMemo } from 'react';
import { TOKENS_ITEMS_PER_PAGE } from '../../constants';
import { useSearchApiTokens, useSearchFavoritesTokens, useSearchPlasmaMarketToken } from '../../state/lists/lists.hooks';
import { MarketTokenInfo, WrappedTokenInfo } from '../../types';
import { isAddress } from '../../utils';
import { useToken } from '../use-token';

/**
 * Search tokens in all sources
 *
 * Local Store
 * hyper-api.plasma.finance/v1/bff/token;
 * raw.githubusercontent.com/plasmadlt/plasma-finance-market-tokenlist/main/plasma-finance-market-list.json
 *
 * @param search
 * @param page
 */
export function useSearchTokens(search?: string, page = 0): [boolean, Token[]] {
  const [searchAddress, searchQuery]: [string | undefined, string | undefined] = useMemo(() => {
    const address = isAddress(search);
    return address ? [address, undefined] : [undefined, search?.trim().toLowerCase()];
  }, [search]);

  // Find tokens in all sources
  const favoritesTokensMap: { [address: string]: Token } = useSearchFavoritesTokens(searchQuery);
  const favoritesTokensAddresses: string[] = useMemo(() => Object.keys(favoritesTokensMap), [favoritesTokensMap]);
  const [apiTokensLoading, apiTokensList]: [boolean, WrappedTokenInfo[]] = useSearchApiTokens(searchQuery, page);
  const [marketTokensLoading, marketTokensMap]: [boolean, { [address: string]: WrappedTokenInfo<MarketTokenInfo> }] = useSearchPlasmaMarketToken(searchQuery);
  const addressToken: Token | null | undefined = useToken(searchAddress);

  const favoritesTokens: Token[] = useMemo(() => {
    if (marketTokensLoading) {
      return [];
    }
    return favoritesTokensAddresses.map(address => (marketTokensMap[address] ? marketTokensMap[address] : favoritesTokensMap[address]));
  }, [favoritesTokensAddresses, favoritesTokensMap, marketTokensLoading, marketTokensMap]);

  const apiTokens: WrappedTokenInfo[] = useMemo(() => {
    if (marketTokensLoading) {
      return [];
    }

    return apiTokensList.reduce<WrappedTokenInfo[]>((list, token) => {
      if (!favoritesTokensAddresses.includes(token.address)) {
        list.push(marketTokensMap[token.address] ? marketTokensMap[token.address] : token);
      }
      return list;
    }, []);
  }, [apiTokensList, favoritesTokensAddresses, marketTokensLoading, marketTokensMap]);

  const marketTokens: WrappedTokenInfo[] = useMemo(() => {
    if (apiTokensLoading || marketTokensLoading || apiTokens.length) {
      return [];
    }
    const marketTokensAddresses = Object.keys(marketTokensMap);
    if (!marketTokensAddresses.length) {
      return [];
    }

    const tokens: WrappedTokenInfo[] = [];
    for (let i = 0; i < marketTokensAddresses.length; i++) {
      const marketTokensAddress = marketTokensAddresses[i];
      if (favoritesTokensAddresses.includes(marketTokensAddress)) {
        continue;
      }

      tokens.push(marketTokensMap[marketTokensAddress]);
      if (tokens.length >= TOKENS_ITEMS_PER_PAGE) {
        break;
      }
    }
    return tokens;
  }, [apiTokens.length, apiTokensLoading, marketTokensLoading, marketTokensMap, favoritesTokensAddresses]);

  const currencies: Token[] = useMemo(() => {
    if (searchAddress) {
      return addressToken ? [addressToken] : [];
    }

    return [...favoritesTokens, ...apiTokens, ...marketTokens];
  }, [searchAddress, favoritesTokens, apiTokens, marketTokens, addressToken]);

  const loading: boolean = useMemo(() => addressToken === null || apiTokensLoading || marketTokensLoading, [addressToken, apiTokensLoading, marketTokensLoading]);

  return useMemo(() => [loading, currencies], [loading, currencies]);
}
