import { Interface } from '@ethersproject/abi';
import { BigNumber } from '@ethersproject/bignumber';
import { Signature } from '@ethersproject/bytes';
import { ChainId, EIP712Domain, EIP712Object, EIP712Parameter, EIP712TypedData, TokenAmount } from '@plasma/plasmaswap-sdk';
import { ApprovalTokenConfig } from '../types';

function getPrimaryTypeAbi(abiString: string): { type: string; abi: EIP712Parameter[] } {
  const [type, ...types] = abiString.split(/[(),]/g);
  const abi = types.slice(0, -1).map(item => {
    const [type, name] = item.split(/\s/);
    return { type, name } as EIP712Parameter;
  });

  return { type, abi };
}

function getMessage(owner: string, spender: string, amount: TokenAmount, nonce: BigNumber, deadline: BigNumber, conf: ApprovalTokenConfig): EIP712Object {
  switch (conf.primaryType) {
    case 'Permit(address owner,address spender,uint256 value,uint256 nonce,uint256 deadline)':
      return { owner, spender, value: amount.raw.toString(), nonce: nonce.toString(), deadline: deadline.toString() };
    case 'Permit(address holder,address spender,uint256 nonce,uint256 expiry,bool allowed)':
      return { holder: owner, spender, nonce: nonce.toString(), expiry: deadline.toString(), allowed: true as any };
    default:
      throw new Error('Unknown config');
  }
}

function getDomain(tokenName: string, tokenAddress: string, chainId: ChainId, conf: ApprovalTokenConfig): EIP712Domain {
  switch (conf.domainType) {
    case 'EIP712Domain(string name,string version,uint256 chainId,address verifyingContract)':
      return { name: tokenName, version: conf.version, chainId: chainId as number, verifyingContract: tokenAddress };
    case 'EIP712Domain(string name,uint256 chainId,address verifyingContract)':
      return { name: tokenName, chainId: chainId as number, verifyingContract: tokenAddress } as any;
    default:
      throw new Error('Unknown config');
  }
}

export function getSignTypedData(
  tokenName: string,
  owner: string,
  spender: string,
  amount: TokenAmount,
  nonce: BigNumber,
  deadline: BigNumber,
  conf: ApprovalTokenConfig,
): EIP712TypedData {
  const { type: domainType, abi: domainTypeAbi } = getPrimaryTypeAbi(conf.domainType);
  const { type: primaryType, abi: primaryTypeAbi } = getPrimaryTypeAbi(conf.primaryType);

  return {
    types: {
      [domainType]: domainTypeAbi,
      [primaryType]: primaryTypeAbi,
    },
    domain: getDomain(tokenName, amount.token.address, amount.token.chainId, conf),
    primaryType,
    message: getMessage(owner, spender, amount, nonce, deadline, conf),
  };
}

/**
 * Returns token permit method call data.
 * @param owner
 * @param spender
 * @param amount
 * @param nonce
 * @param deadline
 * @param signature
 * @param conf
 */
export function getCallData(owner: string, spender: string, amount: TokenAmount, nonce: BigNumber, deadline: BigNumber, signature: Signature, conf: ApprovalTokenConfig): string {
  const permitInterface = new Interface([`function ${conf.permitMethod}`]);

  switch (conf.permitMethod) {
    case 'permit(address owner,address spender,uint256 value,uint256 deadline,uint8 v,bytes32 r,bytes32 s)':
      return permitInterface.encodeFunctionData(conf.permitMethod, [owner, spender, amount.raw.toString(), deadline, signature.v, signature.r, signature.s]);
    case 'permit(address holder,address spender,uint256 nonce,uint256 expiry,bool allowed,uint8 v,bytes32 r,bytes32 s)':
      return permitInterface.encodeFunctionData(conf.permitMethod, [owner, spender, nonce, deadline, true, signature.v, signature.r, signature.s]);
    default:
      throw new Error('Unknown config');
  }
}
