import { Currency } from '@plasma/plasmaswap-sdk';
import React, { useMemo } from 'react';
import { formatTokenSymbol } from '../../utils/format-token-symbol';

export interface FormattedTokenSymbolProps {
  currency?: Currency | null;
  currencySecond?: Currency | null;
  separator?: string;
  /**
   * If true, each currency symbol will be a link to the market page
   */
  isMarketLink?: boolean;
}

export function FormattedTokenSymbol({ currency, currencySecond, separator = '/', isMarketLink }: FormattedTokenSymbolProps): JSX.Element {
  const symbol: JSX.Element | null = useMemo(() => {
    if (!currency) {
      return null;
    }
    const symbol = formatTokenSymbol(currency);

    if (isMarketLink) {
      // const wrapped = currency.wrapped();
      // return <Link to={`/market/${wrapped.address}`}>{symbol}</Link>
      return <div>ссылка{symbol}</div>;
    }

    return <>{symbol}</>;
  }, [currency, isMarketLink]);

  const symbolSecond: JSX.Element | null = useMemo(() => {
    if (!currencySecond) {
      return null;
    }
    const symbol = formatTokenSymbol(currencySecond);

    if (isMarketLink) {
      // const wrapped = currencySecond.wrapped();
      // return <Link to={`/market/${wrapped.address}`}>{symbol}</Link>;
      return <div>ссылка{symbol}</div>;
    }

    return <>{symbol}</>;
  }, [currencySecond, isMarketLink]);

  return useMemo(() => {
    if (symbol) {
      if (symbolSecond) {
        return (
          <>
            {symbol}
            {separator}
            {symbolSecond}
          </>
        );
      }
      return symbol;
    }
    return <></>;
  }, [symbol, symbolSecond, separator]);
}
