import { Token } from '@plasma/plasmaswap-sdk';

export enum PriceConfigurationVersion {
  V2 = 2,
  V3,
}

export interface PriceConfiguration {
  /**
   * Used liquidity pool version
   */
  version: PriceConfigurationVersion;
  /**
   * Liquidity pool init hash
   */
  initCodeHash: string;
  /**
   * Liquidity pool factory address
   */
  factory: string;
  /**
   * Stable coin token, used as USD currecy
   */
  usdToken: Token;
}
