import { useEffect, useMemo } from 'react';
import { Interface } from '@ethersproject/abi';
import { CallListenerOptions, CallResult, CallState, OptionalCallMethodArg } from '../../types';
import { call2Key, callResult2State, isCallMethodArgs, key2Call } from './multicall.helpers';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '..';
import { addMulticallListeners, removeMulticallListeners } from './multicall.actions';
import { Contract } from '@ethersproject/contracts';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';

const INVALID_CALL_RESULT: CallResult = { valid: false, blockNumber: undefined, data: undefined };

/**
 * The lowest level hook for subscribing to contract data
 * @param serializedCallKeys
 * @param blocksPerFetch
 */
function useCallsData(serializedCallKeys: string, blocksPerFetch?: number): CallResult[] {
  const { chainId } = useActiveWeb3React();
  const callResults = useSelector((state: AppState) => state.multicall.callResults);
  const dispatch = useDispatch<AppDispatch>();

  const serializedCallResults: string = useMemo(() => {
    const callKeys: (string | null)[] = JSON.parse(serializedCallKeys);
    const results = callKeys.map(callKey => {
      if (!chainId || !callKey) {
        return INVALID_CALL_RESULT;
      }
      const result = callResults[chainId]?.[callKey];
      let data;
      if (result?.data && result?.data !== '0x') {
        data = result.data;
      }

      return { valid: true, data, blockNumber: result?.blockNumber };
    });

    return JSON.stringify(results);
  }, [callResults, chainId, serializedCallKeys]);

  // Update listeners when there is an actual change that persists for at least 100ms
  useEffect(() => {
    const callKeys: (string | null)[] = JSON.parse(serializedCallKeys);
    const validCallKeys: string[] = callKeys.filter(Boolean) as string[];

    if (!chainId || validCallKeys.length === 0) {
      return undefined;
    }
    const calls = validCallKeys.map(key => key2Call(key));
    dispatch(
      addMulticallListeners({
        chainId,
        calls,
        options: blocksPerFetch ? { blocksPerFetch } : undefined,
      }),
    );

    return () => {
      dispatch(
        removeMulticallListeners({
          chainId,
          calls,
          options: blocksPerFetch ? { blocksPerFetch } : undefined,
        }),
      );
    };
  }, [chainId, dispatch, blocksPerFetch, serializedCallKeys]);

  return useMemo(() => {
    return JSON.parse(serializedCallResults);
  }, [serializedCallResults]);
}

export function useMultipleContractSingleData(
  addresses: (string | undefined)[],
  contractInterface: Interface,
  methodName: string,
  callInputs?: OptionalCallMethodArg,
  options?: CallListenerOptions,
): CallState[] {
  const fragment = useMemo(() => contractInterface.getFunction(methodName), [contractInterface, methodName]);

  const callData: string | undefined = useMemo(() => {
    if (fragment && isCallMethodArgs(callInputs)) {
      return contractInterface.encodeFunctionData(fragment, callInputs);
    } else {
      return undefined;
    }
  }, [callInputs, contractInterface, fragment]);

  const serializedCallKeys = useMemo<string>(() => {
    if (fragment && addresses && addresses.length > 0 && callData) {
      const callKeys = addresses.map<string | null>(address => {
        return address ? call2Key({ address, callData, methodName }) : null;
      });

      return JSON.stringify(callKeys);
    } else {
      return '[]';
    }
  }, [addresses, callData, fragment, methodName]);

  const results = useCallsData(serializedCallKeys, options?.blocksPerFetch);

  return useMemo(() => {
    return results.map(result => callResult2State(result, contractInterface, fragment));
  }, [fragment, results, contractInterface]);
}

/**
 *
 * @param contract
 * @param methodName
 * @param callInputs
 * @param options
 */
export function useSingleContractMultipleData(
  contract: Contract | null | undefined,
  methodName: string,
  callInputs: OptionalCallMethodArg[],
  options?: CallListenerOptions,
): CallState[] {
  const fragment = useMemo(() => contract?.interface?.getFunction(methodName), [contract, methodName]);

  const serializedCallKeys = useMemo<string>(() => {
    if (contract && fragment && callInputs && callInputs.length > 0) {
      const callKeys = callInputs.map<string>(inputs => {
        const callData = contract.interface.encodeFunctionData(fragment, inputs);
        return call2Key({ address: contract.address, callData, methodName });
      });
      return JSON.stringify(callKeys);
    } else {
      return '[]';
    }
  }, [callInputs, contract, fragment, methodName]);

  const results = useCallsData(serializedCallKeys, options?.blocksPerFetch);

  return useMemo(() => {
    return results.map(result => callResult2State(result, contract?.interface, fragment));
  }, [fragment, contract, results]);
}

export function useSingleCallResult(contract: Contract | null | undefined, methodName: string, inputs?: OptionalCallMethodArg, options?: CallListenerOptions): CallState {
  const fragment = useMemo(() => contract?.interface?.getFunction(methodName), [contract, methodName]);

  const serializedCallKeys = useMemo<string>(() => {
    if (contract?.address && contract?.interface && fragment && isCallMethodArgs(inputs)) {
      return JSON.stringify([
        call2Key({
          address: contract.address,
          callData: contract.interface.encodeFunctionData(fragment, inputs),
          methodName: fragment.name,
        }),
      ]);
    } else {
      return '[null]';
    }
  }, [contract?.address, contract?.interface, fragment, inputs]);

  const result = useCallsData(serializedCallKeys, options?.blocksPerFetch)[0];

  return useMemo(() => {
    return callResult2State(result, contract?.interface, fragment);
  }, [result, contract, fragment]);
}
