import Big from 'big.js';
import React, { useMemo } from 'react';
import { useCurrentCurrency } from '../../state/user/user.hooks';
import { AmountInput, formatAmount, FormatAmountSettings } from '../../utils/format-amount';
import { isNumber } from '../../utils/is-number';

export interface FormattedAmountInFiatProps extends FormatAmountSettings {
  amount: AmountInput; // The amount MUST be in USD!!!
  hideSymbol?: boolean; // Default false
  replacerForEmpty?: string; // Default ''
}

export function FormattedAmountInFiat({
  amount,
  decimalPlaces,
  preferredStringLength,
  groupSeparator,
  maxDecimals,
  replacerForEmpty = '',
  hideSymbol,
}: FormattedAmountInFiatProps): JSX.Element {
  const [localCurrency] = useCurrentCurrency();

  const formattedAmount = useMemo(() => {
    let result: string | null = null;
    if (amount !== undefined && amount !== null && isNumber(amount) && localCurrency) {
      try {
        const localAmount = Big(amount).div(localCurrency.rate).toString();
        result = formatAmount(localAmount, { decimalPlaces, preferredStringLength, groupSeparator, maxDecimals });

        if (result) {
          result = `${!hideSymbol ? localCurrency.sign : ''}${result}`;
        }
      } catch (e) {
        console.error('Amount parse failed', amount);
        console.error(e);
      }
    }
    return result ? result : replacerForEmpty;
  }, [amount, localCurrency, replacerForEmpty, decimalPlaces, preferredStringLength, groupSeparator, maxDecimals, hideSymbol]);

  return <>{formattedAmount}</>;
}
