/**
 * It is thrown out when the transaction is canceled and no further processing is needed.
 */
export class GsnRelayAbortError extends Error {
  code?: number;
  constructor(message?: string, code?: number) {
    super(message);
    this.code = code;
  }
}

export class GsnRuntimeError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export class GsnTxExecuteError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export class GsnNoLiquidityError extends Error {
  constructor() {
    super('No liquidity for exchange fee tokens');
  }
}
