import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Connector, useConnect } from 'wagmi';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { Loading } from '../loading/loading';

type ViewName = 'pending' | 'error' | 'success';

export interface ConnectWalletProcessProps {
  connector?: Connector;
  onReset?: () => void;
}

export function ConnectWalletProcess({ connector, onReset }: ConnectWalletProcessProps): JSX.Element {
  const { t } = useTranslation();
  const { connectAsync } = useConnect();
  const { chainId } = useActiveWeb3React();

  const [view, setView] = useState<ViewName>('pending');
  const [account, setAccount] = useState<string>();
  const [error, setError] = useState<any>();

  const onCancel = useCallback(() => onReset && onReset(), [onReset]);

  // Start to connection
  useEffect(() => {
    setTimeout(() => {
      connectAsync({ chainId, connector })
        .then(result => {
          if (result.account) {
            return setAccount(result.account);
          }
          throw new Error('No account to connected');
        })
        .catch(error => {
          setError(error);
        });
    }, 10);
  }, [chainId, connector, connectAsync]);

  // Show error view
  useEffect(() => {
    if (error) {
      setView('error');
    }
  }, [error]);

  // Show success view
  useEffect(() => {
    if (account) {
      setView('success');
    }
  }, [account]);

  return (
    <div className="connect-wallet-process">
      {view === 'pending' ? (
        <div className="view pending-view">
          <div className="icon loading">
            <Loading size="sm" />
          </div>
          <h3>
            <Trans i18nKey="connecting_to" values={{ provider: connector?.name || '' }} />
          </h3>
          <p>
            <Trans i18nKey="connect_to_plasma_finance" values={{ provider: connector?.name || '' }} />
          </p>
          <div className="button-block">
            <button className="btn btn-thirdly" onClick={onCancel}>
              <span>{t('cancel')}</span>
            </button>
          </div>
        </div>
      ) : view === 'error' ? (
        <div className="view error-view">
          <div className="icon error">
            <i className="pi pi-error" />
          </div>
          <h3>{t('error_connecting')}</h3>
          {error?.message ? <p>{error.message}</p> : null}
          <div className="button-block">
            <button className="btn btn-thirdly" onClick={onCancel}>
              <span>{t('try_again')}</span>
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
