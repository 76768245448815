import { SupportedAlgorithm, computeHmac } from 'ethers/lib/utils';

const OTP_PERIOD = 30; // OTP token valid period in seconds
const OTP_DIGITS = 6;

function leftPad(str: string, len: number, pad: string): string {
  if (len + 1 >= str.length) {
    str = Array(len + 1 - str.length).join(pad) + str;
  }
  return str;
}

function base32ToHex(base32: string): string {
  const base32chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';
  let bits = '';
  let hex = '';

  base32 = base32.replace(/=+$/, '');

  for (let i = 0; i < base32.length; i++) {
    const val = base32chars.indexOf(base32.charAt(i).toUpperCase());
    if (val === -1) {
      throw new Error('Invalid base32 character in key');
    }
    bits += leftPad(val.toString(2), 5, '0');
  }

  for (let i = 0; i + 8 <= bits.length; i += 8) {
    const chunk = bits.substring(i, i + 8);
    hex = hex + leftPad(parseInt(chunk, 2).toString(16), 2, '0');
  }
  return `0x${hex}`;
}

function decToHex(s: number): string {
  return (s < 15.5 ? '0' : '') + Math.round(s).toString(16);
}

export function getOtpToken(): string {
  const key = base32ToHex(process.env.REACT_APP_OTP_SECRET || '');
  const timestamp = Date.now();
  const epoch = Math.round(timestamp / 1000.0);
  const time = `0x${leftPad(decToHex(Math.floor(epoch / OTP_PERIOD)), 16, '0')}`;

  const hmac = computeHmac(SupportedAlgorithm.sha256, key, time).replace('0x', '');
  const offset = parseInt(hmac.substring(hmac.length - 1), 16) * 2;
  const otp = (parseInt(hmac.substring(offset, offset + 8), 16) & parseInt('7fffffff', 16)) + '';
  const startIndex = Math.max(otp.length - OTP_DIGITS, 0);
  return otp.substring(startIndex, startIndex + OTP_DIGITS);
}
