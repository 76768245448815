import { useEffect, useState } from 'react';
import { bridgeSocketSupportedChains } from '../../api';
import { BridgeEvmNetworkMeta } from '../../types';
import { getPromiseState } from '../../utils/get-promise-state';

let EVM_NETWORKS_PROMISE: Promise<BridgeEvmNetworkMeta[]> | undefined = undefined;

async function fetchNetworks(): Promise<BridgeEvmNetworkMeta[]> {
  if (!EVM_NETWORKS_PROMISE || (await getPromiseState(EVM_NETWORKS_PROMISE)) === 'rejected') {
    EVM_NETWORKS_PROMISE = bridgeSocketSupportedChains();
  }

  return EVM_NETWORKS_PROMISE;
}

export const useEvmNetworks = (): BridgeEvmNetworkMeta[] | null | undefined => {
  const [networks, setNetworks] = useState<BridgeEvmNetworkMeta[] | null | undefined>();

  useEffect(() => {
    let canceled = false;
    setNetworks(null);

    fetchNetworks()
      .then(networks => {
        if (!canceled) {
          setNetworks(networks);
        }
      })
      .catch(error => {
        if (!canceled) {
          setNetworks(undefined);
        }
        console.error(error);
      });

    return () => {
      canceled = true;
    };
  }, []);

  return networks;
};
