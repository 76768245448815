import React, { useMemo } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { useTheme } from '../../state/user/user.hooks';

export function LoadingSkeleton({ children }: { children?: React.ReactNode }): JSX.Element {
  const [isDark] = useTheme();

  const [color, highlightColor] = useMemo(() => {
    if (isDark) {
      return ['#2D2D2F', '#3A3A3C'];
    } else {
      return ['#eee', '#f5f5f5'];
    }
  }, [isDark]);

  return (
    <SkeletonTheme color={color} highlightColor={highlightColor}>
      {children}
    </SkeletonTheme>
  );
}
