import { ChainId, Token } from '@plasma/plasmaswap-sdk';
import { AllSubstringsIndexStrategy, Search } from 'js-search';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchTokens } from '../../api/search-tokens';
import { TOKENS_ITEMS_PER_PAGE } from '../../constants';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { MarketTokenInfo, SerializedToken, WrappedTokenInfo } from '../../types';
import { isAddress } from '../../utils';
import { getTokenTags } from '../../utils/get-token-tags';
import { deserializeToken, serializeToken } from '../../utils/serialize-token';
import { AppDispatch, AppState } from '../index';
import { addFavoriteSerializedToken, fetchPlasmaMarketTokens, removeFavoriteSerializedToken } from './lists.actions';

const MARKET_TOKENS_CACHE: { [chainId in ChainId]?: { [address: string]: WrappedTokenInfo<MarketTokenInfo> } } = {};

const MARKET_TOKENS_SEARCH_INDEX: { [chainId in ChainId]?: Search } = {};

function addToSearchIndex(token: WrappedTokenInfo): void {
  if (!MARKET_TOKENS_SEARCH_INDEX[token.chainId]) {
    const search = new Search('address');
    search.indexStrategy = new AllSubstringsIndexStrategy();
    search.addIndex('name');
    search.addIndex('symbol');

    MARKET_TOKENS_SEARCH_INDEX[token.chainId] = search;
  }

  (MARKET_TOKENS_SEARCH_INDEX[token.chainId] as Search).addDocument({ name: token.name, symbol: token.symbol, address: token.address });
}

/**
 * Json from Git repo
 * https://raw.githubusercontent.com/plasmadlt/plasma-finance-market-tokenlist/main/plasma-finance-market-list.json
 */
export function usePlasmaMarketTokenList(): [boolean, { [address: string]: WrappedTokenInfo<MarketTokenInfo> }] {
  const { chainId } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector<AppState, boolean>((state: AppState) => state.lists.marketTokens.loading);
  const marketTokensListAll = useSelector<AppState, AppState['lists']['marketTokens']['list']>(state => state.lists.marketTokens.list);
  const marketTokensList = useMemo(() => (chainId ? marketTokensListAll[chainId] : {}), [marketTokensListAll, chainId]);

  const tokens = useMemo(() => {
    if (!chainId || !marketTokensList) {
      return {};
    }

    if (!MARKET_TOKENS_CACHE[chainId]) {
      MARKET_TOKENS_CACHE[chainId] = {};
    }

    const addresses = Object.keys(marketTokensList);
    const result: { [address: string]: WrappedTokenInfo<MarketTokenInfo> } = {};
    addresses.forEach(address => {
      if (!MARKET_TOKENS_CACHE[chainId]?.[address]) {
        const tokenInfo = marketTokensList[address];
        const tokenTags = getTokenTags(tokenInfo.tags);
        const token = new WrappedTokenInfo<MarketTokenInfo>(tokenInfo, tokenTags);

        addToSearchIndex(token);
        (MARKET_TOKENS_CACHE[chainId] as { [address: string]: WrappedTokenInfo<MarketTokenInfo> })[address] = token;
      }

      result[address] = (MARKET_TOKENS_CACHE[chainId] as { [address: string]: WrappedTokenInfo<MarketTokenInfo> })[address];
    });

    return result;
  }, [chainId, marketTokensList]);

  // Enable list fetch
  useEffect(() => {
    dispatch(fetchPlasmaMarketTokens());
  }, [dispatch]);

  return [loading, tokens];
}

/**
 * Search tokens in plasma finance tokens list
 * @param search
 */
export function useSearchPlasmaMarketToken(search?: string): [boolean, { [address: string]: WrappedTokenInfo<MarketTokenInfo> }] {
  const { chainId } = useActiveWeb3React();
  const [loading, marketTokens] = usePlasmaMarketTokenList();

  const foundAddresses: string[] | undefined = useMemo(() => {
    if (!chainId || !search || loading) {
      return [];
    }
    return (MARKET_TOKENS_SEARCH_INDEX[chainId]?.search(search) || []).map((i: any) => i.address);
  }, [chainId, loading, search]);

  const foundTokens = useMemo(() => {
    return foundAddresses.reduce<{ [address: string]: WrappedTokenInfo<MarketTokenInfo> }>((map, address) => {
      map[address] = marketTokens[address];
      return map;
    }, {});
  }, [foundAddresses, marketTokens]);

  return useMemo(() => [loading, search === '' ? marketTokens : foundTokens], [foundTokens, loading, marketTokens, search]);
}

/**
 * Search tokes in all lists
 * @param {string|undefined} search
 * Search string. If undefined, it does not make a request and returns an empty array.
 *
 * @param {number} page=0
 * Search results page.
 *
 * @returns {Array}
 * First param if true, then data is loading.
 * Second param is all found currencies list.
 */
export function useSearchApiTokens(search?: string, page = 0): [boolean, WrappedTokenInfo[]] {
  const { chainId } = useActiveWeb3React();
  const [tokens, setTokens] = useState<WrappedTokenInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>();

  const pageValid = useMemo(() => {
    const maxPage = total !== undefined ? Math.floor(total / TOKENS_ITEMS_PER_PAGE) : undefined;
    return maxPage !== undefined && maxPage < page ? maxPage : page;
  }, [page, total]);

  // Delete the results of previous queries after changing the search parameters.
  useEffect(() => setTokens([]), [search]);

  // Fetch tokens with search parameters
  useEffect(() => {
    if (search === undefined || !chainId) {
      setTokens([]);
      setTotal(undefined);
      setLoading(false);
      return;
    }

    const limit = TOKENS_ITEMS_PER_PAGE;
    const offset = pageValid * TOKENS_ITEMS_PER_PAGE;
    const query = { chainId, search, limit, offset };
    const abortController = new AbortController();

    setLoading(true);

    searchTokens(query, abortController.signal)
      .then(result => {
        const tokens = result.result
          .map(i => {
            if (!i.address || (i.chainId && i.chainId !== chainId) || (!i.decimals && i.decimals !== 0)) {
              return null;
            }
            const address = isAddress(i.address);
            if (!address) {
              return null;
            }

            const tokenInfo = {
              chainId: i.chainId || chainId,
              address,
              name: i.name,
              decimals: i.decimals,
              symbol: i.symbol,
              logoURI: i.logoURI?.svg || i.logoURI?.png || i.logoURI?.jpg || i.logoURI?.ipfs || undefined,
              tags: i.tags,
            };
            return new WrappedTokenInfo(tokenInfo, getTokenTags(i.tags));
          })
          .filter(i => !!i) as WrappedTokenInfo[];

        setTokens((old: WrappedTokenInfo[]) => ([] as WrappedTokenInfo[]).concat(old, tokens));
        setTotal(tokens.length ? result.count : 0);
        setLoading(false);
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          setTokens([]);
          setTotal(undefined);
          setLoading(false);
          console.error(error);
        }
      });

    return () => {
      abortController.abort();
    };
  }, [search, pageValid, chainId]);

  return useMemo(() => [loading, tokens], [tokens, loading]);
}

/**
 * User's tokens list manager (functions for add and remove tokens)
 */
export function useFavoritesTokensManager(): { addToken: (token: Token) => void; removeToken: (address: string) => void } {
  const { chainId } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>();

  const addToken = useCallback(
    (token: Token) => {
      const serializedToken: SerializedToken = serializeToken(token);
      dispatch(addFavoriteSerializedToken(serializedToken));
    },
    [dispatch],
  );

  const removeToken = useCallback(
    (address: string) => {
      if (chainId) {
        dispatch(removeFavoriteSerializedToken({ chainId, address }));
      }
    },
    [chainId, dispatch],
  );

  return useMemo(() => ({ addToken, removeToken }), [addToken, removeToken]);
}

/**
 * Search tokens in stored user's tokens
 */
export function useSearchFavoritesTokens(search?: string): { [address: string]: Token } {
  const { chainId } = useActiveWeb3React();
  const allFavoritesTokens = useSelector<AppState, AppState['lists']['favoritesTokens']>(({ lists: { favoritesTokens } }) => favoritesTokens);

  const serializedFavoritesTokens = useMemo(() => {
    return chainId && allFavoritesTokens[chainId] ? allFavoritesTokens[chainId] : {};
  }, [allFavoritesTokens, chainId]);

  return useMemo(() => {
    if (search === undefined) {
      return {};
    }

    const address = isAddress(search);
    if (address) {
      const serializedToken = serializedFavoritesTokens[address];
      return serializedToken ? { [address]: deserializeToken(serializedToken) } : {};
    } else {
      return Object.keys(serializedFavoritesTokens).reduce<{ [address: string]: Token }>((map, address) => {
        const serializedToken = serializedFavoritesTokens[address];
        const symbol = serializedToken.symbol?.toLowerCase() || '';
        const name = serializedToken.name?.toLowerCase() || '';

        if (!search || symbol.includes(search.toLowerCase()) || name.includes(search.toLowerCase())) {
          map[address] = deserializeToken(serializedToken);
        }
        return map;
      }, {});
    }
  }, [search, serializedFavoritesTokens]);
}

/**
 * Returns whether the token was added to favorites or not.
 * @param address
 */
export function useIsFavoriteToken(address?: string): boolean {
  const { chainId } = useActiveWeb3React();
  const allUsersTokensMap = useSelector<AppState, AppState['lists']['favoritesTokens']>(({ lists: { favoritesTokens } }) => favoritesTokens);

  return useMemo(() => {
    if (!chainId) {
      return false;
    }
    const checksummedAddress = isAddress(address);
    if (!checksummedAddress) {
      return false;
    }
    return !!(allUsersTokensMap[chainId] ?? {})[checksummedAddress];
  }, [address, allUsersTokensMap, chainId]);
}
