import { Currency } from '@plasma/plasmaswap-sdk';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../loading/loading';
import { useWrapCallback, WrapType } from '../../hooks/use-wrap-callback';

export interface WrapUnwrapModeProps {
  fromCurrency?: Currency;
  toCurrency?: Currency;
  typedValue?: string;

  resetForm: () => void;
}

export function WrapUnwrapMode({ fromCurrency, toCurrency, typedValue, resetForm }: WrapUnwrapModeProps): JSX.Element {
  const { t } = useTranslation();

  // If the user selected ETH and WETH, the 1:1 exchange mode is enabled (Wrap/Unwrap mode)
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(fromCurrency || undefined, toCurrency || undefined, typedValue);
  const [wrapLoading, setWrapLoading] = useState(false);

  /**
   * Wrap or unwrap NATIVE currency
   */
  const onWrapUnwrap = useCallback(() => {
    if (!onWrap) {
      return;
    }
    setWrapLoading(true);
    onWrap()
      .then(() => {
        resetForm();
        setWrapLoading(false);
      })
      .catch(() => {
        setWrapLoading(false);
      });
  }, [onWrap, resetForm]);

  return (
    <div className="button-block">
      <button className="btn btn-primary btn-lg" disabled={Boolean(wrapInputError) || wrapLoading} onClick={onWrapUnwrap}>
        {wrapLoading ? <Loading size="sm" /> : <span>{wrapInputError ?? (wrapType === WrapType.WRAP ? t('wrap') : wrapType === WrapType.UNWRAP ? t('unwrap') : null)}</span>}
      </button>
    </div>
  );
}
