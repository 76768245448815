import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { AppState } from '../../state';
import { PlasmaTokenInfo } from '../../types';
import { NetworkItemSkeleton } from '../networks-total/network-item-skeleton';
import { AssetItem } from './asset-item';
import './assets-list.scss';

export const AssetsList: FC = () => {
  const { account } = useActiveWeb3React();
  const { assets, loading } = useSelector((state: AppState) => state.portfolioAssets);
  const { t } = useTranslation();

  if (!account || !assets[account]) {
    return null;
  }

  const loadingList = Array(3)
    .fill('')
    .map((v, i) => <NetworkItemSkeleton key={i} />);

  return (
    <div className="assets-block">
      <h2 className="assets-title">{t('assets')}</h2>
      <div className="assets-header">
        <div className="asset">{t('asset')}</div>
        <div className="balance">{t('balance')}</div>
        <div className="value">{t('value')}</div>
      </div>
      <div className="asset-list">
        {!loading
          ? assets[account]?.map((asset: PlasmaTokenInfo) => {
              return <AssetItem key={`${asset.chainId}-${asset.address}`} asset={asset} />;
            })
          : loadingList}
      </div>
    </div>
  );
};
