import { Currency, NATIVE, Token } from '@plasma/plasmaswap-sdk';
import { useMemo } from 'react';
import { useActiveWeb3React } from '../web3/use-active-web3-react';
import { useSearchTokens } from './use-search-tokens';

export function useSearchTokensWithNative(search: string, page = 0): [boolean, Currency[]] {
  const { chainId } = useActiveWeb3React();
  const native = useMemo(() => (chainId ? NATIVE[chainId] : null), [chainId]);

  const [loading, foundTokens]: [boolean, Token[]] = useSearchTokens(search, page);

  const shouldAddNative: boolean = useMemo(() => {
    if (!native?.symbol || search === undefined) {
      return false;
    }
    if (search === '') {
      return true;
    }

    const regExp = native.symbol
      .toLowerCase()
      .split('')
      .map((s, i, arr) => arr.slice(0, i + 1).join(''))
      .join('|');

    return new RegExp(`^(${regExp})$`, 'ig').test(search);
  }, [search, native]);

  return useMemo(() => {
    return [loading, shouldAddNative && native ? [native, ...foundTokens] : foundTokens];
  }, [foundTokens, loading, native, shouldAddNative]);
}
