import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DEFAULT_GAS_PRICE_SPEED, GAS_PRICES_REFRESH_INTERVAL } from '../../constants';
import { useIntervalBetweenCalls } from '../../hooks/use-interval-between-calls';
import { useOnline } from '../../hooks/use-online';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { GasPrices, GsnWeb3ProviderState, TransactionType } from '../../types';
import { AppDispatch } from '../index';
import { updateGasPrices } from './gas-station.actions';
import { useGasPriceSettingsManager, useGetGasPricesCallback, useGsnProviderState, useGsnToken, useMaxSupportedTxType } from './gas-station.hooks';

const GAS_PRICES_REFRESH_INTERVAL_MS = GAS_PRICES_REFRESH_INTERVAL * 1000;

export function GasStationUpdater(): null {
  const { provider, chainId } = useActiveWeb3React();
  const isOnline = useOnline();
  const dispatch = useDispatch<AppDispatch>();
  const [gasPriceSettings, setGasPriceSettings] = useGasPriceSettingsManager();
  const [gsnToken] = useGsnToken();

  const getGasPricesCallback = useGetGasPricesCallback();
  const gsnProviderState = useGsnProviderState();

  const maxSupportedTxType: TransactionType | undefined = useMaxSupportedTxType();

  const updateGasPricesCallback = useCallback(async () => {
    if (!chainId || !provider) {
      throw new Error('Provider is not ready');
    }

    const gasPrices: GasPrices = await getGasPricesCallback();
    dispatch(updateGasPrices(gasPrices));
  }, [chainId, provider, getGasPricesCallback, dispatch]);

  useIntervalBetweenCalls(updateGasPricesCallback, isOnline && gsnProviderState !== GsnWeb3ProviderState.PENDING ? GAS_PRICES_REFRESH_INTERVAL_MS : null);

  // Update the fee token in GSN Web3 provider.
  useEffect(() => {
    if (!provider || gsnToken === null) {
      return;
    }

    provider.setTxFeeToken(gsnToken);
  }, [gsnToken, provider]);

  // Create default gas price settings
  useEffect(() => {
    if (maxSupportedTxType !== undefined && (!gasPriceSettings || gasPriceSettings.type !== maxSupportedTxType)) {
      setGasPriceSettings({ type: maxSupportedTxType, speed: DEFAULT_GAS_PRICE_SPEED });
    }
  }, [gasPriceSettings, maxSupportedTxType, setGasPriceSettings]);

  return null;
}
