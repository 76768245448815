import { Currency, CurrencyAmount, Token } from '@plasma/plasmaswap-sdk';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { useFavoritesTokensManager, useIsFavoriteToken } from '../../state/lists/lists.hooks';
import { useCurrencyBalances } from '../../state/wallets/wallets.hooks';
import { WrappedTokenInfo } from '../../types';
import { formatTokenSymbol } from '../../utils/format-token-symbol';
import { FormattedCurrencyAmount } from '../formatted-currency-amount/formatted-currency-amount';
import { ImgNotFound } from '../img-not-found/img-not-found';
import { Loading } from '../loading/loading';
import { CurrencyLogo } from '../logo/logo';
import { Tooltip } from '../tooltip/tooltip';
import { CommonBases } from './_common-bases';

export interface CurrencyListViewProps {
  currencies: Currency[];
  loading: boolean;
  selected?: Currency | null;
  disabled?: Currency | null;
  onSelect: (currency: Currency) => void;
  onScroll: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
}

export function CurrencyListView({ currencies, loading, selected, disabled, onSelect, onScroll }: CurrencyListViewProps): JSX.Element {
  const { account } = useActiveWeb3React();
  const { t } = useTranslation();
  const divElementRef = useRef<HTMLDivElement>();
  const prevCurrenciesLengthRef = useRef<number>(0);

  const balances = useCurrencyBalances(account ?? undefined, currencies);

  // To reset element scroll position
  useEffect(() => {
    if (currencies.length < prevCurrenciesLengthRef.current) {
      divElementRef.current?.scrollTo(0, 0);
    }
    prevCurrenciesLengthRef.current = currencies.length;
  }, [currencies.length]);

  return (
    <div className="search-currency-scroll-block" ref={divElementRef as any} onScroll={onScroll}>
      <CommonBases onSelect={onSelect} selectedCurrency={selected} />
      <div className="search-currency-sorter">
        <span className="label">{t('token_name')}</span>
      </div>
      <div className="search-currency-list">
        <div className="currency-list-view">
          {currencies.length ? (
            currencies.map((currency, index) => {
              return (
                <CurrencyRow
                  key={index}
                  currency={currency}
                  balance={balances[index]}
                  isSelected={!!(selected && selected.equals(currency))}
                  isDisabled={!!(disabled && disabled.equals(currency))}
                  onSelect={() => onSelect(currency)}
                />
              );
            })
          ) : !loading ? (
            <div className="empty-currency-list">
              <div className="image">
                <ImgNotFound />
              </div>
              <div className="message">
                <span>{t('nothing_found')}</span>
              </div>
            </div>
          ) : null}
          {loading ? (
            <div className="list-loading">
              <Loading size="sm" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function TokenTags({ currency }: { currency: Currency }) {
  if (!(currency instanceof WrappedTokenInfo)) {
    return <span />;
  }

  const tags = currency.tags;
  if (!tags || tags.length === 0) {
    return <span />;
  }

  const tag = tags[0];

  return (
    <div className="currency-tag-container">
      <Tooltip text={tag.description}>
        <div className="tag" key={tag.id}>
          {tag.name}
        </div>
      </Tooltip>
      {tags.length > 1 ? (
        <Tooltip
          text={tags
            .slice(1)
            .map(({ name, description }) => `${name}: ${description}`)
            .join('; \n')}
        >
          <div className="tag">*</div>
        </Tooltip>
      ) : null}
    </div>
  );
}

interface CurrencyRowProps {
  currency: Currency;
  balance?: CurrencyAmount;
  onSelect: () => void;
  isSelected: boolean;
  isDisabled: boolean;
}

function CurrencyRow({ currency, balance, isSelected, isDisabled, onSelect }: CurrencyRowProps) {
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();
  const { addToken, removeToken } = useFavoritesTokensManager();
  const isFavoriteToken = useIsFavoriteToken(currency instanceof Token ? currency.address : undefined);

  return (
    <div className={`search-currency-item${isDisabled ? ' disabled' : ''}${isSelected ? ' selected' : ''}`} onClick={() => (isSelected ? null : onSelect())}>
      <div className="currency-logo">
        <CurrencyLogo currency={currency} size={22} />
      </div>
      <div className="currency-name">
        <div className="symbol" title={currency.name}>
          <span>{formatTokenSymbol(currency)}</span>
        </div>
        <div className="info">
          <span>{currency.name}</span>
        </div>
      </div>
      <TokenTags currency={currency} />
      <div className="currency-balance">
        {balance ? (
          <span title={balance.toExact()} className="balance">
            <FormattedCurrencyAmount amount={balance} hideSymbol />
          </span>
        ) : account ? (
          <Loading size="sm" />
        ) : null}
      </div>
      {currency.isToken ? (
        <div className="actions" onClick={event => event.stopPropagation()}>
          {isFavoriteToken ? (
            <Tooltip text={t('remove_token_from_list')} placement="top-end">
              <button className="btn btn-icon" onClick={() => removeToken((currency as Token).address)}>
                <i className="pi pi-disconnect" />
              </button>
            </Tooltip>
          ) : (
            <Tooltip text={t('add_token_to_list')} placement="top-end">
              <button className="btn btn-icon" onClick={() => addToken(currency as Token)}>
                <i className="pi pi-plus" />
              </button>
            </Tooltip>
          )}
        </div>
      ) : null}
    </div>
  );
}
