import { ChainId } from '@plasma/plasmaswap-sdk';
import { Arbitrum, Avalanche, BinanceSmartChain, Bitcoin, BitcoinCash, DigiByte, Dogecoin, Ethereum, Fantom, Filecoin, Polygon, Terra, Zcash } from '@renproject/chains';
import Hop from '../assets/images/hop.png';
import { BridgeRenNetwork, BridgeRenNetworkId, BridgeRenNetworkMeta } from '../types';
import { ETH_ADDRESS } from './common';

export const REN_NETWORKS: { [networkId in BridgeRenNetworkId]: BridgeRenNetwork } = {
  [BridgeRenNetworkId.Ethereum]: Ethereum,
  [BridgeRenNetworkId.BinanceSmartChain]: BinanceSmartChain,
  [BridgeRenNetworkId.Polygon]: Polygon,
  [BridgeRenNetworkId.Fantom]: Fantom,
  [BridgeRenNetworkId.Arbitrum]: Arbitrum,
  [BridgeRenNetworkId.Avalanche]: Avalanche,
  [BridgeRenNetworkId.Bitcoin]: Bitcoin,
  [BridgeRenNetworkId.BitcoinCash]: BitcoinCash,
  [BridgeRenNetworkId.Dogecoin]: Dogecoin,
  [BridgeRenNetworkId.Filecoin]: Filecoin,
  [BridgeRenNetworkId.Terra]: Terra,
  [BridgeRenNetworkId.Zcash]: Zcash,
  [BridgeRenNetworkId.DigiByte]: DigiByte,
};

export const REN_NETWORKS_META: BridgeRenNetworkMeta[] = [
  { value: BridgeRenNetworkId.Ethereum, label: 'Ethereum', logo: '/images/ethereum.svg' },
  { value: BridgeRenNetworkId.BinanceSmartChain, label: 'Binance', logo: '/images/binance.svg' },
  { value: BridgeRenNetworkId.Polygon, label: 'Polygon', logo: '/images/polygon.svg' },
  { value: BridgeRenNetworkId.Fantom, label: 'Fantom', logo: '/images/fantom.svg' },
  { value: BridgeRenNetworkId.Arbitrum, label: 'Arbitrum', logo: '/images/arbitrum.svg' },
  { value: BridgeRenNetworkId.Avalanche, label: 'Avalanche', logo: '/images/avalanche.svg' },
  { value: BridgeRenNetworkId.Bitcoin, label: 'Bitcoin', logo: '/images/currencies/btc.png' },
  { value: BridgeRenNetworkId.BitcoinCash, label: 'BitcoinCash', logo: '/images/currencies/bch.png' },
  { value: BridgeRenNetworkId.Dogecoin, label: 'Dogecoin', logo: '/images/currencies/doge.png' },
  { value: BridgeRenNetworkId.Filecoin, label: 'FileCoin', logo: '/images/currencies/fil.png' },
  { value: BridgeRenNetworkId.Terra, label: 'Luna', logo: '/images/currencies/luna.png' },
  { value: BridgeRenNetworkId.Zcash, label: 'Zcash', logo: '/images/currencies/zec.png' },
  { value: BridgeRenNetworkId.DigiByte, label: 'DigiByte', logo: '/images/currencies/dgb.png' },
];

export const REN_EVM_NETWORK_IDS: BridgeRenNetworkId[] = [
  BridgeRenNetworkId.Ethereum,
  BridgeRenNetworkId.BinanceSmartChain,
  BridgeRenNetworkId.Polygon,
  BridgeRenNetworkId.Fantom,
  BridgeRenNetworkId.Arbitrum,
  BridgeRenNetworkId.Avalanche,
];

export const REN_EVM_ASSET_PRICE_CONF: {
  [symbol: string]: { chainId: ChainId; address: string; coingeckoId?: string } | { coingeckoId: string; chainId?: ChainId; address?: string };
} = {
  ETH: { chainId: ChainId.MAINNET, address: ETH_ADDRESS },
  DAI: { chainId: ChainId.MAINNET, address: '0x6b175474e89094c44da98b954eedeac495271d0f' },
  REN: { chainId: ChainId.MAINNET, address: '0x408e41876cccdc0f92210600ef50372656052a38' },
  USDC: { chainId: ChainId.MAINNET, address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48' },
  USDT: { chainId: ChainId.MAINNET, address: '0xdac17f958d2ee523a2206206994597c13d831ec7' },
  EURT: { chainId: ChainId.MAINNET, address: '0xc581b735a1688071a1746c968e0798d642ede491' },
  BUSD: { chainId: ChainId.MAINNET, address: '0x4fabb145d64652a948d72533023f6e7a623c7c53' },
  MIM: { chainId: ChainId.MAINNET, address: '0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3' },
  CRV: { chainId: ChainId.MAINNET, address: '0xd533a949740bb3306d119cc777fa900ba034cd52' },
  LINK: { chainId: ChainId.MAINNET, address: '0x514910771af9ca656af840dff83e8264ecf986ca' },
  UNI: { chainId: ChainId.MAINNET, address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984' },
  SUSHI: { chainId: ChainId.MAINNET, address: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2' },
  FTT: { chainId: ChainId.MAINNET, address: '0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9' },
  ROOK: { chainId: ChainId.MAINNET, address: '0xfa5047c9c78b8877af97bdcb85db743fd7313d4a' },
  BADGER: { chainId: ChainId.MAINNET, address: '0x3472a5a71965499acd81997a54bba8d852c6e53d' },
  KNC: { chainId: ChainId.MAINNET, address: '0xdd974d5c2e2928dea5f71b9825b8b646686bd200' },
  BNB: { chainId: ChainId.BSC, address: ETH_ADDRESS },
  MATIC: { chainId: ChainId.MATIC, address: ETH_ADDRESS },
  FTM: { chainId: ChainId.FANTOM, address: ETH_ADDRESS },
  ArbETH: { chainId: 42161 as ChainId, address: ETH_ADDRESS },
  AVAX: { chainId: ChainId.AVALANCHE, address: ETH_ADDRESS },
  BTC: { coingeckoId: 'bitcoin' },
  BCH: { coingeckoId: 'bitcoin-cash' },
  DOGE: { coingeckoId: 'dogecoin' },
  FIL: { coingeckoId: 'filecoin' },
  LUNA: { coingeckoId: 'terra-luna-2' },
  ZEC: { coingeckoId: 'zcash' },
  DGB: { coingeckoId: 'digibyte' },
};

export const REN_ASSET_NAME_BY_SYMBOL: { [symbol: string]: string } = {
  ETH: 'Ethereum',
  DAI: 'Dai Stablecoin',
  EURT: 'Euro Tether',
  REN: 'Republic Token',
  MIM: 'Magic Internet Money',
  CRV: 'Curve DAO Token',
  LINK: 'ChainLink Token',
  UNI: 'Uniswap',
  SUSHI: 'SushiToken',
  FTT: 'FTT Token',
  BADGER: 'Badger',
  KNC: 'Kyber Network Crystal',
  BTC: 'Bitcoin',
  BCH: 'BitcoinCash',
  DOGE: 'Dogecoin',
  FIL: 'Filecoin',
  LUNA: 'Terra',
  ZEC: 'Zcash',
  DGB: 'DigiByte',
};

export const EVM_BRIDGE_IMAGES: { [name: string]: string } = {
  hop: Hop,
};
