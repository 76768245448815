import { Placement } from '@popperjs/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../tooltip/tooltip';
import './info-helper.scss';

export type InfoHelperBackdrop = 'white' | 'gray';

export interface InfoHelperProps {
  text?: string;
  placement?: Placement;
  backdrop?: InfoHelperBackdrop;
  children?: React.ReactNode;
}

export function InfoHelper({ text, placement = 'top', backdrop = 'gray', children, ...rest }: InfoHelperProps): JSX.Element {
  const [show, setShow] = useState<boolean>(false);
  const { t } = useTranslation();

  const content = useMemo(() => {
    return text ? t(text) : children;
  }, [children, t, text]);

  const open = useCallback(() => setShow(true), [setShow]);
  const close = useCallback(() => setShow(false), [setShow]);

  return (
    <span {...rest}>
      <Tooltip text={content} placement={placement} toggle={show ? 'show' : 'hide'}>
        <span className={`info-helper backdrop-${backdrop}`} onClick={open} onMouseEnter={open} onMouseLeave={close}>
          <i className="pi pi-info ico" />
        </span>
      </Tooltip>
    </span>
  );
}
