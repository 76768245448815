import { NEVER_RELOAD } from '../state/multicall/multicall.helpers';
import { useSingleCallResult } from '../state/multicall/multicall.hooks';
import { useArgentWalletDetectorContract } from './use-contract';
import { useActiveWeb3React } from './web3/use-active-web3-react';

export function useIsArgentWallet(): boolean {
  const { account } = useActiveWeb3React();
  const argentWalletDetector = useArgentWalletDetectorContract();
  const call = useSingleCallResult(argentWalletDetector, 'isArgentWallet', [account ?? undefined], NEVER_RELOAD);
  return call?.result?.[0] ?? false;
}
