import { Currency, Native, Price, Token, ZERO_ADDRESS } from '@plasma/plasmaswap-sdk';
import Big from 'big.js';
import { useEffect, useState } from 'react';
import { bridgeSocketTokenPrice } from '../../api';
import { ETH_ADDRESS } from '../../constants';

export const useEvmTokenPrice = (currency?: Currency): Price | null | undefined => {
  const [price, setPrice] = useState<Price | null | undefined>();

  useEffect(() => {
    if (!currency) {
      return setPrice(undefined);
    }

    const abortController = new AbortController();
    const address = currency instanceof Native ? ETH_ADDRESS : currency.address;

    setPrice(null);

    bridgeSocketTokenPrice(address, currency.chainId, abortController.signal)
      .then(result => {
        const usd = new Token(result.chainId, ZERO_ADDRESS, result.decimals, 'USD', 'USD');
        setPrice(new Price(currency, usd, '1000000000', Big(result.tokenPrice).times(1_000_000_000).toFixed(0)));
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          return;
        }
        setPrice(undefined);
        console.error(error);
      });

    return () => {
      abortController.abort();
    };
  }, [currency]);

  return price;
};
