import { ChainId } from '@plasma/plasmaswap-sdk';
import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit';

export const fetchWalletTotal: Readonly<{
  pending: ActionCreatorWithPayload<{ account: string }>;
  resolved: ActionCreatorWithPayload<{ account: string; total: number }>;
  finished: ActionCreatorWithPayload<{ account: string }>;
}> = {
  pending: createAction('wallets/fetchWalletTotal/pending'),
  resolved: createAction('wallets/fetchWalletTotal/resolved'),
  finished: createAction('wallets/fetchWalletTotal/finished'),
};

export const addTokenToWatchBalance = createAction<{ chainId: ChainId; address: string }>('wallets/addTokenToWatchBalance');
