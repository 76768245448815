import { ChainId, Trade0xLiquiditySource } from '@plasma/plasmaswap-sdk';

export const ZERO_EX_API_HOSTS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: `${process.env.REACT_APP_HYPER_API}/api.0x`, // proxy to https://api.0x.org,
  // [ChainId.GOERLI]: `${process.env.REACT_APP_HYPER_API}/goerli.api.0x`, // proxy to https://goerli.api.0x.org,
  [ChainId.MATIC]: `${process.env.REACT_APP_HYPER_API}/polygon.api.0x`, // etc,
  // [ChainId.MUMBAI]: `${process.env.REACT_APP_HYPER_API}/mumbai.api.0x`,
  [ChainId.BSC]: `${process.env.REACT_APP_HYPER_API}/bsc.api.0x`,
  [ChainId.FANTOM]: `${process.env.REACT_APP_HYPER_API}/fantom.api.0x`,
  [ChainId.CELO]: `${process.env.REACT_APP_HYPER_API}/celo.api.0x`,
  [ChainId.AVALANCHE]: `${process.env.REACT_APP_HYPER_API}/avalanche.api.0x`,
  [ChainId.OPTIMISM]: `${process.env.REACT_APP_HYPER_API}/optimism.api.0x`,
  // [ChainId.ARBITRUM]: `${process.env.REACT_APP_HYPER_API}/arbitrum.api.0x`,
  // [ChainId.BASE]: `${process.env.REACT_APP_HYPER_API}/base.api.0x`,
};

/**
 * Liquidity sources map
 */
export const ZERO_EX_SWAP_SOURCE_NAME: { [provider in Trade0xLiquiditySource]: string } = {
  [Trade0xLiquiditySource.ZeroEx]: '0x',
  [Trade0xLiquiditySource.Native]: 'Native',
  [Trade0xLiquiditySource.Uniswap]: 'Uniswap',
  [Trade0xLiquiditySource.UniswapV2]: 'Uniswap V2',
  [Trade0xLiquiditySource.UniswapV3]: 'Uniswap V3',
  [Trade0xLiquiditySource.Eth2Dai]: 'ETH to DAI',
  [Trade0xLiquiditySource.Kyber]: 'Kyber',
  [Trade0xLiquiditySource.Curve]: 'Curve',
  [Trade0xLiquiditySource.CurveV2]: 'Curve V2',
  [Trade0xLiquiditySource.LiquidityProvider]: 'Liquidity Provider',
  [Trade0xLiquiditySource.MultiBridge]: 'MultiBridge',
  [Trade0xLiquiditySource.Balancer]: 'Balancer',
  [Trade0xLiquiditySource.BalancerV2]: 'Balancer V2',
  [Trade0xLiquiditySource.Cream]: 'CREAM',
  [Trade0xLiquiditySource.Bancor]: 'Bancor',
  [Trade0xLiquiditySource.BancorV3]: 'Bancor V3',
  [Trade0xLiquiditySource.MakerPsm]: 'MakerPSM',
  [Trade0xLiquiditySource.MStable]: 'mStable',
  [Trade0xLiquiditySource.Mooniswap]: 'Mooniswap',
  [Trade0xLiquiditySource.MultiHop]: 'HyperDEX',
  [Trade0xLiquiditySource.Shell]: 'Shell',
  [Trade0xLiquiditySource.Swerve]: 'Swerve',
  [Trade0xLiquiditySource.SnowSwap]: 'SnowSwap',
  [Trade0xLiquiditySource.SushiSwap]: 'SushiSwap',
  [Trade0xLiquiditySource.Synapse]: 'Synapse',
  [Trade0xLiquiditySource.Dodo]: 'DODO',
  [Trade0xLiquiditySource.DodoV2]: 'DODO V2',
  [Trade0xLiquiditySource.CryptoCom]: 'CryptoCom',
  [Trade0xLiquiditySource.Linkswap]: 'Linkswap',
  [Trade0xLiquiditySource.KyberDmm]: 'KyberDMM',
  [Trade0xLiquiditySource.Smoothy]: 'Smoothy',
  [Trade0xLiquiditySource.Component]: 'Component',
  [Trade0xLiquiditySource.Saddle]: 'Saddle',
  [Trade0xLiquiditySource.XSigma]: 'xSigma',
  [Trade0xLiquiditySource.Lido]: 'Lido',
  [Trade0xLiquiditySource.ShibaSwap]: 'ShibaSwap',
  [Trade0xLiquiditySource.Clipper]: 'Clipper',
  [Trade0xLiquiditySource.PancakeSwap]: 'PancakeSwap',
  [Trade0xLiquiditySource.PancakeSwapV2]: 'PancakeSwap V2',
  [Trade0xLiquiditySource.BakerySwap]: 'BakerySwap',
  [Trade0xLiquiditySource.Nerve]: 'Nerve',
  [Trade0xLiquiditySource.Belt]: 'Belt',
  [Trade0xLiquiditySource.Ellipsis]: 'Ellipsis',
  [Trade0xLiquiditySource.ApeSwap]: 'ApeSwap',
  [Trade0xLiquiditySource.CafeSwap]: 'CafeSwap',
  [Trade0xLiquiditySource.CheeseSwap]: 'CheeseSwap',
  [Trade0xLiquiditySource.JulSwap]: 'JulSwap',
  [Trade0xLiquiditySource.ACryptos]: 'ACryptoS',
  [Trade0xLiquiditySource.QuickSwap]: 'QuickSwap',
  [Trade0xLiquiditySource.ComethSwap]: 'ComethSwap',
  [Trade0xLiquiditySource.Dfyn]: 'Dfyn',
  [Trade0xLiquiditySource.WaultSwap]: 'WaultSwap',
  [Trade0xLiquiditySource.Polydex]: 'Polydex',
  [Trade0xLiquiditySource.FirebirdOneSwap]: 'FirebirdOneSwap',
  [Trade0xLiquiditySource.JetSwap]: 'JetSwap',
  [Trade0xLiquiditySource.IronSwap]: 'IronSwap',
  [Trade0xLiquiditySource.Pangolin]: 'Pangolin',
  [Trade0xLiquiditySource.TraderJoe]: 'TraderJoe',
  [Trade0xLiquiditySource.Beethovenx]: 'Beethovenx',
  [Trade0xLiquiditySource.MorpheusSwap]: 'MorpheusSwap',
  [Trade0xLiquiditySource.SpiritSwap]: 'SpiritSwap',
  [Trade0xLiquiditySource.UbeSwap]: 'UbeSwap',
  [Trade0xLiquiditySource.BiSwap]: 'BiSwap',
  [Trade0xLiquiditySource.AaveV2]: 'Aave V2',
  [Trade0xLiquiditySource.GMX]: 'GMX',
  [Trade0xLiquiditySource.Platypus]: 'Platypus',
  [Trade0xLiquiditySource.Geist]: 'Geist',
  [Trade0xLiquiditySource.SpookySwap]: 'SpookySwap',
  [Trade0xLiquiditySource.Yoshi]: 'Yoshi',
  [Trade0xLiquiditySource.MobiusMoney]: 'MobiusMoney',
  [Trade0xLiquiditySource.KnightSwap]: 'KnightSwap',
  [Trade0xLiquiditySource.MDex]: 'MDex',
  [Trade0xLiquiditySource.MeshSwap]: 'MeshSwap',
  [Trade0xLiquiditySource.Velodrome]: 'Velodrome',
};

/**
 * The addresses of our proxy contracts, to collect the commission.
 */
export const HYPER_DEX_ROUTER_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: '0xAd17d367679f0DBdf23209A70640Cff3c58f4E99',
  [ChainId.BSC]: '0x4DB8B4784B7A3eC4745c90e6E4D16C6caCDEc862',
  [ChainId.MATIC]: '0x70cD88f92203B1243f1Fa9c6DeD28FeF94101EFB',
  [ChainId.AVALANCHE]: '0x5AdDFfc7C28f5582Ca0cf4094dCc54c7Dd7D3c52',
  [ChainId.FANTOM]: '0xefc05c196B49eb0646669516CB077365a866B282',
  // [ChainId.CELO]: '0xbD25F0E20aBD1E0Acb157e1f23B6f058ea4Bc53D',
  // [ChainId.ROPSTEN]: '0x34fb8116b7add1441d7197cf71a2ec48f3b055bd',
  // [ChainId.KOVAN]: '0x7ADF6d20A1D989c2d0c92bAfCc74eAE41622E1F4',
  // [ChainId.ROPSTEN]: '0x15f8f5df5AfC7b0Ebc25488f845c7C3776a1cD28',
};

/**
 * External 0x proxy contracts
 */
export const ZERO_EX_PROXY_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
  [ChainId.BSC]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
  [ChainId.MATIC]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
  [ChainId.AVALANCHE]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
  [ChainId.FANTOM]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
  [ChainId.CELO]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
  [ChainId.ROPSTEN]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
  [ChainId.KOVAN]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
};
