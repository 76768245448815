import { TransactionResponse } from '@ethersproject/providers';
import { Currency, NATIVE, WNATIVE } from '@plasma/plasmaswap-sdk';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTransactionAdder } from '../state/transactions/transactions.hooks';
import { useCurrencyBalance } from '../state/wallets/wallets.hooks';
import { formatAmount } from '../utils/format-amount';
import { formatTokenSymbol } from '../utils/format-token-symbol';
import { tryParseAmount } from '../utils/try-parse-amount';
import { useWETHContract } from './use-contract';
import { useActiveWeb3React } from './web3/use-active-web3-react';

export enum WrapType {
  NOT_APPLICABLE,
  WRAP,
  UNWRAP,
}

export interface WrapCallback {
  wrapType: WrapType;
  execute?: () => Promise<TransactionResponse>;
  inputError?: string;
}

const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE };

/**
 * Returns true, if inputCurrency === WETH and outputCurrency === ETH or vice versa
 * @param inputCurrency
 * @param outputCurrency
 */
export function useIsWrapMode(inputCurrency?: Currency, outputCurrency?: Currency): boolean {
  const { chainId } = useActiveWeb3React();

  return useMemo(() => {
    if (!chainId || !inputCurrency || !outputCurrency) {
      return false;
    }

    return (inputCurrency === NATIVE[chainId] && WNATIVE[chainId].equals(outputCurrency)) || (WNATIVE[chainId].equals(inputCurrency) && outputCurrency === NATIVE[chainId]);
  }, [chainId, inputCurrency, outputCurrency]);
}

/**
 * Given the selected input and output currency, return a wrap callback
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 * @param typedValue the user input value
 */
export function useWrapCallback(inputCurrency?: Currency, outputCurrency?: Currency, typedValue?: string): WrapCallback {
  const { chainId, account } = useActiveWeb3React();
  const { t } = useTranslation();
  const wethContract = useWETHContract();
  const balance = useCurrencyBalance(account ?? undefined, inputCurrency);
  const addTransaction = useTransactionAdder();

  // We can always parse the amount typed as the input currency, since wrapping is 1:1
  const inputAmount = useMemo(() => tryParseAmount(typedValue, inputCurrency), [inputCurrency, typedValue]);

  return useMemo(() => {
    if (!wethContract || !chainId || !inputCurrency || !outputCurrency) {
      return NOT_APPLICABLE;
    }

    const wrapType =
      inputCurrency === NATIVE[chainId] && WNATIVE[chainId].equals(outputCurrency)
        ? WrapType.WRAP
        : WNATIVE[chainId].equals(inputCurrency) && outputCurrency === NATIVE[chainId]
        ? WrapType.UNWRAP
        : WrapType.NOT_APPLICABLE;
    const sufficientBalance = inputAmount && balance && !balance.lessThan(inputAmount);

    // Insufficient balance
    if (!sufficientBalance) {
      return {
        wrapType,
        inputError: t('insufficient_balance', { code: formatTokenSymbol(inputCurrency) }),
      };
    }

    if (!inputAmount) {
      return { wrapType };
    }

    // Wrap
    if (wrapType === WrapType.WRAP) {
      return {
        wrapType: WrapType.WRAP,
        execute: () => {
          return wethContract.deposit({ value: `0x${inputAmount.raw.toString(16)}` }).then((txReceipt: TransactionResponse) => {
            addTransaction(txReceipt, { summary: `Wrap ${formatAmount(inputAmount.toExact(), { maxDecimals: 6 })} ETH to WETH` });
            return txReceipt;
          });
        },
      };
    }

    // Unwrap
    if (wrapType === WrapType.UNWRAP) {
      return {
        wrapType: WrapType.UNWRAP,
        execute: () => {
          return wethContract.withdraw(`0x${inputAmount.raw.toString(16)}`).then((txReceipt: TransactionResponse) => {
            addTransaction(txReceipt, { summary: `Unwrap ${formatAmount(inputAmount.toExact(), { maxDecimals: 6 })} WETH to ETH` });
            return txReceipt;
          });
        },
      };
    }

    return NOT_APPLICABLE;
  }, [t, wethContract, chainId, inputCurrency, outputCurrency, inputAmount, balance, addTransaction]);
}
