import { useEffect, useRef } from 'react';

/**
 * Unlike useInterval, it starts the timer only after the promise is completed
 * @param callback
 * @param delay
 */
export function useIntervalBetweenCalls(callback: () => Promise<any>, delay: null | number): void {
  const savedCallback = useRef<() => Promise<any>>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const callback = savedCallback.current;
    if (!callback || delay === null) {
      return;
    }

    let timeout: NodeJS.Timeout | undefined = undefined;

    function tick(): void {
      if (!callback || delay === null) {
        return;
      }
      callback().then(() => {
        timeout = setTimeout(tick, delay);
      });
    }

    tick();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [delay]);
}
