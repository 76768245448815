import { CurrencyAmount } from '@plasma/plasmaswap-sdk';
import React, { useMemo } from 'react';
import { formatAmount, FormatAmountSettings } from '../../utils/format-amount';
import { formatTokenSymbol } from '../../utils/format-token-symbol';

export interface FormattedCurrencyAmountProps extends FormatAmountSettings {
  amount?: CurrencyAmount;
  hideSymbol?: boolean; // Default false
  replacerForEmpty?: string;
}

export function FormattedCurrencyAmount(props: FormattedCurrencyAmountProps): JSX.Element {
  const { amount, maxDecimals, groupSeparator, hideSymbol, preferredStringLength, decimalPlaces, replacerForEmpty = '' } = props;

  const formattedAmount = useMemo(() => {
    const symbol = !hideSymbol && amount?.currency ? ` ${formatTokenSymbol(amount.currency)}` : '';
    let result: string | null = null;

    try {
      const strAmount = amount?.toExact();
      result = formatAmount(strAmount, { decimalPlaces, preferredStringLength, maxDecimals, groupSeparator });
    } catch (e) {
      console.error('Currency amount parse failed');
      console.error(e);
    }

    return result ? `${result}${symbol}` : replacerForEmpty;
  }, [hideSymbol, amount, replacerForEmpty, maxDecimals, groupSeparator, preferredStringLength, decimalPlaces]);

  return <>{formattedAmount}</>;
}
