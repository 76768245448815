import { ChainId } from '@plasma/plasmaswap-sdk';
import React, { FC, useCallback } from 'react';
import { AllChainId, ExtraChainId } from '../../types';
import { NETWORK_ICON } from '../../constants';
import XDAI from '../../assets/images/xdai.svg';
import ARBITRUM from '../../assets/images/arbitrum.svg';
import AVALANCHE from '../../assets/images/avalanche.svg';
import CELO from '../../assets/images/celo.svg';
import FTM from '../../assets/images/fantom.svg';
import HECO from '../../assets/images/heco.svg';
import MOONRIVER from '../../assets/images/moonriver.svg';
import OEC from '../../assets/images/oec.svg';
import OPTIMISM from '../../assets/images/optimism.svg';

export interface NetworkLogoProps {
  size?: number;
  chainId?: AllChainId;
  alt?: string;
}

const defaultAlt = 'networkLogo';

export const NetworkLogo: FC<NetworkLogoProps> = props => {
  const { size = 24, chainId, alt = defaultAlt } = props;
  const getChainLogo = useCallback(chainId => {
    switch (chainId) {
      case ExtraChainId.XDAI:
        return { src: XDAI, alt: 'XDAI' };
      case ChainId.FANTOM:
        return { src: FTM, alt: 'FTM' };
      case ExtraChainId.OEC:
        return { src: OEC, alt: 'OEC' };
      case ExtraChainId.HECO:
        return { src: HECO, alt: 'HECO' };
      case ChainId.AVALANCHE:
        return { src: AVALANCHE, alt: 'AVALANCHE' };
      case ChainId.OPTIMISM:
        return { src: OPTIMISM, alt: 'OPTIMISM' };
      case ExtraChainId.ARBITRUM:
        return { src: ARBITRUM, alt: 'ARBITRUM' };
      case ExtraChainId.MOONRIVER:
        return { src: MOONRIVER, alt: 'MOONRIVER' };
      case ChainId.CELO:
        return { src: CELO, alt: 'CELO' };
      default:
        return null;
    }
  }, []);

  if (chainId) {
    if (NETWORK_ICON[chainId]) {
      return <img alt={alt} src={NETWORK_ICON[chainId]} style={{ width: size, minWidth: size, height: size }} />;
    } else {
      const chainLogo = getChainLogo(chainId);
      if (chainLogo) {
        return <img alt={chainLogo.alt} src={chainLogo.src} style={{ width: size, minWidth: size, height: size }} />;
      }
    }
  }
  return null;
};
