import React from 'react';
import './img-not-found.scss';

export interface ImgNotFoundProps {
  width?: string;
  height?: string;
}

export function ImgNotFound({ width, height }: ImgNotFoundProps): JSX.Element {
  return (
    <svg className="img-not-found" style={{ width, height }} xmlns="http://www.w3.org/2000/svg" width="122" height="72" viewBox="0 0 122 72" fill="none" version="1.1">
      <circle className="filled-circle" cx="65" cy="36" r="36" />
      <g style={{ fill: 'none' }} id="g902" transform="matrix(1.0007924,0,0,1.0004056,-0.05685859,-0.01324854)">
        <circle className="dashed-circle" cx="36" cy="36" r="35" strokeWidth="2" strokeDasharray="4, 4" />
      </g>
      <g style={{ fill: 'none' }} id="g958" transform="translate(82.5,17.5)">
        <circle className="loupe" cx="17" cy="17" r="15" strokeWidth="3" strokeLinecap="round" />
        <path className="loupe-handle" d="M 27.5,27.5 38,38" strokeWidth="3" strokeLinecap="round" />
      </g>
    </svg>
  );
}
