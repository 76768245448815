import { Currency, CurrencyAmount, Native, Token } from '@plasma/plasmaswap-sdk';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDebounce } from '../../hooks/use-debounce';
import { BridgeAssetMeta } from '../../types';
import { FormattedCurrencyAmount } from '../formatted-currency-amount/formatted-currency-amount';
import { LoadingSkeleton } from '../loading-skeleton/loading-skeleton';
import { CurrencyLogo, Logo } from '../logo/logo';
import { NumericalInput } from '../numerical-input/numerical-input';
import './bridge-asset-input-panel.scss';

export interface BridgeAssetInputPanelProps {
  id: string;
  label?: string | JSX.Element;
  /**
   * Input value stuff
   */
  value: string;
  debounce?: number;
  onUserInput?: (value: string) => void;
  /**
   * Selected asset
   */
  asset?: BridgeAssetMeta | Currency | null;
  onClickAsset?: () => void;
  disableAssetSelect?: boolean;
  /**
   * Max button stuff
   */
  showMaxButton?: boolean;
  onMax?: () => void;

  balance?: CurrencyAmount | null;

  isError?: boolean;
  readOnly?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

export function BridgeAssetInputPanel(props: BridgeAssetInputPanelProps): JSX.Element {
  const { id, label, value, debounce = 500, onUserInput, asset, onClickAsset, disableAssetSelect, showMaxButton, onMax, balance, isError, readOnly, loading, disabled } = props;
  const { t } = useTranslation();
  const [innerValue, setInnerValue] = useState(value);
  const debouncedInnerValue = useDebounce(innerValue, debounce);

  const onUserInputRef = useRef(onUserInput);
  onUserInputRef.current = onUserInput;

  // Update inner value
  useEffect(() => setInnerValue(value), [value]);

  // Call user input callback with debounced value
  useEffect(() => {
    onUserInputRef?.current?.(debouncedInnerValue);
  }, [debouncedInnerValue]);

  return (
    <div className={`bridge-asset-input-panel ${isError ? 'is-error' : ''}`} id={id}>
      {loading ? (
        <div className="loading">
          <div className="backdrop" />
          <i className="pi pi-loader pi-rotate" />
        </div>
      ) : null}

      <div className="label-row">
        <span className="label">{label}</span>
        <span onClick={() => showMaxButton && onMax && onMax()} className={`balance ${!showMaxButton ? 'max-disabled' : ''}`}>
          {balance === null ? (
            <LoadingSkeleton>
              <Skeleton height={16} width={90} />
            </LoadingSkeleton>
          ) : balance ? (
            <>
              <span>{`${t('balance')}:`}</span>
              <span>&nbsp;</span>
              <FormattedCurrencyAmount amount={balance} maxDecimals={11} hideSymbol />
            </>
          ) : null}
        </span>
      </div>
      <div className="input-row">
        <NumericalInput
          className="token-amount-input"
          decimals={asset?.decimals}
          value={innerValue}
          readOnly={readOnly}
          onUserInput={setInnerValue}
          disabled={disabled || !asset}
        />

        {showMaxButton && (
          <button className="btn btn-max" onClick={onMax}>
            <span>MAX</span>
          </button>
        )}

        {asset === null ? (
          <LoadingSkeleton>
            <Skeleton height={32} width={120} />
          </LoadingSkeleton>
        ) : asset ? (
          <button className={`btn btn-currency-selector ${disabled || disableAssetSelect ? 'disabled' : ''}`} onClick={() => !disabled && !disableAssetSelect && onClickAsset?.()}>
            <div className="token-logo">
              {asset instanceof Token || asset instanceof Native ? (
                <CurrencyLogo currency={asset} size={24} />
              ) : (
                <Logo currency={asset.symbol?.replace(/^ren/, '')} size={24} fallbackUrl={asset.logoURI} />
              )}
            </div>
            <span className="token-name">{asset.symbol}</span>
            {!disableAssetSelect ? <span className="drop-down" /> : null}
          </button>
        ) : (
          <button className="btn btn-primary btn-not-selected" onClick={() => !disableAssetSelect && onClickAsset?.()}>
            <span>{t('select_token')}</span>
            {!disableAssetSelect && <span className="drop-down" />}
          </button>
        )}
      </div>
    </div>
  );
}
