import { ChainId } from '@plasma/plasmaswap-sdk';
import { EVMNetworkConfig } from '@renproject/chains-ethereum';
import { Gateway, GatewayTransaction } from '@renproject/ren';
import Big from 'big.js';
import Lottie from 'lottie-react';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QRCode } from 'react-qrcode';
import Success from '../../assets/animations/loader-confirmed.json';
import Loader from '../../assets/animations/loader-transparent.json';
import NetworkConfirmation from '../../assets/animations/network-confirmation.json';
import { REN_EVM_NETWORK_IDS, REN_NETWORKS_META } from '../../constants';
import { useRenNetworkMeta } from '../../hooks/bridge/use-ren-network-meta';
import { useChainSelector } from '../../hooks/use-chain-selector';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { BridgeAssetMeta, BridgeRenNetworkId } from '../../types';
import { formatAmount } from '../../utils/format-amount';
import { getViewOnScanText } from '../../utils/get-view-on-scan-text';
import { BtnCopy } from '../btn-copy/btn-copy';
import { ExternalLink } from '../external-link/external-link';
import { FormattedAmount } from '../formatted-amount/formatted-amount';
import { InfoHelper } from '../info-helper/info-helper';
import { Loading } from '../loading/loading';
import { CurrencyLogo } from '../logo/logo';
import { Modal, ModalProps } from '../modal/modal';
import { ModalTxErrorView } from '../modal/modal-tx-error-view';
import { Tooltip } from '../tooltip/tooltip';
import './modal-bridge-ren-confirm.scss';

const symbol2id = (symbol: string | undefined) => symbol?.replace(/^ren/, '');

export interface ModalBridgeRenConfirmProps extends ModalProps {
  gateway?: Gateway | null;
  fromNetworkId?: BridgeRenNetworkId;
  toNetworkId?: BridgeRenNetworkId;
  fromAsset?: BridgeAssetMeta;
  toAsset?: BridgeAssetMeta;
  fromAmount?: string;
  toAmount?: string;
  onSuccess?: () => void;
}

export const ModalBridgeRenConfirm: FC<ModalBridgeRenConfirmProps> = ({ onDismiss, isOpen, gateway, ...props }: ModalBridgeRenConfirmProps) => {
  const gatewayRef = useRef(gateway);
  const [, rerender] = useState(0);

  // Update gateway ref
  useEffect(() => {
    if (gatewayRef.current || !gateway || !isOpen) {
      return;
    }
    gatewayRef.current = gateway;
    rerender(v => ++v);
  }, [gateway, isOpen]);

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      {isOpen && gatewayRef.current ? <ModalBridgeRenConfirmContent isOpen={isOpen} onDismiss={onDismiss} gateway={gatewayRef.current} {...props} /> : null}
    </Modal>
  );
};

type ModalBridgeRenConfirmContentProps = ModalBridgeRenConfirmProps & { gateway: Gateway };

/**
 * Main modal view
 * @param onDismiss
 * @param onSuccess
 * @param props
 * @constructor
 */
const ModalBridgeRenConfirmContent: FC<ModalBridgeRenConfirmContentProps> = ({ onDismiss, onSuccess, ...props }) => {
  const { gateway, fromAmount, fromAsset, fromNetworkId } = props;
  const { t } = useTranslation();

  // Modal states
  const [run, setRun] = useState(false);
  const [error, setError] = useState<Error>();
  const fromEvm = useMemo(() => (fromNetworkId ? REN_EVM_NETWORK_IDS.includes(fromNetworkId) : undefined), [fromNetworkId]);
  const [gatewayTx, setGatewayTx] = useState<GatewayTransaction>();

  const waitGatewayTransactionCallback = useCallback(async (): Promise<GatewayTransaction> => {
    if (!gateway || fromEvm === undefined) {
      throw new Error('No gateway instance for wait deposit');
    }

    if (fromEvm) {
      // Process each setup transaction (approve, etc.)
      for (const setupTx of Object.values(gateway.inSetup)) {
        await setupTx.submit?.();
        await setupTx.wait();
      }

      // Send transaction to bridge
      await gateway.in?.submit?.();
      // Wait for the first confirmation.
      await gateway.in?.wait(1);
    }

    return new Promise<GatewayTransaction>(resolve => {
      gateway.on('transaction', async tx => {
        if (!tx.out.progress.transaction) {
          return resolve(tx);
        }
      });
    });
  }, [gateway, fromEvm]);

  const onDismissCallback = useCallback(() => {
    if (gatewayTx) {
      onSuccess?.();
    }
    onDismiss();
  }, [onDismiss, onSuccess, gatewayTx]);

  // Create or/and wait gateway transaction after set run flag to true
  useEffect(() => {
    if (!run || !gateway || fromEvm === undefined) {
      return;
    }

    let cancel = false;

    waitGatewayTransactionCallback()
      .then(gatewayTx => {
        if (cancel) {
          return;
        }
        setGatewayTx(gatewayTx);
      })
      .catch(error => {
        if (cancel) {
          return;
        }
        setError(error);
      });

    return () => {
      cancel = true;
    };
  }, [run, fromEvm, gateway, waitGatewayTransactionCallback]);

  return (
    <div className="modal modal-bridge-ren-confirm">
      {fromEvm === undefined || !gateway || error ? (
        <ModalTxErrorView caption={t('ren_bridge_error')} info={error?.message || t('something_went_wrong')} onDismiss={onDismissCallback} />
      ) : !run ? (
        <TransactionInfoContent {...props} gateway={gateway} onDismiss={onDismissCallback} onConfirm={() => setRun(true)} />
      ) : run && !gatewayTx && fromEvm ? (
        <EvmTransactionConfirmationContent {...props} gateway={gateway} onDismiss={onDismissCallback} />
      ) : run && !gatewayTx && !fromEvm ? (
        <AwaitingIncomingTransferContent address={gateway.gatewayAddress} networkId={fromNetworkId} amount={fromAmount} symbol={fromAsset?.symbol} onDismiss={onDismissCallback} />
      ) : run && gatewayTx ? (
        <DestinationTransactionStatusContent {...props} onDismiss={onDismissCallback} gatewayTx={gatewayTx} onError={setError} />
      ) : null}
    </div>
  );
};

/**
 * Transaction information for check
 * @param props
 * @constructor
 */
const TransactionInfoContent: FC<ModalBridgeRenConfirmContentProps & { onConfirm: () => void }> = props => {
  const { fromNetworkId, toNetworkId, fromAsset, toAsset, fromAmount, toAmount, onDismiss, onConfirm } = props;
  const { t } = useTranslation();

  const fromNetwork = useRenNetworkMeta(fromNetworkId);
  const toNetwork = useRenNetworkMeta(toNetworkId);
  const feeAmount = useMemo(() => (fromAmount && toAmount ? Big(fromAmount).minus(toAmount).toString() : undefined), [fromAmount, toAmount]);

  return (
    <>
      <div className="modal-header">
        <div className="title">{t('confirm_bridge')}</div>
        <button className="btn" onClick={onDismiss}>
          <i className="pi pi-close" />
        </button>
      </div>
      <div className="modal-content">
        {fromNetwork && toNetwork ? (
          <>
            <div className="bridge-transfer">
              <div className="label">{t('bridge_transfer')}</div>
              <div className="chains">
                <div>
                  <img src={fromNetwork.logo} alt={fromNetwork.label} />
                  <span>{fromNetwork.label}</span>
                </div>
                <div className="separator">
                  <i className="pi pi-right-tick" />
                </div>
                <div>
                  <img src={toNetwork.logo} alt={toNetwork.label} />
                  <span>{toNetwork.label}</span>
                </div>
              </div>
            </div>
            <div className="bridge-info">
              <div className="label">{t('bridge_to', { chainName: toNetwork.label })}</div>
              <div className="amount">
                <FormattedAmount amount={fromAmount} maxDecimals={6} unit={fromAsset?.symbol} unitSeparator={' '} />
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div className="modal-footer">
        <div className="estimation-block">
          <div className="row-between">
            <div className="label">
              <span>{t('you_will_receive')}</span>
            </div>
            <div className="value">
              <CurrencyLogo currency={symbol2id(toAsset?.symbol)} size={16} className="logo" />
              <FormattedAmount amount={toAmount} unit={toAsset?.symbol} unitSeparator={' '} />
            </div>
          </div>
          <div className="row-between">
            <div className="label">
              <span>{t('total_fee')}</span>
              <InfoHelper backdrop="white" placement="top-start" text={t('hop_fee_tooltip')} />
            </div>
            <div className="value">
              <FormattedAmount amount={feeAmount} unit={fromAsset?.symbol} unitSeparator={' '} />
            </div>
          </div>
        </div>
        <button className="btn btn-primary btn-lg" id="confirm-button" onClick={onConfirm}>
          <span>{t('confirm_bridge_transfer')}</span>
        </button>
      </div>
    </>
  );
};

/**
 * Awaiting transfer to not EVM chains
 * @param props
 * @constructor
 */
const AwaitingIncomingTransferContent: FC<{ address?: string; networkId?: BridgeRenNetworkId; amount?: string; symbol?: string; onDismiss: () => void }> = props => {
  const { address, networkId, amount, symbol, onDismiss } = props;
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  const network = useMemo(() => REN_NETWORKS_META.find(({ value }) => value === networkId), [networkId]);

  return (
    <>
      <div className="modal-header">
        <div className="title" />
        <button className="btn" onClick={onDismiss}>
          <i className="pi pi-close" />
        </button>
      </div>
      <div className="modal-content">
        <div className="awaiting-animation">
          <img src={network?.logo} alt={network?.label} className="chain-logo" />
          <Lottie animationData={Loader} loop={true} />
        </div>
        <div className="awaiting-incoming-transfer">
          {address ? (
            <>
              <div className="title">
                {t('send_transfer_to_header', {
                  amount: <FormattedAmount amount={amount} unit={symbol} unitSeparator={' '} key="amount" />,
                })}
              </div>
              <div className="qr-code">
                <QRCode value={address} margin={0} width={144} />
              </div>
              <div className="lock-address">
                <div className="lock-header">{t('send_transfer_to', { symbol })}</div>
                <div className="lock-content">
                  <div>{address}</div>
                  <Tooltip text={isCopied ? t('copied') : t('copy_to_clipboard')} placement="top">
                    <BtnCopy text={address} className="btn btn-icon-rect" copiedCallback={setIsCopied}>
                      <i className="pi pi-copy" />
                    </BtnCopy>
                  </Tooltip>
                </div>
              </div>
            </>
          ) : null}
          <div className="disclaimer">
            <span>{t('send_transfer_disclaimer')}</span>
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * Confirm transfer for EVM chains
 * @param props
 * @constructor
 */
const EvmTransactionConfirmationContent: FC<ModalBridgeRenConfirmContentProps> = props => {
  const { fromNetworkId, toNetworkId, fromAmount, toAmount, fromAsset, toAsset, onDismiss } = props;
  const { t } = useTranslation();

  const fromNetwork = useRenNetworkMeta(fromNetworkId);
  const toNetwork = useRenNetworkMeta(toNetworkId);

  return (
    <>
      <div className="modal-header">
        <div className="title" />
        <button className="btn" onClick={onDismiss}>
          <i className="pi pi-close" />
        </button>
      </div>
      <div className="modal-content">
        <div className="awaiting-animation">
          <img src={fromNetwork?.logo} alt={fromNetwork?.label} className="chain-logo" />
          <Lottie animationData={Loader} loop={true} />
        </div>
        <div className="evm-transaction-confirmation">
          <div className="caption">
            <span>{t('confirm_in_network', { network: fromNetwork?.label })}</span>
          </div>
          <div className="swap-info">
            {t('swapping_currencies', {
              amountFrom: <strong key="from">{`${formatAmount(fromAmount)} ${fromAsset?.symbol}`}</strong>,
              amountTo: <strong key="to">{`${formatAmount(toAmount)} ${toAsset?.symbol}`}</strong>,
            })}
          </div>
          <div className="note">
            <span>{t('confirm_this_transaction_in_your_wallet')}</span>
          </div>
        </div>
        <div className="bridge-transfer">
          <div className="chains">
            <div>
              <img src={fromNetwork?.logo} alt={fromNetwork?.label} />
              <span>{fromNetwork?.label}</span>
            </div>
            <div className="separator">
              <i className="pi pi-right-tick" />
            </div>
            <div>
              <img src={toNetwork?.logo} alt={toNetwork?.label} />
              <span>{toNetwork?.label}</span>
            </div>
          </div>
        </div>
        <div className="bridge-info">
          <div className="label">{t('bridge_to', { chainName: toNetwork?.label })}</div>
          <div className="amount">
            <FormattedAmount amount={toAmount} maxDecimals={6} unit={toAsset?.symbol} unitSeparator={' '} />
          </div>
        </div>
        <div className="separator" />
      </div>
    </>
  );
};

/**
 * The destination transaction status view.
 * @param props
 * @constructor
 */
const DestinationTransactionStatusContent: FC<ModalBridgeRenConfirmContentProps & { gatewayTx: GatewayTransaction; onError: (error: Error) => void }> = props => {
  const { gatewayTx, fromNetworkId, toNetworkId, onError, onDismiss } = props;
  const { provider } = useActiveWeb3React();
  const { t } = useTranslation();
  const chainSelector = useChainSelector();

  const fromNetwork = useRenNetworkMeta(fromNetworkId);
  const toNetwork = useRenNetworkMeta(toNetworkId);

  const [chainId, setChainId] = useState<ChainId>();
  const [handleOutputTx, setHandleOutputTx] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [success, setSuccess] = useState<boolean>(false);
  const [progress, setProgress] = useState<{ percent: number; info?: { network: string; progress: string } }>({ percent: 0 });

  const fromEvm = useMemo(() => (fromNetworkId ? REN_EVM_NETWORK_IDS.includes(fromNetworkId) : false), [fromNetworkId]);
  const toEvm = useMemo(() => (toNetworkId ? REN_EVM_NETWORK_IDS.includes(toNetworkId) : false), [toNetworkId]);

  const fromChainId: ChainId | null = useMemo(() => {
    if (!fromEvm) {
      return null;
    }
    const hexChainId = (gatewayTx.fromChain.network as EVMNetworkConfig)?.config?.chainId;
    if (!hexChainId) {
      return null;
    }
    return parseInt(hexChainId, 16) as ChainId;
  }, [gatewayTx, fromEvm]);

  const toChainId: ChainId | null = useMemo(() => {
    if (!toEvm) {
      return null;
    }
    const hexChainId = (gatewayTx.toChain.network as EVMNetworkConfig)?.config?.chainId;
    if (!hexChainId) {
      return null;
    }
    return parseInt(hexChainId, 16) as ChainId;
  }, [toEvm, gatewayTx]);

  const updateProgressCallback = useMemo(() => {
    const inTxSteps = gatewayTx.in.progress.target;
    const renVmSteps = gatewayTx.renVM.progress.target;
    const outTxSteps = gatewayTx.out.submit ? gatewayTx.out.progress.target : 0;
    const totalSteps = inTxSteps + renVmSteps + outTxSteps;
    const percentByStep = Math.ceil(100 / totalSteps);

    let handleTx: 'in' | 'vm' | 'out' = 'in';
    let prevNetwork: string;
    let prevConfirmation: number;
    let step = 0;

    return (network: string, confirmation?: number) => {
      if ((network === prevNetwork && prevConfirmation === confirmation) || confirmation === undefined) {
        return;
      }

      // Set tx type
      if (confirmation === 0 && prevNetwork && network !== prevNetwork) {
        handleTx = handleTx === 'in' ? 'vm' : 'out';
      }

      const maxConfirmations = handleTx === 'in' ? inTxSteps : handleTx === 'vm' ? renVmSteps : outTxSteps;
      confirmation = confirmation > maxConfirmations ? maxConfirmations : confirmation;

      if (prevConfirmation <= confirmation) {
        step += confirmation - prevConfirmation;
      } else {
        step = handleTx === 'in' ? 0 : handleTx === 'vm' ? inTxSteps : renVmSteps + inTxSteps;
      }

      prevNetwork = network;
      prevConfirmation = confirmation;
      const percent = percentByStep * step;

      setProgress({ percent: percent > 100 ? 100 : percent, info: { network, progress: `${confirmation}/${maxConfirmations}` } });
    };
  }, [gatewayTx]);

  // Await confirm input transaction
  useEffect(() => {
    async function awaitInputTx() {
      // Wait tx in 6 confirmations
      updateProgressCallback(gatewayTx.in.progress.chain, 0);
      await gatewayTx.in.wait().on('progress', ({ chain, confirmations }) => updateProgressCallback(chain, confirmations));

      // Submit and wait transaction to the RenVM.
      updateProgressCallback(gatewayTx.renVM.progress.chain, 0);
      await gatewayTx.renVM.submit().on('progress', ({ chain, confirmations }) => updateProgressCallback(chain, confirmations));
      await gatewayTx.renVM.wait();
    }

    awaitInputTx()
      .then(() => setHandleOutputTx(true))
      .catch(setError);
  }, [gatewayTx, updateProgressCallback]);

  // Send and wait confirm output transaction
  useEffect(() => {
    if (!handleOutputTx) {
      return;
    }
    if (!gatewayTx.out.submit) {
      return setSuccess(true);
    }

    if (chainId !== toChainId || !provider) {
      return;
    }

    gatewayTx.toChain.signer = provider.getSigner();

    async function awaitInputTx() {
      updateProgressCallback(gatewayTx.out.progress.chain, 0);
      await gatewayTx.out?.submit?.({ txConfig: { gasLimit: 1000000 } }).on('progress', ({ chain, confirmations }) => updateProgressCallback(chain, confirmations));
      await gatewayTx.out.wait();
    }

    awaitInputTx()
      .then(() => setSuccess(true))
      .catch(setError);
  }, [gatewayTx, chainId, handleOutputTx, toChainId, provider, updateProgressCallback]);

  // Getting the provider's chainId
  useEffect(() => {
    if (!provider) {
      return;
    }
    let cancel = false;

    provider.getNetwork().then(({ chainId }) => {
      if (!cancel) {
        setChainId(chainId as ChainId);
      }
    });

    return () => {
      cancel = true;
    };
  }, [provider]);

  // Handle error
  useEffect(() => {
    if (!error) {
      return;
    }
    onError(error);
  }, [error, onError]);

  return (
    <>
      <div className="modal-header">
        {!success ? <div className="title">{t('transfer_in_progress')}</div> : <div className="title" />}
        <button className="btn" onClick={onDismiss}>
          <i className="pi pi-close" />
        </button>
      </div>
      <div className="modal-content">
        <div className="awaiting-animation">
          {success ? <Lottie animationData={Success} loop={false} className="animation" /> : <Lottie animationData={NetworkConfirmation} loop={true} className="animation" />}
        </div>
        {success ? (
          <div className="caption">
            <span>{t('transfer_was_finished')}</span>
          </div>
        ) : (
          <>
            <div className="disclaimer">
              <span>{t('transaction_is_en_route', { time: <strong key="time">~7-8 minutes</strong>, network: toNetwork?.label })}</span>
            </div>
            <div className="separator" />
            <div className="progress-bar">
              <div className="label">
                <span>{t('transfer_progress')}</span>
                <b>{progress.percent}%</b>
              </div>
              <div className="progress-bar-wrapper">
                <div className="progress-bar-value" style={{ width: `${progress.percent}%` }} />
              </div>
              <div className="info">{progress.info ? t('bridge_progress_confirmations', { network: progress.info.network, progress: progress.info.progress }) : null}</div>
            </div>
          </>
        )}
        <div className="bridge-transfer">
          <div className="chains">
            <div>
              <img src={fromNetwork?.logo} alt={fromNetwork?.label} />
              <span>{fromNetwork?.label}</span>
            </div>
            <div className="separator">
              <i className="pi pi-right-tick" />
            </div>
            <div>
              <img src={toNetwork?.logo} alt={toNetwork?.label} />
              <span>{toNetwork?.label}</span>
            </div>
          </div>
        </div>
        <div className="tx-links">
          <div className="link">
            <ExternalLink href={gatewayTx.params.fromTx.explorerLink}>
              <span>{fromChainId ? getViewOnScanText(t, fromChainId) : t('view_on_some_explorer', { name: fromNetwork?.label })}</span>
              <i className="pi pi-back" />
            </ExternalLink>
          </div>
          {gatewayTx.out.progress.transaction?.explorerLink ? (
            <div className="link">
              <ExternalLink href={gatewayTx.out.progress.transaction.explorerLink}>
                <span>{toChainId ? getViewOnScanText(t, toChainId) : t('view_on_some_explorer', { name: toNetwork?.label })}</span>
                <i className="pi pi-back" />
              </ExternalLink>
            </div>
          ) : null}
        </div>
      </div>
      {!gatewayTx.out.submit || success ? (
        <div className="modal-footer">
          <button className="btn btn-primary btn-lg" onClick={onDismiss}>
            <span>{t('back_to_dashboard')}</span>
          </button>
        </div>
      ) : toChainId && handleOutputTx && chainId !== toChainId ? (
        <div className="modal-footer">
          <div className="help-message">
            <span>{t('to_continue_change_network')}</span>
          </div>
          <button className="btn btn-primary btn-lg" onClick={() => chainSelector(toChainId)}>
            <span>{t('switch_network_to_chain', { chainTo: toNetwork?.label })}</span>
          </button>
        </div>
      ) : (
        <div className="modal-footer">
          <button className="btn btn-primary btn-lg btn-loading disabled">
            <Loading size="sm" />
          </button>
        </div>
      )}
    </>
  );
};
