import React, { useMemo } from 'react';
import { AmountInput, formatAmount, FormatAmountSettings } from '../../utils/format-amount';

export interface FormattedAmountProps extends FormatAmountSettings {
  amount: AmountInput;
  replacerForEmpty?: string;
  unit?: string;
  unitSeparator?: string;
}

export function FormattedAmount({
  unitSeparator,
  unit,
  amount,
  decimalPlaces,
  preferredStringLength,
  maxDecimals,
  groupSeparator,
  replacerForEmpty = '',
}: FormattedAmountProps): JSX.Element {
  const formattedAmount = useMemo(() => {
    let result: string | null = null;
    try {
      result = formatAmount(amount, { decimalPlaces, preferredStringLength, maxDecimals, groupSeparator });
    } catch (e) {
      console.error('Amount parse failed', amount);
      console.error(e);
    }
    const resultWithUnit = `${result}${unitSeparator || ''}${unit || ''}`.trim();
    return result ? resultWithUnit : replacerForEmpty;
  }, [amount, decimalPlaces, groupSeparator, maxDecimals, preferredStringLength, replacerForEmpty, unitSeparator, unit]);

  return <>{formattedAmount}</>;
}
