// Editable field
// export enum LiquidityField {
//   CURRENCY_A = 'CURRENCY_A',
//   CURRENCY_B = 'CURRENCY_B',
// }

export enum SwapField {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

// export enum PriceField {
//   RATE = 'RATE',
//   PERCENT = 'PERCENT',
// }

// export enum LimitTradingField {
//   INPUT = 'INPUT',
//   OUTPUT = 'OUTPUT',
//   INPUT_RATE = 'INPUT_RATE',
//   OUTPUT_RATE = 'OUTPUT_RATE',
// }

// export enum HyperDexLimitField {
//   INPUT = 'INPUT',
//   OUTPUT = 'OUTPUT',
//   RATE = 'RATE',
//   PERCENT = 'PERCENT',
// }
