import { useMemo } from 'react';
import { useSingleCallResult } from '../../state/multicall/multicall.hooks';
import { ApprovalTokenConfig } from '../../types';
import { useTokensApproverContract } from '../use-contract';

/**
 * Returns the ApproveConfig or undefined if not supported.
 * @param address
 */
export function useApproverContractConfig(address?: string): ApprovalTokenConfig | null | undefined {
  const approverContract = useTokensApproverContract();

  // Token is support EIP712 sign
  const hasConfiguredResult = useSingleCallResult(approverContract, 'hasConfigured', [address]);
  const hasConfigured = useMemo(() => (hasConfiguredResult.result ? !!hasConfiguredResult.result[0] : undefined), [hasConfiguredResult]);

  // Token signature configuration
  const configResult = useSingleCallResult(hasConfigured ? approverContract : undefined, 'getConfig', [address]);
  const config = useMemo(() => (configResult.result ? (configResult.result[0] as ApprovalTokenConfig) : undefined), [configResult]);

  const loading = useMemo(() => hasConfiguredResult.loading || configResult.loading, [configResult.loading, hasConfiguredResult.loading]);

  return useMemo(() => (loading ? null : config), [loading, config]);
}
