import { CurrencyAmount, JSBI } from '@plasma/plasmaswap-sdk';
import Big from 'big.js';
import React, { useMemo } from 'react';
import { useCurrencyPrice } from '../../hooks/use-currency-price';
import { useCurrentCurrency } from '../../state/user/user.hooks';
import { formatAmount, FormatAmountSettings } from '../../utils/format-amount';

const AMOUNT_SYMBOL_TO_REPLACE = '$amount$';

export interface FormattedCurrencyAmountInFiatProps extends FormatAmountSettings {
  amount?: CurrencyAmount;
  hideSymbol?: boolean; // Default false
  replacerForEmpty?: string; // Default ''
  wrapper?: string; // Wrap output, braces example ($amount$)
}

export function FormattedCurrencyAmountInFiat(props: FormattedCurrencyAmountInFiatProps): JSX.Element {
  const { amount, decimalPlaces = 2, hideSymbol, replacerForEmpty = '', maxDecimals, groupSeparator, preferredStringLength, wrapper } = props;
  const usdPrice = useCurrencyPrice(amount && amount.greaterThan(JSBI.BigInt('0')) ? amount.currency : undefined);
  const [localCurrency] = useCurrentCurrency();

  const symbol = useMemo(() => `${!hideSymbol ? localCurrency.sign : ''}`, [hideSymbol, localCurrency.sign]);

  const formattedAmount = useMemo(() => {
    if (amount && localCurrency) {
      if (amount.equalTo(JSBI.BigInt('0'))) {
        return `${(0).toFixed(decimalPlaces)}`;
      } else if (usdPrice) {
        try {
          const localAmount = Big(usdPrice.quote(amount).toExact()).div(localCurrency.rate).toString();
          return formatAmount(localAmount, { decimalPlaces, maxDecimals, groupSeparator, preferredStringLength });
        } catch (e) {
          console.error('Currency amount parse failed');
          console.error(e);
        }
      }
    }
    return null;
  }, [amount, localCurrency, usdPrice, decimalPlaces, maxDecimals, groupSeparator, preferredStringLength]);

  const result = useMemo(() => {
    let resultToWrap: string;
    if (formattedAmount) {
      resultToWrap = `${symbol}${formattedAmount}`;
    } else {
      resultToWrap = replacerForEmpty;
    }
    if (!wrapper || !wrapper.includes(AMOUNT_SYMBOL_TO_REPLACE) || !resultToWrap) {
      return resultToWrap;
    }
    return wrapper.replace(AMOUNT_SYMBOL_TO_REPLACE, resultToWrap);
  }, [formattedAmount, replacerForEmpty, symbol, wrapper]);

  return <>{result}</>;
}
