import { Price } from '@plasma/plasmaswap-sdk';
import React, { useState } from 'react';
import { formatTokenSymbol } from '../../utils/format-token-symbol';

export function TradePrice({ price }: { price?: Price | null }): JSX.Element {
  const [showInverted, setShowInverted] = useState(false);
  try {
    const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6);
    const show = Boolean(price?.baseCurrency && price?.quoteCurrency);
    const label = showInverted
      ? `${formatTokenSymbol(price?.quoteCurrency)} per ${formatTokenSymbol(price?.baseCurrency)}`
      : `${formatTokenSymbol(price?.baseCurrency)} per ${formatTokenSymbol(price?.quoteCurrency)}`;

    return show ? (
      <>
        {formattedPrice ?? '-'} {label}
        <button className="btn btn-icon" onClick={() => setShowInverted(!showInverted)}>
          <i className="pi pi-repeat" />
        </button>
      </>
    ) : (
      <>-</>
    );
  } catch (e) {
    return <>-</>;
  }
}
