import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { AppState } from '../../state';
import { getEtherscanLink, shortenHash } from '../../utils';
import { getViewOnScanText } from '../../utils/get-view-on-scan-text';
import { AssetsLoaderBtn } from '../assets-loader-btn.tsx/assets-loader-btn';
import { BtnCopy } from '../btn-copy/btn-copy';
import { ExternalLink } from '../external-link/external-link';
import { FormattedAmountInFiat } from '../formatted-amount-in-fiat/formatted-amount-in-fiat';
import { LoadingSkeleton } from '../loading-skeleton/loading-skeleton';
import { Tooltip } from '../tooltip/tooltip';
import { WalletCircleIcon } from '../wallet-circle-icon/wallet-circle-icon';
import './wallet-info.scss';

export const WalletInfo: FC = () => {
  const { account, chainId } = useActiveWeb3React();
  const { loading, totals } = useSelector((state: AppState) => state.portfolioAssets);
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  if (!account || !chainId) {
    return null;
  }
  const accountTotal = totals[account]?.accountTotal || '0';

  return (
    <div className="wallet-info">
      <div className="icon">
        <WalletCircleIcon size={24} account={account} />
      </div>
      <div className="address">{shortenHash(account, 4)}</div>
      <div className="button-container">
        <BtnCopy text={account || ''} className="btn btn-action" onClick={e => e.stopPropagation()} copiedCallback={setIsCopied}>
          <Tooltip text={isCopied ? t('copied') : t('copy_to_clipboard')} placement="top">
            <i className="pi pi-copy" />
          </Tooltip>
        </BtnCopy>

        <button className="btn btn-action">
          <ExternalLink href={getEtherscanLink(chainId, account, 'address')}>
            <Tooltip text={getViewOnScanText(t, chainId)} placement="top">
              <i className="pi pi-open-new-alt" />
            </Tooltip>
          </ExternalLink>
        </button>
        <button className="btn btn-action">
          <ExternalLink href={`https://apy.plasma.finance/#/portfolio/${account}`}>
            <Tooltip text={t('portfolio')} placement="top">
              <i className="pi pi-portfolio-menu" />
            </Tooltip>
          </ExternalLink>
        </button>
      </div>
      <AssetsLoaderBtn />
      <div className="total">
        {!loading ? (
          <FormattedAmountInFiat amount={accountTotal} decimalPlaces={2} />
        ) : (
          <LoadingSkeleton>
            <Skeleton height={24} width={100} />
          </LoadingSkeleton>
        )}
      </div>
    </div>
  );
};
