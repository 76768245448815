import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { LoadingSkeleton } from '../loading-skeleton/loading-skeleton';

export const NetworkItemSkeleton: FC = () => {
  return (
    <div className="network-item">
      <div className="network">
        <div className="network-logo">
          <LoadingSkeleton>
            <Skeleton circle={true} width={20} height={20} />
          </LoadingSkeleton>
        </div>
        <span className="network-name">
          <LoadingSkeleton>
            <Skeleton width={100} height={16} />
          </LoadingSkeleton>
        </span>
      </div>
      <div className="share">
        <LoadingSkeleton>
          <Skeleton width={50} height={20} />
        </LoadingSkeleton>
      </div>
      <div className="value">
        <LoadingSkeleton>
          <Skeleton width={70} height={20} />
        </LoadingSkeleton>
      </div>
    </div>
  );
};
