import React, { FC, useEffect, useState } from 'react';
import './wallet.scss';
import { useDispatch } from 'react-redux';
import { LoadingSkeleton } from '../loading-skeleton/loading-skeleton';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { CurrencyAmount, JSBI, NATIVE, toCurrencyAmount } from '@plasma/plasmaswap-sdk';
import { FormattedCurrencyAmount } from '../formatted-currency-amount/formatted-currency-amount';
import { WalletAddress } from './wallet-address/wallet-address';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { setActiveTab } from '../../state/user/user.actions';
import { WidgetTab } from '../../types/widget-tabs';

export const Wallet: FC = () => {
  const { t } = useTranslation();
  const { account, provider, chainId } = useActiveWeb3React();
  const [nativeBalance, setNativeBalance] = useState<CurrencyAmount>();
  const [loading, setLoading] = useState<boolean | null>(null);
  const dispatch = useDispatch();

  // get native balance
  useEffect(() => {
    if (!account || !chainId || !provider) {
      return;
    }
    const fetchBalance = async () => {
      setLoading(true);
      try {
        const bigBalance = await provider.getBalance(account);
        if (provider._network.chainId === chainId) {
          const currencyBalance = toCurrencyAmount(NATIVE[chainId], JSBI.BigInt(bigBalance));
          setNativeBalance(currencyBalance);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchBalance();
  }, [chainId, account, provider]);

  // useEffect(() => {
  //   if (!account) {
  //     return;
  //   }
  //   const fetchWalletsData = async () => {
  //     dispatch(fetchWalletTotal.pending({ account }));

  //     try {
  //       const total = await getAccountTotal(account);
  //       dispatch(fetchWalletTotal.resolved({ account, total }));
  //     } catch (error) {
  //       console.error(error);
  //     } finally {
  //       dispatch(fetchWalletTotal.finished({ account }));
  //     }
  //   };
  //   fetchWalletsData();
  // }, [account, dispatch]);

  return account ? (
    <div className="wallet connected">
      {/* <div className={s.icon}>
        <WalletCircleIcon account={account} />
      </div> */}
      <div className="info">
        <div className="balance">
          {!loading ? (
            <FormattedCurrencyAmount maxDecimals={2} amount={nativeBalance} />
          ) : (
            // <FormattedAmountInFiat decimalPlaces={2} amount={walletTotal[account]?.total} />
            <LoadingSkeleton>
              <Skeleton width={50} height={12} />
            </LoadingSkeleton>
          )}
        </div>

        <WalletAddress />
      </div>
    </div>
  ) : (
    <div className="wallet" onClick={() => dispatch(setActiveTab(WidgetTab.portfolio))}>
      {t('connect_wallet')}
    </div>
  );
};
