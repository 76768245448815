import { ChainId } from '@plasma/plasmaswap-sdk';
import { createReducer } from '@reduxjs/toolkit';
import { GasPrices, GasPriceSettings } from '../../types';
import { updateVersion } from '../global/global.actions';
import { updateGasPrices, updateGasPriceSettings, updateGsnTokenAddress } from './gas-station.actions';

export interface GasStationState {
  /**
   * Contains which token to pay for gas. If not defined, then ETH
   */
  gasFeeTokenAddress: { [chainId in ChainId]?: string };
  /**
   * The gas that the user selected.
   */
  options: { [chainId in ChainId]?: GasPriceSettings };
  /**
   * Gas prices configuration by speed
   */
  gasPrices?: GasPrices;
}

export const initialState: GasStationState = {
  gasFeeTokenAddress: {},
  options: {},
};

export default createReducer(initialState, builder => {
  return builder
    .addCase(updateVersion, state => {
      Object.keys(initialState).forEach((key: string) => {
        if (!state[key as keyof GasStationState]) {
          (state as any)[key as keyof GasStationState] = initialState[key as keyof GasStationState];
        }
      });
      return state;
    })
    .addCase(updateGsnTokenAddress, (state, { payload: { chainId, address } }) => {
      state.gasFeeTokenAddress[chainId] = address;
      return state;
    })
    .addCase(updateGasPriceSettings, (state, { payload: { chainId, settings } }) => {
      return {
        ...state,
        options: {
          ...state.options,
          [chainId]: {
            ...state.options[chainId],
            ...settings,
          },
        },
      };
    })
    .addCase(updateGasPrices, (state, { payload: gasPrices }) => {
      state.gasPrices = gasPrices;
      return state;
    });
});
