import { NATIVE_ADDRESSES, NativeAmount, Trade0xSwap, ZERO_ADDRESS } from '@plasma/plasmaswap-sdk';
import { BigNumber } from '@ethersproject/bignumber';
import { useMemo } from 'react';
import { SimpleTransaction } from '../../types';
import { useHyperDexRouterContract } from '../use-contract';
import { useTrade0xSwapMaxSellAmount } from './use-trade-0x-swap-max-sell-amount';
import { useActiveWeb3React } from '../web3/use-active-web3-react';
import { useReferrals } from '../../state/user/user.hooks';

/**
 *
 * Convert the trade to the transaction
 * @param trade - 0x swap trade
 * @param approvalData - approval method call data
 */
export function useTrade0xSwapToTx(trade: Trade0xSwap | undefined | null, approvalData?: string): SimpleTransaction | undefined | null {
  const { chainId } = useActiveWeb3React();
  const hyperDexRouterContract = useHyperDexRouterContract();
  const definedReferrals = useReferrals();
  const maxSellAmount = useTrade0xSwapMaxSellAmount(trade);

  // Calculate tx fields
  const from = useMemo(() => trade?.tx?.from, [trade?.tx?.from]);
  const to = useMemo(() => hyperDexRouterContract?.address, [hyperDexRouterContract]);
  const value = useMemo(() => {
    if (trade?.tx?.value && trade?.inputAmount.currency.isNative) {
      return BigNumber.from(trade.tx.value)
        .add(trade?.feeAmount.raw.toString() || '0')
        .toHexString();
    }
    return '0x0';
  }, [trade?.feeAmount.raw, trade?.inputAmount.currency.isNative, trade?.tx?.value]);

  const data = useMemo(() => {
    if (!hyperDexRouterContract || !trade || !trade.tx || !chainId || !maxSellAmount) {
      return undefined;
    }
    const msgData = trade.tx.data;
    const inputToken = trade.inputAmount instanceof NativeAmount ? NATIVE_ADDRESSES[chainId] : trade.inputAmount.token.address;
    const inputAmount = maxSellAmount.raw.toString();
    const outputToken = trade.outputAmount instanceof NativeAmount ? NATIVE_ADDRESSES[chainId] : trade.outputAmount.token.address;
    const referrals = new Array(4).fill(ZERO_ADDRESS).map((zeroAddress, index) => definedReferrals[index] || zeroAddress);
    const fnParams = [msgData, inputToken, inputAmount, outputToken, referrals];
    if (approvalData) {
      return hyperDexRouterContract.interface.encodeFunctionData('multiRouteWithPermit', [...fnParams, approvalData]);
    } else {
      return hyperDexRouterContract.interface.encodeFunctionData('multiRoute', fnParams);
    }
  }, [chainId, definedReferrals, maxSellAmount, trade, hyperDexRouterContract, approvalData]);
  const loading = useMemo(() => trade === null, [trade]);

  return useMemo(() => {
    if (loading) {
      return null;
    }
    if (!from || !to || !data) {
      return undefined;
    }
    return { from, to, value, data };
  }, [loading, from, to, data, value]);
}
