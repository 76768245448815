import Big from 'big.js';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UnmarshalSupportedChainId } from '../../types';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { AppState } from '../../state';
import { NetworkItem } from './network-item';
import './networks-total.scss';

export const NetworksTotal: FC = () => {
  const { account } = useActiveWeb3React();
  const { totals, loading } = useSelector((state: AppState) => state.portfolioAssets);
  const { t } = useTranslation();

  if (!account) {
    return null;
  }

  const accountTotal = totals[account]?.accountTotal || '0';

  return (
    <div className="networks-block">
      <h2 className="networks-title">{t('networks')}</h2>
      <div className="networks-header">
        <div className="network">{t('network')}</div>
        <div className="share">{t('share')}</div>
        <div className="value">{t('value')}</div>
      </div>
      <div className="networks-list">
        {Object.keys(totals[account]?.totalByChain || {}).map(key => {
          const chainId = +key as UnmarshalSupportedChainId;
          const chainTotal = totals[account].totalByChain?.[chainId] || '0';
          const percent = Number(accountTotal) ? Big(chainTotal).div(accountTotal).times(100).toString() : '0';

          return <NetworkItem loading={loading} percent={percent} totalUsd={chainTotal} chainId={chainId} key={chainId} />;
        })}
      </div>
    </div>
  );
};
