import { TransactionRequest } from '@ethersproject/abstract-provider';
import { BigNumber } from '@ethersproject/bignumber';
import { CurrencyAmount, toCurrencyAmount, Token } from '@plasma/plasmaswap-sdk';
import Big from 'big.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_FEE_PER_GAS, MAX_FEE_PER_GAS_MULTIPLIER } from '../../constants';
import { useInterval } from '../../hooks/use-interval';
import { useCurrency, useToken, useTokens } from '../../hooks/use-token';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { GasPrices, GasPriceSettings, GsnWeb3ProviderState, SimpleTransaction, TransactionSpeed, TransactionType } from '../../types';
import { calculateGasMargin } from '../../utils';
import { compareAddresses } from '../../utils/compare-addresses';
import { useBlock } from '../application/application.hooks';
import { AppDispatch, AppState } from '../index';
import { updateGasPriceSettings, updateGsnTokenAddress } from './gas-station.actions';

const BLOCKS_RANGE_FOR_AVG_TIME_CALC = 100_000;

/**
 * The token that is used to pay for gas. If not defined, then ETH
 */
export function useGsnToken(): [Token | null | undefined, (token?: Token) => void] {
  const { chainId } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>();
  const feeTokenAddresses = useSelector<AppState, AppState['gasStation']['gasFeeTokenAddress']>(state => state.gasStation.gasFeeTokenAddress);
  const feeTokenAddress = useMemo(() => (chainId ? feeTokenAddresses[chainId] : undefined), [feeTokenAddresses, chainId]);

  const feeToken = useToken(feeTokenAddress);

  const setFeeToken = useCallback(
    (feeToken?: Token) => {
      if (!chainId) {
        return;
      }
      dispatch(updateGsnTokenAddress({ chainId, address: feeToken ? feeToken.address : undefined }));
    },
    [chainId, dispatch],
  );

  return useMemo(() => [feeToken, setFeeToken], [feeToken, setFeeToken]);
}

/**
 * GSN Provider readiness status
 */
export function useGsnProviderState(): GsnWeb3ProviderState {
  const { provider } = useActiveWeb3React();
  const [gsnProviderState, setGsnProviderState] = useState(provider ? provider.state : GsnWeb3ProviderState.PENDING);

  const updateState = useCallback(() => {
    if (provider) {
      setGsnProviderState(provider.state);
    }
  }, [provider]);

  useInterval(updateState, gsnProviderState === GsnWeb3ProviderState.PENDING ? 100 : null);

  // Reset state after change library instance
  useEffect(() => {
    setGsnProviderState(provider ? provider.state : GsnWeb3ProviderState.PENDING);
  }, [provider]);

  return gsnProviderState;
}

/**
 * Returns supported tokens for fee pay
 */
export function useGsnSupportedTokens(): Token[] | null | undefined {
  const { provider } = useActiveWeb3React();
  const gsnProviderState = useGsnProviderState();

  const addresses = useMemo(() => {
    if (provider && gsnProviderState === GsnWeb3ProviderState.READY) {
      return provider.supportedTokensAddresses;
    }
    return [];
  }, [provider, gsnProviderState]);

  const supportedTokens = useTokens(addresses);

  const loading = useMemo(() => {
    if (gsnProviderState === GsnWeb3ProviderState.PENDING) {
      return true;
    }
    return supportedTokens.length ? supportedTokens.some(i => i === null) : false;
  }, [gsnProviderState, supportedTokens]);

  return useMemo(() => {
    if (loading) {
      return null;
    }
    if (gsnProviderState === GsnWeb3ProviderState.FAIL) {
      return undefined;
    }
    return supportedTokens.filter(i => !!i) as Token[];
  }, [gsnProviderState, loading, supportedTokens]);
}

/**
 * Returns a function to get gas prices by transaction speed
 */
export function useGetGasPricesCallback(): () => Promise<GasPrices> {
  const { provider, chainId } = useActiveWeb3React();

  return useCallback(async () => {
    if (!chainId || !provider) {
      throw new Error('Provider is not ready');
    }

    // Try to get gas price from gas station
    if (provider.state !== GsnWeb3ProviderState.PENDING) {
      try {
        return await provider.getGasPrices();
      } catch (e) {}
    }

    const defaultFeePerGas = DEFAULT_FEE_PER_GAS[chainId];
    const latestBlock = await provider.getBlock('latest');
    const pastBlock = await provider.getBlock(latestBlock.number - BLOCKS_RANGE_FOR_AVG_TIME_CALC);
    const avgBlockTime = Big(latestBlock.timestamp).minus(pastBlock.timestamp).div(BLOCKS_RANGE_FOR_AVG_TIME_CALC).toString();

    if (latestBlock.baseFeePerGas) {
      const baseFeePerGas = Big(latestBlock.baseFeePerGas.toString()).times(MAX_FEE_PER_GAS_MULTIPLIER).round(0);

      return {
        [TransactionSpeed.HIGH]: {
          maxPriorityFeePerGas: defaultFeePerGas.high,
          maxFeePerGas: baseFeePerGas.add(defaultFeePerGas.high).toFixed(0),
        },
        [TransactionSpeed.MIDDLE]: {
          maxPriorityFeePerGas: defaultFeePerGas.middle,
          maxFeePerGas: baseFeePerGas.add(defaultFeePerGas.middle).toFixed(0),
        },
        [TransactionSpeed.LOW]: {
          maxPriorityFeePerGas: defaultFeePerGas.low,
          maxFeePerGas: baseFeePerGas.add(defaultFeePerGas.low).toFixed(0),
        },
        avgBlockTime,
      };
    } else {
      return {
        [TransactionSpeed.HIGH]: { gasPrice: defaultFeePerGas.high },
        [TransactionSpeed.MIDDLE]: { gasPrice: defaultFeePerGas.middle },
        [TransactionSpeed.LOW]: { gasPrice: defaultFeePerGas.low },
        avgBlockTime,
      };
    }
  }, [chainId, provider]);
}

/**
 * Gas prices by speed, and avg block time
 */
export function useGasPrices(): GasPrices | null {
  const gasPrices = useSelector<AppState, AppState['gasStation']['gasPrices']>(state => state.gasStation.gasPrices);
  return gasPrices || null;
}

/**
 * Returns CUSTOM gas price settings and setter callback
 */
export function useGasPriceSettingsManager(): [GasPriceSettings | undefined, (gasOpts: Partial<GasPriceSettings>) => void] {
  const { chainId } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>();
  const gasPriceOptionsByChainId = useSelector<AppState, AppState['gasStation']['options']>(state => state.gasStation.options);

  const gasPriceOptions: GasPriceSettings | undefined = useMemo(() => {
    if (!chainId || !gasPriceOptionsByChainId[chainId]) {
      return;
    }
    return gasPriceOptionsByChainId[chainId];
  }, [gasPriceOptionsByChainId, chainId]);

  const updateGasOptions = useCallback(
    (settings: Partial<GasPriceSettings>) => {
      if (!chainId) {
        return;
      }
      dispatch(updateGasPriceSettings({ chainId, settings }));
    },
    [chainId, dispatch],
  );

  return useMemo(() => [gasPriceOptions, updateGasOptions], [gasPriceOptions, updateGasOptions]);
}

/**
 * Return gas price for transaction
 */
export function useGasPriceSettings(): { gasPrice?: BigNumber; maxPriorityFeePerGas?: BigNumber; maxFeePerGas?: BigNumber; type: TransactionType } | undefined {
  const block = useBlock();
  const baseFeePerGas = useMemo(() => block?.baseFeePerGas, [block?.baseFeePerGas]);
  const gasPrices = useGasPrices();
  const [gasPriceSettings] = useGasPriceSettingsManager();

  const maxPriorityFeePerGas = useMemo(() => {
    if (!gasPriceSettings || gasPriceSettings.type !== TransactionType.EIP1559 || !gasPrices) {
      return undefined;
    }
    if (gasPriceSettings.speed !== undefined) {
      const maxPriorityFeePerGas = gasPrices[gasPriceSettings.speed].maxPriorityFeePerGas;
      return maxPriorityFeePerGas ? BigNumber.from(maxPriorityFeePerGas) : undefined;
    }
    return gasPriceSettings.maxPriorityFeePerGas ? BigNumber.from(gasPriceSettings.maxPriorityFeePerGas) : undefined;
  }, [gasPriceSettings, gasPrices]);

  const maxFeePerGas = useMemo(() => {
    if (!gasPriceSettings || gasPriceSettings.type !== TransactionType.EIP1559 || !gasPrices) {
      return undefined;
    }

    if (gasPriceSettings.speed !== undefined) {
      const maxFeePerGas = gasPrices[gasPriceSettings.speed].maxFeePerGas;
      return maxFeePerGas ? BigNumber.from(maxFeePerGas) : undefined;
    }

    if (gasPriceSettings.maxFeePerGas) {
      return BigNumber.from(gasPriceSettings.maxFeePerGas);
    } else if (baseFeePerGas && maxPriorityFeePerGas) {
      return BigNumber.from(Big(baseFeePerGas).times(MAX_FEE_PER_GAS_MULTIPLIER).plus(maxPriorityFeePerGas.toString()).toFixed(0));
    } else {
      return undefined;
    }
  }, [baseFeePerGas, gasPriceSettings, gasPrices, maxPriorityFeePerGas]);

  const gasPrice = useMemo(() => {
    if (!gasPriceSettings || gasPriceSettings.type !== TransactionType.LEGACY || !gasPrices) {
      return undefined;
    }
    if (gasPriceSettings.speed !== undefined) {
      const gasPrice = gasPrices[gasPriceSettings.speed].gasPrice;
      return gasPrice ? BigNumber.from(gasPrice) : undefined;
    }
    return gasPriceSettings.gasPrice ? BigNumber.from(gasPriceSettings.gasPrice) : undefined;
  }, [gasPriceSettings, gasPrices]);

  return useMemo(() => {
    if (gasPriceSettings?.type === undefined) {
      return undefined;
    }

    switch (gasPriceSettings.type) {
      case TransactionType.LEGACY:
        return gasPrice ? { gasPrice, type: TransactionType.LEGACY } : undefined;
      case TransactionType.EIP1559:
        return maxPriorityFeePerGas && maxFeePerGas ? { maxPriorityFeePerGas, maxFeePerGas, type: TransactionType.EIP1559 } : undefined;
    }
  }, [gasPriceSettings?.type, maxPriorityFeePerGas, maxFeePerGas, gasPrice]);
}

/**
 * Returns callback function for add gas fee
 */
export function useGasAdder(): (overrides: Omit<TransactionRequest, 'gasPrice' | 'maxPriorityFeePerGas' | 'maxFeePerGas'>) => TransactionRequest {
  const gasPriceSettings = useGasPriceSettings();
  const { account, provider } = useActiveWeb3React();

  return useCallback(
    overrides => {
      if (!provider || !account || !gasPriceSettings) {
        throw new Error('Invalid dependency');
      }
      // We use only global gas settings
      Reflect.deleteProperty(overrides, 'gasPrice');
      Reflect.deleteProperty(overrides, 'maxPriorityFeePerGas');
      Reflect.deleteProperty(overrides, 'maxFeePerGas');

      const { type, gasPrice, maxFeePerGas, maxPriorityFeePerGas } = gasPriceSettings;
      const txWithGas: TransactionRequest = {};

      const { gasLimit } = overrides;
      Reflect.deleteProperty(overrides, 'gasLimit');
      if (gasLimit instanceof BigNumber) {
        txWithGas.gasLimit = calculateGasMargin(gasLimit);
      }

      if (type === TransactionType.EIP1559 && maxFeePerGas && maxPriorityFeePerGas) {
        txWithGas.maxPriorityFeePerGas = maxPriorityFeePerGas;
        txWithGas.maxFeePerGas = maxFeePerGas;
      } else if (type === TransactionType.LEGACY && gasPrice) {
        txWithGas.gasPrice = gasPrice;
      }
      return Object.assign({}, overrides, txWithGas);
    },
    [gasPriceSettings, provider, account],
  );
}

/**
 * Maximum gas cost, for network fee calculation
 */
export function useMaxGasPrice(): BigNumber | null {
  const gasPriceSettings = useGasPriceSettings();

  return useMemo(() => {
    if (!gasPriceSettings) {
      return null;
    }

    if (gasPriceSettings.type === TransactionType.LEGACY && gasPriceSettings.gasPrice) {
      return gasPriceSettings.gasPrice;
    } else if (gasPriceSettings.type === TransactionType.EIP1559 && gasPriceSettings.maxFeePerGas) {
      return gasPriceSettings.maxFeePerGas;
    } else {
      return null;
    }
  }, [gasPriceSettings]);
}

/**
 * Calculate and returns max supported tx type (EIP1559 or Legacy) for current chainId
 */
export function useMaxSupportedTxType(): TransactionType | undefined {
  const { chainId } = useActiveWeb3React();
  const block = useBlock();

  return useMemo(() => {
    if (!block || !chainId) {
      return undefined;
    }
    if (!block.baseFeePerGas) {
      return TransactionType.LEGACY;
    }
    return TransactionType.EIP1559;
  }, [block, chainId]);
}

/**
 * Does the GSN contract support
 * @param address
 */
export function useAddressIsSupportGsn(address?: string): boolean | null {
  const { provider } = useActiveWeb3React();
  const gsnProviderState = useGsnProviderState();
  const [isSupport, setIsSupport] = useState<boolean | null>(null);

  useEffect(() => {
    if (!address || !provider || gsnProviderState === GsnWeb3ProviderState.FAIL) {
      return setIsSupport(false);
    }
    if (gsnProviderState === GsnWeb3ProviderState.PENDING) {
      return setIsSupport(null);
    }

    let canceled = false;
    setIsSupport(null);
    provider.hasSupportGsnContract(address).then(isSupport => {
      if (!canceled) {
        setIsSupport(isSupport);
      }
    });

    return () => {
      canceled = true;
    };
  }, [provider, address, gsnProviderState]);

  return isSupport;
}

/**
 * Does the GSN transaction support
 * @param tx
 */
export function useTxIsSupportGsn(tx: SimpleTransaction | null | undefined): boolean | null | undefined {
  const { provider } = useActiveWeb3React();
  const gsnProviderState = useGsnProviderState();
  const [isSupport, setIsSupport] = useState<boolean | null | undefined>(null);

  useEffect(() => {
    if (tx === undefined || gsnProviderState === GsnWeb3ProviderState.FAIL || !provider) {
      return setIsSupport(undefined);
    }
    if (tx === null || gsnProviderState === GsnWeb3ProviderState.PENDING) {
      return setIsSupport(null);
    }

    let canceled = false;
    setIsSupport(null);

    provider.hasSupportGsnTx(tx).then(isSupport => {
      if (!canceled) {
        setIsSupport(isSupport);
      }
    });

    return () => {
      canceled = true;
    };
  }, [provider, tx, gsnProviderState]);

  return isSupport;
}

/**
 * Returns transaction fee in selected GSN tokens or ETH
 * @param tx
 */
export function useTransactionFee(tx: SimpleTransaction | null | undefined): CurrencyAmount | null | undefined {
  const { provider } = useActiveWeb3React();
  const [token] = useGsnToken();
  const native = useCurrency('NATIVE');
  const isSupportGsn = useTxIsSupportGsn(tx);

  // Calculate rounding gas price
  const maxGasPrice = useMaxGasPrice();
  const maxGasPriceString = useMemo(() => (maxGasPrice ? Big(maxGasPrice.toString()).round(-8, 3).toString() : null), [maxGasPrice]);

  // Intermediate data for the calculation of fee
  const [txFeeAmount, setTxFeeAmount] = useState<string | null | undefined>(undefined);
  const [txFeeCurrency, setTxFeeCurrency] = useState<string | null | undefined>(undefined);

  const loading = useMemo(() => {
    return token === null || tx === null || isSupportGsn === null || maxGasPriceString === null || txFeeAmount === null || txFeeCurrency === null;
  }, [isSupportGsn, maxGasPriceString, token, tx, txFeeAmount, txFeeCurrency]);

  // Get fees
  useEffect(() => {
    if (!provider || !tx || !maxGasPriceString) {
      return;
    }

    setTxFeeAmount(null);
    setTxFeeCurrency(null);

    const cancelRequest = new AbortController();

    provider
      .getTxFee(tx.from, tx.to, tx.data, tx.value, maxGasPriceString, cancelRequest.signal)
      .then(({ fee, currency }) => {
        setTxFeeAmount(fee);
        setTxFeeCurrency(currency);
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          return;
        }
        setTxFeeAmount(undefined);
        setTxFeeCurrency(undefined);
        console.error(error);
      });

    return () => {
      cancelRequest.abort();
    };
  }, [maxGasPriceString, provider, token, tx]);

  return useMemo(() => {
    if (loading) {
      return null;
    }

    if (txFeeCurrency === 'NATIVE' && native) {
      return txFeeAmount ? toCurrencyAmount(native, txFeeAmount) : (txFeeAmount as null | undefined);
    }

    if (txFeeCurrency && token && compareAddresses(token.address, txFeeCurrency)) {
      return txFeeAmount ? toCurrencyAmount(token, txFeeAmount) : (txFeeAmount as null | undefined);
    }
    return undefined;
  }, [loading, native, token, txFeeAmount, txFeeCurrency]);
}
