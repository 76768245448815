import { useEffect, useState } from 'react';
import { BridgeSocketAsset, bridgeSocketToAssetsList } from '../../api';

export const useEvmToAssetsList = (fromChainId?: number, toChainId?: number): BridgeSocketAsset[] | null | undefined => {
  const [assets, setAssets] = useState<BridgeSocketAsset[] | null | undefined>();

  useEffect(() => {
    if (!fromChainId || !toChainId || fromChainId === toChainId) {
      return;
    }

    const abortController = new AbortController();

    setAssets(null);

    bridgeSocketToAssetsList(fromChainId, toChainId, abortController.signal)
      .then(setAssets)
      .catch(error => {
        if (error.name === 'AbortError') {
          return;
        }
        setAssets(undefined);
        console.error(error);
      });

    return () => {
      abortController.abort();
    };
  }, [fromChainId, toChainId]);

  return assets;
};
