import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AnimationSuccess from '../../assets/animations/loader-confirmed.json';
import AnimationWaiting from '../../assets/animations/waiting.json';
import { useTransactionStatus } from '../../state/transactions/transactions.hooks';
import { getEtherscanLink } from '../../utils';
import { ExternalLink } from '../external-link/external-link';
import { ViewOnScanLinkContent } from '../view-on-scan-link-content/view-on-scan-link-content';
import './modal-tx-sent-view.scss';
import { ModalTxErrorView } from './modal-tx-error-view';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';

enum ActiveView {
  Default,
  Waiting,
  Confirmed,
  Error,
}

export interface ModalTxSentViewProps {
  txHash?: string;
  message?: string;
  subText?: string;
  buttonText?: string;
  onDismiss?: () => void;
}

export function ModalTxSentView({ message, subText, buttonText, txHash, onDismiss }: ModalTxSentViewProps): JSX.Element {
  const { t } = useTranslation();
  const { chainId } = useActiveWeb3React();
  const status = useTransactionStatus(txHash);
  const [view, setView] = useState<ActiveView>(ActiveView.Default);

  useEffect(() => {
    if (status === undefined) {
      setView(ActiveView.Default);
    } else if (status === null || status.confirmations <= 0) {
      setView(ActiveView.Waiting);
    } else if (!status.isFail) {
      setView(ActiveView.Confirmed);
    } else {
      setView(ActiveView.Error);
    }
  }, [status]);

  return view === ActiveView.Error ? (
    <ModalTxErrorView caption={t('transaction_failure')} info={t('transaction_execution_error')} txHash={txHash} onDismiss={onDismiss} />
  ) : (
    <div className="modal modal-content modal-tx-sent-view">
      {view === ActiveView.Confirmed ? (
        <>
          <div className="icon">
            <Lottie animationData={AnimationSuccess} loop={false} className="animation-success" />
          </div>
          <div className="caption">
            <span>{message || t('transaction_confirmed')}</span>
          </div>
          <div className="confirmations">
            <span>{status?.confirmations || 0}</span>
            <span>&nbsp;</span>
            <span>{t('confirmations')}.</span>
          </div>
        </>
      ) : view === ActiveView.Waiting ? (
        <>
          <div className="icon">
            <Lottie animationData={AnimationWaiting} loop={true} className="animation-waiting" />
          </div>
          <div className="caption">
            <span>{t('transaction_submitted')}</span>
          </div>
          <div className="confirmations">
            <span>{t('waiting_confirmations')}</span>
            <span className="dots" />
          </div>
        </>
      ) : (
        <>
          <div className="icon">
            <Lottie animationData={AnimationSuccess} loop={false} className="animation-success" />
          </div>
          <div className="caption">
            <span>{message || t('transaction_submitted')}</span>
          </div>
        </>
      )}
      {subText && <div className="subtext">{subText}</div>}

      <div className="button-block">
        {chainId && txHash && (
          <ExternalLink href={getEtherscanLink(chainId, txHash, 'transaction')}>
            {/* <ViewOnScanLinkContent chainId={chainId} /> */}
            <button className="btn btn-secondary btn-lg">
              <span>
                <ViewOnScanLinkContent chainId={chainId} />
              </span>
            </button>
          </ExternalLink>
        )}
        <button className="btn btn-primary btn-lg" onClick={onDismiss}>
          <span>{buttonText || t('back')}</span>
        </button>
      </div>
    </div>
  );
}
