import { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers';
import { ChainId } from '@plasma/plasmaswap-sdk';
import { EVMNetworkConfig } from '@renproject/chains-ethereum';
import RenJS from '@renproject/ren';
import { Chain } from '@renproject/utils';
import { useMemo } from 'react';
import { REN_EVM_NETWORK_IDS, REN_NETWORKS, RPC_URLS } from '../../constants';
import { BridgeRenNetworkId } from '../../types';
import { useIsMainnet } from '../use-is-mainnet';
import { useActiveWeb3React } from '../web3/use-active-web3-react';

function getRenNetwork(networkId: BridgeRenNetworkId, type: 'mainnet' | 'testnet', signer?: JsonRpcSigner): Chain {
  const network = REN_NETWORKS[networkId];

  // For EVM networks
  if (REN_EVM_NETWORK_IDS.includes(networkId)) {
    const hexChainId = (network.configMap[type] as EVMNetworkConfig)?.config?.chainId;
    const chainId: ChainId = parseInt(hexChainId, 16);
    const rpcUrl = RPC_URLS[chainId];
    if (!rpcUrl) {
      throw new Error(`Unsupported bridge chain id - "${chainId}"`);
    }
    const provider = new JsonRpcProvider(rpcUrl);
    return new (network as any)({ network: type, provider, signer });
  }

  // For other networks
  return new (network as any)({ network: type });
}

export const useRenExchanger = (fromNetworkId?: BridgeRenNetworkId, toNetworkId?: BridgeRenNetworkId): RenJS | undefined => {
  const { provider } = useActiveWeb3React();
  const isMainnet = useIsMainnet();

  const signer = useMemo(() => provider?.getSigner(), [provider]);

  const fromNetwork = useMemo(() => {
    if (!fromNetworkId || isMainnet === undefined || !signer) {
      return undefined;
    }
    try {
      return getRenNetwork(fromNetworkId, isMainnet ? 'mainnet' : 'testnet', signer);
    } catch (e) {
      console.warn(e.message);
      return undefined;
    }
  }, [fromNetworkId, isMainnet, signer]);

  const toNetwork = useMemo(() => {
    if (!toNetworkId || isMainnet === undefined || !signer) {
      return undefined;
    }
    try {
      return getRenNetwork(toNetworkId, isMainnet ? 'mainnet' : 'testnet', signer);
    } catch (e) {
      console.warn(e.message);
      return undefined;
    }
  }, [toNetworkId, isMainnet, signer]);

  return useMemo(() => {
    if (!fromNetwork || !toNetwork || isMainnet === undefined) {
      return undefined;
    }
    return new RenJS(isMainnet ? 'mainnet' : 'testnet').withChains(fromNetwork, toNetwork);
  }, [fromNetwork, isMainnet, toNetwork]);
};
