import { ChainId } from '@plasma/plasmaswap-sdk';
import { useMemo } from 'react';
import { useConnect } from 'wagmi';

const CHAIN_IDS: ChainId[] = [ChainId.MAINNET, ChainId.MATIC, ChainId.BSC, ChainId.AVALANCHE, ChainId.FANTOM, ChainId.CELO, ChainId.OPTIMISM];

export function useSupportedChains(): ChainId[] {
  const { activeConnector } = useConnect();

  return useMemo(() => {
    if (!activeConnector?.chains) {
      return CHAIN_IDS;
    }
    const supportedChainIds: ChainId[] = activeConnector.chains.map(chain => chain.id);
    return CHAIN_IDS.filter(chainId => supportedChainIds.includes(chainId));
  }, [activeConnector?.chains]);
}
