import * as d3 from 'd3';
import React, { useCallback, useMemo, useState } from 'react';
import { Tooltip } from '../tooltip/tooltip';
import './btn-refresh.scss';

const REFRESH_INTERVAL = 15_000;

interface Refresher {
  start: (interval: number) => void;
  stop: () => void;
}

export interface BtnRefreshProps {
  // Refresh interval (ms)
  interval?: number;
  // Refresh callback
  onRefresh: () => void;
  // Button tooltip
  tooltip?: string;
}

export function BtnRefresh({ interval = REFRESH_INTERVAL, onRefresh, tooltip }: BtnRefreshProps): JSX.Element {
  const [disabled, setDisabled] = useState<boolean>(false);

  const refresher: Refresher = useMemo(() => {
    const loop = (duration: number) => {
      d3.select('#arrow-progress')
        .attr('stroke-dashoffset', 30)
        .transition('loader')
        .duration(duration)
        .ease(d3.easeLinear)
        .attr('stroke-dashoffset', 0)
        .on('end', () => {
          refresher.stop();
        });
    };

    const start = (interval: number) => {
      setDisabled(true);
      loop(interval);
    };

    const stop = () => {
      setDisabled(false);
      d3.select('#arrow-progress').interrupt('loader').attr('stroke-dashoffset', 0);
    };

    return { start, stop };
  }, []);

  const onClick = useCallback(() => {
    if (disabled) {
      return;
    }

    setTimeout(() => {
      onRefresh();

      d3.select('#arrow-svg')
        .style('transform', 'rotate(0)')
        .transition('rotation')
        .duration(800)
        .ease(d3.easeBackInOut)
        .styleTween('transform', () => {
          return (t: number) => `rotate(${360 * t}deg)`;
        })
        .on('end', () => {
          refresher.start(interval);
        });
    }, 100);
  }, [onRefresh, refresher, interval, disabled]);

  return (
    <button className="btn btn-refresh" disabled={disabled} onClick={onClick}>
      <BtnRefreshWrap tooltip={disabled ? '' : tooltip}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" id="arrow-svg">
          <defs>
            <path
              id="path-arrow"
              d="M 17.148437,7.3261719 C 15.045121,7.0478696 12.91175,7.5436918 11.146484,8.7207031 9.3812705,9.897691 8.1025723,11.675981 7.5507812,13.724609 c -0.5517924,2.048634 -0.3395866,4.22844 0.5957032,6.132813 0.9352885,1.904371 2.5297506,3.40494 4.4882816,4.220703 a 0.750075,0.750075 0 0 0 0.002,0 c 1.958585,0.815631 4.145654,0.892261 6.15625,0.214844 2.010592,-0.677416 3.707796,-2.063859 4.773437,-3.898438 a 0.75,0.75 0 0 0 -0.271484,-1.02539 0.75,0.75 0 0 0 -1.025391,0.271484 c -0.883157,1.520418 -2.288874,2.669086 -3.955078,3.230469 -1.6662,0.561382 -3.478351,0.498233 -5.101562,-0.177735 C 11.589825,22.017324 10.267297,20.773538 9.4921875,19.195312 8.717079,17.617089 8.5427134,15.812997 9,14.115234 9.4572879,12.417467 10.515653,10.94414 11.978516,9.96875 13.441447,8.9933234 15.208092,8.5818628 16.951172,8.8125 c 1.743078,0.230628 3.343105,1.0858692 4.501953,2.408203 0.831759,0.948974 1.283125,2.118463 1.535156,3.337891 l -2.115234,-1.208985 a 0.75,0.75 0 0 0 -1.023438,0.277344 0.75,0.75 0 0 0 0.277344,1.023438 l 3.5,2 a 0.75,0.75 0 0 0 0.189453,0.02344 A 0.75,0.75 0 0 0 24,16.75 a 0.75,0.75 0 0 0 0.06055,-0.02539 0.75,0.75 0 0 0 0.09766,-0.0059 0.75,0.75 0 0 0 0.189453,-0.111328 0.75,0.75 0 0 0 0.208985,-0.138672 0.75,0.75 0 0 0 0.05273,-0.0332 l 2.5,-3.5 a 0.75,0.75 0 0 0 -0.173828,-1.044922 0.75,0.75 0 0 0 -1.044922,0.173828 l -1.480469,2.072266 C 24.093937,12.711539 23.555523,11.34533 22.580078,10.232422 21.181729,8.6367988 19.251755,7.6044634 17.148437,7.3261719 Z"
              stroke="none"
              fill="none"
            />
            <clipPath id="path-arrow-clip">
              <use xlinkHref="#path-arrow" />
            </clipPath>
          </defs>
          <path
            className="arrow-bg"
            d="M 17.148437,7.3261719 C 15.045121,7.0478696 12.91175,7.5436918 11.146484,8.7207031 9.3812705,9.897691 8.1025723,11.675981 7.5507812,13.724609 c -0.5517924,2.048634 -0.3395866,4.22844 0.5957032,6.132813 0.9352885,1.904371 2.5297506,3.40494 4.4882816,4.220703 a 0.750075,0.750075 0 0 0 0.002,0 c 1.958585,0.815631 4.145654,0.892261 6.15625,0.214844 2.010592,-0.677416 3.707796,-2.063859 4.773437,-3.898438 a 0.75,0.75 0 0 0 -0.271484,-1.02539 0.75,0.75 0 0 0 -1.025391,0.271484 c -0.883157,1.520418 -2.288874,2.669086 -3.955078,3.230469 -1.6662,0.561382 -3.478351,0.498233 -5.101562,-0.177735 C 11.589825,22.017324 10.267297,20.773538 9.4921875,19.195312 8.717079,17.617089 8.5427134,15.812997 9,14.115234 9.4572879,12.417467 10.515653,10.94414 11.978516,9.96875 13.441447,8.9933234 15.208092,8.5818628 16.951172,8.8125 c 1.743078,0.230628 3.343105,1.0858692 4.501953,2.408203 0.831759,0.948974 1.283125,2.118463 1.535156,3.337891 l -2.115234,-1.208985 a 0.75,0.75 0 0 0 -1.023438,0.277344 0.75,0.75 0 0 0 0.277344,1.023438 l 3.5,2 a 0.75,0.75 0 0 0 0.189453,0.02344 A 0.75,0.75 0 0 0 24,16.75 a 0.75,0.75 0 0 0 0.06055,-0.02539 0.75,0.75 0 0 0 0.09766,-0.0059 0.75,0.75 0 0 0 0.189453,-0.111328 0.75,0.75 0 0 0 0.208985,-0.138672 0.75,0.75 0 0 0 0.05273,-0.0332 l 2.5,-3.5 a 0.75,0.75 0 0 0 -0.173828,-1.044922 0.75,0.75 0 0 0 -1.044922,0.173828 l -1.480469,2.072266 C 24.093937,12.711539 23.555523,11.34533 22.580078,10.232422 21.181729,8.6367988 19.251755,7.6044634 17.148437,7.3261719 Z"
          />
          <g clipPath="url(#path-arrow-clip)">
            <circle
              id="arrow-progress"
              className="arrow-progress"
              cx="16"
              cy="16"
              r="5"
              transform="rotate(365,16,16)"
              fill="none"
              stroke="currentColor"
              strokeWidth="16"
              strokeDasharray="30"
              strokeDashoffset="0"
            />
          </g>
        </svg>
      </BtnRefreshWrap>
    </button>
  );
}

function BtnRefreshWrap({ tooltip, children }: { tooltip?: string; children: React.ReactNode }): JSX.Element {
  return tooltip ? (
    <Tooltip text={tooltip} placement="top">
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
}
