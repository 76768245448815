import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { useENS } from '../../hooks/use-ens';
import { getEtherscanLink } from '../../utils';
import { ExternalLink } from '../external-link/external-link';
import './address-input-panel.scss';
import { ViewOnScanLinkContent } from '../view-on-scan-link-content/view-on-scan-link-content';
import { ChainId } from '@plasma/plasmaswap-sdk';

export function AddressInputPanel({
  id,
  value,
  onChange,
  customChainId,
  customValidator,
  disabled = false,
}: {
  id?: string;
  // the typed string value
  value: string;
  // triggers whenever the typed value changes
  onChange: (value: string) => void;
  // Overrides the active chainId
  customChainId?: ChainId;
  customValidator?: (value: string) => boolean;
  disabled?: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const { chainId } = useActiveWeb3React();
  const { address, loading } = useENS(value);
  const activeChainId = customChainId || chainId;

  const handleInput = useCallback(
    event => {
      const input = event.target.value;
      const withoutSpaces = input.replace(/\s+/g, '');
      onChange(withoutSpaces);
    },
    [onChange],
  );

  const error = customValidator ? customValidator(value) : Boolean(value.length > 0 && !loading && !address);

  return (
    <div className={`address-input-panel ${error ? 'is-error' : ''}`} id={id}>
      {address && activeChainId && (
        <div className="label-row">
          <ExternalLink className="address" href={getEtherscanLink(activeChainId, address, 'address')}>
            <ViewOnScanLinkContent chainId={activeChainId} />
          </ExternalLink>
        </div>
      )}

      <div className="input-row">
        <input
          className="recipient-address-input"
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          placeholder={t('wallet_address_or_ens_name')}
          pattern="^(0x[a-fA-F0-9]{40})$|^([13]|bc1)[A-HJ-NP-Za-km-z1-9]{27,34}$"
          onChange={handleInput}
          value={value}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
