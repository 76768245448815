import { Contract, ContractInterface } from '@ethersproject/contracts';
import { ChainId, ERC20_ABI, WNATIVE } from '@plasma/plasmaswap-sdk';
import { useMemo } from 'react';
import { HYPER_DEX_ROUTER_ADDRESS, TOKENS_APPROVER_CONTRACTS, XPPAY_EXCHANGE_CONTRACT_ADDRESS, ZERO_ADDRESS, ZERO_EX_PROXY_ADDRESS } from '../constants';
import { ARGENT_WALLET_DETECTOR_ABI, ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS } from '../constants/abis/argent-wallet-detector';
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20';
import { HYPER_DEX_ROUTER_INTERFACE } from '../constants/abis/hyper-dex-router';
import { TOKENS_APPROVER_INTERFACE } from '../constants/abis/tokens-approver';
import { XPPAY_EXCHANGE_RATE_INTERFACE } from '../constants/abis/xppay-exchange';
import { ZERO_EX_PROXY_INTERFACE } from '../constants/abis/zero-ex-proxy';
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall';
import { useGsnProviderState } from '../state/gas-station/gas-station.hooks';
import { GsnWeb3ProviderState } from '../types';
import { getContract } from '../utils';
import WETH_ABI from '../constants/abis/weth.json';
import { useActiveWeb3React } from './web3/use-active-web3-react';
import ENS_ABI from '../constants/abis/ens-registrar.json';
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json';

/**
 * Creates contract or gets from cache
 * @param address
 * @param ABI
 * @param withSignerIfPossible
 */
export function useContract(address?: string, ABI?: ContractInterface, withSignerIfPossible = true): Contract | null {
  const { provider } = useActiveWeb3React();
  const signerOrProvider = useMemo(() => (withSignerIfPossible ? provider?.getSigner() : provider), [provider, withSignerIfPossible]);

  return useMemo(() => {
    if (!address || address === ZERO_ADDRESS || !ABI || !signerOrProvider) {
      return null;
    }

    try {
      return getContract(address, ABI, signerOrProvider);
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, signerOrProvider]);
}

/**
 * Create contracts with save ABI
 * @param addresses
 * @param ABI
 * @param withSignerIfPossible
 */
// export function useContractsSameAbi(addresses: string[], ABI: ContractInterface, withSignerIfPossible = true): Contract[] {
//   const { library, account } = useActiveWeb3React();

//   return useMemo(() => {
//     if (!addresses || !addresses.length || !ABI || !library) {
//       return [];
//     }
//     try {
//       return addresses.map(address => {
//         return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined);
//       });
//     } catch (error) {
//       console.error('Failed to get contract', error);
//       return [];
//     }
//   }, [addresses, ABI, library, withSignerIfPossible, account]);
// }

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React();
  return useContract(chainId ? WNATIVE[chainId].address : undefined, WETH_ABI, withSignerIfPossible);
}

export function useArgentWalletDetectorContract(): Contract | null {
  const { chainId } = useActiveWeb3React();
  return useContract(chainId === ChainId.MAINNET ? ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS : undefined, ARGENT_WALLET_DETECTOR_ABI, false);
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React();
  let address: string | undefined;
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.GÖRLI:
      case ChainId.ROPSTEN:
      case ChainId.RINKEBY:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e';
        break;
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible);
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible);
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible);
}

// export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
//   return useContract(pairAddress, SWAP_PAIR_ABI, withSignerIfPossible);
// }

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React();
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false);
}

// /**
//  * Returns contract for claim tokens
//  * @param account
//  */
// export function useVestingContract(account: string | null | undefined): Contract | null {
//   const { chainId } = useActiveWeb3React();
//   const address = chainId && account ? PPAY_CLAIM_ADDRESSES[chainId][account] : undefined;
//   return useContract(address, ppayVestingJson.abi, true);
// }

// export function usePlasmaStackingContract(address?: string, withSignerIfPossible = true): Contract | null {
//   return useContract(address, PLASMA_STACKING_INTERFACE, withSignerIfPossible);
// }

// export function useAaveLendingPoolContract(address?: string, withSignerIfPossible = true): Contract | null {
//   return useContract(address, AAVE_LENDING_POOL_INTERFACE, withSignerIfPossible);
// }

// export function useVaultRewardsContract(address?: string, withSignerIfPossible = true): Contract | null {
//   return useContract(address, YEARN_VAULT_REWARDS_INTERFACE, withSignerIfPossible);
// }

// export function useCompoundRewards(address?: string, withSignerIfPossible = true): Contract | null {
//   return useContract(address, COMPOUND_REWARDS_INTERFACE, withSignerIfPossible);
// }

// export function useStackingRewardsContract(address?: string, withSignerIfPossible = true): Contract | null {
//   return useContract(address, STAKING_REWARDS_INTERFACE, withSignerIfPossible);
// }

// export function useYearnV2VaultContract(address?: string, withSignerIfPossible = true): Contract | null {
//   return useContract(address, YEARN_VAULT_REWARDS_V2_INTERFACE, withSignerIfPossible);
// }

// export function useSavingsContract(info: SavingsInfo, withSignerIfPossible = true): Contract | null {
//   const ABI = useMemo(() => {
//     switch (info.type) {
//       case SavingContractType.YEARN_VAULT_REWARDS:
//         return YEARN_VAULT_REWARDS_INTERFACE;
//       case SavingContractType.STAKING_REWARDS:
//         return STAKING_REWARDS_INTERFACE;
//       case SavingContractType.PLASMA_STACKING:
//         return PLASMA_STACKING_INTERFACE;
//       case SavingContractType.YEARN_V2_VAULT:
//         return YEARN_VAULT_REWARDS_V2_INTERFACE;
//       default:
//         return;
//     }
//   }, [info]);
//   return useContract(info.address, ABI, withSignerIfPossible);
// }

export function useXPpayExchangeRateContract(): Contract | null {
  const { chainId } = useActiveWeb3React();
  const address = chainId ? XPPAY_EXCHANGE_CONTRACT_ADDRESS[chainId] : undefined;

  return useContract(address, XPPAY_EXCHANGE_RATE_INTERFACE);
}

// export function useHyperLimitContract(): Contract | null {
//   const { chainId } = useActiveWeb3React();
//   return useContract(chainId ? HYPER_LIMIT_CONF[chainId]?.contract : undefined, HYPERLIMIT_EXCHANGE_INTERFACE);
// }

// export function useSpaceportContract(address?: string, withSignerIfPossible = true): Contract | null {
//   const conf = useSpaceportContractsConf();

//   const abi = useMemo(() => {
//     if (!conf || !address) {
//       return undefined;
//     }
//     return conf.deprecatedSpaceports.includes(address.toLowerCase()) ? SPACEPORT_LEGACY_INTERFACE : SPACEPORT_INTERFACE;
//   }, [address, conf]);
//   return useContract(address, abi, withSignerIfPossible);
// }

// export function useSpaceportSettingsContract(): Contract | null {
//   const conf = useSpaceportContractsConf();
//   return useContract(conf?.settings, SPACEPORT_SETTINGS_INTERFACE);
// }

// export function useSpaceportFactoryContract(): Contract | null {
//   const conf = useSpaceportContractsConf();
//   return useContract(conf?.factory, SPACEPORT_FACTORY_INTERFACE);
// }

// export function useSpaceportGeneratorContract(lp?: LiquidityProvider): Contract | null {
//   const conf = useSpaceportContractsConf();
//   return useContract(lp !== undefined ? conf?.dex[lp]?.generator : undefined, SPACEPORT_GENERATOR_INTERFACE);
// }

// export function useSpaceportLockForwarderContract(lp?: LiquidityProvider): Contract | null {
//   const conf = useSpaceportContractsConf();
//   return useContract(lp !== undefined ? conf?.dex[lp]?.lockForwarder : undefined, SPACEPORT_LOCK_FORWARDER);
// }

// export function useSpaceportLiquidityLockerContract(lp?: LiquidityProvider): Contract | null {
//   const conf = useSpaceportContractsConf();
//   return useContract(lp !== undefined ? conf?.dex[lp]?.liquidityLocker : undefined, SPACEPORT_LIQUIDITY_LOCKER);
// }

// export function useSpaceportHelperContract(lp?: LiquidityProvider): Contract | null {
//   const conf = useSpaceportContractsConf();
//   return useContract(lp !== undefined ? conf?.dex[lp]?.helper : undefined, SPACEPORT_HELPER_INTERFACE);
// }

// export function useRebalanceLiquidityContract(liquidityProvider?: LiquidityProvider): Contract | null {
//   const { chainId } = useActiveWeb3React();

//   const abiInterface = useMemo(() => {
//     switch (liquidityProvider) {
//       case LiquidityProvider.UNISWAP:
//         return PLASMA_REBALANSER_INTERFACE;
//       case LiquidityProvider.SUSHISWAP:
//         return SUSHI_REBALANSER_INTERFACE;
//       case LiquidityProvider.PLASMA:
//         return PLASMA_REBALANSER_INTERFACE;
//       default:
//         return undefined;
//     }
//   }, [liquidityProvider]);

//   const address = useMemo(() => {
//     if (!chainId || !liquidityProvider) {
//       return undefined;
//     }
//     return REBALANSER_ADDRESSES[chainId]?.[liquidityProvider];
//   }, [liquidityProvider, chainId]);

//   return useContract(address, abiInterface);
// }

export function use0xProxyContract(): Contract | null {
  const { chainId } = useActiveWeb3React();
  const address = useMemo(() => (chainId ? ZERO_EX_PROXY_ADDRESS[chainId] : undefined), [chainId]);
  return useContract(address, ZERO_EX_PROXY_INTERFACE);
}

export function useHyperDexRouterContract(): Contract | null {
  const { chainId } = useActiveWeb3React();
  const address = useMemo(() => (chainId ? HYPER_DEX_ROUTER_ADDRESS[chainId] : undefined), [chainId]);
  return useContract(address, HYPER_DEX_ROUTER_INTERFACE, true);
}

// export function useTransferNftContract(address?: string, standard?: NftStandard): Contract | null {
//   return useContract(address, standard === NftStandard.ERC1155 ? ERC1155_ABI : ERC721_ABI);
// }

// export function useApproveEip712Contract(address?: string): Contract | null | undefined {
//   return useContract(address, APPROVE_EIP712_INTERFACE, false);
// }

export function useTokensApproverContract(): Contract | null {
  const { chainId } = useActiveWeb3React();
  const address = useMemo(() => (chainId ? TOKENS_APPROVER_CONTRACTS[chainId] : undefined), [chainId]);

  return useContract(address, TOKENS_APPROVER_INTERFACE, false);
}

export function useGasStationContract(): Contract | null | undefined {
  const { provider } = useActiveWeb3React();
  const gsnProviderState = useGsnProviderState();

  return useMemo(() => {
    if (!provider || gsnProviderState === GsnWeb3ProviderState.FAIL) {
      return undefined;
    }
    if (gsnProviderState === GsnWeb3ProviderState.PENDING) {
      return null;
    }
    return provider.gasStationContract;
  }, [provider, gsnProviderState]);
}
