import React, { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from '../../../hooks/use-on-click-outside';
import { getEtherscanLink, shortenHash } from '../../../utils';
import './wallet-address.scss';
import copy from 'copy-to-clipboard';
import { ExternalLink } from '../../external-link/external-link';
import { ViewOnScanLinkContent } from '../../view-on-scan-link-content/view-on-scan-link-content';
import { useActiveWeb3React } from '../../../hooks/web3/use-active-web3-react';
import { useDisconnect } from 'wagmi';

export const WalletAddress: FC = () => {
  const { account, chainId } = useActiveWeb3React();
  const { disconnect } = useDisconnect();

  const [menuIsOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>();
  useOnClickOutside(ref, menuIsOpen ? () => setMenuOpen(false) : undefined);

  const onCopy = useCallback(() => {
    setMenuOpen(false);
    if (account) {
      copy(account);
    }
  }, [account]);

  return (
    <div
      ref={ref as any}
      className="address"
      onClick={e => {
        setMenuOpen(!menuIsOpen);
        e.stopPropagation();
      }}
    >
      {shortenHash(account, 4)}

      {menuIsOpen && (
        <div className="wallet-address-menu" onClick={e => e.stopPropagation()}>
          <ExternalLink href={`https://apy.plasma.finance/#/portfolio/${account}`}>
            <button className="menu-item" onClick={() => setMenuOpen(false)}>
              <i className="pi pi-portfolio-menu" />
              {t('portfolio')}
            </button>
          </ExternalLink>
          <button className="menu-item" onClick={() => onCopy()}>
            <i className="pi pi-copy" />
            {t('copy_address')}
          </button>
          {chainId && account && (
            <ExternalLink href={getEtherscanLink(chainId, account, 'address')}>
              <button className="menu-item" onClick={() => setMenuOpen(false)}>
                <i className="pi pi-open-new-alt" />
                <ViewOnScanLinkContent chainId={chainId} />
              </button>
            </ExternalLink>
          )}
          <button
            className="menu-item disconnect"
            onClick={() => {
              setMenuOpen(false);
              disconnect();
            }}
          >
            <i className="pi pi-disconnect" />
            <span>{t('disconnect')}</span>
          </button>
        </div>
      )}
    </div>
  );
};
