import React, { FC } from 'react';
import OnramperWidget from '@onramper/widget';
import { getWidgetParam } from '../../utils/get-query-params';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';

export const FiatTab: FC = () => {
  const darkMode = Number(getWidgetParam('darkTheme') || 0);
  const { account } = useActiveWeb3React();

  return (
    <div
      style={{
        width: '430px',
        height: '660px',
        marginLeft: '-30px',
        marginTop: '-15px',
      }}
    >
      <OnramperWidget
        API_KEY="pk_prod_ytue0KpWx6omTfd0SxGC1Y5PA8WI_47xI5WA_dSHsiU0"
        color="#000"
        defaultAddrs={{ ETH: { address: account } }}
        defaultCrypto="ETH"
        isAddressEditable
        darkMode={darkMode}
        supportSell
      />
    </div>
  );
};
