import Big from 'big.js';

const PERCENT_MULTIPLIER = 100;
const AMOUNT_MULTIPLIER = PERCENT_MULTIPLIER ** 2;

/**
 * Calculate the swap commission as it is done in the contract. For exact input.
 * @param totalSell - Total sell raw amount with fee. (e.g. 1 ETH to raw 1000000000000000000)
 * @param feePercent - Fees in percent. The maximum number of decimal places is 2. (e.g. 0.05)
 */
export function zeroExSwapFeesExactInput(totalSell: string, feePercent: string): string {
  const feePercentRaw = Big(feePercent).times(PERCENT_MULTIPLIER).round(0, 0);
  const sellApproxAmount = Big(totalSell).div(Big(AMOUNT_MULTIPLIER).add(feePercentRaw)).mul(AMOUNT_MULTIPLIER).toFixed(0, 0);
  return Big(sellApproxAmount).div(AMOUNT_MULTIPLIER).round(0, 0).mul(feePercentRaw).toFixed(0, 0);
}

/**
 * Calculate the swap commission as it is done in the contract. For exact output.
 * @param sell - Sell raw amount without fee. (e.g. 1 ETH to raw 1000000000000000000)
 * @param feePercent - Fees in percent. The maximum number of decimal places is 2. (e.g. 0.05)
 */
export function zeroExSwapFeesExactOutput(sell: string, feePercent: string): string {
  const feePercentRaw = Big(feePercent).times(PERCENT_MULTIPLIER).round(0, 0);
  return Big(sell).div(AMOUNT_MULTIPLIER).round(0, 0).times(feePercentRaw).toFixed(0, 0);
}
