import { Trade0xLiquiditySource } from '@plasma/plasmaswap-sdk';
import { createReducer } from '@reduxjs/toolkit';
import { DEFAULT_ALLOWED_SLIPPAGE, DEFAULT_TRANSACTION_EXPIRY } from '../../constants';
import { SerializedToken } from '../../types';
import { WidgetTab } from '../../types/widget-tabs';
import { getWidgetTab } from '../../utils/get-query-params';
import { updateVersion } from '../global/global.actions';
import {
  addSerializedToken,
  changeTheme,
  removeSerializedToken,
  setActiveTab,
  setExclude0xLiquiditySources,
  updateCurrency,
  updateExchangeRates,
  updateReferrals,
  updateSlippageTolerance,
  updateTransactionExpiry,
  updateUsedWallet,
} from './user.actions';

const currentTimestamp = () => new Date().getTime();
export interface UserState {
  activeTab: WidgetTab;
  // User's local currency
  currency: string;
  isDarkTheme: boolean;
  exchangeRates: { [key: string]: string };
  // Excluded 0x liquidity providers
  exclude0xLiquiditySources: Trade0xLiquiditySource[];
  slippageTolerance: number;
  timestamp: number;
  // Deadline set by user in seconds, used in all txns
  transactionExpiry: number;
  // Saved user's tokens
  tokens: {
    [chainId: number]: {
      [address: string]: SerializedToken;
    };
  };
  // Stored encoded referral addresses
  referrals?: string[];
  // Time of the user's first visit
  firstVisit?: number;
  // Wallet addresses that the user has ever used. These addresses should not be in the list of referrals.
  usedWallets?: string[];
}

export const initialState: UserState = {
  activeTab: getWidgetTab(),
  tokens: {},
  timestamp: currentTimestamp(),
  currency: 'USD',
  exchangeRates: {},
  isDarkTheme: false,
  exclude0xLiquiditySources: [],
  slippageTolerance: DEFAULT_ALLOWED_SLIPPAGE,
  transactionExpiry: DEFAULT_TRANSACTION_EXPIRY,
};

export default createReducer(initialState, builder =>
  builder
    .addCase(updateVersion, state => {
      // slippage isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (typeof state.slippageTolerance !== 'number') {
        state.slippageTolerance = initialState.slippageTolerance;
      }

      // deadline isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (typeof state.transactionExpiry !== 'number') {
        state.transactionExpiry = initialState.transactionExpiry;
      }

      if (!state.exclude0xLiquiditySources) {
        state.exclude0xLiquiditySources = initialState.exclude0xLiquiditySources;
      }

      if (typeof state.currency !== 'string') {
        state.currency = 'USD';
      }

      if (state.isDarkTheme === undefined) {
        state.isDarkTheme = false;
      } else {
        if (state.isDarkTheme) {
          document.body.classList.add('dark-mode');
        } else {
          document.body.classList.remove('dark-mode');
        }
      }
      state.exchangeRates = {};

      if (!state.tokens) {
        state.tokens = initialState.tokens;
      }
      if (!state.activeTab) {
        state.activeTab = initialState.activeTab;
      }

      if (typeof state.referrals === 'string') {
        state.referrals = undefined;
      }

      return state;
    })
    .addCase(updateCurrency, (state, action) => {
      state.currency = action.payload.toUpperCase();
    })
    .addCase(updateExchangeRates, (state, action) => {
      state.exchangeRates = action.payload;
    })
    .addCase(changeTheme, (state, action) => {
      state.isDarkTheme = action.payload;
    })
    .addCase(setExclude0xLiquiditySources, (state, action) => {
      state.exclude0xLiquiditySources = action.payload;
      return state;
    })
    .addCase(updateSlippageTolerance, (state, action) => {
      state.slippageTolerance = action.payload;
      state.timestamp = currentTimestamp();
    })
    .addCase(updateTransactionExpiry, (state, action) => {
      state.transactionExpiry = action.payload;
      state.timestamp = currentTimestamp();
    })
    .addCase(addSerializedToken, (state, { payload: serializedToken }) => {
      state.tokens[serializedToken.chainId] = state.tokens[serializedToken.chainId] || {};
      state.tokens[serializedToken.chainId][serializedToken.address] = serializedToken;
      state.timestamp = currentTimestamp();
    })
    .addCase(removeSerializedToken, (state, { payload: { address, chainId } }) => {
      state.tokens[chainId] = state.tokens[chainId] || {};
      delete state.tokens[chainId][address];
      state.timestamp = currentTimestamp();
    })
    .addCase(setActiveTab, (state, action) => {
      const newActiveTab = action.payload;
      state.activeTab = newActiveTab;
      return state;
    })
    .addCase(updateUsedWallet, (state, { payload: account }) => {
      if (state.referrals) {
        state.referrals = state.referrals.filter(i => i !== account);
      }
      if (!state.usedWallets?.includes(account)) {
        state.usedWallets = (state.usedWallets || []).concat([account]);
      }
      return state;
    })
    .addCase(updateReferrals, (state, { payload }) => {
      if (!state.firstVisit) {
        state.firstVisit = Math.round(Date.now() / 1000);

        if (payload) {
          const referrals = state.usedWallets ? payload.filter(i => !(state.usedWallets as string[]).includes(i)) : payload;
          if (referrals.length) {
            state.referrals = referrals;
          }
        }
      }
      return state;
    }),
);
