import { TOKENS_TAGS } from '../constants';
import { TokenTag } from '../types';

export function getTokenTags(tagsIds: string[] = []): TokenTag[] {
  return tagsIds
    .map(id => {
      const tag = TOKENS_TAGS[id];
      if (!tag) {
        return null;
      }
      return { id, name: tag.name, description: tag.description };
    })
    .filter(Boolean) as TokenTag[];
}
