import { ChainId, Currency, CurrencyAmount, NATIVE, Native, toCurrencyAmount } from '@plasma/plasmaswap-sdk';
import { useEffect, useState } from 'react';
import { bridgeSocketGetQuote, BridgeSocketQuote, BridgeSocketQuoteRoute } from '../../api';
import { ETH_ADDRESS } from '../../constants';
import { BridgeEvmQuote, BridgeEvmRouterType } from '../../types';
import { useActiveWeb3React } from '../web3/use-active-web3-react';

const SORT_FUNCTION: { [type in BridgeEvmRouterType]: (a: BridgeSocketQuoteRoute, b: BridgeSocketQuoteRoute) => number } = {
  [BridgeEvmRouterType.fastest]: (a: BridgeSocketQuoteRoute, b: BridgeSocketQuoteRoute) => a.serviceTime - b.serviceTime,
  [BridgeEvmRouterType.lowFee]: (a: BridgeSocketQuoteRoute, b: BridgeSocketQuoteRoute) => Number(b.toAmount) - Number(a.toAmount),
  [BridgeEvmRouterType.highReturn]: (a: BridgeSocketQuoteRoute, b: BridgeSocketQuoteRoute) => Number(a.totalGasFeesInUsd) - Number(b.totalGasFeesInUsd),
};

export const useEvmQuote = (routerType: BridgeEvmRouterType, fromAmount?: CurrencyAmount | null, toCurrency?: Currency | null): BridgeEvmQuote | null | undefined => {
  const { account } = useActiveWeb3React();
  const [quote, setQuote] = useState<BridgeEvmQuote | null | undefined>();
  const [rawQuote, setRawQuote] = useState<BridgeSocketQuote | null | undefined>();

  // Fetch quote
  useEffect(() => {
    if (!account || !fromAmount || !toCurrency) {
      return;
    }

    setRawQuote(null);

    const fromCurrency = fromAmount.currency;
    const abortController = new AbortController();
    const query = {
      fromTokenAddress: fromCurrency instanceof Native ? ETH_ADDRESS : fromCurrency.address,
      fromChainId: fromCurrency.chainId.toString(),
      toTokenAddress: toCurrency instanceof Native ? ETH_ADDRESS : toCurrency.address,
      toChainId: toCurrency.chainId.toString(),
      fromAmount: fromAmount.raw.toString(),
      userAddress: account,
    };

    bridgeSocketGetQuote(query, abortController.signal)
      .then(setRawQuote)
      .catch(error => {
        if (error.name === 'AbortError') {
          return;
        }
        setRawQuote(undefined);
        console.error(error);
      });

    return () => {
      abortController.abort();
    };
  }, [account, fromAmount, toCurrency]);

  // Find best router
  useEffect(() => {
    if (!rawQuote) {
      return setQuote(rawQuote);
    }
    if (!rawQuote.routes.length || !toCurrency || !fromAmount) {
      return setQuote(undefined);
    }

    const [route] = rawQuote.routes.sort(SORT_FUNCTION[routerType]);

    const toAmount = toCurrencyAmount(toCurrency, route.toAmount);
    const fee = toCurrencyAmount(NATIVE[rawQuote.fromChainId as ChainId], route.chainGasBalances[rawQuote.fromChainId].minGasBalance);

    setQuote({
      toAmount,
      fee,
      feeInUsd: route.totalGasFeesInUsd,
      serviceTime: route.serviceTime,
      maxServiceTime: route.maxServiceTime,
      bridgeName: route.usedBridgeNames[0] || 'unknown',
      route,
    });
  }, [fromAmount, rawQuote, routerType, toCurrency]);

  return quote;
};
