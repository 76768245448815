import {
  // Fraction,
  // toCurrencyAmount, Trade
  // JSBI,
  // Percent,
  BaseTrade,
  CurrencyAmount,
  Fraction,
  JSBI,
  Percent,
  toCurrencyAmount,
  Trade,
} from '@plasma/plasmaswap-sdk';
import { ALLOWED_PRICE_IMPACT_HIGH, ALLOWED_PRICE_IMPACT_LOW, ALLOWED_PRICE_IMPACT_MEDIUM, BLOCKED_PRICE_IMPACT_NON_EXPERT } from '../constants';
import { SwapField } from '../types';
import { basisPointsToPercent } from './index';

const BASE_FEE = new Percent(JSBI.BigInt(30), JSBI.BigInt(10000));
const ONE_HUNDRED_PERCENT = new Percent(JSBI.BigInt(10000), JSBI.BigInt(10000));
const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE);

/**
 * Computes price breakdown for the trade
 * @param trade
 */
export function computeTradePriceBreakdown(trade?: Trade): { priceImpactWithoutFee?: Percent; realizedLPFee?: CurrencyAmount } {
  // for each hop in our trade, take away the x*y=k price impact from 0.3% fees
  // e.g. for 3 tokens/2 hops: 1 - ((1 - .03) * (1-.03))
  const realizedLPFee = !trade
    ? undefined
    : ONE_HUNDRED_PERCENT.subtract(trade.route.pairs.reduce<Fraction>((currentFee: Fraction): Fraction => currentFee.multiply(INPUT_FRACTION_AFTER_FEE), ONE_HUNDRED_PERCENT));

  // remove lp fees from price impact
  const priceImpactWithoutFeeFraction = trade && realizedLPFee ? trade.priceImpact.subtract(realizedLPFee) : undefined;

  // the x*y=k impact
  const priceImpactWithoutFeePercent = priceImpactWithoutFeeFraction
    ? new Percent(priceImpactWithoutFeeFraction?.numerator, priceImpactWithoutFeeFraction?.denominator)
    : undefined;

  // the amount of the input that accrues to LPs
  const realizedLPFeeAmount = realizedLPFee && trade && toCurrencyAmount(trade.inputAmount.currency, realizedLPFee.multiply(trade.inputAmount.raw).quotient);

  return { priceImpactWithoutFee: priceImpactWithoutFeePercent, realizedLPFee: realizedLPFeeAmount };
}

/**
 * Computes the minimum amount out and maximum amount in for a trade given a user specified allowed slippage in bips
 * @param trade
 * @param allowedSlippage
 */
export function computeSlippageAdjustedAmounts(trade: BaseTrade | undefined, allowedSlippage: number): { [field in SwapField]?: CurrencyAmount } {
  const pct = basisPointsToPercent(allowedSlippage);
  return {
    [SwapField.INPUT]: trade?.maximumAmountIn(pct),
    [SwapField.OUTPUT]: trade?.minimumAmountOut(pct),
  };
}

export enum WarningSeverity {
  ALLOWED,
  ALLOWED_PRICE_IMPACT_LOW,
  ALLOWED_PRICE_IMPACT_MEDIUM,
  ALLOWED_PRICE_IMPACT_HIGH,
  BLOCKED_PRICE_IMPACT_NON_EXPERT,
}

export function warningSeverity(priceImpact: Percent | undefined): WarningSeverity {
  if (!priceImpact?.lessThan(BLOCKED_PRICE_IMPACT_NON_EXPERT)) {
    return WarningSeverity.BLOCKED_PRICE_IMPACT_NON_EXPERT;
  }
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_HIGH)) {
    return WarningSeverity.ALLOWED_PRICE_IMPACT_HIGH;
  }
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_MEDIUM)) {
    return WarningSeverity.ALLOWED_PRICE_IMPACT_MEDIUM;
  }
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_LOW)) {
    return WarningSeverity.ALLOWED_PRICE_IMPACT_LOW;
  }
  return WarningSeverity.ALLOWED;
}

// export function formatExecutionPrice(trade?: Trade, inverted?: boolean): string {
//   if (!trade) {
//     return '';
//   }
//   return inverted
//     ? `${trade.executionPrice.invert().toSignificant(6)} ${trade.inputAmount.currency.symbol} / ${trade.outputAmount.currency.symbol}`
//     : `${trade.executionPrice.toSignificant(6)} ${trade.outputAmount.currency.symbol} / ${trade.inputAmount.currency.symbol}`;
// }
