import { ChainId } from '@plasma/plasmaswap-sdk';
import { PriceConfiguration, PriceConfigurationVersion } from '../types';
import { TOKENS } from './common';

export const PRICES_CONFIGURATION: { [chainId in ChainId]: PriceConfiguration } = {
  [ChainId.MAINNET]: {
    version: PriceConfigurationVersion.V3,
    initCodeHash: '0xe34f199b19b2b4f47f68442619d555527d244f78a3297ea89325f843f87b8b54',
    factory: '0x1f98431c8ad98523631ae4a59f267346ea31f984',
    usdToken: TOKENS[ChainId.MAINNET]['USDT'],
  },
  [ChainId.RINKEBY]: {
    version: PriceConfigurationVersion.V2,
    initCodeHash: '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f',
    factory: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
    usdToken: TOKENS[ChainId.RINKEBY]['USDT'],
  },
  [ChainId.ROPSTEN]: {
    version: PriceConfigurationVersion.V2,
    initCodeHash: '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f',
    factory: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
    usdToken: TOKENS[ChainId.ROPSTEN]['USDC'],
  },
  [ChainId.GÖRLI]: {
    version: PriceConfigurationVersion.V2,
    initCodeHash: '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f',
    factory: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
    usdToken: TOKENS[ChainId.GÖRLI]['USDC'],
  },
  [ChainId.KOVAN]: {
    version: PriceConfigurationVersion.V2,
    initCodeHash: '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f',
    factory: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
    usdToken: TOKENS[ChainId.KOVAN]['USDT'],
  },
  [ChainId.MATIC]: {
    version: PriceConfigurationVersion.V3,
    initCodeHash: '0xe34f199b19b2b4f47f68442619d555527d244f78a3297ea89325f843f87b8b54',
    factory: '0x1f98431c8ad98523631ae4a59f267346ea31f984',
    usdToken: TOKENS[ChainId.MATIC]['USDT'],
  },
  [ChainId.BSC]: {
    version: PriceConfigurationVersion.V2,
    initCodeHash: '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
    factory: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
    usdToken: TOKENS[ChainId.BSC]['USDT'],
  },
  [ChainId.BSC_TEST]: {
    version: PriceConfigurationVersion.V2,
    initCodeHash: '0xecba335299a6693cb2ebc4782e74669b84290b6378ea3a3873c7231a8d7d1074',
    factory: '0xb7926c0430afb07aa7defde6da862ae0bde767bc',
    usdToken: TOKENS[ChainId.BSC_TEST]['USDT'],
  },
  [ChainId.AVALANCHE]: {
    version: PriceConfigurationVersion.V2,
    initCodeHash: '0xe18a34eb0e04b04f7a0ac29a6e80748dca96319b42c54d679cb821dca90c6303',
    factory: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
    usdToken: TOKENS[ChainId.AVALANCHE]['USDT'],
  },
  [ChainId.CELO]: {
    version: PriceConfigurationVersion.V2,
    initCodeHash: '0xe18a34eb0e04b04f7a0ac29a6e80748dca96319b42c54d679cb821dca90c6303',
    factory: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
    usdToken: TOKENS[ChainId.CELO]['USDC'],
  },
  [ChainId.FANTOM]: {
    version: PriceConfigurationVersion.V2,
    initCodeHash: '0xe18a34eb0e04b04f7a0ac29a6e80748dca96319b42c54d679cb821dca90c6303',
    factory: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
    usdToken: TOKENS[ChainId.FANTOM]['USDT'],
  },
  [ChainId.OPTIMISM]: {
    version: PriceConfigurationVersion.V3,
    initCodeHash: '0xe34f199b19b2b4f47f68442619d555527d244f78a3297ea89325f843f87b8b54',
    factory: '0x1f98431c8ad98523631ae4a59f267346ea31f984',
    usdToken: TOKENS[ChainId.OPTIMISM]['USDT'],
  },
  [ChainId.OPTIMISM_KOVAN]: {
    version: PriceConfigurationVersion.V3,
    initCodeHash: '0xe34f199b19b2b4f47f68442619d555527d244f78a3297ea89325f843f87b8b54',
    factory: '0x1f98431c8ad98523631ae4a59f267346ea31f984',
    usdToken: TOKENS[ChainId.OPTIMISM_KOVAN]['USDC'],
  },
};
