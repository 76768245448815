import React, { useMemo } from 'react';
import { formatPercent } from '../../utils/format-amount';
import './formatted-percent.scss';

export interface FormattedPercentProps {
  percent: number | string | undefined | null;
  decimalPlaces?: number; // Default 2
  color?: boolean;
  replacerForEmpty?: string; // Default ''
  hideSign?: boolean; // Hide + or -
  prefix?: string;
  suffix?: string;
}

export function FormattedPercent({ percent, decimalPlaces = 2, color = true, replacerForEmpty = '', hideSign, prefix, suffix }: FormattedPercentProps): JSX.Element {
  return useMemo(() => {
    const percentNum = parseFloat(percent as string);
    if (isNaN(percentNum)) {
      return <>{replacerForEmpty}</>;
    }

    const formattedPercent = formatPercent(percent, decimalPlaces);
    const growthClass = formattedPercent ? (formattedPercent < 0 ? 'down' : 'up') : '';

    return (
      <span className={`formatted-percent ${hideSign ? '' : 'show-sign'} ${growthClass} ${color ? 'color' : ''}`}>
        {prefix ? <span className="prefix">{prefix}</span> : null}
        <span className="percent">{Math.abs(formattedPercent)}%</span>
        {suffix ? <span className="suffix">{suffix}</span> : null}
      </span>
    );
  }, [percent, decimalPlaces, hideSign, color, prefix, suffix, replacerForEmpty]);
}
