import { ChainId } from '@plasma/plasmaswap-sdk';
import { ExtraChainId, UnmarshalSupportedChainId } from '../types';

export const PORTFOLIO_CHAIN_ID: { [key in string]?: UnmarshalSupportedChainId } = {
  eth: ChainId.MAINNET,
  bsc: ChainId.BSC,
  matic: ChainId.MATIC,
  xdai: ExtraChainId.XDAI,
  ftm: ChainId.FANTOM,
  okt: ExtraChainId.OEC,
  heco: ExtraChainId.HECO,
  avax: ChainId.AVALANCHE,
  op: ChainId.OPTIMISM,
  // arb: ChainId.ARBITRUM,
  celo: ChainId.CELO,
  movr: ExtraChainId.MOONRIVER,
  boba: ExtraChainId.BOBA,
  cro: ExtraChainId.CRONOS,
  metis: ExtraChainId.METIS,
  aurora: ExtraChainId.AURORA,
  mobm: ExtraChainId.MOONBEAM,
  sbch: ExtraChainId.SMARTBCH,
  btt: ExtraChainId.BTTC,
};
