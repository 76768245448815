import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPlasmaFinanceMarketTokenList } from '../../api';
import { AppDispatch, AppState } from '../index';
import { fetchPlasmaMarketClearError, fetchPlasmaMarketTokenList } from './lists.actions';

const RESET_ERROR_TIMEOUT = 5000;
const LIST_CACHE_TIMEOUT = 14400000; // 4 hours

export function ListsUpdater(): null {
  const dispatch = useDispatch<AppDispatch>();
  const marketTokensFetch = useSelector<AppState, AppState['lists']['marketTokens']['fetch']>(state => state.lists.marketTokens.fetch);
  const marketTokensError = useSelector<AppState, AppState['lists']['marketTokens']['error']>(state => state.lists.marketTokens.error);
  const marketTokensLastUpdate = useSelector<AppState, AppState['lists']['marketTokens']['timestamp']>(state => state.lists.marketTokens.timestamp);

  const marketTokensShouldUpdate = useMemo(() => {
    if (!marketTokensFetch || marketTokensError) {
      return false;
    }
    if (!marketTokensLastUpdate) {
      return true;
    }

    const timestamp = Math.round(Date.now() / 1000);
    const cacheTimeoutInSec = Math.round(LIST_CACHE_TIMEOUT / 1000);
    return marketTokensLastUpdate + cacheTimeoutInSec < timestamp;
  }, [marketTokensError, marketTokensFetch, marketTokensLastUpdate]);

  // Fetch plasma finance market token list
  useEffect(() => {
    if (!marketTokensShouldUpdate) {
      return;
    }

    dispatch(fetchPlasmaMarketTokenList.pending());

    getPlasmaFinanceMarketTokenList()
      .then(list => {
        dispatch(fetchPlasmaMarketTokenList.resolved({ list }));
      })
      .catch(err => {
        dispatch(fetchPlasmaMarketTokenList.rejected({ errorMessage: err.message }));
      });
  }, [marketTokensShouldUpdate, dispatch]);

  // Resets the error of fetch plasma finance market token list
  useEffect(() => {
    if (!marketTokensError) {
      return;
    }

    const timeout = setTimeout(() => dispatch(fetchPlasmaMarketClearError()), RESET_ERROR_TIMEOUT);
    return () => clearTimeout(timeout);
  }, [marketTokensError, dispatch]);

  return null;
}
