import Big from 'big.js';
import React, { CSSProperties, FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { useDebounce } from '../../hooks/use-debounce';
import { BridgeAssetMeta } from '../../types';
import { FormattedAmount } from '../formatted-amount/formatted-amount';
import { LoadingSkeleton } from '../loading-skeleton/loading-skeleton';
import { Logo } from '../logo/logo';
import { Modal, ModalProps } from '../modal/modal';
import './modal-bridge-asset-selector.scss';

export interface ModalBridgeAssetSelectorProps extends ModalProps {
  asset?: BridgeAssetMeta;
  onChangeAsset?: (asset: BridgeAssetMeta) => void;

  networkId?: string | number;
  onChangeNetworkId?: (networkId: string | number) => void;

  /**
   * Networks and assets
   */
  networks: {
    id: string | number;
    name: string;
    logoURI: string;
  }[];
  /**
   * Assets list to display
   */
  assets: (BridgeAssetMeta & { balance?: string | null })[] | null;
}

export const ModalBridgeAssetSelector: FC<ModalBridgeAssetSelectorProps> = ({ onDismiss, isOpen, ...props }) => {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      {isOpen ? <ModalBridgeAssetSelectorContent isOpen={isOpen} onDismiss={onDismiss} {...props} /> : null}
    </Modal>
  );
};

const ModalBridgeAssetSelectorContent: FC<ModalBridgeAssetSelectorProps> = props => {
  const { asset: selectedAsset, onChangeAsset, networkId, onChangeNetworkId, networks, assets, onDismiss } = props;
  const { t } = useTranslation();
  const fixedSizeListRef = useRef<FixedSizeList>();

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const selectedNetwork = useMemo(() => (networkId ? networks.find(({ id }) => id === networkId) : undefined), [networks, networkId]);

  const sortedAssets = useMemo(() => {
    if (!assets) {
      return assets;
    }
    return assets.sort((asset0, asset1) => {
      return Big(asset1.balance || '0')
        .minus(asset0.balance || '0')
        .toNumber();
    });
  }, [assets]);

  const filteredAssets = useMemo(() => {
    if (!sortedAssets) {
      return sortedAssets;
    }
    if (!debouncedSearchValue) {
      return sortedAssets;
    }

    const regEx = new RegExp(debouncedSearchValue.toLowerCase(), 'gi');

    return sortedAssets.filter(asset => {
      return (asset.symbol && regEx.test(asset.symbol)) || (asset.name && regEx.test(asset.name)) || (asset.address && regEx.test(asset.address));
    });
  }, [sortedAssets, debouncedSearchValue]);

  const onChangeCallback = useCallback(
    (asset: BridgeAssetMeta) => {
      onChangeAsset?.(asset);
      onDismiss();
    },
    [onChangeAsset, onDismiss],
  );

  const assetItemsRenderer = useMemo(() => createAssetItemsRenderer(selectedAsset, onChangeCallback), [onChangeCallback, selectedAsset]);

  useEffect(() => fixedSizeListRef.current?.scrollTo(0), [debouncedSearchValue]);

  return (
    <div className="modal modal-bridge-asset-selector">
      <div className="modal-content">
        <div className="networks-selector">
          {networks.map(({ id, logoURI, name }, index) => (
            <button key={index} className={`btn btn-network ${networkId === id ? 'active' : ''}`} onClick={() => onChangeNetworkId?.(id)}>
              <img className="logo" alt={name} src={logoURI} />
            </button>
          ))}
        </div>
        <div className="assets-container">
          <div className="header">
            <span>{selectedNetwork ? t(`choose_bridge_token`, { networkName: selectedNetwork.name }) : t(`choose_asset`)}</span>
            <button className="btn btn-close" onClick={onDismiss}>
              <i className="pi pi-close" />
            </button>
          </div>
          <div className="search">
            <i className="pi pi-search search-icon" />
            <input
              className="input"
              autoFocus
              placeholder={t('search_name_or_paste_address')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
              value={searchValue}
            />
          </div>
          <div className="assets">
            {filteredAssets === null ? (
              <AssetsListLoading />
            ) : (
              <FixedSizeList ref={fixedSizeListRef as any} className="list" height={561} width="100%" itemSize={56} itemData={filteredAssets} itemCount={filteredAssets.length}>
                {assetItemsRenderer}
              </FixedSizeList>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface AssetItemProps extends BridgeAssetMeta {
  style?: CSSProperties;
  balance?: string | null;
  selectedAsset?: BridgeAssetMeta;
  onChange?: (asset: BridgeAssetMeta) => void;
}

const AssetItem = memo(function AssetItem({ style, balance, selectedAsset, onChange, ...asset }: AssetItemProps): JSX.Element {
  const isActive =
    !!selectedAsset &&
    asset.chainId === selectedAsset.chainId &&
    asset.name === selectedAsset.name &&
    asset.symbol === selectedAsset.symbol &&
    asset.decimals === selectedAsset.decimals;

  return (
    <button className={`btn btn-asset ${isActive ? 'active' : ''}`} style={style} onClick={() => onChange?.(asset)}>
      <Logo currency={asset.symbol?.replace(/^ren/, '')} size={32} fallbackUrl={asset.logoURI} />
      <div className="info">
        <div className="symbol">{asset.symbol}</div>
        <div className="name">{asset.name}</div>
      </div>
      <div className="balance">
        {balance ? (
          <FormattedAmount amount={balance} groupSeparator={' '} />
        ) : balance === null ? (
          <LoadingSkeleton>
            <Skeleton height={16} width={50} />
          </LoadingSkeleton>
        ) : null}
      </div>
    </button>
  );
});

const AssetsListLoading: FC = () => {
  return (
    <LoadingSkeleton>
      <div className="loading-asset">
        <Skeleton height={32} width={32} circle={true} />
        <Skeleton height={22} width={200} style={{ marginLeft: '12px' }} />
      </div>
      <div className="loading-asset">
        <Skeleton height={32} width={32} circle={true} />
        <Skeleton height={22} width={160} style={{ marginLeft: '12px' }} />
      </div>
      <div className="loading-asset">
        <Skeleton height={32} width={32} circle={true} />
        <Skeleton height={22} width={160} style={{ marginLeft: '12px' }} />
      </div>
      <div className="loading-asset">
        <Skeleton height={32} width={32} circle={true} />
        <Skeleton height={22} width={160} style={{ marginLeft: '12px' }} />
      </div>
      <div className="loading-asset">
        <Skeleton height={32} width={32} circle={true} />
        <Skeleton height={22} width={120} style={{ marginLeft: '12px' }} />
      </div>
    </LoadingSkeleton>
  );
};

function createAssetItemsRenderer(selectedAsset?: BridgeAssetMeta, onChange?: (asset: BridgeAssetMeta) => void) {
  return function Renderer({ index, data, style }: ListChildComponentProps<BridgeAssetMeta[]>) {
    return <AssetItem {...data[index]} key={index} style={style} selectedAsset={selectedAsset} onChange={onChange} />;
  };
}
