import { ChainId } from '@plasma/plasmaswap-sdk';
import { getOtpToken } from '../utils/get-otp-token';

export interface SearchTokensQuery {
  chainId: ChainId;
  search: string;
  limit: number;
  offset: number;
}

export interface SearchTokensResultItem {
  /**
   * Token id in Hyper DEX API
   */
  id: string;
  chainId: ChainId;
  address: string;
  decimals: number;
  symbol: string;
  name: string;
  logoURI?: {
    ipfs: string | null;
    jpg: string | null;
    png: string | null;
    svg: string | null;
  };
  tags?: string[];
  /**
   * Token in other chains
   */
  chains?: string[];
}

export interface SearchTokensResult {
  count: number;
  result: SearchTokensResultItem[];
}

function getQueryUrl(query: SearchTokensQuery): string {
  let url = `${process.env.REACT_APP_HYPER_API}/v1/token`;
  url += `?offset=${query.offset}`;
  url += `&limit=${query.limit}`;
  url += `&chainId=${query.chainId}`;
  query.search && (url += `&search=${query.search}`);
  return url;
}

export async function searchTokens(query: SearchTokensQuery, abort?: AbortSignal): Promise<SearchTokensResult> {
  const res: Response = await fetch(getQueryUrl(query), {
    method: 'GET',
    mode: 'cors',
    signal: abort,
    headers: {
      'x-plasma-totp': getOtpToken(),
    },
  });

  if (!res.ok) {
    if (res.status === 404) {
      return { count: 0, result: [] };
    }

    const defaultErrorMessage = `Failed to search tokens`;
    let errorMessage: string | undefined = undefined;
    try {
      const errorBody = await res.json();
      if (errorBody.message) {
        errorMessage = errorBody.message;
      }
    } catch (e) {}

    throw new Error(errorMessage || defaultErrorMessage);
  }

  return await res.json();
}
