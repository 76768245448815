import { TransactionReceipt } from '@ethersproject/abstract-provider';
import { TransactionResponse } from '@ethersproject/providers';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';

// import usePrevious from '../../hooks/use-previous';
import { AppDispatch } from '../index';
import { addTransaction } from './transactions.actions';
// import { TransactionDetails } from './transactions.reducer';

/**
 * Helper that can take a ethers library transaction response and add it to the list of transactions
 */
export function useTransactionAdder(): (
  response: TransactionResponse,
  customData?: {
    summary?: string;
    approval?: { tokenAddress: string; spender: string };
    claim?: { recipient: string };
  },
) => void {
  const { chainId, account } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>();

  return useCallback(
    (
      response: TransactionResponse,
      { summary, approval, claim }: { summary?: string; claim?: { recipient: string }; approval?: { tokenAddress: string; spender: string } } = {},
    ) => {
      if (!account || !chainId) {
        return;
      }

      const { hash } = response;
      if (!hash) {
        throw Error('No transaction hash found.');
      }
      dispatch(addTransaction({ hash, from: account, chainId, approval, summary, claim }));
    },
    [dispatch, chainId, account],
  );
}

/**
 * Returns all the transactions for the current chain
 */
// export function useAllTransactions(): [{ [txHash: string]: TransactionDetails }, boolean] {
//   const { chainId } = useActiveWeb3React();

//   const state = useSelector<AppState, AppState['transactions']>(state => state.transactions);

//   const prevAllTransactions = usePrevious(chainId ? state[chainId] : {});
//   const allTransactionsLength = Object.keys(chainId ? state[chainId] ?? {} : {}).length;
//   const prevAllTransactionsLength = prevAllTransactions && Object.keys(prevAllTransactions).length ? Object.keys(prevAllTransactions).length : allTransactionsLength;

//   return [chainId ? state[chainId] ?? {} : {}, Math.abs(prevAllTransactionsLength - allTransactionsLength) < 2 && allTransactionsLength !== prevAllTransactionsLength];
// }

/**
 * Returns true if the transaction (with passed hash) is pending
 * @param transactionHash
 */
// export function useIsTransactionPending(transactionHash?: string): boolean | undefined {
//   const [transactions] = useAllTransactions();

//   if (!transactionHash || !transactions[transactionHash]) {
//     return undefined;
//   }

//   return !transactions[transactionHash].receipt;
// }

/**
 * Watch for submissions to claim return transaction if not done loading, return undefined if not have pending claim transaction
 * @param account
 */
// export function useGetPendingClaimTx(account: string | null | undefined): TransactionDetails | undefined {
//   const [allTransactions] = useAllTransactions();

//   // get the txn if it has been submitted
//   return useMemo<TransactionDetails | undefined>(() => {
//     const txnIndex = Object.keys(allTransactions).find(hash => {
//       const tx = allTransactions[hash];
//       return tx.claim && tx.claim.recipient === account && !tx.receipt;
//     });
//     return txnIndex && allTransactions[txnIndex] ? allTransactions[txnIndex] : undefined;
//   }, [account, allTransactions]);
// }

/**
 * Returns the number of transaction confirmations
 * @param txHash
 */
export function useTransactionStatus(txHash?: string): { confirmations: number; isFail: boolean } | undefined {
  const { provider } = useActiveWeb3React();
  const [txStatus, setTxStatus] = useState<{ confirmations: number; isFail: boolean } | undefined>();

  useEffect(() => {
    if (!provider || !txHash) {
      return;
    }

    let cancelled = false;

    function txHandler(receipt: TransactionReceipt): void {
      setTxStatus({ confirmations: receipt.confirmations, isFail: !!receipt.confirmations && !receipt.status });
    }

    setTxStatus({ confirmations: 0, isFail: false });

    provider
      .getTransactionReceipt(txHash)
      .then(receipt => {
        if (!cancelled) {
          const confirmations = receipt?.confirmations || 0;
          setTxStatus({ confirmations, isFail: !!confirmations && !receipt?.status });
          provider.on(txHash, txHandler);
        }
      })
      .catch(error => {
        if (!cancelled) {
          setTxStatus(undefined);
          console.error(`failed to check transaction hash: ${txHash}`, error);
        }
      });

    return () => {
      cancelled = true;
      provider.removeListener(txHash, txHandler);
    };
  }, [provider, txHash]);

  return txStatus;
}
