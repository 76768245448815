import { BigNumber, BigNumberish } from '@ethersproject/bignumber';

export function toBigNumber(value: BigNumberish | null | undefined): BigNumber | null {
  if (value === null || value === undefined) {
    return null;
  }
  try {
    return BigNumber.from(value);
  } catch (e) {
    return null;
  }
}
