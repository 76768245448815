import { ChainId } from '@plasma/plasmaswap-sdk';
import { createReducer } from '@reduxjs/toolkit';
import { ALL_SUPPORTED_CHAIN_IDS, BLACKLISTED_TOKENS } from '../../constants';
import { MarketTokenInfo, SerializedToken } from '../../types';
import { updateVersion } from '../global/global.actions';
import { addFavoriteSerializedToken, fetchPlasmaMarketClearError, fetchPlasmaMarketTokenList, fetchPlasmaMarketTokens, removeFavoriteSerializedToken } from './lists.actions';

export interface ListsState {
  // Market tokens list from (https://raw.githubusercontent.com/plasmadlt/plasma-finance-market-tokenlist/main/plasma-finance-market-list.json)
  readonly marketTokens: {
    readonly list: {
      [chainId in ChainId]: {
        [address: string]: MarketTokenInfo;
      };
    };
    readonly fetch: boolean;
    readonly loading: boolean;
    readonly timestamp?: number; // Last update timestamp
    readonly error?: string;
  };
  // Saved user's tokens
  readonly favoritesTokens: {
    [chainId: number]: {
      [address: string]: SerializedToken;
    };
  };
}

const initialState: ListsState = {
  marketTokens: {
    list: marketTokenListDefault(),
    fetch: false,
    loading: false,
    timestamp: undefined,
    error: undefined,
  },
  favoritesTokens: {},
};

export default createReducer(initialState, builder =>
  builder
    // Init and check states
    .addCase(updateVersion, state => {
      if (!state.favoritesTokens) {
        state.favoritesTokens = initialState.favoritesTokens;
      }

      if (state.marketTokens) {
        state.marketTokens.error = undefined;
        state.marketTokens.fetch = false;
        state.marketTokens.loading = false;
      } else {
        state.marketTokens = initialState.marketTokens;
      }
    })
    // Market tokens reducers
    .addCase(fetchPlasmaMarketTokenList.pending, state => {
      state.marketTokens.loading = true;
      return state;
    })
    .addCase(fetchPlasmaMarketTokenList.resolved, (state, { payload: { list } }) => {
      const marketTokensList: ListsState['marketTokens']['list'] = marketTokenListDefault();

      list.forEach(item => {
        item.tokens.forEach(tokenInfo => {
          if (!marketTokensList[tokenInfo.chainId]) {
            return;
          }
          if (BLACKLISTED_TOKENS[tokenInfo.chainId].includes(tokenInfo.address)) {
            return;
          }
          const marketTokenInfo: MarketTokenInfo = Object.assign({}, item, tokenInfo, {
            addresses: item.tokens.map(i => ({ address: i.address, chainId: i.chainId })),
          });
          Reflect.deleteProperty(marketTokenInfo, 'tokens');
          marketTokensList[tokenInfo.chainId][tokenInfo.address] = marketTokenInfo;
        });
      });

      state.marketTokens.list = marketTokensList;
      state.marketTokens.loading = false;
      state.marketTokens.timestamp = Math.round(Date.now() / 1000);

      return state;
    })
    .addCase(fetchPlasmaMarketTokenList.rejected, (state, { payload: { errorMessage } }) => {
      state.marketTokens.error = errorMessage;
      state.marketTokens.fetch = false;
      state.marketTokens.timestamp = Math.round(Date.now() / 1000);
      return state;
    })
    .addCase(fetchPlasmaMarketTokens, state => {
      state.marketTokens.fetch = true;
      return state;
    })
    .addCase(fetchPlasmaMarketClearError, state => {
      state.marketTokens.error = undefined;
      return state;
    })
    // User's tokens reducers
    .addCase(addFavoriteSerializedToken, (state, { payload: serializedToken }) => {
      state.favoritesTokens[serializedToken.chainId] = state.favoritesTokens[serializedToken.chainId] || {};
      state.favoritesTokens[serializedToken.chainId][serializedToken.address] = serializedToken;
      return state;
    })
    .addCase(removeFavoriteSerializedToken, (state, { payload: { address, chainId } }) => {
      state.favoritesTokens[chainId] = state.favoritesTokens[chainId] || {};
      delete state.favoritesTokens[chainId][address];
      return state;
    }),
);

function marketTokenListDefault(): ListsState['marketTokens']['list'] {
  return ALL_SUPPORTED_CHAIN_IDS.reduce<ListsState['marketTokens']['list']>((acc, chainId) => {
    acc[chainId] = {};
    return acc;
  }, {} as ListsState['marketTokens']['list']);
}
