import { ChainId } from '@plasma/plasmaswap-sdk';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNetwork } from 'wagmi';
import { AppDispatch } from '../state';
import { setDefaultChainId } from '../state/application/application.actions';

export function useChainSelector(): (chainId: ChainId) => Promise<ChainId> {
  const dispatch = useDispatch<AppDispatch>();
  const { switchNetworkAsync } = useNetwork();

  return useCallback(
    (newChainId: ChainId): Promise<ChainId> => {
      if (!switchNetworkAsync) {
        dispatch(setDefaultChainId(newChainId));
        return Promise.resolve(newChainId);
      }

      return switchNetworkAsync(newChainId).then(chain => chain.id as ChainId);
    },
    [dispatch, switchNetworkAsync],
  );
}
