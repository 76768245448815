import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit';
import { MarketTokenListItem, SerializedToken } from '../../types';

export const fetchPlasmaMarketTokenList: Readonly<{
  pending: ActionCreatorWithoutPayload;
  resolved: ActionCreatorWithPayload<{ list: MarketTokenListItem[] }>;
  rejected: ActionCreatorWithPayload<{ errorMessage: string }>;
}> = {
  pending: createAction('lists/fetchPlasmaMarketTokenList/pending'),
  resolved: createAction('lists/fetchPlasmaMarketTokenList/resolved'),
  rejected: createAction('lists/fetchPlasmaMarketTokenList/rejected'),
};
export const fetchPlasmaMarketTokens = createAction('lists/fetchPlasmaMarketTokens');
export const fetchPlasmaMarketClearError = createAction('lists/fetchPlasmaMarketClearError');

export const addFavoriteSerializedToken = createAction<SerializedToken>('lists/addFavoriteSerializedToken');
export const removeFavoriteSerializedToken = createAction<{ chainId: number; address: string }>('lists/removeFavoriteSerializedToken');
