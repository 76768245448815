import React, { FC } from 'react';
import './wallet-circle-icon.scss';
import blockies from 'blockies-ts';

export interface WalletCircleIconProps {
  account: string;
  size?: number; // value in pixels
  onClick?: () => void;
}

const blockiesSize = 8; // size like in metamask

export const WalletCircleIcon: FC<WalletCircleIconProps> = props => {
  const { size = 24, account, onClick } = props;
  const imgSrc = blockies.create({ size: blockiesSize, seed: account.toLowerCase() }).toDataURL();

  return (
    <div className="wallet-circle-icon" style={{ width: size, minWidth: size, height: size }} onClick={onClick}>
      <img src={imgSrc} alt="" />
    </div>
  );
};
