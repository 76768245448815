import { ChainId } from '@plasma/plasmaswap-sdk';
import { createReducer } from '@reduxjs/toolkit';
import { updateVersion } from '../global/global.actions';
import { setDefaultChainId, updateBlock } from './application.actions';

// type PopupList = Array<{ key: string; show: boolean; content: PopupContent; removeAfterMs: number | null }>;

export interface ApplicationState {
  readonly defaultChainId: ChainId;
  readonly block: {
    readonly [chainId: number]: {
      readonly hash: string;
      readonly parentHash: string;
      readonly number: number;
      readonly timestamp: number;
      readonly baseFeePerGas?: number;
    } | null;
  };
}

const initialState: ApplicationState = {
  defaultChainId: ChainId.MAINNET,
  block: {},
};

export default createReducer(initialState, builder =>
  builder
    .addCase(updateVersion, state => {
      if (!state.defaultChainId) {
        state.defaultChainId = initialState.defaultChainId;
      }
      if (!state.block) {
        state.block = initialState.block;
      }

      return state;
    })
    .addCase(setDefaultChainId, (state, { payload: chainId }) => {
      state.defaultChainId = chainId;
      return state;
    })
    .addCase(updateBlock, (state, { payload: { chainId, block } }) => {
      state.block[chainId] = block;
      return state;
    }),
);
