import { ChainId } from '@plasma/plasmaswap-sdk';
import { TransactionSpeed } from '../types';

export const GAS_STATION_URL: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: 'https://gsn.plasma.finance/ethereum',
  [ChainId.MATIC]: 'https://gsn.plasma.finance/polygon',
  [ChainId.BSC]: 'https://gsn.plasma.finance/binance',
  [ChainId.AVALANCHE]: 'https://gsn.plasma.finance/avalanche',
  [ChainId.FANTOM]: 'https://gsn.plasma.finance/fantom',
  [ChainId.CELO]: 'https://gsn.plasma.finance/celo',
  [ChainId.OPTIMISM]: 'https://gsn.plasma.finance/optimism',
  [ChainId.ROPSTEN]: 'http://localhost:3200', // http://localhost:3200 || https://gsn.plasma-bank.com/ropsten
  [ChainId.KOVAN]: 'https://gsn.plasma-bank.com/kovan',
  [ChainId.BSC_TEST]: 'https://gsn.plasma-bank.com/bsc-test',
  [ChainId.RINKEBY]: 'https://gsn.plasma-bank.com/rinkeby',
  [ChainId.GÖRLI]: 'https://gsn.plasma-bank.com/gorli',
  [ChainId.OPTIMISM_KOVAN]: 'https://gsn.plasma-bank.com/optimism-kovan',
};

/**
 * Refresh gas prices timeout in seconds
 */
export const GAS_PRICES_REFRESH_INTERVAL = 30;

/**
 * Used if the call to the contract method with estimateGas failed or ETH transfer.
 */
export enum DefaultGasLimit {
  HIGH = 500_000,
  MIDDLE = 350_000,
  LOW = 200_000,
  TRANSFER = 21_000,
}

/**
 * Add to the gas estimate to get the gas limit. In percent (10%)
 */
export const GAS_LIMIT_MARGIN_MULTIPLIER = 1.1;

/**
 * Used for the calculation default maxFeePerGas
 * maxFeePerGas = (MAX_FEE_PER_GAS_MULTIPLIER * block.baseFeePerGas) + maxPriorityFeePerGas)
 */
export const MAX_FEE_PER_GAS_MULTIPLIER = 1.2;

/**
 * What type of gas price is selected by default
 */
export const DEFAULT_GAS_PRICE_SPEED: TransactionSpeed = TransactionSpeed.HIGH;

/**
 * The default priority fee for EIP-1559 or the gas price for legacy
 */
export const DEFAULT_FEE_PER_GAS: { [chainId in ChainId]: { [step in TransactionSpeed]: string } } = {
  [ChainId.MAINNET]: {
    [TransactionSpeed.LOW]: '1000000000', // 1Gwei
    [TransactionSpeed.MIDDLE]: '2000000000', // 2Gwei
    [TransactionSpeed.HIGH]: '3000000000', // 3Gwei
  },
  [ChainId.ROPSTEN]: {
    [TransactionSpeed.LOW]: '1000000000', // 1Gwei
    [TransactionSpeed.MIDDLE]: '2000000000', // 2Gwei
    [TransactionSpeed.HIGH]: '3000000000', // 3Gwei
  },
  [ChainId.RINKEBY]: {
    [TransactionSpeed.LOW]: '1000000000', // 1Gwei
    [TransactionSpeed.MIDDLE]: '2000000000', // 2Gwei
    [TransactionSpeed.HIGH]: '3000000000', // 3Gwei
  },
  [ChainId.GÖRLI]: {
    [TransactionSpeed.LOW]: '1000000000', // 1Gwei
    [TransactionSpeed.MIDDLE]: '2000000000', // 2Gwei
    [TransactionSpeed.HIGH]: '3000000000', // 3Gwei
  },
  [ChainId.KOVAN]: {
    [TransactionSpeed.LOW]: '1000000000', // 1Gwei
    [TransactionSpeed.MIDDLE]: '2000000000', // 2Gwei
    [TransactionSpeed.HIGH]: '3000000000', // 3Gwei
  },
  [ChainId.MATIC]: {
    [TransactionSpeed.LOW]: '30000000000', // 30 Gwei
    [TransactionSpeed.MIDDLE]: '40000000000', // 40 Gwei
    [TransactionSpeed.HIGH]: '50000000000', // 50 Gwei
  },
  [ChainId.BSC]: {
    [TransactionSpeed.LOW]: '5000000000', // 5 Gwei
    [TransactionSpeed.MIDDLE]: '5000000000', // 5 Gwei
    [TransactionSpeed.HIGH]: '5000000000', // 5 Gwei
  },
  [ChainId.BSC_TEST]: {
    [TransactionSpeed.LOW]: '5000000000', // 5 Gwei
    [TransactionSpeed.MIDDLE]: '5000000000', // 5 Gwei
    [TransactionSpeed.HIGH]: '5000000000', // 5 Gwei
  },
  [ChainId.AVALANCHE]: {
    [TransactionSpeed.LOW]: '1000000000', // 1 Gwei
    [TransactionSpeed.MIDDLE]: '2000000000', // 2 Gwei
    [TransactionSpeed.HIGH]: '3000000000', // 3 Gwei
  },
  [ChainId.FANTOM]: {
    [TransactionSpeed.LOW]: '300000000000', // 300 Gwei
    [TransactionSpeed.MIDDLE]: '400000000000', // 400 Gwei
    [TransactionSpeed.HIGH]: '500000000000', // 500 Gwei
  },
  [ChainId.CELO]: {
    [TransactionSpeed.LOW]: '300000000', // 0.3 Gwei
    [TransactionSpeed.MIDDLE]: '500000000', // 0.5 Gwei
    [TransactionSpeed.HIGH]: '600000000', // 0.6 Gwei
  },
  [ChainId.OPTIMISM]: {
    [TransactionSpeed.LOW]: '300000000', // 0.3 Gwei
    [TransactionSpeed.MIDDLE]: '500000000', // 0.5 Gwei
    [TransactionSpeed.HIGH]: '600000000', // 0.6 Gwei
  },
  [ChainId.OPTIMISM_KOVAN]: {
    [TransactionSpeed.LOW]: '300000000', // 0.3 Gwei
    [TransactionSpeed.MIDDLE]: '500000000', // 0.5 Gwei
    [TransactionSpeed.HIGH]: '600000000', // 0.6 Gwei
  },
};
