import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NoLogo from '../../assets/images/no-logo.svg';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import './form-control-select.scss';

export interface FormControlSelectOption {
  logo?: string;
  label?: string;
  value: string;
}

export interface FormControlSelectProps {
  id?: string;
  label?: string;
  className?: string;
  value?: string;
  onChange?: (value: string) => void;
  touched?: boolean;
  onTouched?: () => void;
  error?: string | null;
  disabled?: boolean;
  options: FormControlSelectOption[];
}

export function FormControlSelect({ id, label, className, value, onChange, touched, onTouched, error, disabled, options }: FormControlSelectProps): JSX.Element {
  const { t } = useTranslation();
  const selectRef = useRef<HTMLInputElement>();
  const selectWrapRef = useRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(selectWrapRef, () => setIsOpen(false));

  const componentClassName: string = useMemo(() => {
    let componentClassName = 'form-control-select';
    componentClassName += touched ? ` is-touched` : '';
    componentClassName += error && touched ? ` is-error` : '';
    componentClassName += isOpen ? ` is-open` : '';
    componentClassName += disabled ? ` is-disabled` : '';
    componentClassName += className ? ` ${className}` : '';
    return componentClassName;
  }, [className, disabled, error, isOpen, touched]);

  const selected = useMemo(() => options.find(i => i.value === value), [options, value]);

  return (
    <div className={componentClassName}>
      {label ? (
        <label htmlFor={id}>
          <span>{label}</span>
        </label>
      ) : null}
      <div ref={selectWrapRef as any} className="select-wrap" onClick={() => selectRef.current && selectRef.current.focus()}>
        <select
          id={id}
          onChange={event => {
            onTouched && onTouched();
            onChange && onChange(event.target.value);
          }}
          disabled={disabled}
          ref={selectRef as any}
        >
          {options.map((option, key) => (
            <option key={key} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="value-block" onClick={() => !disabled && setIsOpen(!isOpen)}>
          {selected ? (
            <FormControlOption {...selected} />
          ) : (
            <div className="empty">
              <span>{t('please_select')}</span>
            </div>
          )}
          <div className="drop-down" />
        </div>
        {isOpen ? (
          <div className="options-block">
            {options.map((option, key) => (
              <FormControlOption
                key={key}
                {...option}
                onChange={value => {
                  if (selectRef.current) {
                    selectRef.current.value = value;
                    selectRef.current.dispatchEvent(new Event('change', { bubbles: true }));
                    setIsOpen(false);
                  }
                }}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}

interface FormControlOptionProps {
  logo?: string;
  label?: string;
  value: string;
  onChange?: (value: string) => void;
}

function FormControlOption({ logo, label, value, onChange }: FormControlOptionProps): JSX.Element {
  const [isError, setIsError] = useState(false);

  useEffect(() => setIsError(false), [logo]);

  return (
    <div className="form-control-select-option" onClick={() => onChange && onChange(value)}>
      {logo ? (
        <div className="logo">
          <img src={!isError ? logo : NoLogo} onError={() => setIsError(true)} />
        </div>
      ) : null}
      <div className="label">
        <span>{label ? label : value}</span>
      </div>
    </div>
  );
}
