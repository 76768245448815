import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSupported0xLiquiditySources } from '../../hooks/use-supported-0x-liquidity-sources';
import { useExclude0xLiquiditySources } from '../../state/user/user.hooks';

export function LiquiditySources({ onSetView }: { onSetView: () => void }): JSX.Element {
  const [excludeSources] = useExclude0xLiquiditySources();
  const supportedSources = useSupported0xLiquiditySources();
  const { t } = useTranslation();

  const enabledSourcesCount = useMemo(() => {
    const enabledSources = supportedSources.filter(i => !excludeSources.includes(i));
    return enabledSources.length;
  }, [excludeSources, supportedSources]);

  return (
    <div className="liquidity-sources">
      <div className="label">
        <span>{t('liquidity_sources')}</span>
      </div>

      <div className="enabled-sources-count">
        <div className="count">
          <span>{t('count_sources', { count: enabledSourcesCount })}</span>
        </div>
        <button className="btn btn-secondary" onClick={onSetView}>
          <span>{'change'}</span>
        </button>
      </div>
    </div>
  );
}
