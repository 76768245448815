import { Interface } from '@ethersproject/abi';
import { BigNumber } from '@ethersproject/bignumber';
import { Signature } from '@ethersproject/bytes';
import { TransactionResponse } from '@ethersproject/providers';
import { TokenAmount } from '@plasma/plasmaswap-sdk';
import { EIP712TypedData } from '@plasma/plasmaswap-sdk/dist/utils/eip712';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../state';
import { setPermitCallAttempt, setPermitCallData } from '../../state/approval/approval.actions';
import { useTransactionDeadline } from '../../state/user/user.hooks';
import { ApprovalAmount, ApprovalCallback, ApprovalState } from '../../types';
import { getCallData, getSignTypedData } from '../../utils/approve-sign-utils';
import { signTypedData } from '../../utils/sign-typed-data';
import { useIsArgentWallet } from '../use-is-argent-wallet';
import { useActiveWeb3React } from '../web3/use-active-web3-react';
import { useApprovalId } from './use-approval-id';
import { useApproveToken } from './use-approve-token';
import { useApproverContractConfig } from './use-approver-contract-config';

/**
 * Approve token with some signature types
 */
export function useApproveTokenWithPermit(amount: ApprovalAmount, spender?: string): [ApprovalState, string | undefined, ApprovalCallback] {
  const { provider, account } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>();
  const selector = useSelector<AppState, AppState['approval']['byPermitCall']>(state => state.approval.byPermitCall);
  const isArgentWallet = useIsArgentWallet();
  const deadline = useTransactionDeadline();
  const [approvalState, approveCallback] = useApproveToken(amount, spender);

  const token = amount instanceof TokenAmount ? amount.token : undefined;
  const approveTokenConfig = useApproverContractConfig(token?.address);

  // Approval id to save successful approve result (call data).
  const approvalId = useApprovalId(amount, spender);
  const storedAttempt = useMemo(() => !!(approvalId && selector[approvalId]?.attempt), [selector, approvalId]);
  const storedCallData = useMemo(() => approvalId && selector[approvalId]?.callData, [selector, approvalId]);
  const nonceOffset = useMemo(() => {
    if (!approvalId || !token?.address || !account) {
      return undefined;
    }
    const currentIndex = selector[approvalId]?.index;

    return Object.values(selector)
      .filter(i => i.token === token.address && i.account === account)
      .sort((a, b) => b.index - a.index)
      .reduce((acc, i) => {
        if (currentIndex === undefined || i.index < currentIndex) {
          acc++;
        }
        return acc;
      }, 0);
  }, [selector, approvalId, token?.address, account]);
  const [attempt, setAttempt] = useState<boolean>();
  const [callData, setCallData] = useState<string>();

  // The status of the approval, taking into account the signature EIP712
  const approveWithPermitState: ApprovalState = useMemo(() => {
    if (approveTokenConfig === null) {
      return ApprovalState.LOADING;
    }
    if (storedCallData) {
      return ApprovalState.SIGNED;
    }
    if (storedAttempt) {
      return ApprovalState.SIGNING;
    }
    if (approvalState === ApprovalState.INSUFFICIENT_FUNDS && approveTokenConfig && !isArgentWallet) {
      return ApprovalState.NOT_APPROVED;
    }

    return approvalState;
  }, [storedCallData, storedAttempt, approvalState, approveTokenConfig, isArgentWallet]);

  const getNonceCallback = useCallback(async () => {
    if (!token?.address || !account || !provider || !approveTokenConfig || nonceOffset === undefined) {
      return null;
    }

    try {
      const nonceInterface = new Interface([`function ${approveTokenConfig.noncesMethod}`]);
      const param = {
        from: account,
        to: token?.address,
        data: nonceInterface.encodeFunctionData(approveTokenConfig.noncesMethod, [account]),
      };
      const result = await provider.send('eth_call', [param, 'latest']);
      const [nonce] = nonceInterface.decodeFunctionResult(approveTokenConfig.noncesMethod, result) as [BigNumber];
      return nonce.add(nonceOffset);
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [nonceOffset, account, token?.address, approveTokenConfig, provider]);

  const getTokenNameCallback = useCallback(async () => {
    if (!token?.address || !provider || !account) {
      return null;
    }

    try {
      const tokenNameInterface = new Interface([`function name() returns (string)`]);
      const param = {
        from: account,
        to: token?.address,
        data: tokenNameInterface.encodeFunctionData('name'),
      };
      const result = await provider.send('eth_call', [param, 'latest']);
      const [name] = tokenNameInterface.decodeFunctionResult('name', result) as [string];
      return name;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [account, provider, token?.address]);

  const approveWithPermitCallback = useCallback(async (): Promise<TransactionResponse | void> => {
    if (!account || !spender || !deadline || !(amount instanceof TokenAmount) || !provider) {
      console.error(`Approve Error: Missing dependencies.`);
      return;
    }
    if (approveWithPermitState !== ApprovalState.NOT_APPROVED) {
      console.warn('Approve was called unnecessarily');
      return;
    }

    if (isArgentWallet || !approveTokenConfig) {
      return approveCallback();
    }

    try {
      setAttempt(true);

      const [tokenName, nonce] = await Promise.all([getTokenNameCallback(), getNonceCallback()]);

      if (nonce === null) {
        throw new Error('Approve Error: Missing nonces.');
      }
      if (tokenName === null) {
        throw new Error('Approve Error: Missing token name.');
      }

      const typedData: EIP712TypedData = getSignTypedData(tokenName, account, spender, amount, nonce, deadline, approveTokenConfig);
      const signature: Signature = await signTypedData(provider, account, typedData);
      const callData = getCallData(account, spender, amount, nonce, deadline, signature, approveTokenConfig);

      setCallData(callData);
      setAttempt(false);
      return;
    } catch (e: any) {
      setAttempt(false);
      if (e?.code !== 4001) {
        return approveCallback();
      }
      throw e;
    }
  }, [account, spender, deadline, amount, provider, approveWithPermitState, isArgentWallet, approveTokenConfig, approveCallback, getTokenNameCallback, getNonceCallback]);

  // Reset state after change approval id
  useEffect(() => {
    setAttempt(undefined);
    setCallData(undefined);
  }, [approvalId]);

  // Update stored attempt flag
  useEffect(() => {
    if (!approvalId || attempt === undefined) {
      return;
    }
    dispatch(setPermitCallAttempt({ id: approvalId, attempt }));

    return () => {
      dispatch(setPermitCallAttempt({ id: approvalId, attempt: false }));
    };
  }, [attempt, dispatch, approvalId]);

  // Update stored call data
  useEffect(() => {
    if (!approvalId || callData === undefined || !account || !token?.address) {
      return;
    }
    dispatch(setPermitCallData({ id: approvalId, callData, account, token: token.address }));

    return () => {
      dispatch(setPermitCallData({ id: approvalId, callData: null, account, token: token.address }));
    };
  }, [approvalId, callData, account, token?.address, dispatch]);

  return useMemo(() => [approveWithPermitState, storedCallData, approveWithPermitCallback], [approveWithPermitState, storedCallData, approveWithPermitCallback]);
}
