import { CurrencyAmount, Fraction, JSBI } from '@plasma/plasmaswap-sdk';
import { formatTokenSymbol } from './format-token-symbol';

export interface FormatCurrencyAmountSetting {
  currencyMaxDecimals?: number; // Default 6
  decimalPlaces?: number; // Default undefined
  groupSeparator?: string; // Default ','
  preferredStringLength?: number; // Default 8
  hideSymbol?: boolean; // Default false
}

export function formatCurrencyAmount(currencyAmount?: CurrencyAmount, s: FormatCurrencyAmountSetting = {}): string {
  const preferredStringLength = s.preferredStringLength || 8;
  const currencyMaxDecimals = s.currencyMaxDecimals || 6;
  const currencyMinAmount = new Fraction(JSBI.BigInt(1), JSBI.BigInt(Math.pow(10, currencyMaxDecimals)));
  const formatSettings: { [key: string]: any } = {};

  if (!currencyAmount) {
    return s.decimalPlaces === undefined ? '0' : `${1 / Math.pow(10, s.decimalPlaces)}`.replace('1', '0');
  }

  const symbol = s.hideSymbol ? '' : ` ${formatTokenSymbol(currencyAmount.currency)}`;

  formatSettings.groupSeparator = s?.groupSeparator === undefined ? ',' : s.groupSeparator;

  if (currencyAmount.equalTo(JSBI.BigInt(0))) {
    return `${s.decimalPlaces ? (0).toFixed(s.decimalPlaces) : '0'}${symbol}`;
  }

  if (currencyAmount.greaterThan(currencyMinAmount)) {
    const amount = s.decimalPlaces === undefined ? currencyAmount.toExact(formatSettings) : currencyAmount.toFixed(s.decimalPlaces, formatSettings);
    // eslint-disable-next-line prefer-const
    let [integer, fractional] = amount.split('.');

    if (fractional) {
      if (s.decimalPlaces === undefined) {
        if (integer === '0') {
          fractional = `.${fractional.substr(0, currencyMaxDecimals)}`;
        } else {
          const integerLength = integer.replace(/,/g, '').length;
          const preferredMaxDecimals = preferredStringLength - integerLength;
          const maxDecimals = preferredMaxDecimals < currencyMaxDecimals ? preferredMaxDecimals : currencyMaxDecimals;

          const fractionalNum = fractional.substr(0, maxDecimals);
          fractional = fractionalNum ? `.${fractionalNum}` : '';
        }
      } else {
        fractional = `.${fractional}`;
      }
    } else {
      fractional = '';
    }

    return `${integer}${fractional}${symbol}`;
  }

  if (s.decimalPlaces === undefined) {
    return `<${currencyMinAmount.toSignificant(1)}${symbol}`;
  } else {
    return `<${1 / Math.pow(10, s.decimalPlaces)}${symbol}`;
  }
}
