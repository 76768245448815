import { Token, TokenAmount } from '@plasma/plasmaswap-sdk';
import Big from 'big.js';
import { PlasmaTokenInfo, WrappedTokenInfo, UnmarshalSupportedChainId } from '../../types';

const PORTFOLIO_TOKENS_CACHE: { [chainId in UnmarshalSupportedChainId]?: { [address: string]: WrappedTokenInfo<PlasmaTokenInfo> } } = {};

export function getTokenInstance(tokenInfo: PlasmaTokenInfo, usdToken: Token): { balance: TokenAmount; quote?: TokenAmount } {
  const usdTokenMultiplier = Math.pow(10, usdToken.decimals);
  const usdAmount = tokenInfo.usdQuote ? new TokenAmount(usdToken, Big(tokenInfo.usdQuote).times(usdTokenMultiplier).toFixed(0)) : undefined;
  if (!(tokenInfo.chainId in PORTFOLIO_TOKENS_CACHE)) {
    PORTFOLIO_TOKENS_CACHE[tokenInfo.chainId as UnmarshalSupportedChainId] = {};
  }
  const cacheByChain = PORTFOLIO_TOKENS_CACHE[tokenInfo.chainId as UnmarshalSupportedChainId] as { [address: string]: WrappedTokenInfo<PlasmaTokenInfo> };
  if (!(tokenInfo.address in cacheByChain)) {
    cacheByChain[tokenInfo.address] = new WrappedTokenInfo<PlasmaTokenInfo>(tokenInfo, []);
  }
  const tokenMultiplier = Math.pow(10, tokenInfo.decimals);
  const tokenAmount = Big(tokenInfo.exactBalance).times(tokenMultiplier).toFixed();
  const token = cacheByChain[tokenInfo.address];
  return { balance: new TokenAmount(token, tokenAmount), quote: usdAmount };
}
