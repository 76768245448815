import { Currency, Token } from '@plasma/plasmaswap-sdk';
import EthereumLogo from '../assets/images/ethereum-logo.svg';
import PolygonLogo from '../assets/images/polygon-logo.svg';
import BinanceLogo from '../assets/images/binance-logo.svg';
import PPayLogo from '../assets/images/ppay.svg';
import XPPayLogo from '../assets/images/xppay.svg';
import NoLogo from '../assets/images/no-logo.svg';
import { WrappedTokenInfo } from '../types';
import { isAddress } from './index';
import packageJson from '../../package.json';

/**
 * List of currencies whose icons are located in the plasma dlt storage
 */
const PLASMA_DLT_LOGO_ADDRESSES: string[] = [
  '0x9b31bb425D8263fA1b8B9d090b83CF0C31665355',
  '0x3B3Ac5386837Dc563660FB6a0937DFAa5924333B',
  '0x075b1bb99792c9E1041bA13afEf80C91a1e70fB3',
  '0xdF5e0e81Dff6FAF3A7e52BA697820c5e32D806A8',
  '0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490',
  '0x9Fd1d329BB687fef164F529f6F6DcD6f69e7b978',
  '0xE4fA3C576c31696322e8d7165C5965d5a1F6A1A5',
  '0x5282a4eF67D9C33135340fB3289cc1711c13638C',
  '0x06325440D014e39736583c165C2963BA99fAf14E',
  '0x845838DF265Dcd2c412A1Dc9e959c7d08537f8a2',
  '0x49849C98ae39Fff122806C06791Fa73784FB3675',
  '0xFd2a8fA60Abd58Efe3EeE34dd494cD491dC14900',
  '0xEcd5e75AFb02eFa118AF914515D6521aaBd189F1',
  '0x194eBd173F6cDacE046C53eACcE9B953F28411d1',
  '0xcee60cfa923170e4f8204ae08b4fa6a3f5656f3a',
  '0x3a664Ab939FD8482048609f652f9a0B0677337B9',
  '0xd632f22692FaC7611d2AA1C0D552930D43CAEd3B',
  '0xD2967f45c4f384DEEa880F807Be904762a3DeA07',
  '0x5B5CFE992AdAC0C9D48E05854B2d91C73a003858',
  '0xEd279fDD11cA84bEef15AF5D39BB4d4bEE23F0cA',
  '0x1AEf73d49Dedc4b1778d0706583995958Dc862e6',
  '0x02d341CcB60fAaf662bC0554d13778015d1b285C',
  '0xC25a3A3b969415c80451098fa907EC722572917F',
  '0x4f3E8F405CF5aFC05D68142F3783bDfE13811522',
  '0x7Eb40E450b9655f4B3cC4259BCC731c63ff55ae6',
  '0xA3D87FffcE63B53E0d54fAa1cc983B7eB0b74A9c',
  '0xaA17A236F2bAdc98DDc0Cf999AbB47D47Fc0A6Cf',
  '0xb19059ebb43466C323583928285a49f558E572Fd',
  '0xDE5331AC4B3630f94853Ff322B66407e0D6331E8',
  '0x410e3E86ef427e30B9235497143881f717d93c2A',
  '0x64eda51d3Ad40D56b9dFc5554E06F94e1Dd786Fd',
  '0x2fE94ea3d5d4a175184081439753DE15AeF9d614',
  '0xcee60cFa923170e4f8204AE08B4fA6A3F5656F3a',
];

/**
 * Currency codes whose files are in svg format
 */
const SVG_LOGOS: string[] = [
  'PANCAKESWAP',
  'UNISWAP',
  'PLASMA',
  'SUSHISWAP',
  'XPPAY',
  'OPENSEA',
  'BALANCER',
  'APE_SWAP',
  'BORGSWAP',
  'BSC_SWAP',
  'CHEESE_SWAP',
  'DFYN',
  'JUST_LIQUIDITY',
  'METAMASK',
  'ONEINCH',
  'PANCAKE',
  'PARASWAP',
  'QUICKSWAP',
  'SHIBAINU',
  'UNISWAP_V2',
  'UNISWAP_V3',
  'CURVE',
];

/**
 * Failed urls
 */
const BAD_SRCS: { [url: string]: true } = {};

/**
 * Saved previous results
 */
const RESULTS_SRCS: { [url: string]: Promise<boolean> } = {};

type LogoUrlInput = Currency | string | null | undefined;

/**
 * Object can be address url currency or token
 * @param objs
 */
export async function getLogoUrl(objs?: LogoUrlInput | LogoUrlInput[]): Promise<string> {
  let urls: string[];
  if (!objs) {
    urls = [];
  } else if (Array.isArray(objs)) {
    urls = Array.from(new Set(objs.flatMap(obj => buildUrls(obj))));
  } else {
    urls = buildUrls(objs);
  }

  urls = urls.filter(url => !BAD_SRCS[url]);

  return Promise.all(urls.map(url => validateImage(url))).then((results: boolean[]) => {
    let url: string | undefined = undefined;
    results.forEach((result, index) => {
      if (!result) {
        BAD_SRCS[urls[index]] = true;
      } else if (result && !url) {
        url = urls[index];
      }
    });

    if (url) {
      return url;
    }
    return getLogoUrlFail(objs);
  });
}

function validateImage(url: string): Promise<boolean> {
  if (!RESULTS_SRCS[url]) {
    RESULTS_SRCS[url] = new Promise(resolve => {
      const image = new Image();
      image.onload = () => resolve(image.width > 0 && image.height > 0);
      image.onerror = () => resolve(false);
      image.src = url;
    });
  }
  return RESULTS_SRCS[url];
}

function buildUrls(obj?: Currency | string | null): string[] {
  if (!obj) {
    return [];
  }

  if (typeof obj === 'string') {
    let protocol;
    try {
      const url = new URL(obj);
      protocol = url.protocol;
    } catch (e) {}

    if (protocol) {
      switch (protocol) {
        case 'https:':
          return [obj];
        case 'http:':
          return ['https' + obj.substr(4), obj];
        case 'ipfs:':
          const hash = obj.match(/^ipfs:(\/\/)?(.*)$/i)?.[2];
          return [`https://cloudflare-ipfs.com/ipfs/${hash}/`, `https://ipfs.io/ipfs/${hash}/`];
        case 'ipns:':
          const name = obj.match(/^ipns:(\/\/)?(.*)$/i)?.[2];
          return [`https://cloudflare-ipfs.com/ipns/${name}/`, `https://ipfs.io/ipns/${name}/`];
        default:
          return [];
      }
    }
    if (isAddress(obj)) {
      return [getLogoUrlByAddress(obj)];
    }
    return [getLogoUrlBySymbol(obj)];
  }

  return getLogoUrlsByCurrency(obj);
}

function getLogoUrlsByCurrency(currency: Currency): string[] {
  if ((currency.isNative && currency.symbol === 'ETH') || currency.symbol === 'WETH') {
    return [EthereumLogo];
  }
  if ((currency.isNative && currency.symbol === 'MATIC') || currency.symbol === 'WMATIC') {
    return [PolygonLogo];
  }
  if ((currency.isNative && currency.symbol === 'BNB') || currency.symbol === 'WBNB') {
    return [BinanceLogo];
  }
  if (currency.symbol?.toUpperCase() === 'PPAY') {
    return [PPayLogo];
  }
  if (currency.symbol?.toUpperCase() === 'XPPAY') {
    return [XPPayLogo];
  }
  const urls = [];
  if (currency instanceof WrappedTokenInfo && currency.logoURI) {
    urls.push(currency.logoURI);
  } else if (currency instanceof Token || currency instanceof WrappedTokenInfo) {
    urls.push(getLogoUrlByAddress(currency.address));
  }
  if (currency.symbol) {
    urls.push(getLogoUrlBySymbol(currency.symbol));
  }
  return urls;
}

function getLogoUrlByAddress(address: string): string {
  if (PLASMA_DLT_LOGO_ADDRESSES.includes(address)) {
    return `https://raw.githubusercontent.com/plasmadlt/token-list/master/assets/${address}.png`;
  } else {
    return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`;
  }
}

function getLogoUrlBySymbol(symbol: string): string {
  return `${packageJson.homepage || '/'}images/currencies/${symbol.replace('_', '-').toLowerCase()}.${SVG_LOGOS.includes(symbol.toUpperCase()) ? 'svg' : 'png'}`;
}

function getLogoUrlFail(objs?: LogoUrlInput | LogoUrlInput[]): string {
  let symbol: string | undefined;
  if (!objs) {
    symbol = undefined;
  } else if (Array.isArray(objs)) {
    for (let i = 0; i < objs.length; i++) {
      symbol = getSymbol(objs[i]);
      if (symbol) {
        break;
      }
    }
  } else {
    symbol = getSymbol(objs);
  }

  if (symbol) {
    const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" preserveAspectRatio="none" viewBox="0 0 50 50">
      <defs>
        <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stop-color="#D987FF" />
          <stop offset="100%" stop-color="#63A1FF" />
        </linearGradient>
      </defs>
      <circle r="25" cx="25" cy="25" fill="url(#gradient)" />
      <text x="25" y="38" text-anchor="middle" style="font: bold 36px sans-serif; fill: white">
        ${symbol}
      </text>
    </svg>
  `;
    return `data:image/svg+xml;base64,${btoa(svg.replace(/\s+/g, ' '))}`;
  } else {
    return NoLogo;
  }
}

function getSymbol(obj?: LogoUrlInput): string | undefined {
  if (!obj) {
    return undefined;
  }
  let symbol: string | undefined = undefined;
  if (typeof obj === 'string') {
    symbol = obj.charAt(0).toUpperCase();
  } else if (obj?.symbol) {
    symbol = obj.symbol.charAt(0).toUpperCase();
  }

  if (symbol) {
    try {
      return encodeURI(symbol);
    } catch (e) {}
  }

  return undefined;
}
