import { ChainId, Trade0xLiquiditySource } from '@plasma/plasmaswap-sdk';
import { useMemo } from 'react';
import { useActiveWeb3React } from './web3/use-active-web3-react';

const POLYGON_ONLY: Trade0xLiquiditySource[] = [
  Trade0xLiquiditySource.QuickSwap,
  Trade0xLiquiditySource.ComethSwap,
  Trade0xLiquiditySource.Dfyn,
  Trade0xLiquiditySource.WaultSwap,
  Trade0xLiquiditySource.Polydex,
  Trade0xLiquiditySource.FirebirdOneSwap,
  Trade0xLiquiditySource.JetSwap,
  Trade0xLiquiditySource.IronSwap,
];

const BSC_ONLY: Trade0xLiquiditySource[] = [
  Trade0xLiquiditySource.PancakeSwap,
  Trade0xLiquiditySource.PancakeSwapV2,
  Trade0xLiquiditySource.BakerySwap,
  Trade0xLiquiditySource.Nerve,
  Trade0xLiquiditySource.Belt,
  Trade0xLiquiditySource.Ellipsis,
  Trade0xLiquiditySource.ApeSwap,
  Trade0xLiquiditySource.CafeSwap,
  Trade0xLiquiditySource.CheeseSwap,
  Trade0xLiquiditySource.JulSwap,
  Trade0xLiquiditySource.ACryptos,
];

export function useSupported0xLiquiditySources(): Trade0xLiquiditySource[] {
  const { chainId } = useActiveWeb3React();

  return useMemo(() => {
    if (!chainId) {
      return [];
    }
    const providerIds = Object.values(Trade0xLiquiditySource).sort();
    if (chainId === ChainId.MATIC) {
      return providerIds.filter(i => !BSC_ONLY.includes(i));
    } else if (chainId === ChainId.BSC) {
      return providerIds.filter(i => !POLYGON_ONLY.includes(i));
    }
    return providerIds.filter(i => !POLYGON_ONLY.includes(i) && !BSC_ONLY.includes(i));
  }, [chainId]);
}
