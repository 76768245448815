import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import './toggle-switch.scss';

interface ToggleSwitchProps {
  id?: string;
  value: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
}

export function ToggleSwitch({ id, value, onChange, disabled = false }: ToggleSwitchProps): JSX.Element {
  const switchId = useMemo(() => (id ? id : uuid()), [id]);

  return (
    <div className="toggle-switch">
      <input role="switch" type="checkbox" className="hidden-checkbox" checked={value} onChange={() => onChange && onChange(!value)} id={switchId} disabled={disabled} />
      <label className="switch" htmlFor={switchId}>
        <span className="switch-thumb" />
      </label>
    </div>
  );
}
