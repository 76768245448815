import { parseUnits } from '@ethersproject/units';
import { Currency, CurrencyAmount, toCurrencyAmount } from '@plasma/plasmaswap-sdk';

/**
 * Try to parse a user entered amount for a given token
 * @param value
 * @param currency
 */
export function tryParseAmount(value?: string, currency?: Currency): CurrencyAmount | undefined {
  if (!value || !currency) {
    return undefined;
  }

  try {
    const [integer, fractional] = value.split('.');
    if (!integer) {
      return undefined;
    }
    value = `${integer}${fractional ? `.${fractional.substr(0, currency.decimals)}` : ''}`;

    const typedValueParsed = parseUnits(value, currency.decimals).toString();
    if (typedValueParsed !== '0') {
      return toCurrencyAmount(currency, typedValueParsed);
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.error(`Failed to parse input amount: "${value}"`, error);
  }
  // necessary for all paths to return a value
  return undefined;
}
