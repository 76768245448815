import { ChainId } from '@plasma/plasmaswap-sdk';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getViewOnScanText } from '../../utils/get-view-on-scan-text';

export function ViewOnScanLinkContent({ chainId }: { chainId?: ChainId }): JSX.Element {
  const { t } = useTranslation();
  const content = useMemo(() => getViewOnScanText(t, chainId), [chainId, t]);

  return <>{content}</>;
}
