import { ChainId } from '@plasma/plasmaswap-sdk';
import { stringify } from 'qs';
import { NETWORK_ICON } from '../constants';
import { BridgeEvmNetworkMeta } from '../types';

// export const API_URL = 'https://backend.movr.network/v2';
export const API_URL = 'https://api.socket.tech/v2';

export interface BridgeSocketUserBalance {
  address: string;
  amount: number;
  chainId: ChainId;
  decimals: number;
  icon: string;
  name: string;
  symbol: string;
}

export interface BridgeSocketQuoteQuery {
  fromTokenAddress: string;
  fromChainId: string;
  toTokenAddress: string;
  toChainId: string;
  fromAmount: string;
  userAddress: string;
}

export interface BridgeSocketQuoteRoute {
  routeId: string;
  fromAmount: string;
  toAmount: string;

  recipient: string;
  sender: string;

  serviceTime: number;
  maxServiceTime: number;
  totalGasFeesInUsd: number;
  totalUserTx: number;

  usedBridgeNames: string[];

  chainGasBalances: {
    [chainId: number]: {
      minGasBalance: string;
      hasGasBalance: boolean;
    };
  };
  minimumGasBalances: { [chainId: number]: string };
}

export interface BridgeSocketAsset {
  chainId: number;
  address: string;
  symbol: string;
  name: string;
  decimals: number;
  icon: string;
  logoURI: string;
}

export interface BridgeSocketQuote {
  fromChainId: number;
  fromAsset: BridgeSocketAsset;
  tomChainId: number;
  toAsset: BridgeSocketAsset;
  routes: BridgeSocketQuoteRoute[];
}

export interface BridgeSocketTxApprovalData {
  allowanceTarget: string;
  approvalTokenAddress: string;
  minimumApprovalAmount: string;
  owner: string;
}

export interface BridgeSocketTx {
  chainId: ChainId;
  txTarget: string;
  txData: string;
  txType: string;
  userTxIndex: number;
  userTxType: string;
  approvalData: null | BridgeSocketTxApprovalData;
  /**
   * Hex string value
   */
  value: string;
}

export interface BridgeSocketApprovalTx {
  data: string;
  from: string;
  to: string;
}

export type BridgeSocketTxStatus = 'READY' | 'PENDING' | 'COMPLETED' | 'FAILED';

export interface BridgeSocketStatus {
  fromChainId: ChainId;
  sourceTransactionHash: string | null;
  sourceTxStatus: BridgeSocketTxStatus;

  toChainId: ChainId;
  destinationTransactionHash: string | null;
  destinationTxStatus: BridgeSocketTxStatus;
}

export interface BridgeSocketTokenPrice {
  chainId: ChainId;
  currency: string;
  decimals: number;
  tokenAddress: string;
  tokenPrice: number;
}

export async function bridgeSocketUserBalances(account: string, abort?: AbortSignal): Promise<BridgeSocketUserBalance[]> {
  const url = `${API_URL}/balances?userAddress=${account.toLowerCase()}`;

  const res = await fetch(url, {
    headers: {
      'API-KEY': String(process.env.REACT_APP_BRIDGE_SOCKET_API_KEY),
      'Content-Type': 'application/json',
    },
    signal: abort,
  });

  if (!res.ok) {
    throw new Error(`bridgeSocketUserBalances: ERROR: "${res.statusText}"`);
  }

  const { result: balances } = await res.json();

  return balances || [];
}

export async function bridgeSocketSupportedChains(abort?: AbortSignal): Promise<BridgeEvmNetworkMeta[]> {
  const url = `${API_URL}/supported/chains`;

  const res = await fetch(url, {
    headers: {
      'API-KEY': String(process.env.REACT_APP_BRIDGE_SOCKET_API_KEY),
      'Content-Type': 'application/json',
    },
    signal: abort,
  });

  if (!res.ok) {
    throw new Error(`bridgeSocketSupportedChains: ERROR: "${res.statusText}"`);
  }

  const { result: chains } = await res.json();
  return chains.map(({ chainId, name }: { chainId: ChainId; name: string }) => ({
    logo: NETWORK_ICON[chainId],
    label: name,
    value: chainId,
  }));
}

export async function bridgeSocketGetQuote(query: BridgeSocketQuoteQuery, abort?: AbortSignal): Promise<BridgeSocketQuote> {
  const url = `${API_URL}/quote?${stringify({ ...query, singleTxOnly: 'true' })}`;

  const res = await fetch(url, {
    headers: {
      'API-KEY': String(process.env.REACT_APP_BRIDGE_SOCKET_API_KEY),
      'Content-Type': 'application/json',
    },
    signal: abort,
  });

  if (!res.ok) {
    throw new Error(`bridgeSocketGetQuote: ERROR: "${res.statusText}"`);
  }

  const { result } = await res.json();

  return result;
}

export async function bridgeSocketFromAssetsList(fromChainId: number, toChainId: number, abort?: AbortSignal): Promise<BridgeSocketAsset[]> {
  const url = `${API_URL}/token-lists/from-token-list?fromChainId=${fromChainId}&toChainId=${toChainId}`;

  const res = await fetch(url, {
    headers: {
      'API-KEY': String(process.env.REACT_APP_BRIDGE_SOCKET_API_KEY),
      'Content-Type': 'application/json',
    },
    signal: abort,
  });

  if (!res.ok) {
    throw new Error(`bridgeSocketAssets: ERROR: "${res.statusText}"`);
  }

  const { result } = await res.json();

  return result;
}

export async function bridgeSocketToAssetsList(fromChainId: number, toChainId: number, abort?: AbortSignal): Promise<BridgeSocketAsset[]> {
  const url = `${API_URL}/token-lists/to-token-list?fromChainId=${fromChainId}&toChainId=${toChainId}`;

  const res = await fetch(url, {
    headers: {
      'API-KEY': String(process.env.REACT_APP_BRIDGE_SOCKET_API_KEY),
      'Content-Type': 'application/json',
    },
    signal: abort,
  });

  if (!res.ok) {
    throw new Error(`bridgeSocketAssets: ERROR: "${res.statusText}"`);
  }

  const { result } = await res.json();

  return result;
}

export async function bridgeSocketBuildTx(route: BridgeSocketQuoteRoute, abort?: AbortSignal): Promise<BridgeSocketTx> {
  const res = await fetch(`${API_URL}/build-tx`, {
    method: 'POST',
    body: JSON.stringify({ route }),
    headers: {
      'API-KEY': String(process.env.REACT_APP_BRIDGE_SOCKET_API_KEY),
      'Content-Type': 'application/json',
    },
    signal: abort,
  });

  if (!res.ok) {
    throw new Error(`bridgeSocketBuildTx: ERROR: "${res.statusText}"`);
  }

  const { result } = await res.json();

  return result;
}

export async function bridgeSocketBuildApprovalTx(chainId: ChainId, approvalData: BridgeSocketTxApprovalData, abort?: AbortSignal): Promise<BridgeSocketApprovalTx> {
  const { allowanceTarget, minimumApprovalAmount, owner, approvalTokenAddress } = approvalData;
  const payload = { chainID: String(chainId), owner, allowanceTarget, amount: minimumApprovalAmount, tokenAddress: approvalTokenAddress };

  const res = await fetch(`${API_URL}/approval/build-tx?${stringify(payload)}`, {
    method: 'GET',
    headers: {
      'API-KEY': String(process.env.REACT_APP_BRIDGE_SOCKET_API_KEY),
      'Content-Type': 'application/json',
    },
    signal: abort,
  });

  if (!res.ok) {
    throw new Error(`bridgeSocketBuildApprovalTx: ERROR: "${res.statusText}"`);
  }

  const { result } = await res.json();
  return result;
}

export async function bridgeSocketStatus(txHash: string, fromChainId: number, toChainId: number, abort?: AbortSignal): Promise<BridgeSocketStatus> {
  const payload = { transactionHash: txHash, fromChainId: String(fromChainId), toChainId: String(toChainId) };
  const url = `${API_URL}/bridge-status?${stringify(payload)}`;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'API-KEY': String(process.env.REACT_APP_BRIDGE_SOCKET_API_KEY),
      'Content-Type': 'application/json',
    },
    signal: abort,
  });

  if (!res.ok) {
    throw new Error(`bridgeSocketVerifyTx: ERROR: "${res.statusText}"`);
  }

  const { result } = await res.json();
  return result;
}

export async function bridgeSocketTokenPrice(tokenAddress: string, chainId: number, abort?: AbortSignal): Promise<BridgeSocketTokenPrice> {
  const url = `${API_URL}/token-price?tokenAddress=${tokenAddress.toLowerCase()}&chainId=${chainId}`;

  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'API-KEY': String(process.env.REACT_APP_BRIDGE_SOCKET_API_KEY),
      'Content-Type': 'application/json',
    },
    signal: abort,
  });

  if (!res.ok) {
    throw new Error(`bridgeSocketTokenPrice: ERROR: "${res.statusText}"`);
  }

  const { result } = await res.json();
  return result;
}
