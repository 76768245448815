import React from 'react';
import { safeAmountInput } from '../../utils/safe-amount-input';
import { isNumber } from '../../utils/is-number';
import './numerical-input.scss';

const MAX_INTEGER_LENGTH = 30;

export interface NumericalInputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'> {
  value: string | number;
  decimals?: number;
  onUserInput: (value: string) => void;
  error?: boolean;
  fontSize?: string;
  align?: 'right' | 'left';
}

export const NumericalInput = React.memo(function InnerInput({ value, decimals = 18, onUserInput, placeholder, ...rest }: NumericalInputProps) {
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput.includes('.')) {
      const [integer, fractional] = nextUserInput.split('.');
      nextUserInput = `${integer.substring(0, MAX_INTEGER_LENGTH)}.${fractional.substring(0, decimals)}`;
    } else if (nextUserInput.length > MAX_INTEGER_LENGTH) {
      nextUserInput = nextUserInput.substring(0, MAX_INTEGER_LENGTH);
    }

    if (nextUserInput === '' || !isNumber(nextUserInput) || /(^\.)|(\.[0-9]*0+$)/.test(nextUserInput)) {
      onUserInput(nextUserInput);
    } else {
      onUserInput(safeAmountInput(nextUserInput, decimals));
    }
  };

  return (
    <input
      {...rest}
      value={value}
      onChange={event => {
        const value = event.target.value.trim();
        if (/^[0-9]*[.,/]?[0-9]*$/.test(value)) {
          enforcer(value.replace(/[,/]/g, '.'));
        }
      }}
      onBlur={event => {
        let value = event.target.value.trim().replace(/[,/]/g, '.');
        value = value ? safeAmountInput(value, decimals) : '';
        if (value !== event.target.value) {
          onUserInput(value);
        }
      }}
      // universal input options
      className={`numerical-input ${rest.className || ''}`}
      inputMode="decimal"
      title="Token Amount"
      autoComplete="off"
      autoCorrect="off"
      // text-specific options
      type="text"
      pattern="^[0-9]*[.,]?[0-9]*$"
      placeholder={placeholder || '0.0'}
      minLength={1}
      maxLength={79}
      spellCheck="false"
    />
  );
});
