import { TransactionReceipt } from '@ethersproject/abstract-provider';
import { TransactionResponse } from '@ethersproject/providers';
import { Currency, CurrencyAmount } from '@plasma/plasmaswap-sdk';
import Lottie from 'lottie-react';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bridgeSocketBuildApprovalTx, bridgeSocketBuildTx, BridgeSocketStatus, bridgeSocketStatus } from '../../api';
import Success from '../../assets/animations/loader-confirmed.json';
import Loader from '../../assets/animations/loader-transparent.json';
import NetworkConfirmation from '../../assets/animations/network-confirmation.json';
import { useEvmNetworks } from '../../hooks/bridge/use-evm-networks';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { BridgeEvmQuote } from '../../types';
import { getEtherscanLink } from '../../utils';
import { formatAmount } from '../../utils/format-amount';
import { formatTokenSymbol } from '../../utils/format-token-symbol';
import { getViewOnScanText } from '../../utils/get-view-on-scan-text';
import { sleep } from '../../utils/sleep';
import { ExternalLink } from '../external-link/external-link';
import { FormattedAmountInFiat } from '../formatted-amount-in-fiat/formatted-amount-in-fiat';
import { FormattedCurrencyAmount } from '../formatted-currency-amount/formatted-currency-amount';
import { FormattedTokenSymbol } from '../formatted-token-symbol/formatted-token-symbol';
import { InfoHelper } from '../info-helper/info-helper';
import { CurrencyLogo } from '../logo/logo';
import { Modal, ModalProps } from '../modal/modal';
import { ModalTxErrorView } from '../modal/modal-tx-error-view';
import './modal-bridge-evm-confirm.scss';

const SOURCE_TX_TOTAL_CONFIRMATIONS = 6;

export interface ModalBridgeEvmConfirmProps extends ModalProps {
  fromAmount?: CurrencyAmount;
  toCurrency?: Currency;
  quote?: BridgeEvmQuote | null;
  onSuccess?: () => void;
}

export const ModalBridgeEvmConfirm: FC<ModalBridgeEvmConfirmProps> = ({ onDismiss, isOpen, ...props }) => {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      {isOpen ? <ModalBridgeEvmConfirmContent isOpen={isOpen} onDismiss={onDismiss} {...props} /> : null}
    </Modal>
  );
};

const ModalBridgeEvmConfirmContent: FC<ModalBridgeEvmConfirmProps> = ({ onDismiss, onSuccess, ...props }) => {
  const { quote } = props;
  const { provider, account } = useActiveWeb3React();
  const { t } = useTranslation();

  // Modal states
  const [run, setRun] = useState(false);
  const [txResponse, setTxResponse] = useState<TransactionResponse>();
  const [error, setError] = useState<Error>();

  const onDismissCallback = useCallback(() => {
    if (txResponse) {
      onSuccess?.();
    }
    onDismiss();
  }, [txResponse, onDismiss, onSuccess]);

  // Create or/and wait bridge transaction after set run flag to true
  useEffect(() => {
    if (!quote || !run || !provider || !account || txResponse || error) {
      return;
    }

    const signer = provider.getSigner();
    const abortController = new AbortController();

    bridgeSocketBuildTx(quote.route, abortController.signal)
      .then(result => {
        if (!result.approvalData) {
          return result;
        }
        return bridgeSocketBuildApprovalTx(result.chainId, result.approvalData, abortController.signal)
          .then(approvalTxData => signer.sendTransaction(approvalTxData))
          .then(approvalTx => approvalTx.wait())
          .then(() => result);
      })
      .then(result => {
        return signer.sendTransaction({
          to: result.txTarget,
          data: result.txData,
          value: result.value,
        });
      })
      .then(setTxResponse)
      .catch(error => {
        if (error.name === 'AbortError') {
          return;
        }
        setError(error);
      });

    return () => {
      abortController.abort();
    };
  }, [error, quote, run, provider, account, txResponse]);

  return (
    <div className="modal modal-bridge-evm-confirm">
      {error ? (
        <ModalTxErrorView caption={t('evm_bridge_error')} info={error?.message || t('something_went_wrong')} onDismiss={onDismissCallback} />
      ) : !run ? (
        <TransactionInfoContent {...props} onDismiss={onDismissCallback} onConfirm={() => setRun(true)} />
      ) : run && !txResponse ? (
        <TransactionConfirmationContent {...props} onDismiss={onDismissCallback} />
      ) : txResponse ? (
        <TransactionStatusContent {...props} onDismiss={onDismissCallback} txResponse={txResponse} onError={setError} />
      ) : null}
    </div>
  );
};

/**
 * Transaction information for check
 * @param props
 * @constructor
 */
const TransactionInfoContent: FC<ModalBridgeEvmConfirmProps & { onConfirm: () => void }> = props => {
  const { fromAmount, toCurrency, quote, onDismiss, onConfirm } = props;
  const { t } = useTranslation();
  const networks = useEvmNetworks();

  const fromNetwork = useMemo(() => networks?.find(i => i.value === fromAmount?.currency.chainId), [fromAmount?.currency.chainId, networks]);
  const toNetwork = useMemo(() => networks?.find(i => i.value === toCurrency?.chainId), [toCurrency?.chainId, networks]);
  const isSwap = !!fromAmount && !!toCurrency && fromAmount.currency.symbol !== toCurrency.symbol;

  return (
    <>
      <div className="modal-header">
        <div className="title">{t('confirm_bridge')}</div>
        <button className="btn" onClick={onDismiss}>
          <i className="pi pi-close" />
        </button>
      </div>
      <div className="modal-content">
        {isSwap ? (
          <div className="swap-info">
            <div className="label">
              <span>{t('swapping')}</span>
            </div>
            <div className="token-row">
              <div className="logo">
                <CurrencyLogo currency={fromAmount?.currency} size={24} />
              </div>
              <div className="amount">
                <FormattedCurrencyAmount amount={fromAmount} maxDecimals={6} hideSymbol />
              </div>
              <div className="symbol">
                <FormattedTokenSymbol currency={fromAmount?.currency} />
              </div>
            </div>
            <div className="separator">
              <i className="pi pi-down" />
            </div>
            <div className="token-row">
              <div className="logo">
                <CurrencyLogo currency={toCurrency} size={24} />
              </div>
              <div className="amount">
                <FormattedCurrencyAmount amount={quote?.toAmount} maxDecimals={6} hideSymbol />
              </div>
              <div className="symbol">
                <FormattedTokenSymbol currency={toCurrency} />
              </div>
            </div>
          </div>
        ) : null}

        {fromNetwork && toNetwork ? (
          <>
            <div className="bridge-transfer">
              <div className="label">{t('bridge_transfer')}</div>
              <div className="chains">
                <div>
                  <img src={fromNetwork.logo} alt={fromNetwork.label} />
                  <span>{fromNetwork.label}</span>
                </div>
                <div className="separator">
                  <i className="pi pi-right-tick" />
                </div>
                <div>
                  <img src={toNetwork.logo} alt={toNetwork.label} />
                  <span>{toNetwork.label}</span>
                </div>
              </div>
            </div>
            {!isSwap ? (
              <div className="bridge-info">
                <div className="label">{t('bridge_to', { chainName: toNetwork.label })}</div>
                <div className="amount">
                  <FormattedCurrencyAmount amount={fromAmount} maxDecimals={6} />
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
      <div className="modal-footer">
        <div className="estimation-block">
          <div className="row-between">
            <div className="label">
              <span>{t('you_will_receive')}</span>
            </div>
            <div className="value">
              <CurrencyLogo currency={toCurrency} size={16} className="logo" />
              <FormattedCurrencyAmount amount={quote?.toAmount} />
            </div>
          </div>
          <div className="row-between">
            <div className="label">
              <span>{t('total_fee')}</span>
              <InfoHelper backdrop="white" placement="top-start">
                <>
                  <p>{t('hop_fee_tooltip')}</p>
                  <p>{t('hop_fee_tooltip_lp_fees')}</p>
                </>
              </InfoHelper>
            </div>
            <div className="value">
              <FormattedAmountInFiat amount={quote?.feeInUsd} />
            </div>
          </div>
        </div>
        <button className="btn btn-primary btn-lg" id="confirm-button" onClick={onConfirm}>
          <span>{t('confirm_bridge_transfer')}</span>
        </button>
      </div>
    </>
  );
};

/**
 * Confirm transfer for EVM chains
 * @param props
 * @constructor
 */
const TransactionConfirmationContent: FC<ModalBridgeEvmConfirmProps> = props => {
  const { fromAmount, toCurrency, quote, onDismiss } = props;
  const { t } = useTranslation();
  const networks = useEvmNetworks();

  const fromNetwork = useMemo(() => networks?.find(i => i.value === fromAmount?.currency.chainId), [fromAmount?.currency.chainId, networks]);
  const toNetwork = useMemo(() => networks?.find(i => i.value === toCurrency?.chainId), [toCurrency?.chainId, networks]);

  return (
    <>
      <div className="modal-header">
        <div className="title" />
        <button className="btn" onClick={onDismiss}>
          <i className="pi pi-close" />
        </button>
      </div>
      <div className="modal-content">
        <div className="awaiting-animation">
          <img src={fromNetwork?.logo} alt={fromNetwork?.label} className="chain-logo" />
          <Lottie animationData={Loader} loop={true} />
        </div>
        <div className="transaction-confirmation">
          <div className="caption">
            <span>{t('confirm_in_network', { network: fromNetwork?.label })}</span>
          </div>
          <div className="swap-info">
            {t('swapping_currencies', {
              amountFrom: <strong key="from">{`${formatAmount(fromAmount?.toExact())} ${formatTokenSymbol(fromAmount?.currency)}`}</strong>,
              amountTo: <strong key="to">{`${formatAmount(quote?.toAmount.toExact())} ${formatTokenSymbol(toCurrency)}`}</strong>,
            })}
          </div>
          <div className="note">
            <span>{t('confirm_this_transaction_in_your_wallet')}</span>
          </div>
        </div>
        <div className="bridge-transfer">
          <div className="chains">
            <div>
              <img src={fromNetwork?.logo} alt={fromNetwork?.label} />
              <span>{fromNetwork?.label}</span>
            </div>
            <div className="separator">
              <i className="pi pi-right-tick" />
            </div>
            <div>
              <img src={toNetwork?.logo} alt={toNetwork?.label} />
              <span>{toNetwork?.label}</span>
            </div>
          </div>
        </div>
        <div className="bridge-info">
          <div className="label">{t('bridge_to', { chainName: toNetwork?.label })}</div>
          <div className="amount">
            <FormattedCurrencyAmount amount={quote?.toAmount} maxDecimals={6} />
          </div>
        </div>
        <div className="separator" />
      </div>
    </>
  );
};

/**
 * Show transaction status
 * @param props
 * @constructor
 */
const TransactionStatusContent: FC<ModalBridgeEvmConfirmProps & { txResponse: TransactionResponse; onError: (error: Error) => void }> = props => {
  const { txResponse, fromAmount, toCurrency, onDismiss, onError } = props;
  const { t } = useTranslation();
  const networks = useEvmNetworks();

  const [success, setSuccess] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [sourceTxConfirmations, setSourceTxConfirmations] = useState(0);
  const [sourceTxHash, setSourceTxHash] = useState<string>();
  const [destinationTxHash, setDestinationTxHash] = useState<string>();

  const fromNetwork = useMemo(() => networks?.find(i => i.value === fromAmount?.currency.chainId), [fromAmount?.currency.chainId, networks]);
  const toNetwork = useMemo(() => networks?.find(i => i.value === toCurrency?.chainId), [toCurrency?.chainId, networks]);

  // Waiting source transaction confirmations
  useEffect(() => {
    let cancel = false;

    async function waitConfirmations(): Promise<TransactionReceipt> {
      let nextConfirmation = 1;
      while (true) {
        if (cancel) {
          throw new Error('canceled');
        }
        const tx = await txResponse.wait(nextConfirmation);
        if (tx.confirmations >= SOURCE_TX_TOTAL_CONFIRMATIONS) {
          setSourceTxConfirmations(SOURCE_TX_TOTAL_CONFIRMATIONS);
          return tx;
        }
        setSourceTxConfirmations(tx.confirmations);
        nextConfirmation = tx.confirmations + 1;
      }
    }

    waitConfirmations()
      .then(tx => {
        if (!cancel) {
          setSourceTxHash(tx.transactionHash);
        }
      })
      .catch(error => {
        if (!cancel) {
          onError(error);
          console.error(error);
        }
      });

    return () => {
      cancel = true;
    };
  }, [txResponse, onError]);

  // Waiting destination transaction status
  useEffect(() => {
    if (!sourceTxHash || !fromAmount?.currency.chainId || !toCurrency?.chainId) {
      return;
    }
    const fromChainId = fromAmount.currency.chainId;
    const toChainId = toCurrency.chainId;
    let cancel = false;

    async function waitConfirmations(): Promise<BridgeSocketStatus> {
      while (true) {
        if (cancel) {
          throw Error('canceled');
        }
        const result = await bridgeSocketStatus(sourceTxHash as string, fromChainId, toChainId);
        if (result.destinationTxStatus === 'COMPLETED' && result.destinationTransactionHash) {
          return result;
        }
        await sleep(5000);
      }
    }

    waitConfirmations()
      .then(status => {
        if (!cancel) {
          setDestinationTxHash(status.destinationTransactionHash as string);
          setSuccess(true);
        }
      })
      .catch(error => {
        if (!cancel) {
          onError(error);
          console.error(error);
        }
      });

    return () => {
      cancel = true;
    };
  }, [sourceTxHash, fromAmount?.currency.chainId, toCurrency?.chainId, onError]);

  // Update progress bar
  useEffect(() => {
    if (destinationTxHash) {
      setProgress(100);
    } else {
      setProgress(Math.ceil((70 / SOURCE_TX_TOTAL_CONFIRMATIONS) * sourceTxConfirmations));
    }
  }, [sourceTxConfirmations, destinationTxHash]);

  return (
    <>
      <div className="modal-header">
        {!success ? <div className="title">{t('transfer_in_progress')}</div> : <div className="title" />}
        <button className="btn" onClick={onDismiss}>
          <i className="pi pi-close" />
        </button>
      </div>
      <div className="modal-content">
        <div className="awaiting-animation">
          {success ? <Lottie animationData={Success} loop={false} className="animation" /> : <Lottie animationData={NetworkConfirmation} loop={true} className="animation" />}
        </div>
        {success ? (
          <div className="caption">
            <span>{t('transfer_was_finished')}</span>
          </div>
        ) : (
          <>
            <div className="disclaimer">
              <span>{t('transaction_is_en_route', { time: <strong key="time">~7-8 minutes</strong>, network: toNetwork?.label })}</span>
            </div>
            <div className="separator" />
            <div className="progress-bar">
              <div className="label">
                <span>{t('transfer_progress')}</span>
                <b>{progress}%</b>
              </div>
              <div className="progress-bar-wrapper">
                <div className="progress-bar-value" style={{ width: `${progress}%` }} />
              </div>
              {sourceTxConfirmations < SOURCE_TX_TOTAL_CONFIRMATIONS ? (
                <div className="info">
                  <span>{t('bridge_progress_confirmations', { network: fromNetwork?.label, progress: `${sourceTxConfirmations}/${SOURCE_TX_TOTAL_CONFIRMATIONS}` })}</span>
                </div>
              ) : (
                <div className="info">
                  <span>{t('bridge_progress_waiting', { network: toNetwork?.label })}</span>
                </div>
              )}
            </div>
          </>
        )}
        <div className="bridge-transfer">
          <div className="chains">
            <div>
              <img src={fromNetwork?.logo} alt={fromNetwork?.label} />
              <span>{fromNetwork?.label}</span>
            </div>
            <div className="separator">
              <i className="pi pi-right-tick" />
            </div>
            <div>
              <img src={toNetwork?.logo} alt={toNetwork?.label} />
              <span>{toNetwork?.label}</span>
            </div>
          </div>
        </div>
        <div className="tx-links">
          <div className="link">
            <ExternalLink href={getEtherscanLink(txResponse.chainId, txResponse.hash, 'transaction')}>
              <span>{getViewOnScanText(t, txResponse.chainId)}</span>
              <i className="pi pi-back" />
            </ExternalLink>
          </div>
          {destinationTxHash && toCurrency ? (
            <div className="link">
              <ExternalLink href={getEtherscanLink(toCurrency.chainId, destinationTxHash, 'transaction')}>
                <span>{getViewOnScanText(t, toCurrency.chainId)}</span>
                <i className="pi pi-back" />
              </ExternalLink>
            </div>
          ) : null}
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-primary btn-lg" onClick={onDismiss}>
          <span>{t('back_to_dashboard')}</span>
        </button>
      </div>
    </>
  );
};
