import { getAddress } from '@ethersproject/address';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { getReferral } from '../../utils/get-query-params';
import { AppDispatch } from '../index';
import { updateReferrals, updateUsedWallet } from './user.actions';

export function UserUpdater(): null {
  const { account } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>();

  // Store account to the used wallets
  useEffect(() => {
    if (!account) {
      return;
    }
    try {
      dispatch(updateUsedWallet(getAddress(account)));
    } catch (e) {
      console.error(e);
    }
  }, [account, dispatch]);

  // Store referral address
  useEffect(() => {
    const referral = getReferral();
    if (referral) {
      dispatch(updateReferrals([referral]));
    }
  }, [dispatch]);

  return null;
}
