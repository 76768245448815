import { Block } from '@ethersproject/abstract-provider';
import { ChainId } from '@plasma/plasmaswap-sdk';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { useDebounce } from '../../hooks/use-debounce';
import useIsWindowVisible from '../../hooks/use-is-window-visible';
import { AppDispatch } from '../index';
import { updateBlock } from './application.actions';

export function ApplicationUpdater(): null {
  const { provider, chainId } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>();
  const windowVisible = useIsWindowVisible();

  const [blockInfo, setBlockInfo] = useState<{ chainId?: ChainId; blockNumber: number | null }>({ chainId, blockNumber: null });

  const debouncedBlockInfo = useDebounce(blockInfo, 100);

  // Attach/detach listeners
  useEffect(() => {
    if (!provider || !chainId || !windowVisible) {
      return undefined;
    }

    setBlockInfo({ chainId, blockNumber: null });

    let canceled = false;

    function blockHandler(blockNumber: number): void {
      if (canceled) {
        return;
      }
      setBlockInfo(blockInfo => {
        if (chainId === blockInfo.chainId) {
          if (typeof blockInfo.blockNumber !== 'number') {
            return { chainId, blockNumber };
          }
          return { chainId, blockNumber: Math.max(blockNumber, blockInfo.blockNumber) };
        }
        return blockInfo;
      });
    }

    provider
      .getBlockNumber()
      .then(blockHandler)
      .catch(error => console.error(`Failed to get block number for chainId: ${chainId}`, error));

    provider.on('block', blockHandler);

    return () => {
      canceled = true;
      provider.removeListener('block', blockHandler);
    };
  }, [chainId, provider, windowVisible]);

  // Get full block and dispatch it
  useEffect(() => {
    if (!provider || !debouncedBlockInfo.blockNumber || !debouncedBlockInfo.chainId || !chainId) {
      return;
    }

    let canceled = false;

    provider
      .getBlock(debouncedBlockInfo.blockNumber)
      .then((block?: Block) => {
        if (canceled) {
          return;
        }
        dispatch(
          updateBlock({
            chainId,
            block: block
              ? {
                  hash: block.hash,
                  parentHash: block.parentHash,
                  number: block.number,
                  timestamp: block.timestamp,
                  baseFeePerGas: block.baseFeePerGas?.toNumber(),
                }
              : null,
          }),
        );
      })
      .catch(error => console.error(`Failed to get block number for chainId: ${chainId}`, error));

    return () => {
      canceled = true;
    };
  }, [debouncedBlockInfo.blockNumber, debouncedBlockInfo.chainId, provider, dispatch, chainId]);

  return null;
}
