import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountAllAssetsBalances } from '../../api';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { AppState } from '../../state';
import { fetchPortfolioAssets } from '../../state/portfolio-assets/portfolio-assets.actions';
import { BtnRefresh } from '../btn-refresh/btn-refresh';
import './assets-loader-btn.scss';

export const AssetsLoaderBtn: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();
  const assets = useSelector((state: AppState) => state.portfolioAssets.assets);
  const empty = !account || !assets[account];
  const [reloadAssets, setReloadAssets] = useState<boolean>(empty);

  useEffect(() => {
    setReloadAssets(true);
  }, [account]);

  useEffect(() => {
    if (!account || !reloadAssets) {
      return;
    }

    dispatch(fetchPortfolioAssets.pending());
    getAccountAllAssetsBalances(account)
      .then(items => {
        dispatch(fetchPortfolioAssets.resolved({ account, items }));
      })
      .catch(e => {
        dispatch(fetchPortfolioAssets.rejected());
        console.error(e);
      });

    setReloadAssets(false);
  }, [reloadAssets, account, dispatch]);

  return (
    <div className="assets-loader-btn">
      <BtnRefresh tooltip={t('reload_assets')} onRefresh={() => setReloadAssets(true)} />
    </div>
  );
};
