import { ChainId } from '@plasma/plasmaswap-sdk';

/**
 * Supported Test Nets
 */
export const { MAINNET, MATIC, BSC, AVALANCHE, FANTOM, CELO, OPTIMISM, ...TestnetChainId } = ChainId;
export type TestnetChainId = ChainId.ROPSTEN | ChainId.RINKEBY | ChainId.GÖRLI | ChainId.KOVAN | ChainId.BSC_TEST | ChainId.OPTIMISM_KOVAN;

/**
 * Supported Main Nets
 */
export const { ROPSTEN, RINKEBY, GÖRLI, KOVAN, BSC_TEST, OPTIMISM_KOVAN, ...MainnetChainId } = ChainId;
export type MainnetChainId = Exclude<ChainId, TestnetChainId>;

/**
 * Additional chain ids to display on the market or portfolio page
 */
export enum ExtraChainId {
  OEC = 66,
  XDAI = 100,
  HECO = 128,
  MOONRIVER = 1285,
  ARBITRUM = 42161,
  BOBA = 288,
  CRONOS = 25,
  METIS = 1088,
  AURORA = 1313161554,
  MOONBEAM = 1284,
  BTTC = 199,
  SMARTBCH = 10000,
}

/**
 * All supported and unsupported chain IDs
 */
export const AllChainId = { ...ChainId, ...ExtraChainId };
export type AllChainId = ChainId | ExtraChainId;

/**
 * All supported and unsupported mainnet chain IDs
 */
export const AllMainnetChainId = { ...MainnetChainId, ...ExtraChainId };
export type AllMainnetChainId = MainnetChainId | ExtraChainId;

/**
 * Enum of chain ids supported by Hyper API
 */
export const { XDAI, ...HyperApiSupportedChainId } = AllMainnetChainId;
export type HyperApiSupportedChainId = Exclude<AllMainnetChainId, typeof AllMainnetChainId.XDAI>;

/**
 * Enum of chain ids supported by Unmarshal API
 */
export const UnmarshalSupportedChainId = { ...MainnetChainId, ...ExtraChainId };
export type UnmarshalSupportedChainId = MainnetChainId | ExtraChainId;
