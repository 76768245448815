import { TransactionResponse } from '@ethersproject/providers';
import { Currency, toCurrencyAmount, Trade0xSwap } from '@plasma/plasmaswap-sdk';
import Big from 'big.js';
import { BigNumber } from '@ethersproject/bignumber';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BtnApprove } from '../btn-approve/btn-approve';
import { Loading } from '../loading/loading';
import { ModalHyperDexSwapConfirm } from '../modal-hyper-dex-swap-confirm/modal-hyper-dex-swap-confirm';
import { APPROVE_FLOW_STATES, DefaultGasLimit, SUCCESS_APPROVE_STATES } from '../../constants';
import { GsnWeb3Provider } from '../../gsn-web3-provider/gsn-web3-provider';

import { useApproveTokenWithPermit } from '../../hooks/approval/use-approve-token-with-permit';
import { useTrade0xSwapMaxSellAmount } from '../../hooks/trades/use-trade-0x-swap-max-sell-amount';
import { useTrade0xSwapToTx } from '../../hooks/trades/use-trade-0x-swap-to-tx';
import { use0xProxyContract } from '../../hooks/use-contract';
import { useGasAdder } from '../../state/gas-station/gas-station.hooks';
import { useTransactionAdder } from '../../state/transactions/transactions.hooks';
import { ApprovalState } from '../../types';
import { getSigner } from '../../utils';
import { formatCurrencyAmount } from '../../utils/format-currency-amount';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';

export interface NormalSwapModeProps {
  fromCurrency?: Currency;
  trade?: Trade0xSwap | null;
  formValidationError?: string;
  resetForm: () => void;
}

export function NormalSwapMode({ fromCurrency, trade, formValidationError, resetForm }: NormalSwapModeProps): JSX.Element {
  const { account, provider } = useActiveWeb3React();
  const zeroExProxyContract = use0xProxyContract();
  const { t } = useTranslation();
  const gasAdder = useGasAdder();

  // Issue permissions to transfer tokens (Approve button)
  const maxSellAmount = useTrade0xSwapMaxSellAmount(trade);
  const amountToApprove = useMemo(() => {
    if (!maxSellAmount) {
      return maxSellAmount;
    }
    return toCurrencyAmount(maxSellAmount.currency, Big(maxSellAmount.raw.toString()).times(1.01).toFixed(0, 0));
  }, [maxSellAmount]);
  const [approvalState, approvalCallData, submitApprove] = useApproveTokenWithPermit(amountToApprove, trade?.allowanceTarget);
  const isApproved = useMemo(() => SUCCESS_APPROVE_STATES.includes(approvalState), [approvalState]);
  const txData = useTrade0xSwapToTx(isApproved ? trade : undefined, approvalCallData);

  // Transaction stuff
  const addTransaction = useTransactionAdder();
  const [confirmSwapIsOpen, setConfirmSwapIsOpen] = useState(false);
  const [attempting, setAttempting] = useState<boolean>(false);
  const [hash, setHash] = useState<string | undefined>();
  const [confirmSwapError, setConfirmSwapError] = useState<string>();

  /**
   * Submit swap form (Open confirm modal)
   */
  const onSubmitSwap = useCallback(() => {
    if (!trade) {
      return;
    }
    setConfirmSwapIsOpen(true);
  }, [trade]);

  /**
   * Confirm swap and send transaction
   */
  const onConfirmSwap = useCallback(() => {
    if (!txData || !trade || !(provider instanceof GsnWeb3Provider) || !account || !zeroExProxyContract) {
      return;
    }

    setAttempting(true);

    provider
      .estimateGas(txData)
      .catch(error => {
        console.error(error);
        console.warn('Used default gas limit gas');
        return BigNumber.from(DefaultGasLimit.HIGH);
      })
      .then(estimateGas => {
        return Object.assign({}, txData, { gasLimit: estimateGas });
      })
      .then(gasAdder)
      .then(txData => getSigner(provider, account).sendTransaction(txData))
      .then((response: TransactionResponse) => {
        const summary = `Hyper Dex Swap ${formatCurrencyAmount(trade.inputAmount)} for ${formatCurrencyAmount(trade.outputAmount)}`;
        addTransaction(response, { summary });

        // Reset form
        resetForm();

        // Show tx hash
        setHash(response.hash);

        return response.hash;
      })
      .catch(err => {
        setConfirmSwapError(err.message);
        console.error(err);
      });
  }, [txData, trade, provider, account, zeroExProxyContract, gasAdder, addTransaction, resetForm]);

  return (
    <>
      <div className="button-block">
        {approvalState === ApprovalState.LOADING || trade === null ? (
          <button className="btn btn-primary btn-lg" disabled>
            <Loading size="sm" />
          </button>
        ) : !formValidationError && APPROVE_FLOW_STATES.includes(approvalState) ? (
          <div className="should-approve">
            <BtnApprove approvalState={approvalState} approvalToken={fromCurrency} onClick={submitApprove} />

            <button className="btn btn-primary btn-lg" onClick={onSubmitSwap} id="swap-button" disabled={!!formValidationError || !isApproved}>
              <span>{formValidationError ? formValidationError : t('swap')}</span>
            </button>
          </div>
        ) : (
          <button className="btn btn-primary btn-lg" onClick={onSubmitSwap} id="swap-button" disabled={!!formValidationError || !isApproved}>
            <span>{formValidationError ? formValidationError : t('swap')}</span>
          </button>
        )}
      </div>

      {confirmSwapIsOpen ? (
        <ModalHyperDexSwapConfirm
          isOpen={confirmSwapIsOpen}
          trade={trade || undefined}
          txData={txData}
          txHash={hash}
          attempting={attempting}
          errorMessage={confirmSwapError}
          onConfirm={onConfirmSwap}
          onDismiss={() => {
            setConfirmSwapIsOpen(false);
            setAttempting(false);
            setHash(undefined);
            setConfirmSwapError(undefined);
          }}
        />
      ) : null}
    </>
  );
}
