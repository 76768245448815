import { getDefaultProvider } from '@ethersproject/providers';
import { ChainId, NATIVE } from '@plasma/plasmaswap-sdk';
import { isDesktop } from 'react-device-detect';
import { chain, Chain, createClient } from 'wagmi';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import CeloIcon from '../assets/images/celo-for-wallet.svg';
import CoinbaseIcon from '../assets/images/coinbase-wallet-icon.svg';
import MetamaskIcon from '../assets/images/metamask.svg';
import TrustIcon from '../assets/images/trust-wallet.png';
import WalletConnectIcon from '../assets/images/wallet-connection-logo.svg';
import WalletIcon from '../assets/svg/wallet-logo.svg';
import { MetaMaskMobileConnector } from '../connectors/meta-mask-mobile-connector';
import { GsnWeb3Provider } from '../gsn-web3-provider/gsn-web3-provider';

export const CONNECTOR_ICONS: { [connectorId: string]: string } = {
  metaMask: MetamaskIcon,
  coinbaseWallet: CoinbaseIcon,
  trust: TrustIcon,
  celo: CeloIcon,
  walletConnect: WalletConnectIcon,
  injected: WalletIcon,
};

export const ALL_SUPPORTED_CHAIN_IDS: ChainId[] = Object.values(ChainId).filter(i => typeof i === 'number') as ChainId[];

export const RPC_URLS: { [chainId in ChainId]?: string } = ALL_SUPPORTED_CHAIN_IDS.reduce<{ [chainId in ChainId]?: string }>((acc, chainId) => {
  const url = process.env[`REACT_APP_NETWORK_URL_${chainId}`];
  if (!url) {
    throw new Error(`RPC URl should be defined in env variable: REACT_APP_NETWORK_URL_${chainId}`);
  }
  acc[chainId] = url;
  return acc;
}, {} as { [chainId in ChainId]?: string });

export const CHAINS_CONFIGURATION: { [chainId in ChainId]: Chain } = {
  [ChainId.MAINNET]: {
    ...chain.mainnet,
    rpcUrls: {
      default: RPC_URLS[ChainId.MAINNET] as string,
    },
  },
  [ChainId.ROPSTEN]: {
    ...chain.ropsten,
    rpcUrls: {
      default: RPC_URLS[ChainId.ROPSTEN] as string,
    },
  },
  [ChainId.RINKEBY]: {
    ...chain.rinkeby,
    rpcUrls: {
      default: RPC_URLS[ChainId.RINKEBY] as string,
    },
  },
  [ChainId.GÖRLI]: {
    ...chain.goerli,
    rpcUrls: {
      default: RPC_URLS[ChainId.GÖRLI] as string,
    },
  },
  [ChainId.KOVAN]: {
    ...chain.kovan,
    rpcUrls: {
      default: RPC_URLS[ChainId.KOVAN] as string,
    },
  },
  [ChainId.MATIC]: {
    ...chain.polygon,
    rpcUrls: {
      default: RPC_URLS[ChainId.MATIC] as string,
    },
  },
  [ChainId.BSC]: {
    id: ChainId.BSC,
    name: 'Binance Smart Chain',
    network: 'bsc',
    nativeCurrency: {
      name: NATIVE[ChainId.BSC].name || '',
      symbol: NATIVE[ChainId.BSC].symbol || '',
      decimals: NATIVE[ChainId.BSC].decimals,
    },
    rpcUrls: {
      default: RPC_URLS[ChainId.BSC] as string,
    },
    blockExplorers: {
      etherscan: { name: 'BSCScan', url: 'https://bscscan.com/' },
      default: { name: 'BSCScan', url: 'https://bscscan.com/' },
    },
    testnet: false,
  },
  [ChainId.BSC_TEST]: {
    id: ChainId.BSC_TEST,
    name: 'Binance Smart Chain Testnet',
    network: 'bsc-test',
    nativeCurrency: {
      name: NATIVE[ChainId.BSC_TEST].name || '',
      symbol: NATIVE[ChainId.BSC_TEST].symbol || '',
      decimals: NATIVE[ChainId.BSC_TEST].decimals,
    },
    rpcUrls: {
      default: RPC_URLS[ChainId.BSC_TEST] as string,
    },
    blockExplorers: {
      etherscan: { name: 'BSCScan', url: 'https://testnet.bscscan.com/' },
      default: { name: 'BSCScan', url: 'https://testnet.bscscan.com/' },
    },
    testnet: true,
  },
  [ChainId.AVALANCHE]: {
    id: ChainId.AVALANCHE,
    name: 'Avalanche',
    network: 'avalanche',
    nativeCurrency: {
      name: NATIVE[ChainId.AVALANCHE].name || '',
      symbol: NATIVE[ChainId.AVALANCHE].symbol || '',
      decimals: NATIVE[ChainId.AVALANCHE].decimals,
    },
    rpcUrls: {
      default: RPC_URLS[ChainId.AVALANCHE] as string,
    },
    blockExplorers: {
      etherscan: { name: 'SnowTrace', url: 'https://snowtrace.io/' },
      default: { name: 'SnowTrace', url: 'https://snowtrace.io/' },
    },
    testnet: false,
  },
  [ChainId.FANTOM]: {
    id: ChainId.FANTOM,
    name: 'Fantom',
    network: 'fantom',
    nativeCurrency: {
      name: NATIVE[ChainId.FANTOM].name || '',
      symbol: NATIVE[ChainId.FANTOM].symbol || '',
      decimals: NATIVE[ChainId.FANTOM].decimals,
    },
    rpcUrls: {
      default: RPC_URLS[ChainId.FANTOM] as string,
    },
    blockExplorers: {
      etherscan: { name: 'FTMScan', url: 'https://ftmscan.com/' },
      default: { name: 'FTMScan', url: 'https://ftmscan.com/' },
    },
    testnet: false,
  },
  [ChainId.CELO]: {
    id: ChainId.CELO,
    name: 'Celo',
    network: 'celo',
    nativeCurrency: {
      name: NATIVE[ChainId.CELO].name || '',
      symbol: NATIVE[ChainId.CELO].symbol || '',
      decimals: NATIVE[ChainId.CELO].decimals,
    },
    rpcUrls: {
      default: RPC_URLS[ChainId.CELO] as string,
    },
    blockExplorers: {
      etherscan: { name: 'Celo Explorer', url: 'https://explorer.celo.org/' },
      default: { name: 'Celo Explorer', url: 'https://explorer.celo.org/' },
    },
    testnet: false,
  },
  [ChainId.OPTIMISM]: {
    id: ChainId.OPTIMISM,
    name: 'Optimism',
    network: 'optimism',
    nativeCurrency: {
      name: NATIVE[ChainId.OPTIMISM].name || '',
      symbol: NATIVE[ChainId.OPTIMISM].symbol || '',
      decimals: NATIVE[ChainId.OPTIMISM].decimals,
    },
    rpcUrls: {
      default: RPC_URLS[ChainId.OPTIMISM] as string,
    },
    blockExplorers: {
      etherscan: { name: 'Optimistic Explorer', url: 'https://optimistic.etherscan.io/' },
      default: { name: 'Optimistic Explorer', url: 'https://optimistic.etherscan.io/' },
    },
    testnet: false,
  },
  [ChainId.OPTIMISM_KOVAN]: {
    id: ChainId.OPTIMISM_KOVAN,
    name: 'OP Kovan',
    network: 'optimism-kovan',
    nativeCurrency: {
      name: NATIVE[ChainId.OPTIMISM_KOVAN].name || '',
      symbol: NATIVE[ChainId.OPTIMISM_KOVAN].symbol || '',
      decimals: NATIVE[ChainId.OPTIMISM_KOVAN].decimals,
    },
    rpcUrls: {
      default: RPC_URLS[ChainId.OPTIMISM_KOVAN] as string,
    },
    blockExplorers: {
      etherscan: { name: 'Optimistic Testnet Explorer', url: 'https://kovan-optimistic.etherscan.io/' },
      default: { name: 'Optimistic Testnet Explorer', url: 'https://kovan-optimistic.etherscan.io/' },
    },
    testnet: true,
  },
};

export const WAGMI_CLIENT = createClient({
  autoConnect: true,
  connectors: [
    new (isDesktop ? MetaMaskConnector : MetaMaskMobileConnector)({
      chains: Object.values(CHAINS_CONFIGURATION),
      options: {
        shimDisconnect: true,
        shimChainChangedDisconnect: true,
      },
    }),
    new CoinbaseWalletConnector({
      chains: Object.values(CHAINS_CONFIGURATION),
      options: {
        appName: 'Plasma Finance',
        appLogoUrl: 'https://apy.plasma.finance/images/favicon/android-chrome-192x192.png',
        jsonRpcUrl: 'https://eth-mainnet.alchemyapi.io/v2/yourAlchemyId',
      },
    }),
    new WalletConnectConnector({
      chains: Object.values(CHAINS_CONFIGURATION),
      options: {
        qrcode: true,
      },
    }),
    new InjectedConnector({
      chains: Object.values(CHAINS_CONFIGURATION),
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  provider({ chainId }) {
    const rpcUrl = chainId ? RPC_URLS[chainId as ChainId] : undefined;
    return rpcUrl ? new GsnWeb3Provider(rpcUrl) : getDefaultProvider();
  },
});
