import { BigNumber as EtherBigNumber } from '@ethersproject/bignumber';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { ChainId, CurrencyAmount, NETWORK_NAME, toCurrencyAmount, Token } from '@plasma/plasmaswap-sdk';
import type { EVMNetworkConfig } from '@renproject/chains-ethereum';
import type { Gateway } from '@renproject/ren';
import RenJS from '@renproject/ren';
import Big from 'big.js';
import BigNumber from 'bignumber.js';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { REN_ASSET_NAME_BY_SYMBOL, REN_EVM_NETWORK_IDS, REN_NETWORKS, REN_NETWORKS_META, ZERO_ADDRESS } from '../../../constants';
import { useRenExchanger } from '../../../hooks/bridge/use-ren-exchanger';
import { useRenGateway } from '../../../hooks/bridge/use-ren-gateway';
import { useRenTokenPrice } from '../../../hooks/bridge/use-ren-token-price';
import { useChainSelector } from '../../../hooks/use-chain-selector';
import { useIsMainnet } from '../../../hooks/use-is-mainnet';
import { useActiveWeb3React } from '../../../hooks/web3/use-active-web3-react';
import { setActiveTab } from '../../../state/user/user.actions';
import { BridgeAssetMeta, BridgeRenAsset, BridgeRenNetworkId, BridgeRenNetworkMeta } from '../../../types';
import { WidgetTab } from '../../../types/widget-tabs';
import { getDecimalsBySymbol } from '../../../utils/get-decimals-by-symbol';
import { isNumber } from '../../../utils/is-number';
import { AddressInputPanel } from '../../address-input-panel/address-input-panel';
import { BridgeAssetInputPanel } from '../../bridge-asset-input-panel/bridge-asset-input-panel';
import { FormattedAmountInFiat } from '../../formatted-amount-in-fiat/formatted-amount-in-fiat';
import { FormattedAmount } from '../../formatted-amount/formatted-amount';
import { FormControlSelect } from '../../forms';
import { InfoHelper } from '../../info-helper/info-helper';
import { CurrencyLogo } from '../../logo/logo';
import { ModalBridgeAssetSelector } from '../../modal-bridge-asset-selector/modal-bridge-asset-selector';
import { ModalBridgeRenConfirm } from '../../modal-bridge-ren-confirm/modal-bridge-ren-confirm';

const BN = BigNumber.clone({ EXPONENTIAL_AT: [-18, 30] });

const MAIN_ASSET_SYMBOLS: string[] = ['ETH', 'BNB', 'MATIC', 'FTM', 'ArbETH', 'AVAX', 'BTC', 'BCH', 'DOGE', 'FIL', 'LUNA', 'ZEC', 'DGB'];

const SUPPORTED_ASSETS: BridgeRenAsset[] = Object.values(BridgeRenNetworkId).reduce<BridgeRenAsset[]>((acc, networkId: BridgeRenNetworkId) => {
  const network = REN_NETWORKS[networkId];
  return [
    ...acc,
    ...Object.values(network.assets).map((symbol: string) => ({
      symbol,
      lockChain: network.chain as BridgeRenNetworkId,
      mintChains: REN_EVM_NETWORK_IDS.filter(mintChain => mintChain !== network.chain),
      decimals: getDecimalsBySymbol(symbol),
    })),
  ];
}, []);

const NETWORK_SELECTOR_OPTIONS = REN_NETWORKS_META.map(({ value: id, label: name, logo: logoURI }) => ({ id, name, logoURI }));

const ASSETS_SELECTOR_OPTIONS = REN_NETWORKS_META.reduce<{ [networkId in BridgeRenNetworkId]: BridgeAssetMeta[] }>((acc, network) => {
  const networkId = network.value;
  const availableAssets = SUPPORTED_ASSETS.filter(({ lockChain, mintChains }) => lockChain === networkId || mintChains.includes(networkId));

  const assets = availableAssets
    .map(({ symbol, decimals, lockChain }) => {
      const name = REN_ASSET_NAME_BY_SYMBOL[symbol] || symbol;
      return {
        symbol: lockChain === networkId ? symbol : `ren${symbol}`,
        name: lockChain === networkId ? name : `REN ${name}`,
        decimals: decimals,
      };
    })
    .sort(({ symbol: symbol0 }, { symbol: symbol1 }) => {
      return (MAIN_ASSET_SYMBOLS.includes(symbol1) ? 1 : 0) - (MAIN_ASSET_SYMBOLS.includes(symbol0) ? 1 : 0);
    });

  return {
    ...acc,
    [networkId]: assets,
  };
}, {} as any);

enum AmountValidationError {
  VALID,
  REQUIRED,
  INVALID,
  BALANCE,
  MIN,
}

const symbol2id = (symbol: string | undefined) => symbol?.replace(/^ren/, '');

export const RenForm: FC = () => {
  const { t } = useTranslation();
  const { chainId, account } = useActiveWeb3React();
  const chainSelector = useChainSelector();
  const isMainnet = useIsMainnet();
  const dispatch = useDispatch();

  // Form controls state
  const [fromNetworkId, setFromNetworkId] = useState<BridgeRenNetworkId>(BridgeRenNetworkId.Ethereum);
  const [toNetworkId, setToNetworkId] = useState<BridgeRenNetworkId>(BridgeRenNetworkId.BinanceSmartChain);
  const [fromAsset, setFromAsset] = useState<BridgeAssetMeta>(ASSETS_SELECTOR_OPTIONS[fromNetworkId][0]);
  const [toAsset, setToAsset] = useState<BridgeAssetMeta>(ASSETS_SELECTOR_OPTIONS[toNetworkId][0]);
  const [fromAmountValue, setFromAmountValue] = useState<string>('');
  const [fromAmountTouched, setFromAmountTouched] = useState(false);
  const [recipient, setRecipient] = useState<string>('');
  const fromEvm = useMemo(() => REN_EVM_NETWORK_IDS.includes(fromNetworkId), [fromNetworkId]);
  const toEvm = useMemo(() => REN_EVM_NETWORK_IDS.includes(toNetworkId), [toNetworkId]);

  // Balances
  const [fromBalance, setFromBalance] = useState<CurrencyAmount | null | undefined>();
  const [toBalance, setToBalance] = useState<CurrencyAmount | null | undefined>();

  // Modals stuff
  const [isOpenAssetsSelector, setIsOpenAssetsSelector] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

  // Exchanger stuff
  const exchanger: RenJS | undefined = useRenExchanger(fromNetworkId, toNetworkId);
  const gateway: Gateway | null | undefined = useRenGateway(exchanger, fromNetworkId, toNetworkId, symbol2id(fromAsset?.symbol), recipient);

  const fromAmount: EtherBigNumber | undefined = useMemo(() => {
    if (!fromAsset || !fromAmountValue || !isNumber(fromAmountValue)) {
      return undefined;
    }
    try {
      return parseUnits(fromAmountValue, fromAsset.decimals || 18);
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  }, [fromAmountValue, fromAsset]);

  const toAmountValue: string = useMemo(() => {
    if (!gateway || !fromAmount || !toAsset) {
      return '';
    }
    Big.NE = -18;
    Big.PE = 30;
    try {
      const outputAmount = Big(gateway.fees.estimateOutput(fromAmount.toString()).toString());
      return formatUnits(outputAmount.toString(), toAsset.decimals || 18);
    } catch (e) {
      console.warn(e);
      return '';
    }
  }, [fromAmount, gateway, toAsset]);

  const toNetworkSelectorOptions: BridgeRenNetworkMeta[] = useMemo(() => {
    if (!fromAsset) {
      return [];
    }
    const fromAssetId = symbol2id(fromAsset.symbol);
    const asset = SUPPORTED_ASSETS.find(({ symbol }) => symbol2id(symbol) === fromAssetId);
    if (!asset) {
      return [];
    }
    const isLock = asset.lockChain === fromNetworkId;
    return REN_NETWORKS_META.filter(({ value }) => (isLock ? REN_EVM_NETWORK_IDS.includes(value) : value === asset.lockChain));
  }, [fromAsset, fromNetworkId]);

  const fromChainId: ChainId | null = useMemo(() => {
    if (!fromNetworkId || !exchanger || !fromEvm) {
      return null;
    }
    const hexChainId = (exchanger.chains[fromNetworkId].network as EVMNetworkConfig)?.config?.chainId;
    if (!hexChainId) {
      return null;
    }
    return parseInt(hexChainId, 16) as ChainId;
  }, [fromNetworkId, exchanger, fromEvm]);

  const toChainId: ChainId | null = useMemo(() => {
    if (!toNetworkId || !exchanger || !toEvm) {
      return null;
    }
    const hexChainId = (exchanger.chains[toNetworkId].network as EVMNetworkConfig)?.config?.chainId;
    if (!hexChainId) {
      return null;
    }
    return parseInt(hexChainId, 16) as ChainId;
  }, [toEvm, toNetworkId, exchanger]);

  const feeAmount = useMemo(() => {
    if (!fromAmountValue || !toAmountValue || !isNumber(fromAmountValue)) {
      return undefined;
    }
    try {
      return Big(fromAmountValue).minus(toAmountValue).toString();
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  }, [fromAmountValue, toAmountValue]);

  // Prices and fiat amounts
  const assetPrice = useRenTokenPrice(fromAsset?.symbol);
  const fromAmountInUsd = useMemo(() => {
    if (assetPrice && fromAmountValue && isNumber(fromAmountValue)) {
      try {
        return Big(assetPrice).times(fromAmountValue).toString();
      } catch (e) {
        console.warn(e);
      }
    }
    return undefined;
  }, [assetPrice, fromAmountValue]);
  const toAmountInUsd = useMemo(() => {
    if (assetPrice && toAmountValue && isNumber(toAmountValue)) {
      try {
        return Big(assetPrice).times(toAmountValue).toString();
      } catch (e) {
        console.warn(e);
      }
    }
    return undefined;
  }, [assetPrice, toAmountValue]);

  // Validation flags
  const isValidRecipientAddress = useMemo(() => {
    if (!toNetworkId || toEvm || !exchanger) {
      return true;
    }
    const network = exchanger.chains[toNetworkId];
    return network.validateAddress(recipient);
  }, [toNetworkId, toEvm, exchanger, recipient]);
  const fromAmountError: AmountValidationError = useMemo(() => {
    if (!fromAmountValue) {
      return AmountValidationError.REQUIRED;
    }
    if (!isNumber(fromAmountValue)) {
      return AmountValidationError.INVALID;
    }
    if (gateway && Number(fromAmountValue) < Number(formatUnits(gateway.fees.minimumAmount.toString(), fromAsset?.decimals || 18))) {
      return AmountValidationError.MIN;
    }
    if (fromEvm && fromBalance && Number(fromBalance.toExact()) < Number(fromAmountValue)) {
      return AmountValidationError.BALANCE;
    }
    return AmountValidationError.VALID;
  }, [fromAmountValue, fromAsset?.decimals, fromBalance, fromEvm, gateway]);

  const resetFormCallback = useCallback(() => {
    setFromAmountValue('');
    setFromAmountTouched(false);
    setRecipient('');
  }, []);

  // Update assets from after change the network from id
  useEffect(() => {
    const networkAssets = ASSETS_SELECTOR_OPTIONS[fromNetworkId];
    if (!networkAssets.length || networkAssets.some(({ symbol }) => symbol === fromAsset.symbol)) {
      return;
    }
    setFromAsset(networkAssets[0]);
  }, [fromNetworkId, fromAsset]);

  // Update asset to after change the asset from id
  useEffect(() => {
    const fromAssetId = symbol2id(fromAsset.symbol);
    const networkAsset = ASSETS_SELECTOR_OPTIONS[toNetworkId].find(({ symbol }) => symbol2id(symbol) === fromAssetId);
    if (networkAsset) {
      setToAsset(networkAsset);
    }
  }, [toNetworkId, fromAsset]);

  // Update network id to support network
  useEffect(() => {
    if (toNetworkSelectorOptions.length && !toNetworkSelectorOptions.some(({ value }) => value === toNetworkId)) {
      setToNetworkId(fromNetworkId === toNetworkSelectorOptions[0].value ? toNetworkSelectorOptions[1].value : toNetworkSelectorOptions[0].value);
    }
  }, [toNetworkSelectorOptions, toNetworkId, fromNetworkId]);

  // Update form amount in gateway params
  useEffect(() => {
    if (!gateway?.params.from.params) {
      return;
    }
    gateway.params.from.params.amount = fromAmount ? fromAmount.toString() : '0';
  }, [gateway, fromAmount]);

  // Get balance for the asset from
  useEffect(() => {
    if (!exchanger || !fromAsset || !exchanger.chains[fromNetworkId] || !fromEvm || !account) {
      return setFromBalance(undefined);
    }

    setFromBalance(null);

    let canceled = false;
    const network = exchanger.chains[fromNetworkId];
    const chainId: ChainId = parseInt((network.network as EVMNetworkConfig).config.chainId, 16);
    const assetId = symbol2id(fromAsset.symbol) as string;

    (network.getBalance(assetId, account) as unknown as Promise<BigNumber>)
      .then((balance: BigNumber) => {
        if (canceled) {
          return;
        }
        const token = new Token(chainId, ZERO_ADDRESS, fromAsset.decimals || 18, fromAsset.symbol, fromAsset.name);
        setFromBalance(toCurrencyAmount(token, new BN(balance).toString()));
      })
      .catch(err => {
        if (canceled) {
          return;
        }
        console.error(err);
        setFromBalance(undefined);
      });

    return () => {
      canceled = true;
    };
  }, [fromEvm, account, exchanger, fromNetworkId, fromAsset]);

  // Get balance for the asset to
  useEffect(() => {
    if (!exchanger || !toAsset || !exchanger.chains[toNetworkId] || !toEvm || !account) {
      return setToBalance(undefined);
    }

    setToBalance(null);

    let canceled = false;
    const network = exchanger.chains[toNetworkId];
    const chainId: ChainId = parseInt((network.network as EVMNetworkConfig).config.chainId, 16);
    const assetId = symbol2id(toAsset.symbol) as string;

    (network.getBalance(assetId, account) as unknown as Promise<BigNumber>)
      .then((balance: BigNumber) => {
        if (canceled) {
          return;
        }
        const token = new Token(chainId, ZERO_ADDRESS, toAsset.decimals || 18, toAsset.symbol, toAsset.name);
        setToBalance(toCurrencyAmount(token, new BN(balance).toString()));
      })
      .catch(err => {
        if (canceled) {
          return;
        }
        console.error(err);
        setToBalance(undefined);
      });

    return () => {
      canceled = true;
    };
  }, [toEvm, account, exchanger, toNetworkId, toAsset]);

  return (
    <>
      <div className="bridge-card-content">
        {isMainnet === false ? (
          <div className="test-network-warning">
            <i className="pi pi-warning" />
            <span>Attention! Test networks are being used now.</span>
          </div>
        ) : null}

        {/* From network id */}
        <div className="chain-id-selector">
          <label className="selector-label" htmlFor="from-chain-id">
            <span>{t('from_chain')}</span>
          </label>
          <FormControlSelect
            id="from-chain-id"
            className="selector"
            options={REN_NETWORKS_META}
            value={fromNetworkId}
            onChange={(networkId: string) => {
              if (networkId === toNetworkId) {
                setToNetworkId(fromNetworkId);
              }
              setFromNetworkId(networkId as BridgeRenNetworkId);
            }}
            disabled={!account}
          />
        </div>

        {/* From amount input*/}
        <div className="form-control">
          <BridgeAssetInputPanel
            id="from-asset-amount"
            label={
              <>
                {assetPrice ? (
                  <>
                    <span>≈&nbsp;</span>
                    <FormattedAmountInFiat amount={fromAmountInUsd || '0'} />
                  </>
                ) : null}
              </>
            }
            value={fromAmountValue}
            onUserInput={value => {
              setFromAmountValue(value);
              value && setFromAmountTouched(true);
            }}
            asset={fromAsset}
            balance={fromBalance}
            onClickAsset={() => setIsOpenAssetsSelector(true)}
            disabled={!account}
            isError={fromAmountTouched && !!fromAmountError}
          />
          <div className="error">
            {fromAmountTouched ? (
              fromAmountError === AmountValidationError.REQUIRED ? (
                <span>{t('please_enter_amount')}</span>
              ) : fromAmountError === AmountValidationError.INVALID ? (
                <span>{t('invalid_amount_value')}</span>
              ) : fromAmountError === AmountValidationError.BALANCE ? (
                <span>{t('insufficient_balance', { code: fromAsset?.symbol || fromAsset?.symbol })}</span>
              ) : fromAmountError === AmountValidationError.MIN ? (
                <span>{t('amount_too_small')}</span>
              ) : null
            ) : null}
          </div>
        </div>

        {/* To network id */}
        <div className="chain-id-selector">
          <label className="selector-label" htmlFor="to-chain-id">
            <span>{t('to_chain')}</span>
          </label>
          <FormControlSelect
            id="to-chain-id"
            className="selector"
            options={toNetworkSelectorOptions}
            value={toNetworkId}
            onChange={(networkId: string) => {
              if (networkId === fromNetworkId) {
                setFromNetworkId(toNetworkId);
              }
              setToNetworkId(networkId as BridgeRenNetworkId);
            }}
            disabled={!account}
          />
        </div>

        {/* To amount input */}
        <div className="form-control">
          <BridgeAssetInputPanel
            id="to-asset-amount"
            label={
              <>
                {assetPrice ? (
                  <>
                    <span>≈&nbsp;</span>
                    <FormattedAmountInFiat amount={toAmountInUsd || '0'} />
                  </>
                ) : null}
              </>
            }
            value={toAmountValue}
            asset={toAsset}
            disableAssetSelect
            readOnly
            balance={toBalance}
            disabled={!account}
            loading={gateway === null}
          />
          <div className="error" />
        </div>

        {/* Recipient */}
        <div className="recipient-form-control">
          <label htmlFor="recipient" className="label">
            <span>{t('recipient')}</span>
          </label>
          <AddressInputPanel
            id="recipient"
            value={toEvm && account ? account : recipient}
            onChange={setRecipient}
            customValidator={() => !isValidRecipientAddress}
            customChainId={toChainId || undefined}
            disabled={toEvm || !exchanger}
          />
          <div className="error">
            <span>{!isValidRecipientAddress ? (recipient ? t('incorrect_wallet_address') : t('please_enter_wallet_address')) : null}</span>
          </div>
        </div>

        {/* Submit button */}
        <div className="button-block">
          {!account ? (
            <button className="btn btn-primary btn-lg" onClick={() => dispatch(setActiveTab(WidgetTab.portfolio))}>
              <span>{t('connect_wallet')}</span>
            </button>
          ) : fromChainId && chainId && fromChainId !== chainId ? (
            <button id="switch-chain" className="btn btn-primary btn-lg" onClick={() => chainSelector(fromChainId)}>
              <span>{t('switch_to_chain', { chainTo: NETWORK_NAME[fromChainId] })}</span>
            </button>
          ) : (
            <button
              id="confirm-bridge"
              className={`btn btn-primary btn-lg ${!exchanger || !gateway || !isValidRecipientAddress || fromAmountError ? 'disabled' : ''}`}
              onClick={() => {
                setFromAmountTouched(true);
                if (isValidRecipientAddress && !fromAmountError && exchanger && gateway) {
                  setIsOpenConfirmation(true);
                }
              }}
            >
              <span>{t('confirm_bridge')}</span>
            </button>
          )}
        </div>

        {/* Dropdown */}
        {fromAsset && toAsset && fromAmountValue && toAmountValue && feeAmount && gateway ? (
          <div className="dropdown">
            <div className="row-between">
              <div className="label">
                <span>{t('bridge_transfer')}</span>
              </div>
              <div className="value">
                <CurrencyLogo currency={symbol2id(fromAsset?.symbol)} size={16} className="logo" />
                <FormattedAmount amount={fromAmountValue} unit={fromAsset?.symbol} unitSeparator={' '} />
              </div>
            </div>
            <div className="row-between">
              <div className="label">
                <span>{t('you_will_receive')}</span>
              </div>
              <div className="value">
                <CurrencyLogo currency={symbol2id(toAsset?.symbol)} size={16} className="logo" />
                <FormattedAmount amount={toAmountValue} unit={toAsset?.symbol} unitSeparator={' '} />
              </div>
            </div>
            <div className="row-between">
              <div className="label">
                <span>{t('total_fee')}</span>
                <InfoHelper backdrop="white" placement="top-start" text={t('hop_fee_tooltip')} />
              </div>
              <div className="value">
                <CurrencyLogo currency={symbol2id(fromAsset?.symbol)} size={16} className="logo" />
                <FormattedAmount amount={feeAmount} unit={fromAsset?.symbol} unitSeparator={' '} />
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <ModalBridgeAssetSelector
        isOpen={isOpenAssetsSelector}
        onDismiss={() => setIsOpenAssetsSelector(false)}
        networks={NETWORK_SELECTOR_OPTIONS}
        assets={ASSETS_SELECTOR_OPTIONS[fromNetworkId]}
        asset={fromAsset}
        onChangeAsset={setFromAsset}
        networkId={fromNetworkId}
        onChangeNetworkId={networkId => {
          if (networkId === toNetworkId) {
            setToNetworkId(fromNetworkId);
          }
          setFromNetworkId(networkId as BridgeRenNetworkId);
        }}
      />

      <ModalBridgeRenConfirm
        isOpen={isOpenConfirmation}
        onDismiss={() => setIsOpenConfirmation(false)}
        gateway={gateway}
        fromNetworkId={fromNetworkId}
        toNetworkId={toNetworkId}
        fromAsset={fromAsset}
        toAsset={toAsset}
        fromAmount={fromAmountValue}
        toAmount={toAmountValue}
        onSuccess={resetFormCallback}
      />
    </>
  );
};
