import { getOtpToken } from '../utils/get-otp-token';

export interface AccountAssetsBalance {
  id?: string;
  chain: string;
  name: string;
  symbol: string;
  decimals: number;
  display_symbol?: string;
  optimized_symbol: string;
  logo_url?: string;
  protocol_id: string;
  price: number;
  is_verified?: boolean;
  is_core?: boolean;
  is_wallet?: boolean;
  time_at: number;
  amount: number;
}

export async function getAccountAllAssetsBalances(account: string, abort?: AbortSignal): Promise<AccountAssetsBalance[]> {
  const url = `${process.env.REACT_APP_HYPER_API}/v1/user/all_token_list?id=${account}&is_all=false&has_balance=true`;

  const res = await fetch(url, {
    method: 'GET',
    signal: abort,
    mode: 'cors',
    headers: {
      'x-plasma-totp': getOtpToken(),
    },
  });

  if (!res.ok) {
    throw new Error(`Failed to fetch balances from HAPI ${res.statusText}`);
  }

  return res.json();
}
