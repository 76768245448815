import { MaxUint256 } from '@ethersproject/constants';
import { TransactionResponse } from '@ethersproject/providers';
import { NATIVE, TokenAmount } from '@plasma/plasmaswap-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../state';
import { setApproveCallAttempt, setApproveCallTxHash } from '../../state/approval/approval.actions';
import { useGasAdder, useTransactionFee } from '../../state/gas-station/gas-station.hooks';
import { useTransactionAdder, useTransactionStatus } from '../../state/transactions/transactions.hooks';
import { useEthBalance } from '../../state/wallets/wallets.hooks';
import { ApprovalAmount, ApprovalCallback, ApprovalState } from '../../types';
import { formatTokenSymbol } from '../../utils/format-token-symbol';
import { useTokenContract } from '../use-contract';
import { useTokenAllowance } from '../use-token-allowance';
import { useActiveWeb3React } from '../web3/use-active-web3-react';
import { useApprovalId } from './use-approval-id';

/**
 * Returns a variable indicating the state of the approval and a function which approves if necessary or early returns
 * @param amount
 * @param spender
 */
export function useApproveToken(amount: ApprovalAmount, spender?: string): [ApprovalState, ApprovalCallback] {
  const { account, chainId } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>();
  const selector = useSelector<AppState, AppState['approval']['byApproveCall']>(state => state.approval.byApproveCall);
  const token = amount instanceof TokenAmount ? amount.token : undefined;
  const currentAllowance = useTokenAllowance(token, account ?? undefined, spender);
  const gasAdder = useGasAdder();
  const tokenContract = useTokenContract(token?.address);
  const addTransaction = useTransactionAdder();

  // Current approval state
  const approvalId = useApprovalId(amount, spender);
  const storedAttempt = useMemo(() => !!(approvalId && selector[approvalId]?.attempt), [selector, approvalId]);
  const storedTxHash = useMemo(() => approvalId && selector[approvalId]?.txHash, [selector, approvalId]);
  const [attempt, setAttempt] = useState<boolean>();
  const [txHash, setTxHash] = useState<string>();

  // Transaction stuff
  const approvalTxStatus = useTransactionStatus(storedTxHash);

  // To check balance
  const ethBalance = useEthBalance(account ?? undefined);
  const rawAmount = useMemo(() => (amount instanceof TokenAmount ? amount.raw.toString() : undefined), [amount]);
  const approveTx = useMemo(() => {
    if (!tokenContract || !account || !spender || !rawAmount) {
      return undefined;
    }
    return {
      from: account,
      to: tokenContract.address,
      data: tokenContract.interface.encodeFunctionData('approve', [spender, rawAmount]),
      value: '0',
    };
  }, [account, rawAmount, spender, tokenContract]);
  const maxTxFee = useTransactionFee(approveTx);

  // Check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (storedAttempt) {
      return storedTxHash && approvalTxStatus && !approvalTxStatus.isFail && approvalTxStatus.confirmations > 0 ? ApprovalState.TX_SUCCESS : ApprovalState.TX_PENDING;
    }
    if (!chainId || amount === undefined || !ethBalance) {
      return ApprovalState.UNKNOWN;
    }
    if (amount === null || (amount instanceof TokenAmount && !maxTxFee)) {
      return ApprovalState.LOADING;
    }
    if (amount.currency === NATIVE[chainId]) {
      return ApprovalState.APPROVED;
    }
    if (currentAllowance === undefined || !spender) {
      return ApprovalState.UNKNOWN;
    }
    if (currentAllowance === null) {
      return ApprovalState.LOADING;
    }

    if (currentAllowance.lessThan(amount)) {
      if (!maxTxFee) {
        return ApprovalState.UNKNOWN;
      } else if (ethBalance.lessThan(maxTxFee)) {
        return ApprovalState.INSUFFICIENT_FUNDS;
      } else {
        return ApprovalState.NOT_APPROVED;
      }
    } else {
      return ApprovalState.APPROVED;
    }
  }, [storedAttempt, storedTxHash, chainId, amount, ethBalance, maxTxFee, currentAllowance, spender, approvalTxStatus]);

  // Approve callback
  const approveCallback = useCallback(async (): Promise<TransactionResponse | void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.warn('Approve was called unnecessarily');
      return;
    }
    if (!token) {
      console.error('no token');
      return;
    }

    if (!tokenContract) {
      console.error('tokenContract is null');
      return;
    }

    if (!amount) {
      console.error('missing amount to approve');
      return;
    }

    if (!spender) {
      console.error('no spender');
      return;
    }

    setAttempt(true);

    let useExact = false;
    return tokenContract.estimateGas
      .approve(spender, MaxUint256)
      .catch(() => {
        useExact = true;
        return tokenContract.estimateGas.approve(spender, amount.raw.toString());
      })
      .then(gasLimit => {
        return tokenContract.approve(spender, useExact ? amount.raw.toString() : MaxUint256, gasAdder({ gasLimit }));
      })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `Approve ${formatTokenSymbol(amount.currency)}`,
          approval: { tokenAddress: token.address, spender: spender },
        });

        setTxHash(response.hash);
      })
      .catch(error => {
        setAttempt(false);

        if (error?.code !== 4001) {
          console.debug('Failed to approve token', error);
        }
        throw error;
      });
  }, [approvalState, token, tokenContract, amount, spender, gasAdder, addTransaction]);

  // Set false attempt if tx is fail. And show error popup.
  useEffect(() => {
    if (approvalTxStatus && approvalTxStatus.isFail && approvalTxStatus.confirmations > 0) {
      setAttempt(false);
    }
  }, [approvalTxStatus]);

  // Reset state after change approval id
  useEffect(() => {
    setAttempt(undefined);
    setTxHash(undefined);
  }, [approvalId]);

  // Update stored attempt flag
  useEffect(() => {
    if (!approvalId || attempt === undefined) {
      return;
    }
    dispatch(setApproveCallAttempt({ id: approvalId, attempt }));

    return () => {
      dispatch(setApproveCallAttempt({ id: approvalId, attempt: false }));
    };
  }, [attempt, dispatch, approvalId]);

  // Update stored transaction hash
  useEffect(() => {
    if (!approvalId || txHash === undefined) {
      return;
    }
    dispatch(setApproveCallTxHash({ id: approvalId, txHash: txHash }));

    return () => {
      dispatch(setApproveCallTxHash({ id: approvalId, txHash: null }));
    };
  }, [dispatch, approvalId, txHash]);

  return useMemo(() => [approvalState, approveCallback], [approvalState, approveCallback]);
}
