import { Currency } from '@plasma/plasmaswap-sdk';
import React, { useMemo } from 'react';
import { ButtonProps } from 'rebass';
import { SUCCESS_APPROVE_STATES, DISABLE_APPROVE_STATES, LOADING_APPROVE_STATES } from '../../constants';
import { ApprovalState } from '../../types';
import { FormattedTokenSymbol } from '../formatted-token-symbol/formatted-token-symbol';
import { Loading } from '../loading/loading';
import './btn-approve.scss';
import { useTranslation } from 'react-i18next';

export interface BtnApproveProps extends Omit<ButtonProps, 'type' | 'color'> {
  approvalState: ApprovalState;
  approvalToken?: Currency | null;
  label?: string;
}

export function BtnApprove({ approvalState, approvalToken, label, className, ...rest }: BtnApproveProps): JSX.Element {
  const { t } = useTranslation();

  const btnClassName = useMemo(() => {
    if (SUCCESS_APPROVE_STATES.includes(approvalState)) {
      return 'btn-success';
    }
    return 'btn-primary';
  }, [approvalState]);

  return (
    <button className={`btn btn-lg btn-approve ${btnClassName} ${className || ''}`} {...rest} disabled={DISABLE_APPROVE_STATES.includes(approvalState)}>
      {LOADING_APPROVE_STATES.includes(approvalState) ? (
        <Loading size="sm" />
      ) : approvalState === ApprovalState.INSUFFICIENT_FUNDS ? (
        <span className="error-text">{t('not_enough_funds_for_approve')}</span>
      ) : SUCCESS_APPROVE_STATES.includes(approvalState) ? (
        <>
          <i className="pi pi-approved" />
          <span>{label || t('approved')}</span>
        </>
      ) : (
        <>
          {label ? (
            <span>{label}</span>
          ) : (
            <>
              <span>{t('approve')}</span>
              {approvalToken ? (
                <>
                  <span>&nbsp;</span>
                  <FormattedTokenSymbol currency={approvalToken} />
                </>
              ) : null}
            </>
          )}
        </>
      )}
    </button>
  );
}
