import { ChainId, Currency, CurrencyAmount, Native, NATIVE, toCurrencyAmount } from '@plasma/plasmaswap-sdk';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { bridgeSocketUserBalances } from '../../api';
import { ETH_ADDRESS } from '../../constants';
import { isAddress, WrappedToken } from '../../utils';
import { getPromiseState } from '../../utils/get-promise-state';
import { tryParseAmount } from '../../utils/try-parse-amount';
import { useActiveWeb3React } from '../web3/use-active-web3-react';

type BalancesCache = { [chainId in ChainId]?: { [addressOrId: string]: CurrencyAmount } };

const BALANCES_CACHE_TIMEOUT = 30e3;
let BALANCES_UPDATERS: Dispatch<SetStateAction<number>>[] = [];
let BALANCES_CACHE_ACCOUNT: string;
let BALANCES_CACHE: BalancesCache | undefined = undefined;
let BALANCES_CACHE_PROMISE: Promise<BalancesCache> | undefined = undefined;

async function getBalances(account: string): Promise<BalancesCache> {
  if (!BALANCES_CACHE_PROMISE || (await getPromiseState(BALANCES_CACHE_PROMISE)) === 'rejected' || BALANCES_CACHE_ACCOUNT !== account) {
    BALANCES_CACHE_ACCOUNT = account;
    BALANCES_CACHE_PROMISE = bridgeSocketUserBalances(account).then(balances => {
      const balancesCache = balances.reduce<BalancesCache>((cache, balanceInfo) => {
        const address = isAddress(balanceInfo.address);
        const isNative = address === ETH_ADDRESS;
        const addressOrId = isNative ? 'NATIVE' : address;
        const currency = isNative
          ? NATIVE[balanceInfo.chainId]
          : new WrappedToken(
              {
                chainId: balanceInfo.chainId,
                address: balanceInfo.address,
                name: balanceInfo.name,
                decimals: balanceInfo.decimals,
                symbol: balanceInfo.symbol,
                logoURI: balanceInfo.icon,
              },
              [],
            );
        const amount = currency ? tryParseAmount(`${balanceInfo.amount}`, currency) : undefined;

        if (addressOrId && address && currency && amount) {
          cache = {
            ...cache,
            [balanceInfo.chainId]: {
              ...cache[balanceInfo.chainId],
              [addressOrId]: amount,
            },
          };
        }

        return cache;
      }, {});

      BALANCES_CACHE = balancesCache;

      BALANCES_UPDATERS.forEach(updater => updater(i => ++i));

      setTimeout(() => {
        BALANCES_CACHE_PROMISE = undefined;
        BALANCES_UPDATERS.forEach(updater => updater(i => ++i));
      }, BALANCES_CACHE_TIMEOUT);

      return balancesCache;
    });
  }

  return BALANCES_CACHE_PROMISE;
}

export const useEvmFetchBalances = (): BalancesCache | null | undefined => {
  const { account } = useActiveWeb3React();
  const [balances, setBalances] = useState<BalancesCache | null | undefined>(BALANCES_CACHE);

  const [update, setUpdate] = useState(0);

  // Update balances
  useEffect(() => (BALANCES_CACHE ? setBalances(BALANCES_CACHE) : undefined), [update]);

  // Fetch balances
  useEffect(() => {
    if (!account) {
      return;
    }

    BALANCES_UPDATERS.push(setUpdate);

    setBalances(balances => (balances ? balances : null));

    getBalances(account).catch(console.error);

    return () => {
      BALANCES_UPDATERS = BALANCES_UPDATERS.filter(i => i !== setUpdate);
    };
  }, [account, update]);

  return balances;
};

export const useEvmUserBalance = (currency: Currency | null | undefined): CurrencyAmount | null | undefined => {
  const balances = useEvmFetchBalances();

  return useMemo(() => {
    if (!currency) {
      return currency;
    }
    if (!balances) {
      return balances;
    }
    const addressOrId = currency instanceof Native ? 'NATIVE' : currency.address;
    const balance = balances[currency.chainId]?.[addressOrId];
    if (!balance) {
      return toCurrencyAmount(currency, '0');
    }
    return balance;
  }, [currency, balances]);
};

export const useEvmUserBalances = (currencies: (Currency | null | undefined)[]): (CurrencyAmount | null | undefined)[] => {
  const balances = useEvmFetchBalances();

  return useMemo(() => {
    return currencies.map(currency => {
      if (!currency) {
        return undefined;
      }
      if (!balances) {
        return balances;
      }
      const addressOrId = currency instanceof Native ? 'NATIVE' : currency.address;
      const balance = balances[currency.chainId]?.[addressOrId];
      if (!balance) {
        return toCurrencyAmount(currency, '0');
      }
      return balance;
    });
  }, [currencies, balances]);
};
