import { ChainId } from '@plasma/plasmaswap-sdk';
import { ZERO_EX_API_HOSTS } from '../constants';

/**
 * Returns API url by chain id
 * @param chainId
 */
export function zeroExApiUrl(chainId: ChainId): string {
  if (!(chainId in ZERO_EX_API_HOSTS)) {
    throw new Error('Unsupported chainId');
  }
  const host = ZERO_EX_API_HOSTS[chainId] as string;

  return host;
}
