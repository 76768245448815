import { Token } from '@plasma/plasmaswap-sdk';
import { SerializedToken } from '../types';

export function serializeToken(token: Token): SerializedToken {
  return {
    chainId: token.chainId,
    address: token.address,
    decimals: token.decimals,
    symbol: token.symbol,
    name: token.name,
  };
}

export function deserializeToken(serializedToken: SerializedToken): Token {
  return new Token(serializedToken.chainId, serializedToken.address, serializedToken.decimals, serializedToken.symbol, serializedToken.name);
}
