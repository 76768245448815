import { ChainId, Token } from '@plasma/plasmaswap-sdk';
import { TOKENS_TAGS } from '../constants';
import { HyperApiTokenInfo, TokenInfo, TokenTag } from '../types';
import { isAddress } from './index';

/**
 * Token instances created from token info.
 */
export class WrappedToken<T extends TokenInfo = TokenInfo> extends Token {
  public readonly info: T;

  public readonly tags: TokenTag[];

  static fromHyperApiTokenInfo(tokenInfo: HyperApiTokenInfo, chainId?: ChainId): WrappedToken<TokenInfo & HyperApiTokenInfo> {
    if (tokenInfo.chains.length === 0) {
      throw new Error('Wrong hyper API token, no chains support');
    }
    const tags = (tokenInfo.tags || [])
      .map(id => {
        const tag = TOKENS_TAGS[id];
        if (!tag) {
          return null;
        }
        return { id, name: tag.name, description: tag.description };
      })
      .filter(Boolean) as TokenTag[];

    tokenInfo.chains.sort((t0, t1) => t0.chainId - t1.chainId);

    let chainTokenInfo: TokenInfo;
    if (chainId === undefined) {
      chainTokenInfo = tokenInfo.chains[0];
    } else {
      chainTokenInfo = tokenInfo.chains.find(t => t.chainId === chainId) || tokenInfo.chains[0];
    }

    const info = { ...chainTokenInfo, ...tokenInfo, ...{ decimals: chainTokenInfo.decimals ? Number(chainTokenInfo.decimals) : 18 } };

    return new WrappedToken(info, tags);
  }

  constructor(tokenInfo: T, tags: TokenTag[]) {
    const address = isAddress(tokenInfo.address);
    if (!address) {
      throw new Error(`Invalid token address - ${tokenInfo.address}`);
    }
    super(tokenInfo.chainId, address, tokenInfo.decimals, tokenInfo.symbol, tokenInfo.name);
    this.info = tokenInfo;
    this.tags = tags;
  }

  get logoURI(): string | undefined {
    return this.info.logoURI;
  }
}
