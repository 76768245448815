import { NETWORK_LABEL, Trade0xSwap, TradeType } from '@plasma/plasmaswap-sdk';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import packageJson from '../../../package.json';
import { NETWORK_ICON, SUCCESS_APPROVE_STATES, ZERO_EX_SWAP_SOURCE_NAME } from '../../constants';
import { useApproveTokenForGsn } from '../../hooks/approval/use-approve-token-for-gsn';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { useGsnToken, useTransactionFee } from '../../state/gas-station/gas-station.hooks';
import { useSlippageTolerance } from '../../state/user/user.hooks';
import { useCurrencyBalance } from '../../state/wallets/wallets.hooks';
import { SimpleTransaction, SwapField } from '../../types';
import { formatCurrencyAmount } from '../../utils/format-currency-amount';
import { formatTokenSymbol } from '../../utils/format-token-symbol';
import { computeSlippageAdjustedAmounts } from '../../utils/prices';
import { BtnApprove } from '../btn-approve/btn-approve';
import { FormattedCurrencyAmountInFiat } from '../formatted-currency-amount-in-fiat/formatted-currency-amount-in-fiat';
import { FormattedCurrencyAmount } from '../formatted-currency-amount/formatted-currency-amount';
import { FormattedPercent } from '../formatted-percent/formatted-percent';
import { FormattedTokenSymbol } from '../formatted-token-symbol/formatted-token-symbol';
import { LoadingSkeleton } from '../loading-skeleton/loading-skeleton';
import { CurrencyLogo } from '../logo/logo';
import { ModalProps, Modal } from '../modal/modal';
import { ModalLoadingView } from '../modal/modal-loading-view';
import { ModalTxErrorView } from '../modal/modal-tx-error-view';
import { ModalTxSentView } from '../modal/modal-tx-sent-view';
import './modal-hyper-dex-swap-confirm.scss';

export interface ModalHyperDexSwapConfirmProps extends ModalProps {
  trade?: Trade0xSwap;
  txData: SimpleTransaction | null | undefined;
  txHash?: string;
  attempting?: boolean;
  errorMessage?: string;

  onConfirm: () => void;
  onDismiss: () => void;
}

export function ModalHyperDexSwapConfirm(props: ModalHyperDexSwapConfirmProps): JSX.Element | null {
  const { chainId, account } = useActiveWeb3React();
  const { t } = useTranslation();
  const [allowedSlippage] = useSlippageTolerance();

  // GSN tokens approve state
  const [token] = useGsnToken();
  const transactionFee = useTransactionFee(props.txData);
  const feeCurrencyBalance = useCurrencyBalance(account || undefined, transactionFee?.currency);
  const [approvalState, submitApprove] = useApproveTokenForGsn(transactionFee);

  const onDismissWrap = useCallback(() => {
    props.onDismiss && props.onDismiss();
  }, [props]);

  const slippageAdjustedAmounts = useMemo(() => {
    return computeSlippageAdjustedAmounts(props.trade, allowedSlippage);
  }, [props.trade, allowedSlippage]);

  return (
    <Modal isOpen={props.isOpen} onDismiss={onDismissWrap}>
      <div className="modal modal-hyper-dex-swap-confirm">
        <div className="modal-header">
          <div className="title">
            <span>{!props.attempting && !props.txHash && !props.errorMessage ? t('swap_confirmation') : null}</span>
          </div>
          <button className="btn" onClick={onDismissWrap}>
            <i className="pi pi-close" />
          </button>
        </div>
        {props.errorMessage ? (
          /* Transaction error */
          <ModalTxErrorView caption={props.errorMessage} onDismiss={onDismissWrap} />
        ) : props.txHash ? (
          /* Result */
          <ModalTxSentView txHash={props.txHash} onDismiss={onDismissWrap} />
        ) : props.attempting ? (
          /* Loading */
          <ModalLoadingView
            message={t('swapping_currencies', {
              amountFrom: formatCurrencyAmount(props.trade?.inputAmount),
              amountTo: formatCurrencyAmount(props.trade?.outputAmount),
            })}
          />
        ) : props.trade ? (
          /* Main content */
          <div className="modal-content">
            {/* Trade info (from and to tokens) */}
            <div className="swap-tokens-block">
              {/* From Field */}
              <div className="swap-token">
                <div className="info">
                  <CurrencyLogo currency={props.trade?.inputAmount.currency} size={32} />
                  <div className="symbol">
                    <FormattedTokenSymbol currency={props.trade?.inputAmount.currency} />
                  </div>
                </div>

                <div className="amount">
                  <div className="crypto">
                    <FormattedCurrencyAmount amount={props.trade?.inputAmount} hideSymbol />
                  </div>
                  <div className="fiat">
                    <FormattedCurrencyAmountInFiat amount={props.trade?.inputAmount} />
                  </div>
                </div>
              </div>
              {/*Arrow down*/}
              <div className="separator">
                <i className="pi pi-down" />
              </div>
              {/* To Field */}
              <div className="swap-token">
                <div className="info">
                  <CurrencyLogo currency={props.trade?.outputAmount.currency} size={32} />
                  <div className="symbol">
                    <FormattedTokenSymbol currency={props.trade?.outputAmount.currency} />
                  </div>
                </div>

                <div className="amount">
                  <div className="crypto">
                    <FormattedCurrencyAmount amount={props.trade?.outputAmount} hideSymbol />
                  </div>
                  <div className="fiat">
                    <FormattedCurrencyAmountInFiat amount={props.trade?.outputAmount} />
                  </div>
                </div>
              </div>
            </div>

            {/* Other trade information */}
            <div className="trade-info">
              {/* Maximum sold / Minimum received */}
              <div className="label-container secondary">
                <div className="label">
                  <span>{props.trade.tradeType === TradeType.EXACT_INPUT ? t('minimum_received') : t('maximum_sold')}</span>
                </div>

                <div className="value">
                  {props.trade.tradeType === TradeType.EXACT_INPUT ? (
                    <FormattedCurrencyAmount amount={slippageAdjustedAmounts[SwapField.OUTPUT]} replacerForEmpty="-" />
                  ) : (
                    <FormattedCurrencyAmount amount={slippageAdjustedAmounts[SwapField.INPUT]} replacerForEmpty="-" />
                  )}
                </div>
              </div>
              {/* Network */}
              <div className="label-container secondary">
                <div className="label">
                  <span>{t('network')}</span>
                </div>

                <div className="value">
                  {chainId ? (
                    <div className="provider">
                      <img src={NETWORK_ICON[chainId]} alt="Network" style={{ width: '16px', height: '16px' }} />
                      <span className="name">{NETWORK_LABEL[chainId]}</span>
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Provider Proportions */}
              {props.trade.proportions.map((provider, index) => {
                return (
                  <div className="label-container secondary" key={index}>
                    <div className="label">
                      <span>{t('provider')}</span>
                    </div>
                    <div className="value">
                      <div className="provider">
                        <span className="info">
                          <FormattedPercent percent={provider.proportion} color={false} hideSign={true} />
                        </span>
                        <img
                          src={`${packageJson.homepage || '/'}images/liquidity-sources/${provider.name.toLowerCase().replace('_', '-')}.svg`}
                          alt={ZERO_EX_SWAP_SOURCE_NAME[provider.name]}
                          style={{ width: '16px', height: '16px' }}
                        />
                        <span className="name">{ZERO_EX_SWAP_SOURCE_NAME[provider.name]}</span>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* Transaction Cost */}
              <div className="label-container secondary">
                <div className="label">
                  <span>{t('transaction_cost')}</span>
                </div>
                <div className="value">
                  {transactionFee === null ? (
                    <LoadingSkeleton>
                      <Skeleton height={16} width={150} />
                    </LoadingSkeleton>
                  ) : (
                    <>
                      <span className="fiat">
                        <FormattedCurrencyAmountInFiat amount={transactionFee} />
                      </span>
                      <span className="crypto">
                        <FormattedCurrencyAmount amount={transactionFee} replacerForEmpty="-" />
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Approve and Submit button */}
            <div className="button-block">
              {transactionFee && feeCurrencyBalance?.lessThan(transactionFee) ? (
                <button className="btn btn-primary btn-lg" disabled>
                  <span>{t('insufficient_funds_to_pay_tx_fee')}</span>
                </button>
              ) : !SUCCESS_APPROVE_STATES.includes(approvalState) ? (
                <BtnApprove approvalState={approvalState} onClick={submitApprove} label={t('approve_gas_payment_with_token', { token: formatTokenSymbol(token) })} />
              ) : (
                <button className="btn btn-primary btn-lg" onClick={props.onConfirm}>
                  <span>{t('confirm_swap')}</span>
                </button>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
}
