import { NativeAmount, toCurrencyAmount } from '@plasma/plasmaswap-sdk';
import Big from 'big.js';
import { useEffect, useMemo } from 'react';
import { ApprovalAmount, ApprovalCallback, ApprovalState } from '../../types';
import { useGasStationContract } from '../use-contract';
import { useActiveWeb3React } from '../web3/use-active-web3-react';
import { useApproveTokenWithPermit } from './use-approve-token-with-permit';

/**
 * It is used exclusively for issuing permit paying for gas with GSN tokens
 * @param feeAmount
 */
export function useApproveTokenForGsn(feeAmount: ApprovalAmount): [ApprovalState, ApprovalCallback] {
  const { provider } = useActiveWeb3React();
  const contract = useGasStationContract();

  // Add 10% to approve GSN fee token amount
  const amountToApprove = useMemo(() => {
    if (!feeAmount || feeAmount instanceof NativeAmount) {
      return feeAmount;
    }
    return toCurrencyAmount(feeAmount.currency, Big(feeAmount.raw.toString()).times(1.1).toFixed(0));
  }, [feeAmount]);

  const [approvalState, approvalData, approveCallback] = useApproveTokenWithPermit(amountToApprove, contract?.address);

  useEffect(() => {
    if (!provider) {
      return;
    }
    provider.setApprovalData(approvalData);
  }, [approvalData, provider]);

  return useMemo(() => [approvalState, approveCallback], [approvalState, approveCallback]);
}
