import './i18n';
import React, { StrictMode } from 'react';
import App from './app';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './state';
import { ListsUpdater } from './state/lists/lists.updater';
import { MulticallUpdater } from './state/multicall/multicall.updater';
import { ApplicationUpdater } from './state/application/application.updater';
import { GasStationUpdater } from './state/gas-station/gas-station.updater';
import { UserUpdater } from './state/user/user.updater';
import { WAGMI_CLIENT } from './constants';
import { WagmiConfig } from 'wagmi';
import { ApplicationProvider } from './components/application-provider/application-provider';

function Updaters(): JSX.Element {
  return (
    <>
      <UserUpdater />
      <ListsUpdater />
      <ApplicationUpdater />
      <MulticallUpdater />
      <GasStationUpdater />
    </>
  );
}

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <WagmiConfig client={WAGMI_CLIENT}>
        <ApplicationProvider>
          <>
            <Updaters />
            <App />
          </>
        </ApplicationProvider>
      </WagmiConfig>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);
