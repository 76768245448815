import Lottie from 'lottie-react';
import React from 'react';
import LoaderLight from '../../assets/animations/loader-light.json';
import LoaderDark from '../../assets/animations/loader-dark.json';
import { useTheme } from '../../state/user/user.hooks';
import './loading.scss';

export interface LoadingProps {
  size?: 'lg' | 'md' | 'sm' | 'xs' | 'auto';
}

export function Loading({ size = 'auto' }: LoadingProps): JSX.Element {
  const [isDarkTheme] = useTheme();

  return (
    <div className={`loading-component size-${size}`}>
      <Lottie animationData={isDarkTheme ? LoaderDark : LoaderLight} className="loader" loop={true} />
    </div>
  );
}
