import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetTab } from '../../types/widget-tabs';
import { ExternalLink } from '../external-link/external-link';
import './links-block.scss';

interface LinksBlockProps {
  type: WidgetTab;
}

export const LinksBlock: FC<LinksBlockProps> = props => {
  const { type } = props;
  const { t } = useTranslation();
  const plasmaLink = (
    <span className="plasma-link">
      {t('powered_by')}
      &nbsp;
      <ExternalLink href="http://plasma.finance/">Plasma.Finance</ExternalLink>
    </span>
  );
  const hyperDexLink = (
    <span className="hyper-dex-link">
      {t('dex_aggregator')}&nbsp;
      <ExternalLink href="https://apy.plasma.finance/#/hyper-dex/market">HyperDEX</ExternalLink>. &nbsp;
    </span>
  );
  const multiChainWalletText = <span>{t('multi_chain_wallet')}.&nbsp;</span>;
  const multiChainBridgeText = <span>{t('multi_chain_bridge')}.&nbsp;</span>;
  if (type === WidgetTab.swap) {
    return (
      <div className="links">
        {hyperDexLink}
        {plasmaLink}
      </div>
    );
  }
  if (type === WidgetTab.bridge) {
    return (
      <div className="links">
        {multiChainBridgeText}
        {plasmaLink}
      </div>
    );
  }
  if (type === WidgetTab.portfolio) {
    return (
      <div className="links">
        {multiChainWalletText}
        {plasmaLink}
      </div>
    );
  }
  return null;
};
