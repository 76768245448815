import React, { useState } from 'react';
import './bridge.scss';
import { EvmForm } from './evm-form/evm-form';
import { RenForm } from './ren-form/ren-form';

export const Bridge = (): JSX.Element => {
  // const { t } = useTranslation();
  const [bridge] = useState<'evm' | 'ren'>('evm');

  return (
    <div className="bridge-page">
      {/* <div className="bridge-source-selector">
        <button className={cx('btn btn-tab', { active: bridge === 'evm' })} onClick={() => setBridge('evm')}>
          <span>{t('evm_chain_bridge')}</span>
        </button>
        <button className={cx('btn btn-tab', { active: bridge === 'ren' })} onClick={() => setBridge('ren')}>
          <span>{t('btc_bridge')}</span>
        </button>
      </div> */}
      <div className="card bridge-card">{bridge === 'ren' ? <RenForm /> : <EvmForm />}</div>
    </div>
  );
};
