import { DialogContent, DialogOverlay } from '@reach/dialog';
import '@reach/dialog/styles.css';
import React, { FC } from 'react';
import { getWidgetParam } from '../../utils/get-query-params';
import './modal.scss';

export interface ModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  children?: React.ReactNode;
  padding?: string;
}

const closeBtnHeight = 64;

export const Modal: FC<ModalProps> = props => {
  const { isOpen, onDismiss, children, padding } = props;
  const closeBtn = Number(getWidgetParam('closeBtn'));
  const defaultPadding = closeBtn === 1 ? '24px 0 0' : '24px 0';
  return (
    <DialogOverlay isOpen={isOpen} onDismiss={onDismiss}>
      <DialogContent
        style={{
          height: closeBtn === 1 ? `calc(100% - ${closeBtnHeight}px)` : '100%',
          padding: padding || defaultPadding,
        }}
        aria-label="dialog content"
      >
        {children}
      </DialogContent>
    </DialogOverlay>
  );
};
