import { createAction } from '@reduxjs/toolkit';
import { Call, CallListenerOptions } from '../../types';

export const addMulticallListeners = createAction<{ chainId: number; calls: Call[]; options?: CallListenerOptions }>('multicall/addMulticallListeners');
export const removeMulticallListeners = createAction<{ chainId: number; calls: Call[]; options?: CallListenerOptions }>('multicall/removeMulticallListeners');
export const fetchingMulticallResults = createAction<{ chainId: number; calls: Call[]; fetchingBlockNumber: number }>('multicall/fetchingMulticallResults');
export const errorFetchingMulticallResults = createAction<{
  chainId: number;
  calls: Call[];
  fetchingBlockNumber: number;
}>('multicall/errorFetchingMulticallResults');
export const updateMulticallResults = createAction<{
  chainId: number;
  blockNumber: number;
  results: {
    [callKey: string]: string | null;
  };
}>('multicall/updateMulticallResults');
