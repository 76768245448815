import { Currency, CurrencyAmount, Price, toCurrencyAmount } from '@plasma/plasmaswap-sdk';
import Big from 'big.js';
import React, { ForwardedRef, forwardRef, ForwardRefRenderFunction, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyInputPanel } from '../currency-input-panel/currency-input-panel';
import { TradePrice } from '../trade-price/trade-price';
import { DefaultGasLimit } from '../../constants';
import { useTrade0xSwap } from '../../hooks/trades/use-trade-0x-swap';
import { useTrade0xSwapMaxSellAmount } from '../../hooks/trades/use-trade-0x-swap-max-sell-amount';
import { useIsWrapMode } from '../../hooks/use-wrap-callback';
import { useMaxGasPrice } from '../../state/gas-station/gas-station.hooks';
import { useCurrentCurrency } from '../../state/user/user.hooks';
import { useCurrencyBalances } from '../../state/wallets/wallets.hooks';
import { SwapField } from '../../types';
import { currencyId } from '../../utils/currency-id';
import { formatAmount } from '../../utils/format-amount';
import { maxAmountSpend } from '../../utils/max-amount-spend';
import { tryParseAmount } from '../../utils/try-parse-amount';
import { NormalSwapMode } from './_normal-swap-mode';
import { WrapUnwrapMode } from './_wrap-unwrap-mode';
import './swap-form.scss';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { setActiveTab } from '../../state/user/user.actions';
import { WidgetTab } from '../../types/widget-tabs';
import { useDispatch } from 'react-redux';
import { useCurrencyPrice } from '../../hooks/use-currency-price';

const DECREASING_PERCENTAGE_QUOTE_PRICE = 2; // Decreasing percentage for 0x quote price, for market price calculation.

export interface SwapFormProps {
  fromCurrency?: Currency | null;
  toCurrency?: Currency | null;
  onChangeFrom?: (from?: string) => void;
  onChangeTo?: (to?: string) => void;
  onSwapFields?: () => void;
}

export interface SwapFormRefs {
  // Refresh 0x trade from external component
  refresh: () => void;
  // If no trade, we should disable refresh button
  disabled: boolean;
}

const SwapFormRenderFn: ForwardRefRenderFunction<SwapFormRefs, SwapFormProps> = (props: SwapFormProps, refs: ForwardedRef<SwapFormRefs>): JSX.Element => {
  const { fromCurrency, toCurrency } = props;
  const { account, chainId } = useActiveWeb3React();
  const { t } = useTranslation();
  const [localCurrency] = useCurrentCurrency();
  const maxGasPrice = useMaxGasPrice();
  const dispatch = useDispatch();

  // Currencies info
  const fromTokenUsdPrice = useCurrencyPrice(fromCurrency || undefined);
  const toTokenUsdPrice = useCurrencyPrice(toCurrency || undefined);
  const balances: (CurrencyAmount | undefined)[] = useCurrencyBalances(account ?? undefined, [fromCurrency || undefined, toCurrency || undefined]);

  // Is wrap mode enabled
  const isWrapMode: boolean = useIsWrapMode(fromCurrency || undefined, toCurrency || undefined);
  // Swap form fields state
  const [typedValue, setTypedValue] = useState('');
  const [typedField, setTypedField] = useState<SwapField>(SwapField.INPUT);
  const parsedAmount = useMemo(() => {
    return tryParseAmount(typedValue, (typedField === SwapField.INPUT ? fromCurrency : toCurrency) ?? undefined);
  }, [fromCurrency, toCurrency, typedField, typedValue]);

  // 0x trade (approximately exchange prices)
  const from = useMemo(() => (isWrapMode ? undefined : typedField === SwapField.INPUT ? parsedAmount : fromCurrency), [fromCurrency, isWrapMode, parsedAmount, typedField]);
  const to = useMemo(() => (isWrapMode ? undefined : typedField === SwapField.OUTPUT ? parsedAmount : toCurrency), [isWrapMode, parsedAmount, toCurrency, typedField]);
  const [trade0xSwap, refreshTrade] = useTrade0xSwap(from, to);

  const maxAmountToSend = useTrade0xSwapMaxSellAmount(trade0xSwap);

  // Limit order execution price
  const limitOrderExecutionPrice: Price | null | undefined = useMemo(() => {
    if (!trade0xSwap) {
      return trade0xSwap as null | undefined;
    }

    const numerator = Big(trade0xSwap.executionPrice.numerator.toString());
    const newNumerator = numerator.minus(numerator.times(Big(DECREASING_PERCENTAGE_QUOTE_PRICE).div(100))).toFixed(0);
    return new Price(trade0xSwap.executionPrice.baseCurrency, trade0xSwap.executionPrice.quoteCurrency, trade0xSwap.executionPrice.denominator, newNumerator);
  }, [trade0xSwap]);

  // Returns translated fields validation error
  const formValidationError = useMemo(() => {
    if (!account) {
      return t('connect_wallet');
    }
    if (!parsedAmount) {
      return t('enter_an_amount');
    }
    // if (trade0xSwap === null) {
    //   return t('loading');
    // }
    if (!fromCurrency || !toCurrency) {
      return t('select_a_token');
    }

    if (trade0xSwap === undefined) {
      return t('insufficient_liquidity_for_this_trade');
    }

    // Compare balance to input amount
    const balanceIn = balances[0];
    if (balanceIn && maxAmountToSend && balanceIn.lessThan(maxAmountToSend)) {
      return t('insufficient_balance', { code: fromCurrency?.symbol });
    }
    return '';
  }, [account, parsedAmount, trade0xSwap, fromCurrency, toCurrency, balances, maxAmountToSend, t]);

  // Amounts for displaying and amounts in fiat local currency
  const parsedAmounts = useMemo(() => {
    // Wrap mode (inputAmount === outputAmount)
    if (isWrapMode) {
      return {
        [SwapField.INPUT]: parsedAmount,
        [SwapField.OUTPUT]: parsedAmount,
      };
    }

    // Normal swap used trade for amount calculation
    return {
      [SwapField.INPUT]: typedField === SwapField.INPUT ? parsedAmount : trade0xSwap?.inputAmount,
      [SwapField.OUTPUT]: typedField === SwapField.OUTPUT ? parsedAmount : trade0xSwap?.outputAmount,
    };
  }, [isWrapMode, parsedAmount, typedField, trade0xSwap]);

  const formattedAmounts = useMemo(() => {
    return {
      [typedField]: typedValue,
      [typedField === SwapField.INPUT ? SwapField.OUTPUT : SwapField.INPUT]: isWrapMode
        ? parsedAmounts[typedField]?.toExact() ?? ''
        : parsedAmounts[typedField === SwapField.INPUT ? SwapField.OUTPUT : SwapField.INPUT]?.toSignificant(6) ?? '',
    };
  }, [parsedAmounts, isWrapMode, typedField, typedValue]);

  const localAmounts = useMemo(() => {
    const inputAmount = parsedAmounts[SwapField.INPUT];
    const outputAmount = parsedAmounts[SwapField.OUTPUT];

    const inputAmountInUsd = fromTokenUsdPrice && inputAmount && fromTokenUsdPrice.baseCurrency.equals(inputAmount.currency) && fromTokenUsdPrice.quote(inputAmount).toExact();
    const outputAmountInUsd = toTokenUsdPrice && outputAmount && toTokenUsdPrice.baseCurrency.equals(outputAmount.currency) && toTokenUsdPrice.quote(outputAmount).toExact();

    const inputAmountInLocal = inputAmountInUsd && Big(inputAmountInUsd).div(localCurrency.rate).toString();
    const outputAmountInLocal = outputAmountInUsd && Big(outputAmountInUsd).div(localCurrency.rate).toString();

    const symbol = localCurrency.sign;

    return {
      [SwapField.INPUT]: inputAmountInLocal ? `≈ ${symbol}${formatAmount(inputAmountInLocal, { decimalPlaces: 2 })}` : `-${symbol}`,
      [SwapField.OUTPUT]: outputAmountInLocal ? `≈ ${symbol}${formatAmount(outputAmountInLocal, { decimalPlaces: 2 })}` : `-${symbol}`,
    };
  }, [fromTokenUsdPrice, localCurrency.rate, localCurrency.sign, parsedAmounts, toTokenUsdPrice]);

  // Handlers and callbacks
  const [isMax, onMax] = useMemo(() => {
    let maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(balances[0]);
    if (!maxAmountInput || !maxAmountInput.greaterThan('0') || !maxGasPrice) {
      return [false, () => undefined];
    }

    if (maxAmountInput.currency.isNative) {
      const maxAmountInputBig = Big(maxAmountInput.raw.toString()).minus(Big(maxGasPrice.toString()).mul(DefaultGasLimit.HIGH));
      if (maxAmountInputBig.gt(0)) {
        maxAmountInput = toCurrencyAmount(maxAmountInput.currency, maxAmountInputBig.toString());
      }
    }

    return [
      !parsedAmounts[SwapField.INPUT]?.equalTo(maxAmountInput),
      () => {
        setTypedValue((maxAmountInput as CurrencyAmount).toExact());
        setTypedField(SwapField.INPUT);
      },
    ];
  }, [balances, parsedAmounts, maxGasPrice]);

  const onSwapFields = useCallback(() => {
    setTypedField(field => (field === SwapField.INPUT ? SwapField.OUTPUT : SwapField.INPUT));
    props.onSwapFields && props.onSwapFields();
  }, [props]);

  const onChangeFromCurrency = useCallback((newFromCurrency: Currency) => props.onChangeFrom?.(currencyId(newFromCurrency, chainId)), [props, chainId]);
  const onChangeToCurrency = useCallback((newToCurrency: Currency) => props.onChangeTo?.(currencyId(newToCurrency, chainId)), [props, chainId]);
  // const isOpenDropdown = useMemo(() => !!parsedAmount && !!trade0xSwap && !isWrapMode, [isWrapMode, parsedAmount, trade0xSwap]);

  // Reset form fields
  const onResetForm = useCallback(() => {
    setTypedValue('');
    setTypedField(SwapField.INPUT);
  }, []);

  // Update refs
  useImperativeHandle(refs, () => ({ refresh: refreshTrade, disabled: !trade0xSwap }), [refreshTrade, trade0xSwap]);

  return (
    <div className="swap-form">
      {/* From amount input field */}
      <div className="form-field">
        <div className="field-label">
          <span>{t('swap_from')}</span>
        </div>
        <CurrencyInputPanel
          id="hyper-dex-currency-input"
          value={formattedAmounts[SwapField.INPUT]}
          showMaxButton={isMax}
          currency={fromCurrency || undefined}
          onUserInput={value => {
            setTypedValue(value);
            setTypedField(SwapField.INPUT);
          }}
          label={localAmounts[SwapField.INPUT]}
          onMax={onMax}
          onCurrencySelect={onChangeFromCurrency}
          otherCurrency={toCurrency}
          loading={(trade0xSwap === null || limitOrderExecutionPrice === null) && typedField === SwapField.OUTPUT}
        />
      </div>

      {/* Swap fields */}
      <div className="swap-fields-button">
        <button className="btn btn-icon-rect btn-foreground-white change" onClick={onSwapFields}>
          <i className="pi pi-change" />
        </button>
      </div>

      {/*To amount field*/}
      <div className="form-field">
        <div className="field-label">
          <span>{t('swap_to')}</span>
        </div>
        <CurrencyInputPanel
          id="swap-currency-output"
          value={formattedAmounts[SwapField.OUTPUT]}
          onUserInput={value => {
            setTypedValue(value);
            setTypedField(SwapField.OUTPUT);
          }}
          label={localAmounts[SwapField.OUTPUT]}
          showMaxButton={false}
          currency={toCurrency || undefined}
          onCurrencySelect={onChangeToCurrency}
          otherCurrency={fromCurrency}
          loading={(trade0xSwap === null || limitOrderExecutionPrice === null) && typedField === SwapField.INPUT}
        />
      </div>

      {/*Trade info and slippage tolerance info */}
      {isWrapMode ? null : (
        <div className="trade-info">
          {/*Execution rate*/}
          <div className="row">
            <div className="label">
              <span>{t('price')}</span>
            </div>
            <div className="value">
              <TradePrice price={trade0xSwap?.executionPrice} />
            </div>
          </div>
        </div>
      )}

      {!account ? (
        <div className="button-block">
          <button className="btn btn-primary btn-lg disabled" onClick={() => dispatch(setActiveTab(WidgetTab.portfolio))}>
            <span>{t('connect_wallet')}</span>
          </button>
        </div>
      ) : isWrapMode ? (
        <WrapUnwrapMode fromCurrency={fromCurrency || undefined} toCurrency={toCurrency || undefined} typedValue={typedValue} resetForm={onResetForm} />
      ) : (
        <NormalSwapMode fromCurrency={fromCurrency || undefined} trade={trade0xSwap} formValidationError={formValidationError} resetForm={onResetForm} />
      )}
    </div>
  );
};

export const SwapForm = forwardRef(SwapFormRenderFn);
