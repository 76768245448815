import { ChainId, JSBI, NATIVE_ADDRESSES, Percent, Token, WNATIVE } from '@plasma/plasmaswap-sdk';
import packageJson from '../../package.json';
import { getAddress } from '@ethersproject/address';
import Big from 'big.js';
import { DeviceType, UnmarshalSupportedChainId } from '../types';
import { isAndroid, isDesktop } from 'react-device-detect';
import { AllChainId, AllMainnetChainId, ExtraChainId, HyperApiSupportedChainId, MainnetChainId } from '../types';

const numberFilter = (i: any) => typeof i === 'number';
export const SUPPORTED_CHAIN_IDS: ChainId[] = Object.values(ChainId).filter(numberFilter) as ChainId[];
export const SUPPORTED_MAINNET_CHAIN_IDS: MainnetChainId[] = Object.values(MainnetChainId).filter(numberFilter) as MainnetChainId[];
export const ALL_CHAIN_IDS: AllChainId[] = Object.values(AllChainId).filter(numberFilter) as AllChainId[];
export const ALL_MAINNET_CHAIN_IDS: AllMainnetChainId[] = Object.values(AllMainnetChainId).filter(numberFilter) as AllMainnetChainId[];
export const HYPER_API_SUPPORTED_CHAIN_IDS: HyperApiSupportedChainId[] = Object.values(HyperApiSupportedChainId).filter(numberFilter) as HyperApiSupportedChainId[];

export const CURRENCY_LIST = [
  { value: 'USD', title: 'United States dollar', sign: '$' },
  { value: 'EUR', title: 'Euro', sign: '€' },
  { value: 'CNY', title: 'Yuan', sign: '¥' },
  { value: 'RUB', title: 'Russian Ruble', sign: '₽' },
  { value: 'GBP', title: 'British pound', sign: '£' },
  { value: 'JPY', title: 'Japanese yen', sign: '¥' },
  { value: 'CHF', title: 'Swiss franc', sign: 'Fr.' },
  { value: 'AED', title: 'United Arab Emirates Dirham', sign: 'د.إ;' },
  { value: 'AFN', title: 'Afghan Afghani', sign: 'Afs' },
  { value: 'ALL', title: 'Albanian Lek', sign: 'L' },
  { value: 'AMD', title: 'Armenian Dram', sign: 'AMD' },
  { value: 'ANG', title: 'Netherlands Antillean Guilder', sign: 'NAƒ' },
  { value: 'AOA', title: 'Angolan Kwanza', sign: 'Kz' },
  { value: 'ARS', title: 'Argentine Peso', sign: '$' },
  { value: 'AUD', title: 'Australian dollar', sign: '$' },
  { value: 'AWG', title: 'Aruban Florin', sign: 'ƒ' },
  { value: 'AZN', title: 'Azerbaijani Manat', sign: 'AZN' },
  { value: 'BAM', title: 'Bosnia-Herzegovina Convertible Mark', sign: 'KM' },
  { value: 'BBD', title: 'Barbadian Dollar', sign: 'Bds$' },
  { value: 'BDT', title: 'Bangladeshi Taka', sign: '৳' },
  { value: 'BGN', title: 'Bulgarian Lev', sign: 'BGN' },
  { value: 'BHD', title: 'Bahraini Dinar', sign: '.د.ب' },
  { value: 'BIF', title: 'Burundian Franc', sign: 'FBu' },
  { value: 'BMD', title: 'Bermudan Dollar', sign: 'BD$' },
  { value: 'BND', title: 'Brunei Dollar', sign: 'B$' },
  { value: 'BOB', title: 'Bolivian Boliviano', sign: 'Bs.' },
  { value: 'BRL', title: 'Brazilian real', sign: 'R$' },
  { value: 'BSD', title: 'Bahamian Dollar', sign: 'B$' },
  { value: 'BTC', title: 'Bitcoin', sign: 'BTC' },
  { value: 'BTN', title: 'Bhutanese Ngultrum', sign: 'Nu.' },
  { value: 'BWP', title: 'Botswanan Pula', sign: 'P' },
  { value: 'BYN', title: 'Belarusian ruble', sign: 'BYN' },
  { value: 'BYR', title: 'Belarusian Ruble', sign: 'Br' },
  { value: 'BZD', title: 'Belize Dollar', sign: 'BZ$' },
  { value: 'CAD', title: 'Canadian dollar', sign: 'Can$' },
  { value: 'CDF', title: 'Congolese Franc', sign: 'F' },
  { value: 'CLF', title: 'Chilean Unit of Account (UF)', sign: 'CLF' },
  { value: 'CLP', title: 'Chilean peso', sign: 'peso' },
  { value: 'COP', title: 'Colombian Peso', sign: 'Col$' },
  { value: 'CRC', title: 'Costa Rican Colón', sign: '₡' },
  { value: 'CUC', title: 'Cuban Convertible Peso', sign: '$' },
  { value: 'CUP', title: 'Cuban Peso', sign: 'CUP' },
  { value: 'CVE', title: 'Cape Verdean Escudo', sign: 'Esc' },
  { value: 'CZK', title: 'Czech koruna', sign: 'Kč' },
  { value: 'DJF', title: 'Djiboutian Franc', sign: 'Fdj' },
  { value: 'DKK', title: 'Danish krone', sign: 'Kr.' },
  { value: 'DOP', title: 'Dominican Peso', sign: 'RD$' },
  { value: 'DZD', title: 'Algerian Dinar', sign: 'د.ج' },
  { value: 'EGP', title: 'Egyptian Pound', sign: '£' },
  { value: 'ERN', title: 'Eritrean Nakfa', sign: 'Nfa' },
  { value: 'ETB', title: 'Ethiopian Birr', sign: 'Br' },
  { value: 'FJD', title: 'Fijian Dollar', sign: 'FJ$' },
  { value: 'FKP', title: 'Falkland Islands Pound', sign: '£' },
  { value: 'GEL', title: 'Georgian Lari', sign: 'GEL' },
  { value: 'GGP', title: 'Guernsey Pound', sign: 'GGP' },
  { value: 'GHS', title: 'Ghanaian Cedi', sign: 'GH₵' },
  { value: 'GIP', title: 'Gibraltar Pound', sign: '£' },
  { value: 'GMD', title: 'Gambian Dalasi', sign: 'D' },
  { value: 'GNF', title: 'Guinean Franc', sign: 'FG' },
  { value: 'GTQ', title: 'Guatemalan Quetzal', sign: 'Q' },
  { value: 'GYD', title: 'Guyanaese Dollar', sign: 'GY$' },
  { value: 'HKD', title: 'Hong Kong dollar', sign: 'HK$' },
  { value: 'HNL', title: 'Honduran Lempira', sign: 'L' },
  { value: 'HRK', title: 'Croatian Kuna', sign: 'kn' },
  { value: 'HTG', title: 'Haitian Gourde', sign: 'G' },
  { value: 'HUF', title: 'Hungarian forint', sign: 'Ft' },
  { value: 'IDR', title: 'Indonesian rupiah', sign: 'Rp' },
  { value: 'ILS', title: 'Israeli Shekel', sign: '₪' },
  { value: 'IMP', title: 'Manx pound', sign: 'IMP' },
  { value: 'INR', title: 'Indian rupee', sign: '₹' },
  { value: 'IQD', title: 'Iraqi Dinar', sign: 'د.ع' },
  { value: 'IRR', title: 'Iranian Rial', sign: 'IRR' },
  { value: 'ISK', title: 'Icelandic Króna', sign: 'kr' },
  { value: 'JEP', title: 'Jersey Pound', sign: 'JEP' },
  { value: 'JMD', title: 'Jamaican Dollar', sign: 'J$' },
  { value: 'JOD', title: 'Jordanian Dinar', sign: 'JOD' },
  { value: 'KES', title: 'Kenyan Shilling', sign: 'KSh' },
  { value: 'KGS', title: 'Kyrgystani Som', sign: 'сом' },
  { value: 'KHR', title: 'Cambodian Riel', sign: '៛' },
  { value: 'KMF', title: 'Comorian Franc', sign: 'KMF' },
  { value: 'KPW', title: 'North Korean Won', sign: 'W' },
  { value: 'KRW', title: 'South Korean won', sign: '₩' },
  { value: 'KWD', title: 'Kuwaiti Dinar', sign: 'KWD' },
  { value: 'KYD', title: 'Cayman Islands Dollar', sign: 'KY$' },
  { value: 'KZT', title: 'Kazakhstani tenge', sign: '₸' },
  { value: 'LAK', title: 'Laotian Kip', sign: 'KN' },
  { value: 'LBP', title: 'Lebanese Pound', sign: '£' },
  { value: 'LKR', title: 'Sri Lankan Rupee', sign: 'Rs' },
  { value: 'LRD', title: 'Liberian Dollar', sign: 'L$' },
  { value: 'LSL', title: 'Lesotho Loti', sign: 'M' },
  { value: 'LTL', title: 'Lithuanian Litas', sign: 'Lt' },
  { value: 'LVL', title: 'Latvian Lats', sign: 'Ls' },
  { value: 'LYD', title: 'Libyan Dinar', sign: 'LD' },
  { value: 'MAD', title: 'Moroccan Dirham', sign: 'MAD' },
  { value: 'MDL', title: 'Moldovan Leu', sign: 'MDL' },
  { value: 'MGA', title: 'Malagasy Ariary', sign: 'FMG' },
  { value: 'MKD', title: 'Macedonian Denar', sign: 'MKD' },
  { value: 'MMK', title: 'Myanma Kyat', sign: 'K' },
  { value: 'MNT', title: 'Mongolian Tugrik', sign: '₮' },
  { value: 'MOP', title: 'Macanese Pataca', sign: 'P' },
  { value: 'MRO', title: 'Mauritanian Ouguiya', sign: 'UM' },
  { value: 'MUR', title: 'Mauritian Rupee', sign: 'Rs' },
  { value: 'MVR', title: 'Maldivian Rufiyaa', sign: 'Rf' },
  { value: 'MWK', title: 'Malawian Kwacha', sign: 'MK' },
  { value: 'MXN', title: 'Mexican peso', sign: 'Mex$' },
  { value: 'MYR', title: 'Malaysian ringgit', sign: 'RM' },
  { value: 'MZN', title: 'Mozambican Metical', sign: 'MZN' },
  { value: 'NAD', title: 'Namibian Dollar', sign: 'N$' },
  { value: 'NGN', title: 'Nigerian Naira', sign: '₦' },
  { value: 'NIO', title: 'Nicaraguan Córdoba', sign: 'C$' },
  { value: 'NOK', title: 'Norwegian krone', sign: 'kr' },
  { value: 'NPR', title: 'Nepalese Rupee', sign: 'NRs' },
  { value: 'NZD', title: 'New Zealand dollar', sign: '$' },
  { value: 'OMR', title: 'Omani rial', sign: 'ر.ع' },
  { value: 'PAB', title: 'Panamanian Balboa', sign: 'B./' },
  { value: 'PEN', title: 'Peruvian Nuevo Sol', sign: 'S/.' },
  { value: 'PGK', title: 'Papua New Guinean Kina', sign: 'K' },
  { value: 'PHP', title: 'Philippine peso', sign: '₱' },
  { value: 'PKR', title: 'Pakistani Rupee', sign: 'Rs.' },
  { value: 'PLN', title: 'Polish Zloty', sign: 'zł' },
  { value: 'PYG', title: 'Paraguayan Guarani', sign: '₲' },
  { value: 'QAR', title: 'Qatari Rial', sign: 'QR' },
  { value: 'RON', title: 'Romanian Leu', sign: 'L' },
  { value: 'RSD', title: 'Serbian Dinar', sign: 'din.' },
  { value: 'RWF', title: 'Rwandan Franc', sign: 'RWF' },
  { value: 'SAR', title: 'Saudi Riyal', sign: 'SR' },
  { value: 'SBD', title: 'Solomon Islands Dollar', sign: 'SI$' },
  { value: 'SCR', title: 'Seychellois Rupee', sign: 'SR' },
  { value: 'SDG', title: 'Sudanese Pound', sign: 'SDG' },
  { value: 'SEK', title: 'Swedish krona', sign: 'kr' },
  { value: 'SGD', title: 'Singapore dollar', sign: '$' },
  { value: 'SHP', title: 'Saint Helena Pound', sign: '£' },
  { value: 'SLL', title: 'Sierra Leonean Leone', sign: 'Le' },
  { value: 'SOS', title: 'Somali Shilling', sign: 'Sh.' },
  { value: 'SRD', title: 'Surinamese Dollar', sign: '$' },
  { value: 'STD', title: 'São Tomé and Príncipe Dobra', sign: 'STD' },
  { value: 'SVC', title: 'Salvadoran Colón', sign: 'SVC' },
  { value: 'SYP', title: 'Syrian Pound', sign: 'LS' },
  { value: 'SZL', title: 'Swazi Lilangeni', sign: 'E' },
  { value: 'THB', title: 'Thai baht', sign: '฿' },
  { value: 'TJS', title: 'Tajikistani Somoni', sign: 'TJS' },
  { value: 'TMT', title: 'Turkmenistani Manat', sign: 'm' },
  { value: 'TND', title: 'Tunisian Dinar', sign: 'DT' },
  { value: 'TOP', title: 'Tongan Paʻanga', sign: 'TOP' },
  { value: 'TRY', title: 'Turkish lira', sign: '₺' },
  { value: 'TTD', title: 'Trinidad and Tobago Dollar', sign: 'TT$' },
  { value: 'TWD', title: 'New Taiwan dollar', sign: 'NT$' },
  { value: 'TZS', title: 'Tanzanian Shilling', sign: 'TZS' },
  { value: 'UAH', title: 'Ukrainian hryvnia ', sign: '₴' },
  { value: 'UGX', title: 'Ugandan Shilling', sign: 'USh' },
  { value: 'UYU', title: 'Uruguayan Peso', sign: '$U' },
  { value: 'UZS', title: 'Uzbekistan Som', sign: 'UZS' },
  { value: 'VEF', title: 'Venezuelan Bolívar Fuerte', sign: 'VEF' },
  { value: 'VND', title: 'Vietnamese dong', sign: '₫' },
  { value: 'VUV', title: 'Vanuatu Vatu', sign: 'VT' },
  { value: 'WST', title: 'Samoan Tala', sign: 'WS$' },
  { value: 'XAF', title: 'CFA Franc BEAC', sign: 'CFA' },
  { value: 'XAG', title: 'Silver (troy ounce)', sign: 'XAG' },
  { value: 'XAU', title: 'Gold (troy ounce)', sign: 'XAU' },
  { value: 'XCD', title: 'East Caribbean Dollar', sign: 'EC$' },
  { value: 'XDR', title: 'Special Drawing Rights', sign: 'SDR' },
  { value: 'XOF', title: 'CFA Franc BCEAO', sign: 'CFA' },
  { value: 'XPF', title: 'CFP Franc', sign: 'F' },
  { value: 'YER', title: 'Yemeni Rial', sign: 'YER' },
  { value: 'ZAR', title: 'South African rand', sign: 'R' },
  { value: 'ZMK', title: 'Zambian Kwacha (pre-2013)', sign: 'ZK' },
  { value: 'ZMW', title: 'Zambian Kwacha', sign: 'ZMW' },
  { value: 'ZWL', title: 'Zimbabwean Dollar', sign: 'ZWL' },
];

// Default user settings
export const DEFAULT_ALLOWED_SLIPPAGE = 50;
export const DEFAULT_TRANSACTION_EXPIRY = 60 * 30;

const Mainnet = `${packageJson.homepage || '/'}images/ethereum.svg`;
const Polygon = `${packageJson.homepage || '/'}images/polygon.svg`;
const Binance = `${packageJson.homepage || '/'}images/binance.svg`;
const Avalanche = `${packageJson.homepage || '/'}images/avalanche.svg`;
const Fantom = `${packageJson.homepage || '/'}images/fantom.svg`;
const Celo = `${packageJson.homepage || '/'}images/celo.svg`;
const Optimism = `${packageJson.homepage || '/'}images/optimism.svg`;
const Oec = `${packageJson.homepage || '/'}images/oec.svg`;
const XDai = `${packageJson.homepage || '/'}images/x-dai.svg`;
const Heco = `${packageJson.homepage || '/'}images/heco.svg`;
const Moonriver = `${packageJson.homepage || '/'}images/moonriver.svg`;
const Arbitrum = `${packageJson.homepage || '/'}images/arbitrum.svg`;
const Boba = `${packageJson.homepage || '/'}images/boba.svg`;
const Cronos = `${packageJson.homepage || '/'}images/cronos.svg`;
const Metis = `${packageJson.homepage || '/'}images/metis.svg`;
const Aurora = `${packageJson.homepage || '/'}images/aurora.svg`;
const Moonbeam = `${packageJson.homepage || '/'}images/moonbeam.svg`;
const Bttc = `${packageJson.homepage || '/'}images/bttc.svg`;
const smartBCH = `${packageJson.homepage || '/'}images/smart-bch.svg`;

export const NETWORK_ICON: { [chainId in AllChainId]: string } = {
  [ChainId.MAINNET]: Mainnet,
  [ChainId.ROPSTEN]: Mainnet,
  [ChainId.RINKEBY]: Mainnet,
  [ChainId.GÖRLI]: Mainnet,
  [ChainId.KOVAN]: Mainnet,
  [ChainId.MATIC]: Polygon,
  [ChainId.BSC]: Binance,
  [ChainId.BSC_TEST]: Binance,
  [ChainId.AVALANCHE]: Avalanche,
  [ChainId.FANTOM]: Fantom,
  [ChainId.CELO]: Celo,
  [ChainId.OPTIMISM]: Optimism,
  [ChainId.OPTIMISM_KOVAN]: Optimism,
  [ExtraChainId.OEC]: Oec,
  [ExtraChainId.XDAI]: XDai,
  [ExtraChainId.HECO]: Heco,
  [ExtraChainId.MOONRIVER]: Moonriver,
  [ExtraChainId.ARBITRUM]: Arbitrum,
  [ExtraChainId.BOBA]: Boba,
  [ExtraChainId.CRONOS]: Cronos,
  [ExtraChainId.METIS]: Metis,
  [ExtraChainId.AURORA]: Aurora,
  [ExtraChainId.MOONBEAM]: Moonbeam,
  [ExtraChainId.BTTC]: Bttc,
  [ExtraChainId.SMARTBCH]: smartBCH,
};

// For the long token symbol display
export const TOKEN_SYMBOL_MAP: { [key: string]: string } = {
  '0x5dbcf33d8c2e976c6b560249878e6f1491bca25c': 'curve.fi/y LP',
  '0xdf5e0e81dff6faf3a7e52ba697820c5e32d806a8': 'yCRV',
  '0x2994529c0652d127b7842094103715ec5299bbed': 'curve.fi/busd LP',
  '0x3b3ac5386837dc563660fb6a0937dfaa5924333b': 'crvBUSD',
  '0x7ff566e1d69deff32a7b244ae7276b9f90e9d0f6': 'curve.fi/sbtc LP',
  '0x075b1bb99792c9e1041ba13afef80c91a1e70fb3': 'crvBTC',
  '0x9ca85572e6a3ebf24dedd195623f188735a5179f': 'curve.fi/3pool LP',
  '0x6c3f90f043a72fa612cbac8115ee7e52bde6e490': 'y3Crv',
  '0xb6741944125082c7a73fc401733fc941b701f31e': 'xPPAY',
  '0x9fd1d329bb687fef164f529f6f6dcd6f69e7b978': 'xPPAY',
  '0xE4fA3C576c31696322e8d7165C5965d5a1F6A1A5': 'GFX',
};

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const TOKENS: { [chainId in ChainId]: { [symbol: string]: Token } } = {
  [ChainId.MAINNET]: {
    PPAY: new Token(ChainId.MAINNET, getAddress('0x054d64b73d3d8a21af3d764efd76bcaa774f3bb2'), 18, 'PPAY', 'Plasma'),
    XPPAY: new Token(ChainId.MAINNET, getAddress('0x9fd1d329bb687fef164f529f6f6dcd6f69e7b978'), 18, 'XPPAY', 'PPAY Staking'),
    DAI: new Token(ChainId.MAINNET, getAddress('0x6b175474e89094c44da98b954eedeac495271d0f'), 18, 'DAI', 'Dai Stablecoin'),
    USDC: new Token(ChainId.MAINNET, getAddress('0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'), 6, 'USDC', 'USD Coin'),
    USDT: new Token(ChainId.MAINNET, getAddress('0xdac17f958d2ee523a2206206994597c13d831ec7'), 6, 'USDT', 'Tether USD'),
    COMP: new Token(ChainId.MAINNET, getAddress('0xc00e94Cb662C3520282E6f5717214004A7f26888'), 18, 'COMP', 'Compound'),
    MKR: new Token(ChainId.MAINNET, getAddress('0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2'), 18, 'MKR', 'Maker'),
    AMPL: new Token(ChainId.MAINNET, getAddress('0xD46bA6D942050d489DBd938a2C909A5d5039A161'), 9, 'AMPL', 'Ampleforth'),
    WBTC: new Token(ChainId.MAINNET, getAddress('0x2260fac5e5542a773aa44fbcfedf7c193bc2c599'), 8, 'WBTC', 'Wrapped BTC'),
    TUSD: new Token(ChainId.MAINNET, getAddress('0x0000000000085d4780B73119b644AE5ecd22b376'), 18, 'TUSD', 'TrueUSD'),
    BUSD: new Token(ChainId.MAINNET, getAddress('0x4Fabb145d64652a948d72533023f6E7A623C7C53'), 18, 'BUSD', 'Binance USD'),
    BNB: new Token(ChainId.MAINNET, getAddress('0xb8c77482e45f1f44de1745f52c74426c631bdd52'), 18, 'BNB', 'Binance Coin'),
    PBK: new Token(ChainId.MAINNET, getAddress('0x560a20eddeddf84217221aef0d5ca7d7ae7ae798'), 2, 'PBK', 'PlasmaBank token'),
    GFX: new Token(ChainId.MAINNET, getAddress('0xE4fA3C576c31696322e8d7165C5965d5a1F6A1A5'), 18, 'GFX', 'GamyFi'),
    UNI: new Token(ChainId.MAINNET, getAddress('0x1f9840a85d5af5bf1d1762f925bdaddc4201f984'), 18, 'UNI', 'Uniswap'),
    UST: new Token(ChainId.MAINNET, getAddress('0xa47c8bf37f92aBed4A126BDA807A7b7498661acD'), 18, 'UST', 'Terra USD'),
  },
  [ChainId.MATIC]: {
    PPAY: new Token(ChainId.MATIC, getAddress('0x08158a6b5d4018340387d1a302f882e98a8bc5b4'), 18, 'PPAY', 'Plasma'),
    USDC: new Token(ChainId.MATIC, getAddress('0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174'), 6, 'USDC', 'USD Coin'),
    USDT: new Token(ChainId.MATIC, getAddress('0xc2132D05D31c914a87C6611C10748AEb04B58e8F'), 6, 'USDT', 'Tether USD'),
    DAI: new Token(ChainId.MATIC, getAddress('0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063'), 18, 'DAI', 'Dai Stablecoin'),
    WBTC: new Token(ChainId.MATIC, getAddress('0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6'), 18, 'WBTC', 'Wrapped BTC'),
    UNI: new Token(ChainId.MATIC, getAddress('0xb33EaAd8d922B1083446DC23f610c2567fB5180f'), 18, 'UNI', 'Uniswap'),
    UST: new Token(ChainId.MATIC, getAddress('0x692597b009d13C4049a947CAB2239b7d6517875F'), 18, 'UST', 'Terra USD'),
  },
  [ChainId.BSC]: {
    PPAY: new Token(ChainId.BSC, getAddress('0xfb288d60d3b66f9c3e231a9a39ed3f158a4269aa'), 18, 'PPAY', 'Plasma'),
    USDC: new Token(ChainId.BSC, getAddress('0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'), 18, 'USDC', 'Binance-Peg USDC Coin'),
    ETH: new Token(ChainId.BSC, getAddress('0x2170Ed0880ac9A755fd29B2688956BD959F933F8'), 18, 'ETH', 'Binance-Peg Ethereum Token'),
    USDT: new Token(ChainId.BSC, getAddress('0x55d398326f99059ff775485246999027b3197955'), 18, 'USDT', 'Binance-Peg BSC-USDT'),
    BUSD: new Token(ChainId.BSC, getAddress('0xe9e7cea3dedca5984780bafc599bd69add087d56'), 18, 'BUSD', 'Binance-Peg BUSD Token'),
    DAI: new Token(ChainId.BSC, getAddress('0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3'), 18, 'DAI', 'Binance-Peg Dai Token'),
    UST: new Token(ChainId.BSC, getAddress('0x23396cf899ca06c4472205fc903bdb4de249d6fc'), 18, 'UST', 'Wrapped UST Token'),
    BTC: new Token(ChainId.BSC, getAddress('0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c'), 18, 'BTC', 'Binance-Peg BTCB Token'),
  },
  [ChainId.RINKEBY]: {
    USDC: new Token(ChainId.RINKEBY, getAddress('0x4dbcdf9b62e891a7cec5a2568c3f4faf9e8abe2b'), 6, 'USDC', 'USD Coin'),
    USDT: new Token(ChainId.RINKEBY, getAddress('0xD9BA894E0097f8cC2BBc9D24D308b98e36dc6D02'), 18, 'USDT', 'Compound USDT'),
  },
  [ChainId.ROPSTEN]: {
    TTA: new Token(ChainId.ROPSTEN, getAddress('0xf84830b73b2ed3c7267e7638f500110ea47fdf30'), 18, 'TTA', 'Test Token A'),
    USDC: new Token(ChainId.ROPSTEN, getAddress('0x07865c6e87b9f70255377e024ace6630c1eaa37f'), 6, 'USDC', 'USD Coin'),
    DAI: new Token(ChainId.ROPSTEN, getAddress('0xad6d458402f60fd3bd25163575031acdce07538d'), 18, 'DAI', 'Dai Stablecoin'),
    COMP: new Token(ChainId.MAINNET, getAddress('0xf76D4a441E4ba86A923ce32B89AFF89dBccAA075'), 18, 'COMP', 'Compound'),
  },
  [ChainId.GÖRLI]: {
    USDC: new Token(ChainId.GÖRLI, getAddress('0xd87ba7a50b2e7e660f678a895e4b72e7cb4ccd9c'), 6, 'USDC', 'USD Coin'),
  },
  [ChainId.KOVAN]: {
    PPAY: new Token(ChainId.KOVAN, getAddress('0x80593cc42d56ee7feffdb7b040aa7ee596accdea'), 18, 'PPAY', 'PlasmaPay'),
    XPPAY: new Token(ChainId.KOVAN, getAddress('0xb6741944125082c7a73fc401733fc941b701f31e'), 18, 'XPPAY', 'PPAY Staking'),
    USDC: new Token(ChainId.KOVAN, getAddress('0x6eac80ad4a8fc514a5082ab52b254d91edbd0155'), 6, 'USDC', 'USD Coin'),
    DAI: new Token(ChainId.KOVAN, getAddress('0xff795577d9ac8bd7d90ee22b6c1703490b6512fd'), 18, 'DAI', 'Dai Stablecoin'),
    USDT: new Token(ChainId.KOVAN, getAddress('0x13512979ade267ab5100878e2e0f485b568328a4'), 6, 'USDT', 'Tether USD'),
    REP: new Token(ChainId.KOVAN, getAddress('0x4E5cB5A0CAca30d1ad27D8CD8200a907854FB518'), 18, 'REP', 'Augur Reputation'),
    EIP712: new Token(ChainId.KOVAN, getAddress('0xf38924f86564BAf7FeC409B7c13f0fED5220d253'), 18, 'EIP712', 'Token with EIP712 support'),
  },
  [ChainId.BSC_TEST]: {
    BUSD: new Token(ChainId.BSC_TEST, getAddress('0x78867bbeef44f2326bf8ddd1941a4439382ef2a7'), 18, 'BUSD', 'BUSD Token'),
    USDT: new Token(ChainId.BSC_TEST, getAddress('0x7ef95a0fee0dd31b22626fa2e10ee6a223f8a684'), 18, 'USDT', 'Tether USD'),
    DAI: new Token(ChainId.BSC_TEST, getAddress('0x8a9424745056eb399fd19a0ec26a14316684e274'), 18, 'DAI', 'Dai Token'),
    CAKE: new Token(ChainId.BSC_TEST, getAddress('0xf9f93cf501bfadb6494589cb4b4c15de49e85d0e'), 18, 'CAKE', 'PancakeSwap Token'),
  },
  [ChainId.AVALANCHE]: {
    USDT: new Token(ChainId.AVALANCHE, getAddress('0xc7198437980c041c805a1edcba50c1ce5db95118'), 6, 'USDT', 'Tether USDT.e'),
    USDC: new Token(ChainId.AVALANCHE, getAddress('0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664'), 6, 'USDC', 'USD Coin'),
    DAI: new Token(ChainId.AVALANCHE, getAddress('0xd586E7F844cEa2F87f50152665BCbc2C279D8d70'), 18, 'DAI', 'Dai Stablecoin'),
    WBTC: new Token(ChainId.AVALANCHE, getAddress('0x50b7545627a5162F82A992c33b87aDc75187B218'), 8, 'WBTC', 'Wrapped Bitcoin'),
    WETH: new Token(ChainId.AVALANCHE, getAddress('0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB'), 18, 'WETH', 'Wrapped Ether'),
  },
  [ChainId.FANTOM]: {
    USDT: new Token(ChainId.FANTOM, getAddress('0x049d68029688eabf473097a2fc38ef61633a3c7a'), 6, 'USDT', 'Frapped USDT'),
    USDC: new Token(ChainId.FANTOM, getAddress('0x04068DA6C83AFCFA0e13ba15A6696662335D5B75'), 6, 'USDC', 'USD Coin'),
    WBTC: new Token(ChainId.FANTOM, getAddress('0x321162Cd933E2Be498Cd2267a90534A804051b11'), 8, 'WBTC', 'Wrapped Bitcoin'),
    DAI: new Token(ChainId.FANTOM, getAddress('0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E'), 18, 'DAI', 'Dai Stablecoin'),
    WETH: new Token(ChainId.FANTOM, getAddress('0x74b23882a30290451A17c44f4F05243b6b58C76d'), 18, 'WETH', 'Wrapped Ether'),
  },
  [ChainId.CELO]: {
    mCUSD: new Token(ChainId.CELO, getAddress('0x64dEFa3544c695db8c535D289d843a189aa26b98'), 18, 'mCUSD', 'Moola cUSD'),
    mCELO: new Token(ChainId.CELO, getAddress('0x7037F7296B2fc7908de7b57a89efaa8319f0C500'), 18, 'mCELO', 'Moola CELO'),
    mcEURO: new Token(ChainId.CELO, getAddress('0xa8d0E6799FF3Fd19c6459bf02689aE09c4d78Ba7'), 18, 'mCEUR', 'Moola Celo Euro'),
    cUSD: new Token(ChainId.CELO, getAddress('0x765DE816845861e75A25fCA122bb6898B8B1282a'), 18, 'cUSD', 'Celo Dollar'),
    cEURO: new Token(ChainId.CELO, getAddress('0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73'), 18, 'cEUR', 'Celo Euro'),
    cBTC: new Token(ChainId.CELO, getAddress('0xD629eb00dEced2a080B7EC630eF6aC117e614f1b'), 18, 'cBTC', 'Wrapped Bitcoin'),
    cETH: new Token(ChainId.CELO, getAddress('0x2DEf4285787d58a2f811AF24755A8150622f4361'), 18, 'cETH', 'Wrapped Ether'),
    WETH: new Token(ChainId.CELO, getAddress('0xE919F65739c26a42616b7b8eedC6b5524d1e3aC4'), 18, 'WETH', 'WETH'),
    WBTC: new Token(ChainId.CELO, getAddress('0xBe50a3013A1c94768A1ABb78c3cB79AB28fc1aCE'), 8, 'WBTC', 'WBTC'),
    USDC: new Token(ChainId.CELO, getAddress('0xef4229c8c3250C675F21BCefa42f58EfbfF6002a'), 6, 'USDC', 'USD Coin'),
  },
  [ChainId.OPTIMISM]: {
    USDT: new Token(ChainId.OPTIMISM, getAddress('0x94b008aa00579c1307b0ef2c499ad98a8ce58e58'), 6, 'USDT', 'Tether USD'),
  },
  [ChainId.OPTIMISM_KOVAN]: {
    USDC: new Token(ChainId.OPTIMISM_KOVAN, getAddress('0x3e22e37cb472c872b5de121134cfd1b57ef06560'), 6, 'USDC', 'Test USDC'),
    DAI: new Token(ChainId.OPTIMISM_KOVAN, getAddress('0xda10009cbd5d07dd0cecc66161fc93d7c9000da1'), 18, 'DAI', 'Dai Stablecoin'),
  },
};

/**
 * Used for display in the default list when adding liquidity
 */
export const SUGGESTED_BASES: { [chainId in ChainId]: Token[] } = {
  [ChainId.MAINNET]: [
    WNATIVE[ChainId.MAINNET],
    TOKENS[ChainId.MAINNET]['DAI'],
    TOKENS[ChainId.MAINNET]['USDC'],
    TOKENS[ChainId.MAINNET]['USDT'],
    TOKENS[ChainId.MAINNET]['UST'],
    TOKENS[ChainId.MAINNET]['PPAY'],
    TOKENS[ChainId.MAINNET]['UNI'],
    TOKENS[ChainId.MAINNET]['WBTC'],
  ],
  [ChainId.ROPSTEN]: [WNATIVE[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WNATIVE[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WNATIVE[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [
    WNATIVE[ChainId.KOVAN],
    TOKENS[ChainId.KOVAN]['PPAY'],
    TOKENS[ChainId.KOVAN]['XPPAY'],
    TOKENS[ChainId.KOVAN]['USDC'],
    TOKENS[ChainId.KOVAN]['DAI'],
    TOKENS[ChainId.KOVAN]['REP'],
  ],
  [ChainId.MATIC]: [
    WNATIVE[ChainId.MATIC],
    TOKENS[ChainId.MATIC]['DAI'],
    TOKENS[ChainId.MATIC]['USDC'],
    TOKENS[ChainId.MATIC]['USDT'],
    TOKENS[ChainId.MATIC]['UST'],
    TOKENS[ChainId.MATIC]['PPAY'],
    TOKENS[ChainId.MATIC]['WBTC'],
    TOKENS[ChainId.MATIC]['UNI'],
  ],
  [ChainId.BSC]: [
    WNATIVE[ChainId.BSC],
    TOKENS[ChainId.BSC]['ETH'],
    TOKENS[ChainId.BSC]['USDT'],
    TOKENS[ChainId.BSC]['BUSD'],
    TOKENS[ChainId.BSC]['USDC'],
    TOKENS[ChainId.BSC]['DAI'],
    TOKENS[ChainId.BSC]['UST'],
    TOKENS[ChainId.BSC]['BTC'],
    TOKENS[ChainId.BSC]['PPAY'],
  ],
  [ChainId.BSC_TEST]: [
    WNATIVE[ChainId.BSC_TEST],
    TOKENS[ChainId.BSC_TEST]['USDT'],
    TOKENS[ChainId.BSC_TEST]['BUSD'],
    TOKENS[ChainId.BSC_TEST]['CAKE'],
    TOKENS[ChainId.BSC_TEST]['DAI'],
  ],
  [ChainId.AVALANCHE]: [
    WNATIVE[ChainId.AVALANCHE],
    TOKENS[ChainId.AVALANCHE]['USDT'],
    TOKENS[ChainId.AVALANCHE]['USDC'],
    TOKENS[ChainId.AVALANCHE]['DAI'],
    TOKENS[ChainId.AVALANCHE]['WBTC'],
    TOKENS[ChainId.AVALANCHE]['WETH'],
  ],
  [ChainId.FANTOM]: [
    WNATIVE[ChainId.FANTOM],
    TOKENS[ChainId.FANTOM]['USDT'],
    TOKENS[ChainId.FANTOM]['USDC'],
    TOKENS[ChainId.FANTOM]['WBTC'],
    TOKENS[ChainId.FANTOM]['DAI'],
    TOKENS[ChainId.FANTOM]['WETH'],
  ],
  [ChainId.CELO]: [WNATIVE[ChainId.CELO], TOKENS[ChainId.CELO]['cUSD']],
  [ChainId.OPTIMISM]: [WNATIVE[ChainId.OPTIMISM], TOKENS[ChainId.OPTIMISM]['USDT']],
  [ChainId.OPTIMISM_KOVAN]: [WNATIVE[ChainId.OPTIMISM_KOVAN], TOKENS[ChainId.OPTIMISM_KOVAN]['vUSD']],
};

export const BLACKLISTED_TOKENS: { [chainId in ChainId]: string[] } = {
  [ChainId.MAINNET]: ['0x57Ab1E02fEE23774580C119740129eAC7081e9D3'],
  [ChainId.ROPSTEN]: [],
  [ChainId.RINKEBY]: [],
  [ChainId.GÖRLI]: [],
  [ChainId.KOVAN]: [],
  [ChainId.MATIC]: ['0x0000000000000000000000000000000000001010'],
  [ChainId.BSC]: [],
  [ChainId.BSC_TEST]: [],
  [ChainId.AVALANCHE]: [],
  [ChainId.CELO]: [],
  [ChainId.FANTOM]: [],
  [ChainId.OPTIMISM]: [],
  [ChainId.OPTIMISM_KOVAN]: [],
};

export const MAX_UINT_256 = Big(2).pow(256).minus(1);
export const MAX_UINT_PE = 78;

export const XPPAY_EXCHANGE_CONTRACT_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: '0x92f6C8f24C59A4857CD74C0CC4a8486992Bb505c',
  [ChainId.KOVAN]: '0x525d7eb9c3dC61838906531B4a6A45cd6248CE21',
};

/**
 * Used to construct intermediary pairs for trading
 */
export const BASES_TO_CHECK_TRADES_AGAINST: { [chainId in ChainId]: Token[] } = {
  [ChainId.MAINNET]: [
    WNATIVE[ChainId.MAINNET],
    TOKENS[ChainId.MAINNET]['DAI'],
    TOKENS[ChainId.MAINNET]['USDC'],
    TOKENS[ChainId.MAINNET]['USDT'],
    TOKENS[ChainId.MAINNET]['PPAY'],
    TOKENS[ChainId.MAINNET]['UST'],
  ],
  [ChainId.ROPSTEN]: [WNATIVE[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WNATIVE[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WNATIVE[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WNATIVE[ChainId.KOVAN]],
  [ChainId.MATIC]: [WNATIVE[ChainId.MATIC]],
  [ChainId.BSC]: [
    WNATIVE[ChainId.BSC],
    TOKENS[ChainId.BSC]['ETH'],
    TOKENS[ChainId.BSC]['DAI'],
    TOKENS[ChainId.BSC]['USDC'],
    TOKENS[ChainId.BSC]['USDT'],
    TOKENS[ChainId.BSC]['BUSD'],
    TOKENS[ChainId.BSC]['PPAY'],
    TOKENS[ChainId.BSC]['UST'],
    TOKENS[ChainId.BSC]['BTC'],
  ],
  [ChainId.BSC_TEST]: [WNATIVE[ChainId.BSC_TEST], TOKENS[ChainId.BSC_TEST]['BUSD'], TOKENS[ChainId.BSC_TEST]['CAKE']],
  [ChainId.AVALANCHE]: [WNATIVE[ChainId.AVALANCHE]],
  [ChainId.FANTOM]: [WNATIVE[ChainId.FANTOM]],
  [ChainId.CELO]: [WNATIVE[ChainId.CELO]],
  [ChainId.OPTIMISM]: [WNATIVE[ChainId.OPTIMISM]],
  [ChainId.OPTIMISM_KOVAN]: [WNATIVE[ChainId.OPTIMISM_KOVAN]],
};

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    [TOKENS[ChainId.MAINNET]['AMPL'].address]: [TOKENS[ChainId.MAINNET]['DAI'], WNATIVE[ChainId.MAINNET]],
  },
};

export const NATIVE_ADDRESSES_LIST: string[] = Array.from(new Set(Object.values(NATIVE_ADDRESSES)));

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)); // .01 ETH

export const REFERRALS_DEADLINE = 2592000 * 3; // Three mouth

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000));
export const BIPS_BASE = JSBI.BigInt(10000);

// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE); // 5%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE); // 15%

/**
 * These tokens are used to fill out the form of any swap, if nothing is selected.
 */
export const DEFAULT_TO_TOKEN: { [chainId in ChainId]: Token } = {
  [ChainId.MAINNET]: TOKENS[ChainId.MAINNET]['USDC'],
  [ChainId.MATIC]: TOKENS[ChainId.MATIC]['USDC'],
  [ChainId.BSC]: TOKENS[ChainId.BSC]['BUSD'],
  [ChainId.RINKEBY]: TOKENS[ChainId.RINKEBY]['USDC'],
  [ChainId.ROPSTEN]: TOKENS[ChainId.ROPSTEN]['USDC'],
  [ChainId.GÖRLI]: TOKENS[ChainId.GÖRLI]['USDC'],
  [ChainId.KOVAN]: TOKENS[ChainId.KOVAN]['PPAY'],
  [ChainId.BSC_TEST]: TOKENS[ChainId.BSC_TEST]['USDT'],
  [ChainId.AVALANCHE]: TOKENS[ChainId.AVALANCHE]['USDC'],
  [ChainId.FANTOM]: TOKENS[ChainId.FANTOM]['USDC'],
  [ChainId.CELO]: TOKENS[ChainId.CELO]['USDC'],
  [ChainId.OPTIMISM]: TOKENS[ChainId.OPTIMISM]['USDT'],
  [ChainId.OPTIMISM_KOVAN]: TOKENS[ChainId.OPTIMISM_KOVAN]['vUSD'],
};

export const DEVICE_TYPE: DeviceType = isDesktop ? DeviceType.DESKTOP : isAndroid ? DeviceType.ANDROID : DeviceType.IPHONE;

export const PORTFOLIO_CHAIN_NAME: { [ChainId in UnmarshalSupportedChainId]: string } = {
  [ChainId.MAINNET]: 'eth',
  [ChainId.BSC]: 'bsc',
  [ChainId.MATIC]: 'matic',
  [ExtraChainId.XDAI]: 'xdai',
  [ChainId.FANTOM]: 'ftm',
  [ExtraChainId.OEC]: 'okt',
  [ExtraChainId.HECO]: 'heco',
  [ChainId.AVALANCHE]: 'avax',
  [ChainId.OPTIMISM]: 'op',
  [ExtraChainId.ARBITRUM]: 'arb',
  [ChainId.CELO]: 'celo',
  [ExtraChainId.MOONRIVER]: 'movr',
  [ExtraChainId.BOBA]: 'boba',
  [ExtraChainId.CRONOS]: 'cro',
  [ExtraChainId.METIS]: 'metis',
  [ExtraChainId.AURORA]: 'aurora',
  [ExtraChainId.MOONBEAM]: 'mobm',
  [ExtraChainId.SMARTBCH]: 'sbch',
  [ExtraChainId.BTTC]: 'btt',
};

export const ETH_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
export const MATIC_ADDRESS = '0x0000000000000000000000000000000000001010';
export const FANTOM_ADDRESS = '0x4e15361fd6b4bb609fa63c81a2be19d873717870';
export const AVALANCHE_ADDRESS = '0x73a312acefb4730a30d18c40aebc617d93c1bfbb';
export const BSC_ADDRESS = '0xB8c77482e45F1F44dE1745F52C74426C631bDD52';
export const XDAI_ADDRESS = '0x0ae055097c6d159879521c384f1d2123d1f195e6';
export const CELO_ADDRESS = '0x471ece3750da237f93b8e339c536989b8978a438';
export const ARBITRUM_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
export const MOONRIVER_ADDRESS = '0x98878b06940ae243284ca214f92bb71a2b032b8a';
export const OEC_ADDRESS = '0x31b820da47b4ebad81f653fdf32952d1bc1bc469';
export const HECO_ADDRESS = '0xa71EdC38d189767582C38A3145b5873052c3e47a';
export const OPTIMISM_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
export const BOBA_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
export const CRONOS_ADDRESS = '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b';
export const METIS_ADDRESS = '0x9e32b13ce7f2e80a01932b42553652e053d6ed8e';
export const AURORA_ADDRESS = '0x5a3b6f18dc79d50ab208af2fcd61d10bf7e4896f';
export const MOONBEAM_ADDRESS = '0xea0626c5c424879a964b3dcc96bbbb2bad184888';
export const BTTC_ADDRESS = '0xe83cccfabd4ed148903bf36d4283ee7c8b3494d1';
export const SMARTBCH_ADDRESS = '0x45a5c4c5e45dbc9b8981465d158b9529ef8c6b82';

export const NATIVE_ADDRESS: { [key: string]: string } = {
  eth: ETH_ADDRESS,
  bsc: MATIC_ADDRESS,
  ftm: FANTOM_ADDRESS,
  avax: AVALANCHE_ADDRESS,
  matic: MATIC_ADDRESS,
  xdai: XDAI_ADDRESS,
  celo: CELO_ADDRESS,
  arb: ARBITRUM_ADDRESS,
  movr: MOONRIVER_ADDRESS,
  okt: OEC_ADDRESS,
  heco: HECO_ADDRESS,
  op: OPTIMISM_ADDRESS,
  boba: BOBA_ADDRESS,
  cro: CRONOS_ADDRESS,
  metis: METIS_ADDRESS,
  aurora: AURORA_ADDRESS,
  mobm: MOONBEAM_ADDRESS,
  btt: BTTC_ADDRESS,
  sbch: SMARTBCH_ADDRESS,
};
