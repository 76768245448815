import { ChainId, NETWORK_NAME } from '@plasma/plasmaswap-sdk';
import { AllChainId, ExtraChainId } from '../types';

export const getNetworkName = (chainId: AllChainId, shortName?: boolean): string => {
  const chainName = (chainId: number) => {
    switch (chainId) {
      case ExtraChainId.XDAI:
        return 'Gnosis Chain';
      case ChainId.FANTOM:
        return 'Fantom';
      case ExtraChainId.OEC:
        return 'OEC';
      case ExtraChainId.HECO:
        return 'HECO';
      case ChainId.AVALANCHE:
        return 'Avalanche';
      case ChainId.OPTIMISM:
        return 'Optimism';
      case ExtraChainId.ARBITRUM:
        return 'Arbitrum';
      case ExtraChainId.MOONRIVER:
        return 'Moonriver';
      case ChainId.CELO:
        return 'CELO';
      case ExtraChainId.BOBA:
        return 'Boba';
      case ExtraChainId.CRONOS:
        return 'Cronos';
      case ExtraChainId.METIS:
        return 'Metis';
      case ExtraChainId.AURORA:
        return 'Aurora';
      case ExtraChainId.MOONBEAM:
        return 'Moonbeam';
      case ExtraChainId.BTTC:
        return 'BTTC';
      case ExtraChainId.SMARTBCH:
        return 'smartBCH';
      case ChainId.BSC:
        return shortName ? 'BSC' : NETWORK_NAME[chainId];
      case ChainId.MAINNET:
        return shortName ? 'Ethereum' : NETWORK_NAME[chainId];
      default:
        return null;
    }
  };

  return chainName(chainId) || NETWORK_NAME[chainId as ChainId] || '';
};
