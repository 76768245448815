import { useEffect, useState } from 'react';
import { bridgeSocketTokenPrice, getCoingeckoCurrencyPrices } from '../../api';
import { REN_EVM_ASSET_PRICE_CONF } from '../../constants';

/**
 * Returns REN asset USD price
 * @param assetSymbol
 */
export const useRenTokenPrice = (assetSymbol?: string): string | null | undefined => {
  const [price, setPrice] = useState<string | null | undefined>();

  useEffect(() => {
    if (!assetSymbol) {
      return setPrice(undefined);
    }

    const symbol = assetSymbol.replace(/^ren/, '');
    const conf = REN_EVM_ASSET_PRICE_CONF[symbol];
    if (!conf) {
      console.warn(`No REN asset price configuration for ${symbol}`);
      return setPrice(undefined);
    }

    const abortController = new AbortController();

    setPrice(null);

    if (conf.chainId && conf.address) {
      bridgeSocketTokenPrice(conf.address, conf.chainId, abortController.signal)
        .then(result => {
          setPrice(`${result.tokenPrice}`);
        })
        .catch(error => {
          if (error.name === 'AbortError') {
            return;
          }
          setPrice(undefined);
          console.error(error);
        });
    } else if (conf.coingeckoId) {
      getCoingeckoCurrencyPrices([conf.coingeckoId], abortController.signal)
        .then(result => {
          const price = result[conf.coingeckoId as string]?.usd;
          setPrice(price ? `${price}` : undefined);
        })
        .catch(error => {
          if (error.name === 'AbortError') {
            return;
          }
          setPrice(undefined);
          console.error(error);
        });
    } else {
      setPrice(undefined);
    }

    return () => {
      abortController.abort();
    };
  }, [assetSymbol]);

  return price;
};
