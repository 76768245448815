import { Signature, splitSignature } from '@ethersproject/bytes';
import { EIP712TypedData, SignMethod } from '@plasma/plasmaswap-sdk';
import { GsnWeb3Provider } from '../gsn-web3-provider/gsn-web3-provider';

/**
 * Sign an EIP712 typed data message with a specific address's private key (`eth_signTypedData`)
 * @param library
 * @param account
 * @param typedData
 * @param signMethod - forced choose signed method
 */
export async function signTypedData(library: GsnWeb3Provider, account: string, typedData: EIP712TypedData, signMethod?: SignMethod): Promise<Signature> {
  const methodsToTry = signMethod ? [signMethod] : ['eth_signTypedData_v4', 'eth_signTypedData_v3', 'eth_signTypedData'];
  let lastErr: Error | undefined;

  for (const method of methodsToTry) {
    try {
      return library.send(method, [account, method === 'eth_signTypedData' ? typedData : JSON.stringify(typedData)]).then(splitSignature);
    } catch (err: any) {
      lastErr = err;
      if (!/(not handled|does not exist|not supported)/.test(err.message)) {
        throw err;
      }
    }
  }
  throw lastErr;
}
