const validation = /^[+-]?\d+(\.\d+)?([eE]{1}[+-]{1}?\d+)?$/;

// Any type can be tested
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isNumber(value: any): boolean {
  if (typeof value === 'number') {
    return !isNaN(value);
  }

  return typeof value === 'string' ? validation.test(value) : false;
}
