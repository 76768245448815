import { id } from '@ethersproject/hash';
import { TokenAmount } from '@plasma/plasmaswap-sdk';
import { useMemo } from 'react';
import { ApprovalAmount } from '../../types';
import { useActiveWeb3React } from '../web3/use-active-web3-react';

/**
 * Approval id to save successful approve result (call data).
 */
export function useApprovalId(amount: ApprovalAmount, spender?: string): string | undefined {
  const { account } = useActiveWeb3React();

  return useMemo(() => {
    if (!account || !spender || !(amount instanceof TokenAmount)) {
      return undefined;
    }
    let salt = amount.token.chainId.toString();
    salt += `:${account.toLowerCase()}`;
    salt += `:${spender.toLowerCase()}`;
    salt += `:${amount.token.address.toLowerCase()}`;
    salt += `:${amount.raw.toString()}`;

    return id(salt);
  }, [account, spender, amount]);
}
