import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { PopupContent } from '../../types/common/popup-content';
import { addPopup } from './application.actions';

import { AppState } from '../index';
import { ApplicationState } from './application.reducer';

export function useBlockNumber(): number | undefined {
  const { chainId } = useActiveWeb3React();
  return useSelector((state: AppState) => state.application.block[chainId ?? -1]?.number);
}

export function useBlock(): ApplicationState['block'][number] | undefined {
  const { chainId } = useActiveWeb3React();
  return useSelector((state: AppState) => state.application.block[chainId ?? -1]);
}

/**
 * Returns a function that allows adding a popup
 */
export function useAddPopup(): (content: PopupContent, key?: string, removeAfterMs?: number | null) => void {
  const dispatch = useDispatch();

  return useCallback(
    (content: PopupContent, key?: string, removeAfterMs?: number | null) => {
      dispatch(addPopup({ content, key, removeAfterMs }));
    },
    [dispatch],
  );
}

// returns a function that allows removing a popup via its key
// export function useRemovePopup(): (key: string) => void {
//   const dispatch = useDispatch();
//   return useCallback(
//     (key: string) => {
//       dispatch(removePopup({ key }));
//     },
//     [dispatch],
//   );
// }

// get the list of active popups
// export function useActivePopups(): AppState['application']['popupList'] {
//   const list = useSelector((state: AppState) => state.application.popupList);
//   return useMemo(() => list.filter(item => item.show), [list]);
// }

// export function useMainMenuCollapsed(): boolean {
//   return useSelector<AppState, AppState['application']['mainMenuCollapsed']>(({ application: { mainMenuCollapsed } }) => mainMenuCollapsed);
// }

// export function useMobileMenuIsOpen(): boolean {
//   return useSelector<AppState, AppState['application']['mobileMenuIsOpen']>(({ application: { mobileMenuIsOpen } }) => mobileMenuIsOpen);
// }

// export function useMainMenuCollapseManager(): [boolean, (s?: boolean) => void] {
//   const dispatch = useDispatch<AppDispatch>();
//   const collapsed = useMainMenuCollapsed();

//   const setCollapsed = useCallback(
//     (state?: boolean) => {
//       if (state === undefined) {
//         dispatch(mainMenuCollapse(!collapsed));
//       } else {
//         dispatch(mainMenuCollapse(state));
//       }
//     },
//     [collapsed, dispatch],
//   );

//   return [collapsed, setCollapsed];
// }

// export function useMobileMenuIsOpenManager(): [boolean, (s?: boolean) => void] {
//   const dispatch = useDispatch<AppDispatch>();
//   const isOpen = useMobileMenuIsOpen();

//   const setIsOpen = useCallback(
//     (state?: boolean) => {
//       if (state === undefined) {
//         dispatch(mobileMenuToggle(!isOpen));
//       } else {
//         dispatch(mobileMenuToggle(state));
//       }
//     },
//     [isOpen, dispatch],
//   );

//   return [isOpen, setIsOpen];
// }
