import { Currency, CurrencyAmount, Pair } from '@plasma/plasmaswap-sdk';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { useCurrencyBalance } from '../../state/wallets/wallets.hooks';
import { formatTokenSymbol } from '../../utils/format-token-symbol';
import { FormattedCurrencyAmount } from '../formatted-currency-amount/formatted-currency-amount';
import { LoadingSkeleton } from '../loading-skeleton/loading-skeleton';
import { CurrencyLogo, Logo } from '../logo/logo';
import { ModalSearchCurrency } from '../modal-search-currency/modal-search-currency';
import { NumericalInput } from '../numerical-input/numerical-input';
import './currency-input-panel.scss';

interface CurrencyInputPanelProps {
  id: string;
  value: string;
  onUserInput?: (value: string) => void;
  onMax?: () => void;
  showMaxButton?: boolean;
  label?: string;
  onCurrencySelect?: (currency: Currency) => void;
  currency?: Currency | null;
  disableCurrencySelect?: boolean;
  hideBalance?: boolean;
  pair?: Pair | null;
  hideInput?: boolean;
  otherCurrency?: Currency | null;
  customBalanceLabel?: string;
  customBalance?: CurrencyAmount;
  customCurrencyOnClick?: () => void;
  customCurrencyPrefix?: string;
  isError?: boolean;
  readOnly?: boolean;
  loading?: boolean;
}

export function CurrencyInputPanel(props: CurrencyInputPanelProps): JSX.Element {
  const {
    id,
    label = '',
    value,
    onUserInput,
    onMax,
    showMaxButton,
    currency,
    onCurrencySelect,
    disableCurrencySelect = false,
    hideBalance = false,
    pair, // used for double token logo
    otherCurrency,
    customBalanceLabel,
    customBalance,
    customCurrencyOnClick,
    isError,
    readOnly,
    loading,
    customCurrencyPrefix = '',
  } = props;
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const { account } = useActiveWeb3React();
  const balance = useCurrencyBalance(!customBalance && account ? account : undefined, currency ?? undefined);
  const handleDismissSearch = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <div className={`currency-input-panel ${isError ? 'is-error' : ''}`} id={id}>
      {loading ? (
        <div className="loading">
          <div className="backdrop" />
          <i className="pi pi-loader pi-rotate" />
        </div>
      ) : null}
      <div className="label-row">
        <span className="label">{label}</span>
        {account && (
          <span onClick={() => showMaxButton && onMax && onMax()} className={`balance ${!showMaxButton ? 'max-disabled' : ''}`}>
            {!hideBalance && !!currency && (balance || customBalance) ? (
              <>
                <span>{customBalanceLabel || `${t('balance')}:`}</span>
                <span>&nbsp;</span>
                <FormattedCurrencyAmount amount={balance || customBalance} maxDecimals={11} />
              </>
            ) : null}
          </span>
        )}
      </div>
      <div className={`input-row ${disableCurrencySelect ? 'selected' : ''}`}>
        <NumericalInput
          className="token-amount-input"
          decimals={currency?.decimals}
          value={value}
          readOnly={readOnly}
          onUserInput={val => {
            onUserInput && onUserInput(val);
          }}
          disabled={!currency}
        />
        {account && currency && showMaxButton && (
          <button className="btn btn-max" onClick={onMax}>
            <span>MAX</span>
          </button>
        )}

        {currency === null ? (
          <LoadingSkeleton>
            <Skeleton height={32} width={120} />
          </LoadingSkeleton>
        ) : currency ? (
          <button
            className={`btn btn-currency-selector ${disableCurrencySelect ? 'disabled' : ''}`}
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true);
              }
              if (!disableCurrencySelect && customCurrencyOnClick) {
                customCurrencyOnClick();
              }
            }}
          >
            {pair ? (
              <div className="pair-logo">
                <CurrencyLogo currency={pair.token0} currencySecond={pair.token1} size={24} />
              </div>
            ) : currency ? (
              <div className="token-logo">
                <Logo currency={currency} size={24} />
              </div>
            ) : null}
            {pair ? (
              <span className="token-name">
                {formatTokenSymbol(pair.token0)}/{formatTokenSymbol(pair.token1)}
              </span>
            ) : (
              <span className="token-name">
                {customCurrencyPrefix}
                {formatTokenSymbol(currency)}
              </span>
            )}
            {!disableCurrencySelect ? <span className="drop-down" /> : null}
          </button>
        ) : (
          <button
            className="btn btn-primary btn-not-selected"
            onClick={() => {
              if (!disableCurrencySelect && !customCurrencyOnClick) {
                setModalOpen(true);
              }
              if (!disableCurrencySelect && customCurrencyOnClick) {
                customCurrencyOnClick();
              }
            }}
          >
            <span>{t('select_token')}</span>
            {!disableCurrencySelect && <span className="drop-down" />}
          </button>
        )}
      </div>

      {!disableCurrencySelect && onCurrencySelect ? (
        <ModalSearchCurrency
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
        />
      ) : null}
    </div>
  );
}
