import { ChainId, NATIVE, NativeAmount, toCurrencyAmount } from '@plasma/plasmaswap-sdk';

export interface GasStationRelayInfo {
  chainId: ChainId;
  gasStation: string;
  feeTokens: string[];
  balance: NativeAmount;
}

export async function getGasStationRelayInfo(url: string, abort?: AbortSignal): Promise<GasStationRelayInfo> {
  const res = await fetch(`${url}/info`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    signal: abort,
  });

  if (!res.ok) {
    throw new Error(`getGsnRelayInfo: ERROR: "${res.statusText}"`);
  }
  const rawInfo = await res.json();
  const chainId = parseInt(rawInfo.chainId, 10) as ChainId;

  return {
    chainId,
    gasStation: rawInfo.gasStation,
    feeTokens: rawInfo.feeTokens,
    balance: toCurrencyAmount(NATIVE[chainId], rawInfo.balance),
  };
}
