import Big from 'big.js';
import { MAX_UINT_256, MAX_UINT_PE } from '../constants';
import { isNumber } from './is-number';

export function safeAmountInput(amount?: string, decimals = 18): string {
  if (!amount || !isNumber(amount)) {
    return '0';
  }

  const _NE = Big.NE;
  const _PE = Big.PE;
  Big.NE = -decimals;
  Big.PE = MAX_UINT_PE;
  const multiplier = Big(10).pow(decimals);
  const integer = Big(amount).times(multiplier).round(0, 0);
  Big.NE = _NE;
  Big.PE = _PE;

  if (integer.gt(MAX_UINT_256)) {
    return MAX_UINT_256.div(multiplier).toString();
  } else {
    return integer.div(multiplier).toString();
  }
}
