import React, { FC, Suspense, useEffect } from 'react';
import { SwapWidget } from './components/swap-widget/swap-widget';
import { getWidgetParam } from './utils/get-query-params';
import { useTheme } from './state/user/user.hooks';
import './assets/scss/style.scss';

const App: FC = () => {
  const [isDarkThemeState, setTheme] = useTheme();
  const isDarkThemeParam = Number(getWidgetParam('darkTheme') || 0);

  useEffect(() => {
    if (isDarkThemeParam && !isDarkThemeState) {
      setTheme(!!isDarkThemeParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={null}>
      <SwapWidget />
    </Suspense>
  );
};

export default App;
