import React, { FC, useState } from 'react';
import './swap-widget.scss';
import { Wallet } from '../wallet/wallet';
import { AdvancedPoolSettings } from '../advanced-pool-settings/advanced-pool-settings';
import { ChainSelector } from '../chain-selector/chain-selector';
import { SwapForm } from '../swap-form/swap-form';
import { useCurrency } from '../../hooks/use-token';
import { getWidgetParam } from '../../utils/get-query-params';
import { useTranslation } from 'react-i18next';
import { LinksBlock } from '../links-block/links-block';
import cx from 'classnames';
import { WidgetTab } from '../../types/widget-tabs';
import { WalletTab } from '../wallet-tab/wallet-tab';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../state';
import { setActiveTab } from '../../state/user/user.actions';
import { Bridge } from '../bridge-tab/bridge';
import { FiatTab } from '../fiat-tab/fiat-tab';

export const SwapWidget: FC = () => {
  const defaultFromCurrencyId = getWidgetParam('fromTokenId');
  const defaultToCurrencyId = getWidgetParam('toTokenId');
  const closeBtn = Number(getWidgetParam('closeBtn'));
  const [fromCurrencyId, setFromCurrencyId] = useState<string | undefined>(defaultFromCurrencyId);
  const [toCurrencyId, setToCurrencyId] = useState<string | undefined>(defaultToCurrencyId);
  const activeTab = useSelector((state: AppState) => state.user.activeTab);
  const fromCurrency = useCurrency(fromCurrencyId);
  const toCurrency = useCurrency(toCurrencyId);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSwapFields = () => {
    setFromCurrencyId(toCurrencyId);
    setToCurrencyId(fromCurrencyId);
  };

  return (
    <div className="hyper-dex-swap-widget">
      <div className="header">
        <ChainSelector />
        <Wallet />
        <AdvancedPoolSettings show0xLiquiditySources={false} />
      </div>
      <div className="widget-tabs">
        {(Object.keys(WidgetTab) as Array<keyof typeof WidgetTab>).map(key => (
          <span className={cx('tab-item', activeTab === WidgetTab[key] && 'active')} onClick={() => dispatch(setActiveTab(WidgetTab[key]))} key={key}>
            {WidgetTab[key]}
          </span>
        ))}
      </div>
      <div className={cx('scroll-content', closeBtn ? 'modal-mode' : 'block-mode')}>
        {activeTab === WidgetTab.swap && (
          <SwapForm fromCurrency={fromCurrency} toCurrency={toCurrency} onChangeFrom={setFromCurrencyId} onChangeTo={setToCurrencyId} onSwapFields={onSwapFields} />
        )}
        {activeTab === WidgetTab.bridge && <Bridge />}
        {activeTab === WidgetTab.fiat && <FiatTab />}
        {activeTab === WidgetTab.portfolio && <WalletTab />}
      </div>

      <div className={cx('fixed-bottom', closeBtn ? 'modal-mode' : 'block-mode')}>
        <LinksBlock type={activeTab} />
        {closeBtn === 1 && (
          <div className="close-area">
            {t('close_hyperdex_window')}
            <i className="pi pi-close close-area-icon"></i>
          </div>
        )}
      </div>
    </div>
  );
};
