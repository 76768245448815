import { useEffect, useRef } from 'react';

export function useInterval(callback: () => void, delay: null | number, leading = true): void {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (!savedCallback.current || delay === null) {
      return;
    }

    if (leading) {
      savedCallback.current();
    }

    const id = setInterval(() => {
      savedCallback.current && savedCallback.current();
    }, delay);

    return () => clearInterval(id);
  }, [delay, leading]);
}
