import { getAddress } from '@ethersproject/address';
import { Provider } from '@ethersproject/abstract-provider';
import { Signer } from '@ethersproject/abstract-signer';
import { ContractInterface, Contract } from '@ethersproject/contracts';
import { JsonRpcSigner } from '@ethersproject/providers';
import { AddressZero } from '@ethersproject/constants';
import { ChainId, JSBI, Percent } from '@plasma/plasmaswap-sdk';
import Big from 'big.js';
import { BigNumber } from '@ethersproject/bignumber';
import { GAS_LIMIT_MARGIN_MULTIPLIER } from '../constants';
import { GsnWeb3Provider } from '../gsn-web3-provider/gsn-web3-provider';

/**
 * Returns the checksummed address if the address is valid, otherwise returns false
 * @param value
 */
export function isAddress(value?: string): string | false {
  try {
    return value ? getAddress(value) : false;
  } catch {
    return false;
  }
}

/**
 * Shorten the checksummed version of the input address to have 0x + 4 characters at start and end
 * @param hash
 * @param chars
 */
export function shortenHash(hash?: string, chars = 4): string {
  if (!hash || hash.length <= chars * 2 + 2) {
    return hash || '';
  }

  return `${hash.substring(0, chars + 2)}...${hash.substr(-chars)}`;
}

// account is not optional
export function getSigner(library: GsnWeb3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(library: GsnWeb3Provider, account?: string): GsnWeb3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(address: string, ABI: ContractInterface, signerOrProvider?: Signer | Provider): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, signerOrProvider);
}

/**
 * Converts a basis points value to a sdk percent
 * @param num
 */
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000));
}

/**
 * Add 10%
 * @param value
 */
export function calculateGasMargin(value: BigNumber): BigNumber {
  return BigNumber.from(Big(value.toString()).mul(GAS_LIMIT_MARGIN_MULTIPLIER).toFixed(0));
}

/**
 * Build and return URL to Etherscan
 * @param chainId
 * @param hash
 * @param type
 */
export function getEtherscanLink(chainId: ChainId, hash: string, type?: 'transaction' | 'token' | 'address' | 'block'): string {
  let url;
  switch (chainId) {
    case ChainId.MAINNET:
      url = 'https://etherscan.io';
      break;
    case ChainId.ROPSTEN:
      url = 'https://ropsten.etherscan.io';
      break;
    case ChainId.RINKEBY:
      url = 'https://rinkeby.etherscan.io';
      break;
    case ChainId.GÖRLI:
      url = 'https://goerli.etherscan.io';
      break;
    case ChainId.KOVAN:
      url = 'https://kovan.etherscan.io';
      break;
    case ChainId.MATIC:
      url = 'https://polygonscan.com';
      break;
    case ChainId.BSC:
      url = 'https://bscscan.com';
      break;
    case ChainId.BSC_TEST:
      url = 'https://testnet.bscscan.com';
      break;
    case 250 as ChainId:
      url = 'https://ftmscan.com';
      break;
    case 128 as ChainId:
      url = 'https://scan.hecochain.com';
      break;
    case 43114 as ChainId:
      url = 'https://snowtrace.io/';
      break;
    case 10 as ChainId:
      url = 'https://optimistic.etherscan.io';
      break;
    case 42161 as ChainId:
      url = 'https://arbiscan.io';
      break;
    case 42220 as ChainId:
      url = 'https://explorer.celo.org';
      break;
    case 1285 as ChainId:
      url = 'https://moonriver.moonscan.io';
      break;
    default:
      url = 'https://etherscan.io';
      break;
  }

  switch (type) {
    case 'transaction': {
      return `${url}/tx/${hash}`;
    }
    case 'token': {
      return `${url}/token/${hash}`;
    }
    case 'block': {
      return `${url}/block/${hash}`;
    }
    case 'address':
    default: {
      return `${url}/address/${hash}`;
    }
  }
}

export { WrappedToken } from './wrapped-token';
