import { ChainId, Token, Trade0xLiquiditySource } from '@plasma/plasmaswap-sdk';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CURRENCY_LIST, REFERRALS_DEADLINE } from '../../constants';

import { SerializedToken, UserLocalCurrency } from '../../types';
import { deserializeToken, serializeToken } from '../../utils/serialize-token';
import { AppDispatch, AppState } from '../index';
import {
  addSerializedToken,
  changeTheme,
  removeSerializedToken,
  setExclude0xLiquiditySources,
  updateCurrency,
  updateSlippageTolerance,
  updateTransactionExpiry,
} from './user.actions';
import { BigNumber } from '@ethersproject/bignumber';
import { useCurrentBlockTimestamp } from '../../hooks/use-current-block-timestamp';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';

/**
 * Used user's local currency, and currency manager
 */
export function useCurrentCurrency(): [UserLocalCurrency, (currency: string) => void] {
  const dispatch = useDispatch<AppDispatch>();
  const currency = useSelector((state: AppState) => state.user.currency);
  const exchangeRates = useSelector((state: AppState) => state.user.exchangeRates);

  const setNewCurrency = useCallback(
    (currency: string) => {
      dispatch(updateCurrency(currency));
    },
    [dispatch],
  );

  const currCurrency = useMemo(() => {
    const selectedValue = CURRENCY_LIST.find(item => item.value === currency);
    const rate = exchangeRates[currency];

    if (selectedValue && rate) {
      return { ...selectedValue, rate };
    }

    return {
      value: 'USD',
      title: 'United States dollar',
      sign: '$',
      rate: '1',
    };
  }, [currency, exchangeRates]);

  return [currCurrency, setNewCurrency];
}

/**
 * Returns isDarkTheme flag and theme switch manager
 */
export function useTheme(): [boolean, (value: boolean) => void] {
  const dispatch = useDispatch<AppDispatch>();
  const isDarkTheme = useSelector((state: AppState) => state.user.isDarkTheme);

  const setTheme = useCallback(
    (value: boolean) => {
      if (value) {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
      dispatch(changeTheme(value));
    },
    [dispatch],
  );

  return [isDarkTheme, setTheme];
}

/**
 * Returns exclude 0x liquidity sources and setter callback
 */
export function useExclude0xLiquiditySources(): [Trade0xLiquiditySource[], (excludeSources: Trade0xLiquiditySource[]) => void] {
  const dispatch = useDispatch<AppDispatch>();
  const excludeSources = useSelector((state: AppState) => state.user.exclude0xLiquiditySources);

  const setExcludeSources = useCallback(
    (excludeSources: Trade0xLiquiditySource[]) => {
      dispatch(setExclude0xLiquiditySources(excludeSources));
    },
    [dispatch],
  );

  return [excludeSources, setExcludeSources];
}

/**
 * Get slippage tolerance value and get setter
 */
export function useSlippageTolerance(): [number, (slippage: number) => void] {
  const dispatch = useDispatch<AppDispatch>();
  const slippageTolerance = useSelector((state: AppState) => state.user.slippageTolerance);

  const setSlippageTolerance = useCallback(
    (slippageTolerance: number) => {
      dispatch(updateSlippageTolerance(slippageTolerance));
    },
    [dispatch],
  );

  return [slippageTolerance, setSlippageTolerance];
}

/**
 * Get transaction expiry value (in sec) and get setter
 */
export function useTransactionExpiry(): [number, (transactionExpiry: number) => void] {
  const dispatch = useDispatch<AppDispatch>();
  const transactionExpiry = useSelector((state: AppState) => state.user.transactionExpiry);

  const setTransactionExpiry = useCallback(
    (transactionExpiry: number) => {
      dispatch(updateTransactionExpiry(transactionExpiry));
    },
    [dispatch],
  );

  return [transactionExpiry, setTransactionExpiry];
}

/**
 * Returns the saved user's tokens
 */
export function useUserTokens(): { [address: string]: Token } {
  const { chainId } = useActiveWeb3React();
  const serializedTokensMap = useSelector<AppState, AppState['user']['tokens']>(state => state.user.tokens);

  return useMemo(() => {
    if (!chainId) {
      return {};
    }

    return Object.keys(serializedTokensMap?.[chainId as ChainId] ?? {}).reduce<{ [address: string]: Token }>((map, address) => {
      map[address] = deserializeToken(serializedTokensMap[chainId as ChainId][address]);
      return map;
    }, {});
  }, [serializedTokensMap, chainId]);
}

/**
 * Returns function for the remove user's token from custom list
 */
export function useRemoveUserToken(): (address: string) => void {
  const { chainId } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>();

  return useCallback(
    (address: string) => {
      if (chainId) {
        dispatch(removeSerializedToken({ chainId, address }));
      }
    },
    [dispatch, chainId],
  );
}

/**
 * Returns function for the add custom user's token
 */
export function useAddUserToken(): (token: Token) => void {
  const dispatch = useDispatch<AppDispatch>();
  return useCallback(
    (token: Token) => {
      const serializedToken: SerializedToken = serializeToken(token);
      dispatch(addSerializedToken(serializedToken));
    },
    [dispatch],
  );
}

/**
 * Returns saved referral addresses
 */
export function useReferrals(): string[] {
  const referrals = useSelector<AppState, AppState['user']['referrals']>(state => state.user.referrals);
  const firstVisit = useSelector<AppState, AppState['user']['firstVisit']>(state => state.user.firstVisit);

  return useMemo(() => {
    if (!referrals || !firstVisit || firstVisit + REFERRALS_DEADLINE < Math.round(Date.now() / 1000)) {
      return [];
    }
    return referrals;
  }, [firstVisit, referrals]);
}

/**
 * Combines the block timestamp with the user setting to give the deadline that should be used for any submitted transaction
 */
export function useTransactionDeadline(): BigNumber | undefined {
  const transactionExpiry = useSelector<AppState, number>(state => state.user.transactionExpiry);
  const blockTimestamp = useCurrentBlockTimestamp();

  return useMemo(() => {
    if (blockTimestamp && transactionExpiry) {
      return blockTimestamp.add(transactionExpiry);
    }
    return undefined;
  }, [blockTimestamp, transactionExpiry]);
}
