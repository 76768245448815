import { getAddress } from '@ethersproject/address';
import { ChainId, Currency, CurrencyAmount, NATIVE, NETWORK_NAME } from '@plasma/plasmaswap-sdk';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BridgeSocketAsset } from '../../../api';
import { setActiveTab } from '../../../state/user/user.actions';
import { WidgetTab } from '../../../types/widget-tabs';
import { BridgeAssetInputPanel } from '../../bridge-asset-input-panel/bridge-asset-input-panel';
import { FormattedAmountInFiat } from '../../formatted-amount-in-fiat/formatted-amount-in-fiat';
import { FormattedCurrencyAmount } from '../../formatted-currency-amount/formatted-currency-amount';
import { FormControlSelect, FormControlSelectOption } from '../../forms';
import { InfoHelper } from '../../info-helper/info-helper';
import { CurrencyLogo } from '../../logo/logo';
import { ModalBridgeAssetSelector } from '../../modal-bridge-asset-selector/modal-bridge-asset-selector';
import { ModalBridgeEvmConfirm } from '../../modal-bridge-evm-confirm/modal-bridge-evm-confirm';
import { ETH_ADDRESS, EVM_BRIDGE_IMAGES, NETWORK_ICON, SUPPORTED_CHAIN_IDS } from '../../../constants';
import { useEvmFromAssetsList } from '../../../hooks/bridge/use-evm-from-assets-list';
import { useEvmNetworks } from '../../../hooks/bridge/use-evm-networks';
import { useEvmQuote } from '../../../hooks/bridge/use-evm-quote';
import { useEvmToAssetsList } from '../../../hooks/bridge/use-evm-to-assets-list';
import { useEvmTokenPrice } from '../../../hooks/bridge/use-evm-token-price';
import { useEvmFetchBalances, useEvmUserBalance } from '../../../hooks/bridge/use-evm-user-balance';
import { useChainSelector } from '../../../hooks/use-chain-selector';
import { useActiveWeb3React } from '../../../hooks/web3/use-active-web3-react';
import { BridgeEvmQuote, BridgeEvmRouterType, ExtraChainId } from '../../../types';
import { isAddress, WrappedToken } from '../../../utils';
import { isNumber } from '../../../utils/is-number';
import { tryParseAmount } from '../../../utils/try-parse-amount';

enum AmountValidationError {
  VALID,
  REQUIRED,
  INVALID,
  BALANCE,
}

export const EvmForm: FC = () => {
  const { t } = useTranslation();
  const { chainId, account } = useActiveWeb3React();
  const chainSelector = useChainSelector();
  const networks = useEvmNetworks();
  const dispatch = useDispatch();

  // Form controls state
  const [fromChainId, setFromChainId] = useState<ChainId>(chainId || ChainId.MAINNET);
  const [toChainId, setToChainId] = useState<ChainId>(ChainId.BSC);
  const [fromAmountValue, setFromAmountValue] = useState<string>('');
  const [fromAmountTouched, setFromAmountTouched] = useState(false);
  const [fromCurrency, setFromCurrency] = useState<Currency>(NATIVE[fromChainId]);
  const [toCurrency, setToCurrency] = useState<Currency>(NATIVE[toChainId]);
  const [routerType, setRouterType] = useState<BridgeEvmRouterType>(BridgeEvmRouterType.fastest);
  const [currencySelectMode, setCurrencySelectMode] = useState<'fromChainId' | 'toChainId'>('fromChainId');

  // Balances
  const fromBalance = useEvmUserBalance(fromCurrency);
  const toBalance = useEvmUserBalance(toCurrency);
  const allBalances = useEvmFetchBalances();

  // Assets list
  const fromAssetsList = useEvmFromAssetsList(currencySelectMode === 'fromChainId' ? fromChainId : undefined, toChainId);
  const toAssetsList = useEvmToAssetsList(currencySelectMode === 'toChainId' ? fromChainId : undefined, toChainId);
  const assetsList: (BridgeSocketAsset & { balance?: string | null })[] | null = useMemo(() => {
    let assets: (BridgeSocketAsset & { balance?: string | null })[] | null;
    if (currencySelectMode === 'fromChainId') {
      assets = fromAssetsList === undefined ? [] : fromAssetsList;
    } else {
      assets = toAssetsList === undefined ? [] : toAssetsList;
    }

    if (!assets || allBalances === undefined) {
      return assets;
    }

    assets.forEach(asset => {
      if (!allBalances) {
        asset.balance = null;
        return;
      }
      try {
        const address = getAddress(asset.address);
        const balance = allBalances[asset.chainId as ChainId]?.[address === ETH_ADDRESS ? 'NATIVE' : address];
        asset.balance = balance ? balance.toExact() : '0';
        return;
      } catch (e) {
        console.error(e);
        asset.balance = '0';
        return;
      }
    });

    return assets;
  }, [currencySelectMode, fromAssetsList, toAssetsList, allBalances]);

  // Modals stuff
  const [isOpenAssetsSelector, setIsOpenAssetsSelector] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

  // Previous chain ID's for
  const prevFromChainId = useRef<ChainId>(fromChainId);
  const prevToChainId = useRef<ChainId>(toChainId);

  const fromAmount: CurrencyAmount | undefined = useMemo(() => {
    if (!fromAmountValue || !isNumber(fromAmountValue) || !fromCurrency) {
      return undefined;
    }
    return tryParseAmount(fromAmountValue, fromCurrency);
  }, [fromAmountValue, fromCurrency]);

  const quote: BridgeEvmQuote | null | undefined = useEvmQuote(routerType, fromAmount, toCurrency);

  // Prices and fiat amounts
  const fromCurrencyPrice = useEvmTokenPrice(fromCurrency);
  const toCurrencyPrice = useEvmTokenPrice(toCurrency);
  const fromAmountInUsd = useMemo(() => {
    if (fromCurrencyPrice && fromAmount && fromCurrencyPrice.baseCurrency.equals(fromAmount.currency)) {
      return fromCurrencyPrice.quote(fromAmount);
    }
    return undefined;
  }, [fromCurrencyPrice, fromAmount]);
  const toAmountInUsd = useMemo(() => {
    if (toCurrencyPrice && quote && toCurrencyPrice.baseCurrency.equals(quote.toAmount.currency)) {
      return toCurrencyPrice.quote(quote.toAmount);
    }
    return undefined;
  }, [toCurrencyPrice, quote]);

  const fromNetworkSelectorOptions: FormControlSelectOption[] = useMemo(() => {
    if (!networks) {
      return [{ logo: NETWORK_ICON[ChainId.MAINNET], value: `${ChainId.MAINNET}`, label: 'Ethereum' }];
    }
    return networks.reduce<FormControlSelectOption[]>((options, network) => {
      if (SUPPORTED_CHAIN_IDS.includes(network.value)) {
        options.push({ value: `${network.value}`, label: network.label, logo: network.logo });
      }
      return options;
    }, []);
  }, [networks]);

  const toNetworkSelectorOptions: FormControlSelectOption[] = useMemo(() => {
    if (!networks) {
      return [{ logo: NETWORK_ICON[ChainId.BSC], value: `${ChainId.BSC}`, label: 'Binance' }];
    }
    return networks.map(network => {
      return { value: `${network.value}`, label: network.label, logo: network.logo };
    });
  }, [networks]);

  const networkSelectorModalOptions = useMemo(() => {
    const options = currencySelectMode === 'fromChainId' ? fromNetworkSelectorOptions : toNetworkSelectorOptions;
    return options.map(({ value, label, logo }) => ({ id: Number(value) as ChainId, name: label || '', logoURI: logo || '' }));
  }, [currencySelectMode, fromNetworkSelectorOptions, toNetworkSelectorOptions]);

  const fromAmountError: AmountValidationError = useMemo(() => {
    if (fromAmountValue && !fromAmount) {
      return AmountValidationError.INVALID;
    }
    if (!fromAmount) {
      return AmountValidationError.REQUIRED;
    }
    if (fromBalance && fromBalance.lessThan(fromAmount)) {
      return AmountValidationError.BALANCE;
    }
    return AmountValidationError.VALID;
  }, [fromAmount, fromAmountValue, fromBalance]);

  const resetFormCallback = useCallback(() => {
    setFromAmountValue('');
    setFromAmountTouched(false);
  }, []);

  // Set chain id from after change network
  useEffect(() => {
    if (!chainId) {
      return;
    }
    setFromChainId(chainId);
  }, [chainId]);

  // Handle and check support fromChainId
  useEffect(() => {
    if (!SUPPORTED_CHAIN_IDS.includes(fromChainId)) {
      return setFromChainId(() => ChainId.MAINNET);
    }
    if (prevFromChainId.current === fromChainId) {
      return;
    }

    // Swapping the chainId if the user has chosen the same as in the other field
    const prevChainId = prevFromChainId.current;
    setToChainId(toChainId => (fromChainId === toChainId ? prevChainId : toChainId));

    // Store previous value
    prevFromChainId.current = fromChainId;
  }, [fromChainId]);

  // Handle and check support fromChainId
  useEffect(() => {
    if (prevToChainId.current === toChainId) {
      return;
    }
    // Swapping the chainId if the user has chosen the same as in the other field
    const prevChainId = SUPPORTED_CHAIN_IDS.includes(prevToChainId.current) ? prevToChainId.current : ChainId.MAINNET;
    setFromChainId(fromChainId => (fromChainId === toChainId ? prevChainId : fromChainId));

    // Store previous value
    prevToChainId.current = toChainId;
  }, [toChainId]);

  // Update selected asset after change fromChainId
  useEffect(() => {
    setFromCurrency(NATIVE[fromChainId]);
  }, [fromChainId]);

  // Update selected asset after change toChainId
  useEffect(() => {
    let native: Currency;
    if (NATIVE[toChainId]) {
      native = NATIVE[toChainId];
    } else if ((toChainId as number) === ExtraChainId.XDAI) {
      native = new WrappedToken(
        {
          address: ETH_ADDRESS,
          chainId: toChainId,
          decimals: 18,
          symbol: 'XDAI',
          name: 'xDAI',
          logoURI: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png',
        },
        [],
      );
    } else {
      native = new WrappedToken({ address: ETH_ADDRESS, chainId: toChainId, decimals: 18, symbol: 'ETH', name: 'Ethereum' }, []);
    }
    setToCurrency(native);
  }, [toChainId]);

  return (
    <>
      <div className="bridge-card-content">
        {/* From chain id */}
        <div className="chain-id-selector">
          <label className="selector-label" htmlFor="from-chain-id">
            <span>{t('from_chain')}</span>
          </label>
          <FormControlSelect
            id="from-chain-id"
            className="selector"
            options={fromNetworkSelectorOptions}
            value={`${fromChainId}`}
            onChange={(strChainId: string) => setFromChainId(parseInt(strChainId, 10) as ChainId)}
            disabled={!account}
          />
        </div>

        {/* From amount input */}
        <div className="form-control">
          <BridgeAssetInputPanel
            id="from-asset-amount"
            label={
              <>
                {fromCurrencyPrice ? (
                  <>
                    <span>≈&nbsp;</span>
                    <FormattedAmountInFiat amount={fromAmountInUsd ? fromAmountInUsd.toExact() : '0'} />
                  </>
                ) : null}
              </>
            }
            value={fromAmountValue}
            onUserInput={value => {
              setFromAmountValue(value);
              value && setFromAmountTouched(true);
            }}
            asset={fromCurrency}
            balance={fromBalance}
            onClickAsset={() => {
              setIsOpenAssetsSelector(true);
              setCurrencySelectMode('fromChainId');
            }}
            disabled={!account}
            isError={fromAmountTouched && !!fromAmountError}
          />
          <div className="error">
            {fromAmountTouched ? (
              fromAmountError === AmountValidationError.REQUIRED ? (
                <span>{t('please_enter_amount')}</span>
              ) : fromAmountError === AmountValidationError.INVALID ? (
                <span>{t('invalid_amount_value')}</span>
              ) : fromAmountError === AmountValidationError.BALANCE ? (
                <span>{t('insufficient_balance', { code: fromAmount?.currency.symbol })}</span>
              ) : null
            ) : null}
          </div>
        </div>

        {/* Swap from-to fields */}
        <div className="swap-fields">
          <button
            className="btn btn-icon-rect btn-foreground-white change"
            onClick={() => {
              setToChainId(fromChainId);
              setFromChainId(toChainId);
              setToCurrency(fromCurrency);
              setFromCurrency(toCurrency);
            }}
          >
            <i className="pi pi pi-swap" />
          </button>
        </div>

        {/* To network id */}
        <div className="chain-id-selector">
          <label className="selector-label" htmlFor="to-chain-id">
            <span>{t('to_chain')}</span>
          </label>
          <FormControlSelect
            id="to-chain-id"
            className="selector"
            options={toNetworkSelectorOptions}
            value={`${toChainId}`}
            onChange={(strChainId: string) => setToChainId(parseInt(strChainId, 10) as ChainId)}
            disabled={!account}
          />
        </div>

        {/* To amount input */}
        <div className="form-control">
          <BridgeAssetInputPanel
            id="to-asset-amount"
            label={
              <>
                {toCurrencyPrice ? (
                  <>
                    <span>≈&nbsp;</span>
                    <FormattedAmountInFiat amount={toAmountInUsd ? toAmountInUsd.toExact() : '0'} />
                  </>
                ) : null}
              </>
            }
            value={quote ? quote.toAmount.toExact() : '0.0'}
            asset={toCurrency}
            readOnly
            balance={toBalance}
            onClickAsset={() => {
              setIsOpenAssetsSelector(true);
              setCurrencySelectMode('toChainId');
            }}
            disabled={!account}
            loading={quote === null}
            isError={fromAmount && quote === undefined}
          />
          <div className="error">{fromAmount && quote === undefined ? <span>{t('no_token_in_bridge')}</span> : null}</div>
        </div>

        {/* Bridge type selector */}
        <div className="bridge-info">
          <div className="row-between">
            <div className="label">
              <span>{t('bridge_route')}</span>
            </div>
            <div className="buttons">
              <button className={`btn btn-icon-rect ${routerType === BridgeEvmRouterType.fastest ? 'active' : ''}`} onClick={() => setRouterType(BridgeEvmRouterType.fastest)}>
                <span>{t('fastest')}</span>
              </button>
              <button className={`btn btn-icon-rect ${routerType === BridgeEvmRouterType.lowFee ? 'active' : ''}`} onClick={() => setRouterType(BridgeEvmRouterType.lowFee)}>
                <span>{t('low_gas_fee')}</span>
              </button>
              <button
                className={`btn btn-icon-rect ${routerType === BridgeEvmRouterType.highReturn ? 'active' : ''}`}
                onClick={() => setRouterType(BridgeEvmRouterType.highReturn)}
              >
                <span>{t('high_return')}</span>
              </button>
            </div>
          </div>
          {quote ? (
            <div className="row-between">
              <div className="label">
                {EVM_BRIDGE_IMAGES[quote.bridgeName] ? <img src={EVM_BRIDGE_IMAGES[quote.bridgeName]} alt={quote.bridgeName} /> : null}
                <span>{quote.bridgeName}</span>
              </div>
              <div className="value">≈ {(Number(quote.serviceTime) / 60).toFixed(1)}min</div>
            </div>
          ) : null}
        </div>

        {/* Submit button */}
        <div className="button-block">
          {!account ? (
            <button id="connect-wallet" className="btn btn-primary btn-lg" onClick={() => dispatch(setActiveTab(WidgetTab.portfolio))}>
              <span>{t('connect_wallet')}</span>
            </button>
          ) : fromChainId && chainId && fromChainId !== chainId ? (
            <button id="switch-chain" className="btn btn-primary btn-lg" onClick={() => chainSelector(fromChainId)}>
              <span>{t('switch_to_chain', { chainTo: NETWORK_NAME[fromChainId] })}</span>
            </button>
          ) : (
            <button
              id="confirm-bridge"
              className={`btn btn-primary btn-lg ${!quote || fromAmountError ? 'disabled' : ''}`}
              onClick={() => {
                setFromAmountTouched(true);
                if (!fromAmountError && quote) {
                  setIsOpenConfirmation(true);
                }
              }}
            >
              <span>{t('confirm_bridge')}</span>
            </button>
          )}
        </div>

        {/* Dropdown */}
        {quote && fromAmount ? (
          <div className="dropdown">
            <div className="row-between">
              <div className="label">
                <span>{t('swapping_and_transfer')}</span>
              </div>
              <div className="value">
                <CurrencyLogo currency={fromCurrency} size={16} className="logo" />
                <FormattedCurrencyAmount amount={fromAmount} />
              </div>
            </div>
            <div className="row-between">
              <div className="label">
                <span>{t('minimum_received')}</span>
              </div>
              <div className="value">
                <CurrencyLogo currency={toCurrency} size={16} className="logo" />
                <FormattedCurrencyAmount amount={quote.toAmount} />
              </div>
            </div>
            <div className="row-between">
              <div className="label">
                <span>{t('total_fee')}</span>
                <InfoHelper backdrop="white" placement="top-start">
                  <div className="hop-estimate-tooltip-content">
                    <p>{t('hop_fee_tooltip')}</p>
                    <p>{t('hop_fee_tooltip_lp_fees')}</p>
                  </div>
                </InfoHelper>
              </div>
              <div className="value">
                <FormattedAmountInFiat amount={quote.feeInUsd} />
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <ModalBridgeAssetSelector
        isOpen={isOpenAssetsSelector}
        onDismiss={() => setIsOpenAssetsSelector(false)}
        networks={networkSelectorModalOptions}
        assets={assetsList}
        asset={currencySelectMode === 'fromChainId' ? fromCurrency : toCurrency}
        onChangeAsset={asset => {
          const address = isAddress(asset.address);
          if (!address) {
            return;
          }
          const chainId = asset.chainId as ChainId;
          const decimals = asset.decimals as number;
          const symbol = asset.symbol as string;
          const name = asset.name as string;
          const native = NATIVE[chainId];
          const currency = address === ETH_ADDRESS && native ? native : new WrappedToken({ address, chainId, decimals, symbol, name, logoURI: asset.logoURI }, []);

          if (currencySelectMode === 'fromChainId') {
            setFromCurrency(currency);
          } else {
            setToCurrency(currency);
          }
        }}
        networkId={currencySelectMode === 'fromChainId' ? fromChainId : toChainId}
        onChangeNetworkId={chainId => (currencySelectMode === 'fromChainId' ? setFromChainId(chainId as ChainId) : setToChainId(chainId as ChainId))}
      />

      <ModalBridgeEvmConfirm
        isOpen={isOpenConfirmation}
        onDismiss={() => setIsOpenConfirmation(false)}
        fromAmount={fromAmount}
        toCurrency={toCurrency}
        quote={quote}
        onSuccess={resetFormCallback}
      />
    </>
  );
};
