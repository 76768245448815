import {
  Currency,
  CurrencyAmount,
  isCurrency,
  isCurrencyAmount,
  Native,
  NativeAmount,
  Percent,
  toCurrencyAmount,
  Token,
  TokenAmount,
  Trade0xSwap,
  TradeType,
  ZERO_ADDRESS,
} from '@plasma/plasmaswap-sdk';
import Big from 'big.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getZeroExSwapPrice, getZeroExSwapQuote, ZeroExSwapPriceResponse, ZeroExSwapQuoteQuery, ZeroExSwapQuoteResponse } from '../../api';
import { useExclude0xLiquiditySources, useSlippageTolerance } from '../../state/user/user.hooks';
import { zeroExSwapFeesExactInput, zeroExSwapFeesExactOutput } from '../../utils/zero-ex-swap-calc-fees';
import { useHyperDexRouterContract } from '../use-contract';
import { useDebounce } from '../use-debounce';
import { useActiveWeb3React } from '../web3/use-active-web3-react';
import { useHyperDexFeePercent } from './use-hyper-dex-fee-percent';

export type GetTrade0xInput = NativeAmount | TokenAmount | Token | Native | undefined | null;

/**
 * Get 0x trade with best price
 * Returns:
 * "undefined" - if trade error (Not liquidity or some on else)
 * "null" - if loading
 * otherwise returns the trade instance
 */
export function useTrade0xSwap(from: GetTrade0xInput, to: GetTrade0xInput): [Trade0xSwap | undefined | null, () => void] {
  const { chainId, account } = useActiveWeb3React();
  const [excludedSources] = useExclude0xLiquiditySources();
  const contract = useHyperDexRouterContract();

  // Fee percent and Allowed Slippage
  const [allowedSlippage] = useSlippageTolerance();
  const slippagePercentage = useMemo(() => Big(allowedSlippage).div(100).toString(), [allowedSlippage]); // In percent (e.g. 0.5%)
  const feePercentFraction = useHyperDexFeePercent();
  const feePercent = useMemo(() => feePercentFraction?.toSignificant(), [feePercentFraction]); // 0.05% base fee

  const [quoteOrPrice, setQuoteOrPrice] = useState<ZeroExSwapQuoteResponse | ZeroExSwapPriceResponse | undefined | null>();
  const [fetchCount, setFetchCount] = useState<number>(0);

  const tradeType = useMemo(() => (from ? (isCurrencyAmount(from) ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT) : undefined), [from]);
  const sellCurrency = useMemo(() => from && (isCurrency(from) ? (from as Currency) : (from as CurrencyAmount).currency), [from]);
  const buyCurrency = useMemo(() => to && (isCurrency(to) ? (to as Currency) : (to as CurrencyAmount).currency), [to]);

  const rawAmounts: { sellAmount?: string; buyAmount?: string; feeAmount?: string } | undefined = useMemo(() => {
    if (!from || !to || !feePercent) {
      return undefined;
    }

    // EXACT_INPUT
    if (isCurrencyAmount(from)) {
      const rawFromAmount = (from as CurrencyAmount).raw.toString();
      const feeAmount = zeroExSwapFeesExactInput(rawFromAmount, feePercent);
      const sellAmount = Big(rawFromAmount).minus(feeAmount).toFixed(0, 0);

      return {
        sellAmount,
        feeAmount,
      };
    }

    // EXACT_OUTPUT
    if (isCurrencyAmount(to)) {
      return { buyAmount: (to as CurrencyAmount).raw.toString() };
    }
    return undefined;
  }, [feePercent, from, to]);

  const currencyIds: { sellToken: string; buyToken: string } | undefined = useMemo(() => {
    if (!sellCurrency || !buyCurrency) {
      return undefined;
    }

    return {
      sellToken: sellCurrency instanceof Token ? sellCurrency.address : (sellCurrency.symbol as string),
      buyToken: buyCurrency instanceof Token ? buyCurrency.address : (buyCurrency.symbol as string),
    };
  }, [sellCurrency, buyCurrency]);

  const priceQuery: ZeroExSwapQuoteQuery | undefined = useMemo(() => {
    if (!rawAmounts || !currencyIds) {
      return undefined;
    }

    return {
      sellToken: currencyIds.sellToken,
      buyToken: currencyIds.buyToken,
      ...(rawAmounts.sellAmount && { sellAmount: rawAmounts.sellAmount }),
      ...(rawAmounts.buyAmount && { buyAmount: rawAmounts.buyAmount }),
      slippagePercentage: Big(slippagePercentage).div(100).toString(),
      excludedSources: excludedSources.length ? excludedSources.join(',') : undefined,
      takerAddress: account || undefined,
      skipValidation: true,
    };
  }, [rawAmounts, currencyIds, account, slippagePercentage, excludedSources]);

  const debouncedPriceQuery = useDebounce(priceQuery, 600);

  const refreshCallback = useCallback(() => {
    if (!quoteOrPrice) {
      return;
    }
    setFetchCount(count => ++count);
  }, [quoteOrPrice]);

  const trade = useMemo(() => {
    if (tradeType === undefined || !sellCurrency || !buyCurrency || !contract || !rawAmounts || !feePercent) {
      return undefined;
    }
    if (!quoteOrPrice) {
      return quoteOrPrice;
    }

    const proportions = quoteOrPrice.sources.filter(i => !!Number(i.proportion)).map(i => ({ name: i.name, proportion: Number(i.proportion) * 100 }));

    let feeAmount = '0';
    if (tradeType === TradeType.EXACT_OUTPUT) {
      const maxSellAmount = sellCurrency.isNative ? quoteOrPrice.value : Big(quoteOrPrice.sellAmount).times(Big(1).add(slippagePercentage)).toFixed(0, 3);
      feeAmount = zeroExSwapFeesExactOutput(maxSellAmount, feePercent);
    } else if (rawAmounts.feeAmount) {
      feeAmount = rawAmounts.feeAmount;
    }
    const inputAmount: CurrencyAmount = toCurrencyAmount(sellCurrency, Big(quoteOrPrice.sellAmount).add(feeAmount).toFixed(0, 0)); // eslint-disable-line prettier/prettier
    const outputAmount: CurrencyAmount = toCurrencyAmount(buyCurrency, quoteOrPrice.buyAmount);

    // Price impact in percent calculation
    let priceImpact: Percent | undefined;
    if (quoteOrPrice.sellTokenToEthRate && quoteOrPrice.sellTokenToEthRate !== '0' && quoteOrPrice.buyTokenToEthRate && quoteOrPrice.buyTokenToEthRate !== '0') {
      const inputAmountInNative = Big(inputAmount.toExact()).div(quoteOrPrice.sellTokenToEthRate);
      const outputAmountInNative = Big(outputAmount.toExact()).div(quoteOrPrice.buyTokenToEthRate);

      if (inputAmountInNative.gt(0) && outputAmountInNative.gt(0)) {
        const denominator = '100';
        const numerator = Big(100).times(Big(outputAmountInNative).div(inputAmountInNative)).minus(100).times(denominator).toFixed(0);
        priceImpact = new Percent(numerator, denominator);
      }
    }

    let txData: string | undefined = (quoteOrPrice as ZeroExSwapQuoteResponse).data;
    const txFrom: string | undefined = (quoteOrPrice as ZeroExSwapQuoteResponse).from;
    const txTo: string | undefined = (quoteOrPrice as ZeroExSwapQuoteResponse).to;

    // Remove affiliate from data
    const result = txData ? /869584cd[0-9a-fA-F]{128}$/.exec(txData) : null;
    if (result) {
      txData = txData.replace(result[0], '');
    }

    const tx = txData && txFrom && txTo ? { from: txFrom, to: txTo, value: quoteOrPrice.value, data: txData } : undefined;

    return new Trade0xSwap({
      tradeType,
      inputAmount,
      outputAmount,
      feeAmount: toCurrencyAmount(sellCurrency, feeAmount),
      allowanceTarget: quoteOrPrice.allowanceTarget === ZERO_ADDRESS ? undefined : contract.address,
      priceImpact,
      proportions,
      tx,
    });
    // (rawAmounts should be excluded from dependency)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeType, sellCurrency, buyCurrency, contract, feePercent, quoteOrPrice, slippagePercentage]);

  // Fetch quote
  useEffect(() => {
    if (!debouncedPriceQuery || !chainId || !contract) {
      setQuoteOrPrice(undefined);
      return;
    }
    setQuoteOrPrice(oldQuote => (oldQuote ? oldQuote : null));

    const cancelRequest = new AbortController();
    const requestMethod = debouncedPriceQuery.takerAddress ? getZeroExSwapQuote : getZeroExSwapPrice;

    requestMethod(chainId, debouncedPriceQuery, cancelRequest.signal)
      .then(quoteOrPrice => setQuoteOrPrice(quoteOrPrice))
      .catch(error => {
        if (error.name !== 'AbortError') {
          setQuoteOrPrice(oldTrade => (oldTrade ? oldTrade : undefined));
          console.error(error);
        }
      });

    return () => {
      cancelRequest.abort();
    };
  }, [contract, chainId, debouncedPriceQuery, fetchCount]);

  // Reset trade after change options
  useEffect(() => {
    setQuoteOrPrice(undefined);
    setFetchCount(0);
  }, [priceQuery]);

  return [trade, refreshCallback];
}
