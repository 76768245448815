import { ChainId } from '@plasma/plasmaswap-sdk';
import MULTICALL_ABI from './abi.json';

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.MATIC]: '0x02817C1e3543c2d908a590F5dB6bc97f933dB4BD',
  [ChainId.BSC]: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
  [ChainId.BSC_TEST]: '0xae11c5b5f29a6a25e955f0cb8ddcc416f522af5c',
  [ChainId.AVALANCHE]: '0xdDCbf776dF3dE60163066A5ddDF2277cB445E0F3',
  [ChainId.FANTOM]: '0x22D4cF72C45F8198CfbF4B568dBdB5A85e8DC0B5',
  [ChainId.CELO]: '0x75f59534dd892c1f8a7b172d639fa854d529ada3',
  [ChainId.OPTIMISM]: '0xeAa6877139d436Dc6d1f75F3aF15B74662617B2C',
  [ChainId.OPTIMISM_KOVAN]: '0x91c88479f21203444d2b20aa001f951ec8cf2f68',
};

/**
 * RPC error codes for retry requests
 */
const MULTICALL_RETRYABLE_ERRORS: { [code: number]: number[] | undefined } = {
  // Internal JSON-RPC error.
  [32603]: [
    32000, // header not found
    32005, // Internal RPC Error. Try again after some time..
    32603, // Internal RPC Error.
  ],
  // Header not found
  [32000]: undefined,
};

export { MULTICALL_ABI, MULTICALL_NETWORKS, MULTICALL_RETRYABLE_ERRORS };
