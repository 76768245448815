import { getAddress } from '@ethersproject/address';
import { ChainId } from '@plasma/plasmaswap-sdk';
import { isAddress } from '.';
import { useSupportedChains } from '../hooks/use-supported-chains';
import { WidgetTab } from '../types/widget-tabs';

/**
 * Returns widget tab from pathname
 */
export function getWidgetTab(): WidgetTab {
  const pathname = new URL(location.href).pathname.split('/')[1];
  const findTab = Object.values(WidgetTab).find(tab => tab.toLowerCase() === pathname.toLowerCase());
  return findTab || WidgetTab.swap;
}

/**
 * Returns param from query string
 */
export function getWidgetParam(paramName: string): string | undefined {
  const params = new URL(location.href).searchParams;
  const paramValue = params.get(paramName) || undefined;
  return paramValue;
}

/**
 * Returns referral address from query string
 */
export function getReferral(): string | null {
  const referralParam = getWidgetParam('r') || '';
  if (isAddress(referralParam)) {
    return getAddress(referralParam);
  } else {
    // console.warn('invalid referral address');
    return null;
  }
}

/**
 * Returns chainId from query string
 */
export function useGetQueryChainId(): ChainId | null {
  const chainIds: ChainId[] = useSupportedChains();
  const chainId = Number(getWidgetParam('chainId') || 0);
  const correctChainId = chainIds.includes(chainId);

  return correctChainId ? chainId : null;
}
