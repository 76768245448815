import { Currency } from '@plasma/plasmaswap-sdk';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { getLogoUrl } from '../../utils/get-logo-url';
import { LoadingSkeleton } from '../loading-skeleton/loading-skeleton';
import './logo.scss';

export interface LogoProps {
  size?: number;
  currency?: Currency | string | null;
  alt?: string;
  fallbackUrl?: string;
  loading?: boolean;
}

export function Logo(props: LogoProps): JSX.Element {
  const { size = 24, currency, alt, fallbackUrl, loading } = props;
  const [logoUrl, setLogoUrl] = useState<string>();

  useEffect(() => {
    let destroyed = false;
    getLogoUrl([currency, fallbackUrl]).then(url => {
      if (!destroyed) {
        setLogoUrl(url);
      }
    });
    return () => {
      destroyed = true;
    };
  }, [currency, fallbackUrl]);

  if (!loading && logoUrl) {
    return <img alt={alt} src={logoUrl} style={{ width: size, minWidth: size, height: size, borderRadius: '50%' }} />;
  } else {
    return (
      <LoadingSkeleton>
        <Skeleton circle={true} width={size} height={size} style={{ display: 'block' }} />
      </LoadingSkeleton>
    );
  }
}

export interface CurrencyLogoProps {
  size?: number;
  currency?: Currency | string | null;
  currencySecond?: Currency | string | null;
  provider?: string | null;
  className?: string;
  loading?: boolean;
}

/**
 * Renders image by currency data
 * @constructor
 */
export function CurrencyLogo(props: CurrencyLogoProps): JSX.Element {
  const { loading, size = 24, currency, currencySecond, className = '', provider } = props;
  return (
    <span className={`currency-logo-wrapper ${className}`}>
      <span className="currency-logo-background">
        <Logo loading={loading} size={size} currency={currency} alt={`${typeof currency === 'string' ? currency : currency?.symbol ?? 'Token'} logo`} />
      </span>
      {currencySecond ? (
        <span className="currency-logo-background">
          <Logo loading={loading} size={size} currency={currencySecond} alt={`${typeof currencySecond === 'string' ? currencySecond : currencySecond?.symbol ?? 'Token'} logo`} />
        </span>
      ) : null}
      {provider ? (
        <div className="provider-container">
          <Logo loading={loading} size={Math.round(size / 1.6)} currency={provider} alt={`${provider} logo`} />
        </div>
      ) : null}
    </span>
  );
}
