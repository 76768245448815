import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTransactionExpiry } from '../../state/user/user.hooks';
import { InfoHelper } from '../info-helper/info-helper';

enum ValidationError {
  VALID,
  INVALID_INPUT,
}

export function TransactionExpiry(): JSX.Element {
  const [transactionExpiry, setTransactionExpiry] = useTransactionExpiry();
  const [transactionExpiryInput, setTransactionExpiryInput] = useState('');
  const { t } = useTranslation();

  const validationError = useMemo<ValidationError>(() => {
    const isValid = transactionExpiryInput === '' || (transactionExpiry / 60).toString() === transactionExpiryInput;

    if (transactionExpiryInput !== '' && !isValid) {
      return ValidationError.INVALID_INPUT;
    } else {
      return ValidationError.VALID;
    }
  }, [transactionExpiry, transactionExpiryInput]);

  // Parse the transaction expiry input value (Remove additional characters)
  const parseCustomTransactionExpiry = useCallback(
    (value: string) => {
      // Filter invalid chars
      value = value.replace(/[^0-9]/gi, '');

      setTransactionExpiryInput(value);

      try {
        const valueAsInt: number = Number.parseInt(value) * 60;
        if (!Number.isNaN(valueAsInt) && valueAsInt > 0) {
          setTransactionExpiry(valueAsInt);
        }
      } catch {}
    },
    [setTransactionExpiry],
  );

  return (
    <div className="settings-block">
      <div className="label">
        <span>{t('transaction_deadline')}</span>
        <InfoHelper text={t('transaction_deadline_tooltip')} backdrop="white" />
      </div>

      <div className="settings-buttons">
        <button
          className={`btn btn-fixed-value ${transactionExpiry === 600 ? 'active' : ''}`}
          onClick={() => {
            setTransactionExpiryInput('');
            setTransactionExpiry(600);
          }}
        >
          <span>10m</span>
        </button>
        <button
          className={`btn btn-fixed-value ${transactionExpiry === 1800 ? 'active' : ''}`}
          onClick={() => {
            setTransactionExpiryInput('');
            setTransactionExpiry(1800);
          }}
        >
          <span>30m</span>
        </button>
        <button
          className={`btn btn-fixed-value ${transactionExpiry === 3600 ? 'active' : ''}`}
          onClick={() => {
            setTransactionExpiryInput('');
            setTransactionExpiry(3600);
          }}
        >
          <span>60m</span>
        </button>

        <div className={`custom-value ${![600, 1800, 3600].includes(transactionExpiry) ? 'active' : ''} ${validationError === ValidationError.INVALID_INPUT ? 'invalid' : ''}`}>
          <input
            value={transactionExpiryInput}
            placeholder={`${transactionExpiry / 60}`}
            onBlur={() => parseCustomTransactionExpiry((transactionExpiry / 60).toFixed(0))}
            onChange={e => parseCustomTransactionExpiry(e.target.value)}
          />

          <span>m</span>
        </div>
      </div>

      <div className="settings-errors">
        {validationError ? <span className="error">{validationError === ValidationError.INVALID_INPUT ? 'invalid_transaction_deadline_input' : ''}</span> : null}
      </div>
    </div>
  );
}
