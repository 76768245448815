import { Currency, NATIVE, Token } from '@plasma/plasmaswap-sdk';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SUGGESTED_BASES } from '../../constants';
import { useTokens } from '../../hooks/use-token';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { formatTokenSymbol } from '../../utils/format-token-symbol';
import { InfoHelper } from '../info-helper/info-helper';
import { CurrencyLogo } from '../logo/logo';

export function CommonBases({ onSelect, selectedCurrency }: { selectedCurrency?: Currency | null; onSelect: (currency: Currency) => void }): JSX.Element | null {
  const { chainId } = useActiveWeb3React();
  const { t } = useTranslation();

  const addresses: string[] = useMemo(() => {
    if (!chainId) {
      return [];
    }
    return SUGGESTED_BASES[chainId].map(token => token.address);
  }, [chainId]);

  const tokens = useTokens(addresses);

  if (!chainId) {
    return null;
  }

  return (
    <div className="common-basses">
      <div className="label">
        <span>{t('common_bases')}</span>
        <InfoHelper text={t('common_bases_tooltip')} backdrop="white" />
      </div>
      <div className="basses-list">
        <button
          className="btn btn-basses-item"
          onClick={() => {
            if (!selectedCurrency || !selectedCurrency.equals(NATIVE[chainId])) {
              onSelect(NATIVE[chainId]);
            }
          }}
          disabled={selectedCurrency === NATIVE[chainId]}
        >
          <CurrencyLogo currency={chainId && NATIVE[chainId]} size={20} />
          <span className="label">{formatTokenSymbol(NATIVE[chainId])}</span>
        </button>

        {tokens.map((token: Token | null | undefined, index: number) => {
          if (!token) {
            return null;
          }
          const selected = !!selectedCurrency?.equals(token);
          return (
            <button className="btn btn-basses-item" onClick={() => !selected && onSelect(token)} disabled={selected} key={index}>
              <CurrencyLogo currency={token} size={20} />
              <span className="label">{formatTokenSymbol(token)}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
}
