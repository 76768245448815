import { ChainId, Currency, NATIVE, Token } from '@plasma/plasmaswap-sdk';
import { NATIVE_ADDRESSES_LIST } from '../constants';

export function currencyId(currency: Currency, chainId?: ChainId): string {
  if (chainId && (currency === NATIVE[chainId] || NATIVE_ADDRESSES_LIST.includes((currency as Token)?.address))) {
    return 'NATIVE';
  }
  if (currency instanceof Token) {
    return currency.address;
  }
  throw new Error('invalid currency');
}
