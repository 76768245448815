import Big from 'big.js';
import { isNumber } from './is-number';

export interface FormatAmountSettings {
  maxDecimals?: number; // Default 6, The maximum number of decimal places. If the number is less than a valid one, for example 0.000000023 converts to <0.000001
  decimalPlaces?: number; // Default undefined, How many numbers should be left after the decimal point, even if they are zeros? 1.00
  groupSeparator?: string | false; // Default ','
  preferredStringLength?: number; // Default 24, Desired string length in characters.
}

export type AmountInput = number | string | undefined | null;

/**
 * Returns formatted number
 * @param amount
 * @param s - settings (one of significantDigits or decimalPlaces)
 */
export function formatAmount(amount: AmountInput, s?: FormatAmountSettings): string | null {
  if (typeof amount === 'undefined' || amount === null || !isNumber(amount) || amount === Infinity || amount === -Infinity) {
    return null;
  }
  Big.NE = -18;
  Big.PE = 30;
  let bAmount: Big;
  try {
    bAmount = Big(amount);
  } catch (e) {
    console.error('Invalid amount input', amount);
    console.error(e);
    return null;
  }

  const decimalPlaces = s?.decimalPlaces;
  const maxDecimals = s?.maxDecimals !== undefined ? s.maxDecimals : decimalPlaces !== undefined ? decimalPlaces : 6;
  const preferredStringLength = s?.preferredStringLength || 24;
  const groupSeparator = s?.groupSeparator === undefined ? ',' : s.groupSeparator;

  // ZERO
  if (bAmount.eq(0)) {
    return decimalPlaces !== undefined ? (0).toFixed(decimalPlaces) : '0';
  }

  // Value less than available
  const minValue = Big(10).pow(-maxDecimals);
  if (bAmount.lt(minValue)) {
    if (decimalPlaces === undefined) {
      return `<${minValue.toString()}`;
    } else {
      return `<${Big(10).pow(-decimalPlaces)}`;
    }
  }

  // let strMultiplier: ShortMultiplier = '';
  let [integer, fractional] = bAmount.toString().split('.');

  // Formatting fractional
  if (fractional) {
    if (decimalPlaces !== undefined) {
      fractional = fractional.length > decimalPlaces ? fractional.substring(0, decimalPlaces) : `${fractional}${'0'.repeat(decimalPlaces - fractional.length)}`;
    } else {
      const preferredFractionalLength = preferredStringLength - integer.length;
      fractional = fractional.substring(0, Math.min(maxDecimals, preferredFractionalLength < 0 ? 0 : preferredFractionalLength));
      fractional = fractional.replace(/0+$/, '');
    }
  }

  // Calculate the multiplier and adjust the string to the desired length
  if (preferredStringLength < integer.length) {
    let exp = Big(fractional?.length ? -fractional.length : 0);

    const roundAmount = `${integer}${fractional || ''}`;
    const significantPart = roundAmount.slice(0, preferredStringLength);
    const insignificantPart = roundAmount.slice(preferredStringLength);

    exp = exp.plus(insignificantPart.length);

    const multiplierIndex = exp.div(3).round(0, 3); // RoundUp = 3,
    if (multiplierIndex.gt(0)) {
      exp = exp.minus(multiplierIndex.times(3));
    }

    const multiplier = Big(10).pow(exp.toNumber()).toString();
    const result = Big(significantPart).times(multiplier).toString();

    [integer, fractional] = result.split('.');
  }

  // Formatting output
  if (groupSeparator) {
    integer = integer.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, `$1${groupSeparator}`);
  }
  if (fractional) {
    integer = `${integer}.${fractional}`;
  }

  return integer;
}

/**
 * Normalize amount to digit string
 * @param amount
 */
export function normalizeAmount(amount: AmountInput): string {
  if (typeof amount === 'undefined' || amount === null || isNaN(parseFloat(amount as string))) {
    amount = '0';
  }
  return `${amount}`.replace(/[^\d.,\-+]/, '');
}

/**
 * Format percent - display number between 0 - 100 (by default showing decimals two digit)
 * @param percent
 * @param decimalPlaces
 */
export function formatPercent(percent: AmountInput, decimalPlaces = 2): number {
  const numPercent = Number(normalizeAmount(percent));

  return Number(numPercent.toFixed(decimalPlaces));
}
