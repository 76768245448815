import { Placement } from '@popperjs/core';
import Portal from '@reach/portal';
import React, { useCallback, useState } from 'react';
import { usePopper } from 'react-popper';
import { useInterval } from '../../hooks/use-interval';
import './tooltip.scss';

interface TooltipProps {
  text: string | JSX.Element | React.ReactNode;
  toggle?: 'show' | 'hide' | 'mouse';
  placement?: Placement;
  children: React.ReactNode;
}

export function Tooltip({ text, toggle = 'mouse', placement = 'auto', children }: TooltipProps): JSX.Element {
  const [mouseIn, setMouseIn] = useState(false);
  const open = useCallback(() => setMouseIn(true), [setMouseIn]);
  const close = useCallback(() => setMouseIn(false), [setMouseIn]);
  const show = (toggle === 'mouse' && mouseIn) || toggle === 'show';

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, update, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: 'fixed',
    modifiers: [
      { name: 'offset', options: { offset: [8, 8] } },
      { name: 'arrow', options: { element: arrowElement, padding: 3 } },
    ],
  });
  const updateCallback = useCallback(() => {
    update && update();
  }, [update]);

  useInterval(updateCallback, show ? 100 : null);

  return (
    <>
      <div className="tooltiped-element" ref={setReferenceElement as any} onMouseEnter={open} onMouseLeave={close}>
        {children}
      </div>
      <Portal>
        <div ref={setPopperElement as any} className={`tooltip ${show ? 'show' : ''}`} style={styles.popper} {...attributes.popper}>
          <span>{text}</span>
          <div
            className={`tooltip-arrow tooltip-arrow-${attributes.popper?.['data-popper-placement'] ?? ''}`}
            ref={setArrowElement as any}
            style={styles.arrow}
            {...attributes.arrow}
          />
        </div>
      </Portal>
    </>
  );
}
