import { TransactionRequest } from '@ethersproject/abstract-provider';
import { BigNumberish } from '@ethersproject/bignumber';
import { hexlify, isBytes } from '@ethersproject/bytes';
import { BigNumber } from '@ethersproject/bignumber';
import { GsnNormalizedTransactionRequest } from '../types';
import { isAddress } from './index';
import { toBigNumber } from './to-big-number';

export function gsnNormalizeTransaction(tx: TransactionRequest & { gas?: BigNumberish }): GsnNormalizedTransactionRequest {
  const to = isAddress(tx.to);
  const from = isAddress(tx.from);
  const gasLimit = toBigNumber(tx.gas || tx.gasLimit);
  const gasPrice = toBigNumber(tx.gasPrice);
  const maxFeePerGas = toBigNumber(tx.maxFeePerGas);
  const maxPriorityFeePerGas = toBigNumber(tx.maxPriorityFeePerGas);
  const value = toBigNumber(tx.value || '0x0') || BigNumber.from(0);
  const data = isBytes(tx.data) ? hexlify(tx.data) : typeof tx.data === 'string' && tx.data.startsWith('0x') ? tx.data : null;
  const nonce = toBigNumber(tx.nonce);

  if (!gasLimit) {
    throw new Error('GSN: Transaction field "gas" must be defined and valid number');
  }
  if (!to) {
    throw new Error('GSN: Transaction field "to" must be defined and valid 42 character address');
  }
  if (!from) {
    throw new Error('GSN: Transaction field "from" must be defined and valid 42 character address');
  }
  if (!data) {
    throw new Error('GSN: Transaction field "data" must be defined and start with 0x');
  }

  let normalizedTx: GsnNormalizedTransactionRequest;
  if (maxFeePerGas && maxPriorityFeePerGas) {
    normalizedTx = {
      from,
      to,
      nonce: nonce ? nonce.toString() : undefined,
      value: value.toString(),
      data: data,
      type: tx.type,
      chainId: tx.chainId,
      gasLimit: gasLimit.toString(),
      maxFeePerGas: maxFeePerGas.toString(),
      maxPriorityFeePerGas: maxPriorityFeePerGas.toString(),
      accessList: tx.accessList,
      customData: tx.customData,
    };
  } else if (gasPrice) {
    normalizedTx = {
      from,
      to,
      nonce: nonce ? nonce.toString() : undefined,
      value: value.toString(),
      data: data,
      type: tx.type,
      chainId: tx.chainId,
      gasLimit: gasLimit.toString(),
      gasPrice: gasPrice.toString(),
      accessList: tx.accessList,
      customData: tx.customData,
    };
  } else {
    throw new Error('GSN: Transaction must provide gasPrice or (maxFeePerGas and maxPriorityFeePerGas)');
  }

  (Object.keys(normalizedTx) as (keyof GsnNormalizedTransactionRequest)[]).forEach(key => (normalizedTx[key] === undefined ? Reflect.deleteProperty(normalizedTx, key) : null));

  return normalizedTx;
}
