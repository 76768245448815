import { LogDescription } from '@ethersproject/abi';
import { TransactionReceipt, TransactionRequest } from '@ethersproject/abstract-provider';
import { getAddress } from '@ethersproject/address';
import { BigNumber } from '@ethersproject/bignumber';
import { hexlify, isBytes, joinSignature, Signature } from '@ethersproject/bytes';
import { Contract } from '@ethersproject/contracts';
import { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers';
import { ChainId, EIP712_DOMAIN_ABI, EIP712Domain, EIP712Parameter, Token } from '@plasma/plasmaswap-sdk';
import { EIP712TypedData } from '@plasma/plasmaswap-sdk/dist/utils/eip712';
import { getGasPrices, getGasStationEstimateGas, getGasStationRelayInfo, getGasStationTxFee, postGasStationSendTx } from '../api';
import { DEFAULT_TRANSACTION_EXPIRY, GAS_STATION_URL } from '../constants';
import { ERC20_INTERFACE } from '../constants/abis/erc20';
import { GAS_STATION_INTERFACE } from '../constants/abis/gas-station';
import { GSN_RECIPIENT_INTERFACE } from '../constants/abis/gsn-recipient';
import { GasPrices, GasStationTxFeeQuery, GsnNormalizedTransactionRequest, GsnPostTransaction, GsnWeb3ProviderFailCode, GsnWeb3ProviderState } from '../types';
import { getContract, isAddress } from '../utils';
import { gsnNormalizeTransaction } from '../utils/gsn-normalize-transaction';
import { signTypedData } from '../utils/sign-typed-data';
import { GsnNoLiquidityError, GsnRelayAbortError, GsnRuntimeError, GsnTxExecuteError } from './gsn-relay-errors';

enum GsnSupportedMethods {
  SEND_TRANSACTION = 'eth_sendTransaction',
  ESTIMATE_GAS = 'eth_estimateGas',
  GET_TRANSACTION_RECEIPT = 'eth_getTransactionReceipt',
}

const METHODS_FOR_SIGN: string[] = [
  'eth_sendTransaction',
  'personal_sign',
  'eth_sign',
  'eth_signTypedData',
  'eth_signTypedData_v2',
  'eth_signTypedData_v3',
  'eth_signTypedData_v4',
  'personal_unlockAccount',
  'eth_accounts',
];
const DEFAULT_BLOCK_GAS_LIMIT = BigNumber.from(20_000_000);
const APPROVE_METHOD_HASH = ERC20_INTERFACE.getSighash('approve'); // '0x095ea7b3';
const TOPICS_MAP_GAS_STATION = Object.values(GAS_STATION_INTERFACE.events).map(({ name }) => GAS_STATION_INTERFACE.getEventTopic(name));

const EIP712_RELAY_TX: EIP712Parameter[] = [
  { name: 'from', type: 'address' },
  { name: 'to', type: 'address' },
  { name: 'gas', type: 'uint256' },
  { name: 'nonce', type: 'uint256' },
  { name: 'deadline', type: 'uint256' },
  { name: 'data', type: 'bytes' },
];

export class GsnWeb3Provider extends JsonRpcProvider {
  /**
   * GSN init status
   */
  state: GsnWeb3ProviderState;
  /**
   * If the GSN provider's status is fail, it contains an error code
   */
  errorCode?: GsnWeb3ProviderFailCode;
  /**
   * The _init method has been called
   * @private
   */
  private _isInitStarted: boolean;
  /**
   * Chain ID promise, resolved hex string
   * @private
   */
  private _chainId: Promise<ChainId>;
  /**
   * Custom signer, user for sign methods
   * @private
   */
  private _signer?: JsonRpcSigner;
  /**
   * URL address of gas station server
   * @private
   */
  private _gasStationUrl?: string;
  /**
   * The whole logic of the fe calculation, signature verification, execution of a user transaction.
   * @private
   */
  private _gasStationContract?: Contract;
  /**
   * Supported tokens addresses. With which we pay a commission.
   * @private
   */
  private _supportedTokensAddresses?: string[];
  /**
   * The token address is added, if there is no liquidity, to pay the fee.
   * @private
   */
  private _disabledTokensAddresses: string[];
  /**
   * Commission as a percentage of the transaction fee, for processing one transaction.
   * @private
   */
  private _txRelayFee?: number;
  /**
   * Relay contract domain, used in eth_signTypedData signature
   * @private
   */
  private _relaySignDomain?: EIP712Domain;
  /**
   * The token used to pay the fee. If not defined, then NATIVE currency is used
   * @private
   */
  private _txFeeToken?: Token;
  /**
   * The permit method call data, includes signature.
   * @private
   */
  private _approvalData?: string;
  /**
   * Saved addresses of contracts that support or do not support GSN
   * @private
   */
  private _gsnSupports: { [address: string]: Promise<boolean> };
  /**
   * Methods used for GSN calls
   * @private
   */
  private static _gsnMethods = Object.values(GsnSupportedMethods);

  constructor(rpcUrl: string) {
    super(rpcUrl);
    this.state = GsnWeb3ProviderState.PENDING;
    this._gsnSupports = {};
    this._disabledTokensAddresses = [];
    this._isInitStarted = false;
    this._chainId = super.send('eth_chainId', []).then(chainId => parseInt(chainId, 16) as ChainId);
  }

  /**
   * Send RPC command
   * @param method
   * @param params
   */
  async send(method: string, params: Array<any>): Promise<any> {
    if (method === 'eth_chainId') {
      return this._chainId;
    }

    // Made for the celo rpc node. The returned block must contain the gasLimit field, which is missing from celo.
    if (method === 'eth_getBlockByNumber') {
      const block = await super.send(method, params);

      // Define gas limit
      if (block && !block.gasLimit) {
        if (block.gasUsed) {
          const gasUsed = BigNumber.from(block.gasUsed);
          if (gasUsed.gt(DEFAULT_BLOCK_GAS_LIMIT)) {
            block.gasLimit = gasUsed.toHexString();
          }
        }

        if (!block.gasLimit) {
          block.gasLimit = DEFAULT_BLOCK_GAS_LIMIT.toHexString();
        }
      }

      // Define Difficulty
      if (block && !block.difficulty && block.totalDifficulty) {
        block.difficulty = block.totalDifficulty;
        Reflect.deleteProperty(block, 'totalDifficulty');
      }

      return block;
    }

    await this._init();

    // Handle transaction with Plasma Gas Station
    if (this.state === GsnWeb3ProviderState.READY && GsnWeb3Provider._gsnMethods.includes(method as any)) {
      switch (method) {
        case GsnSupportedMethods.SEND_TRANSACTION:
          if (this._txFeeToken && !this._disabledTokensAddresses.includes(this._txFeeToken.address)) {
            const tx: TransactionRequest = params[0];
            const isSupport = await this.hasSupportGsnTx(tx);
            if (isSupport) {
              return this._sendTransactionWithGsn(tx);
            }
          }
          break;
        case GsnSupportedMethods.ESTIMATE_GAS:
          return this._estimateGasWithGsn(params);
        case GsnSupportedMethods.GET_TRANSACTION_RECEIPT:
          return await this._getTransactionReceiptWithGsn(params);
        default:
          console.warn(`GsnWeb3Provider: Called unhandled GSN method ${method}`);
      }
    }

    // Use custom signer if possible
    if (this._signer && METHODS_FOR_SIGN.includes(method)) {
      return this._signer.provider.send(method, params);
    }

    return super.send(method, params);
  }

  /**
   * Update custom signer
   * @param signer
   */
  setSigner(signer: JsonRpcSigner | undefined): void {
    this._signer = signer;
  }

  /**
   * Choosing which token to pay fee with
   * @param token
   */
  setTxFeeToken(token?: Token): void {
    this._txFeeToken = token;
  }

  /**
   * The permit method call data, includes signature.
   * @param approvalData
   */
  setApprovalData(approvalData: string | undefined): void {
    this._approvalData = approvalData;
  }

  /**
   * Convert fee into tokens, and add the commission of the relay.
   * @param from
   * @param to
   * @param data
   * @param value
   * @param feePerGas
   * @param abort
   */
  async getTxFee(from: string, to: string, data: string, value: string, feePerGas: string, abort?: AbortSignal): Promise<{ fee: string; currency: string }> {
    if (!this._gasStationUrl) {
      throw new GsnRuntimeError('GsnWeb3Provider: The gas station node URL is undefined');
    }
    const valueBigNumber = BigNumber.from(value);
    const tokenAddress = this._txFeeToken && valueBigNumber.eq('0') ? this._txFeeToken.address : undefined;

    try {
      const query: GasStationTxFeeQuery = {
        from,
        to,
        data,
        value: valueBigNumber.toHexString(),
        token: tokenAddress,
        feePerGas,
      };
      return await getGasStationTxFee(this._gasStationUrl, query, abort);
    } catch (e: any) {
      if (e.name === 'AbortError') {
        throw e;
      }
      if (tokenAddress && /no\sliquidity/gi.test(e.message)) {
        this._disabledTokensAddresses.push(tokenAddress);
        throw new GsnNoLiquidityError();
      }
      throw new GsnRuntimeError(e.message);
    }
  }

  /**
   * Get priority fee or gas price in wei. And also estimated transaction execution time.
   * @param abort
   */
  async getGasPrices(abort?: AbortSignal): Promise<GasPrices> {
    if (!this._gasStationUrl) {
      throw new GsnRuntimeError("GsnWeb3Provider: To get gas prices, the provider's state must be ready");
    }
    return getGasPrices(this._gasStationUrl, abort);
  }

  /**
   * Does the transaction support GSN?
   * @param data
   * @param to
   * @param value
   */
  async hasSupportGsnTx({ data, to, value }: TransactionRequest): Promise<boolean> {
    if (!this._gasStationContract || !to || !data || hexlify(data).startsWith(APPROVE_METHOD_HASH) || (value && BigNumber.from(value).gt(0))) {
      return false;
    }

    return this.hasSupportGsnContract(to);
  }

  /**
   * Does the GSN contract support
   * @param address
   */
  async hasSupportGsnContract(address: string): Promise<boolean> {
    if (!this._gasStationContract) {
      return false;
    }
    if (address in this._gsnSupports) {
      return this._gsnSupports[address];
    }

    this._gsnSupports[address] = this.call({ to: address, data: GSN_RECIPIENT_INTERFACE.encodeFunctionData('isOwnGasStation', [this._gasStationContract.address]) })
      .then(result => {
        const [isOwnGasStation] = GSN_RECIPIENT_INTERFACE.decodeFunctionResult('isOwnGasStation', result);
        return isOwnGasStation;
      })
      .catch(() => false);

    return this._gsnSupports[address];
  }

  /**
   * Sign and Send transaction {@link _gsnMethods} through GSN
   * @param _tx
   * @return transaction hash
   * @private
   */
  private async _sendTransactionWithGsn(_tx: TransactionRequest): Promise<string> {
    if (!this._txFeeToken || !this._gasStationUrl) {
      throw new GsnRuntimeError('GsnWeb3Provider: Invalid dependency in send tx with GSN');
    }

    const tx: GsnNormalizedTransactionRequest = gsnNormalizeTransaction(_tx);

    const [deadline, nonce]: [string, string] = await Promise.all([
      this.getBlock('latest').then(block => (block.timestamp + DEFAULT_TRANSACTION_EXPIRY).toString()),
      this.gasStationContract.getNonce(tx.from).then((nonce: BigNumber) => nonce.toString()),
    ]);

    const approvalData = this._approvalData ? this._approvalData : '0x';

    const txRequest: GsnPostTransaction['tx'] = {
      from: tx.from,
      to: tx.to,
      gas: tx.gasLimit,
      nonce,
      deadline,
      data: tx.data,
    };

    const txData: GsnPostTransaction = {
      tx: txRequest,
      fee: {
        token: this._txFeeToken.address,
        approvalData,
      },
      signature: await this._signRelayRequest(txRequest).then(joinSignature),
    };

    return await postGasStationSendTx(this._gasStationUrl, txData);
  }

  /**
   * Rewriting the data of the requested transaction
   * @param params
   * @private
   */
  private async _getTransactionReceiptWithGsn(params: Array<any>): Promise<TransactionReceipt> {
    return super.send(GsnSupportedMethods.GET_TRANSACTION_RECEIPT, params).then((txReceipt: TransactionReceipt) => {
      const fixedTxReceipt = Object.assign({}, txReceipt);

      const logs = (fixedTxReceipt?.logs || [])
        .map(log => (TOPICS_MAP_GAS_STATION.includes(log.topics[0]?.toLowerCase()) ? GAS_STATION_INTERFACE.parseLog(log) : null))
        .filter(Boolean) as LogDescription[];

      if (!logs.length) {
        return fixedTxReceipt;
      }
      // Find and handle GSN resolved event
      const transactionExecutedLog = logs.find(log => log.name === 'TransactionExecuted');
      if (transactionExecutedLog && transactionExecutedLog.args.from) {
        fixedTxReceipt.from = transactionExecutedLog.args.from;
      }
      return fixedTxReceipt;
    });
  }

  /**
   * Rewriting the data of the estimate gas, add GSN additional gas
   * @param params
   * @private
   */
  private async _estimateGasWithGsn(params: Array<any>): Promise<any> {
    const tx = params[0];
    const to = isAddress(tx.to);
    const from = isAddress(tx.from);
    const data = isBytes(tx.data) ? hexlify(tx.data) : typeof tx.data === 'string' && tx.data.startsWith('0x') ? tx.data : null;
    const value = tx.value || '0x0';

    if (!to || !from || !data) {
      throw new GsnTxExecuteError('Not all required parameters have been transmitted to calculate the gas');
    }

    const token = this._txFeeToken && BigNumber.from(value).eq('0') ? this._txFeeToken.address : undefined;

    return getGasStationEstimateGas(this._gasStationUrl as string, { to, from, data, value, token });
  }

  /**
   * Class initialization, create contracts
   * @private
   */
  private async _init(): Promise<void> {
    if (this._isInitStarted) {
      return;
    }
    this._isInitStarted = true;

    const chainId = await this._chainId;
    this._gasStationUrl = GAS_STATION_URL[chainId];
    if (!this._gasStationUrl) {
      return this._setStateToFail(GsnWeb3ProviderFailCode.NO_CONFIGURATION);
    }

    try {
      const relayInfo = await getGasStationRelayInfo(this._gasStationUrl);
      if (relayInfo.chainId !== chainId) {
        return this._setStateToFail(GsnWeb3ProviderFailCode.WRONG_CHAIN_ID);
      }
      this._gasStationContract = getContract(relayInfo.gasStation, GAS_STATION_INTERFACE, this);

      const [name, version, txRelayFee]: [string, string, BigNumber] = await Promise.all([
        this._gasStationContract.name(),
        this._gasStationContract.version(),
        this._gasStationContract.txRelayFeePercent(),
      ]);

      this._supportedTokensAddresses = relayInfo.feeTokens.map(getAddress);
      this._txRelayFee = txRelayFee.toNumber();

      this._relaySignDomain = {
        name,
        version,
        chainId,
        verifyingContract: this._gasStationContract.address,
      };

      this.state = GsnWeb3ProviderState.READY;
    } catch (e) {
      return this._setStateToFail(GsnWeb3ProviderFailCode.RELAY_NOT_AVAILABLE);
    }
  }

  /**
   * Sets state to fail fills in the error code
   * @param errorCode
   * @private
   */
  private _setStateToFail(errorCode: GsnWeb3ProviderFailCode): void {
    this.state = GsnWeb3ProviderState.FAIL;
    this.errorCode = errorCode;
    return;
  }

  /**
   * Sign call data with eth_signTypedData
   * @private
   */
  private async _signRelayRequest(txRequest: GsnPostTransaction['tx']): Promise<Signature> {
    if (!this._relaySignDomain) {
      throw new GsnRuntimeError('GsnWeb3Provider: The signature domain not configured.');
    }

    const typedData: EIP712TypedData = {
      types: {
        EIP712Domain: EIP712_DOMAIN_ABI,
        TxRequest: EIP712_RELAY_TX,
      },
      domain: this._relaySignDomain,
      primaryType: 'TxRequest',
      message: {
        from: txRequest.from,
        to: txRequest.to,
        gas: txRequest.gas,
        nonce: txRequest.nonce,
        data: txRequest.data,
        deadline: txRequest.deadline,
      },
    };

    try {
      return await signTypedData(this, txRequest.from, typedData);
    } catch (e: any) {
      throw new GsnRelayAbortError(e.message, e.code);
    }
  }

  /**
   * Safe to get the Relay contract instance or throw error
   */
  get gasStationContract(): Contract {
    if (!this._gasStationContract) {
      throw new GsnRuntimeError("GsnWeb3Provider: Trying to get the Gas Station contract but it's undefined, please check the state.");
    }
    return this._gasStationContract;
  }

  /**
   * Safe to get supported tokens or throw error
   */
  get supportedTokensAddresses(): string[] {
    if (!this._supportedTokensAddresses) {
      return [];
    }
    return this._supportedTokensAddresses;
  }
}
