import React, { FC } from 'react';
import { PRICES_CONFIGURATION } from '../../constants';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { getTokenInstance } from '../../state/portfolio-assets/portfolio-assets.hooks';
import { PlasmaTokenInfo, WrappedTokenInfo } from '../../types';
import { getNetworkName } from '../../utils/get-network-name';
import { FormattedCurrencyAmountInFiat } from '../formatted-currency-amount-in-fiat/formatted-currency-amount-in-fiat';
import { FormattedCurrencyAmount } from '../formatted-currency-amount/formatted-currency-amount';
import { FormattedTokenSymbol } from '../formatted-token-symbol/formatted-token-symbol';
import { CurrencyLogo } from '../logo/logo';
import { NetworkLogo } from '../network-logo/network-logo';
import { Tooltip } from '../tooltip/tooltip';
import './asset-item.scss';

interface AssetItemProps {
  asset: PlasmaTokenInfo;
}

export const AssetItem: FC<AssetItemProps> = props => {
  const { asset } = props;
  const { chainId } = useActiveWeb3React();
  if (!chainId) {
    return null;
  }
  const usdToken = PRICES_CONFIGURATION[chainId].usdToken;
  const tokenInstance = getTokenInstance(asset, usdToken);
  const token = tokenInstance.balance.token as WrappedTokenInfo<PlasmaTokenInfo>;

  return (
    <div className="asset-item">
      <div className="symbol-cell">
        <div className="logo-block">
          {/* <Tooltip text={asset.symbol} placement={'top'}> */}
          <div className="logo">
            <CurrencyLogo currency={asset.logoURI} size={24} />
          </div>
          {/* </Tooltip> */}

          <div className="sub-logo">
            <Tooltip text={getNetworkName(asset.chainId)} placement={'top'}>
              <NetworkLogo size={16} chainId={asset.chainId} />
            </Tooltip>
          </div>
        </div>
        <div className="symbol">
          <FormattedTokenSymbol currency={token} />
        </div>
      </div>
      <div className="balance">
        <FormattedCurrencyAmount maxDecimals={4} hideSymbol amount={tokenInstance.balance} />
      </div>
      <div className="value">
        <FormattedCurrencyAmountInFiat amount={tokenInstance.quote} decimalPlaces={2} />
      </div>
    </div>
  );
};
