import React, { FC } from 'react';
import { UnmarshalSupportedChainId } from '../../types';
import { getNetworkName } from '../../utils/get-network-name';
import { FormattedAmountInFiat } from '../formatted-amount-in-fiat/formatted-amount-in-fiat';
import { FormattedPercent } from '../formatted-percent/formatted-percent';
import { NetworkLogo } from '../network-logo/network-logo';
import { NetworkItemSkeleton } from './network-item-skeleton';
import './network-item.scss';

interface NetworkItemProps {
  chainId: UnmarshalSupportedChainId;
  totalUsd: string;
  percent: string;
  loading?: boolean;
}

export const NetworkItem: FC<NetworkItemProps> = props => {
  const { totalUsd, chainId, percent, loading } = props;

  return !loading ? (
    <div className="network-item">
      <div className="network">
        <div className="network-logo">
          {/* <Tooltip text={getNetworkName(chainId)} placement={'top'}> */}
          <NetworkLogo size={20} chainId={chainId} />
          {/* </Tooltip> */}
        </div>
        <span className="network-name">{getNetworkName(chainId, true)}</span>
      </div>
      <div className="share">
        <FormattedPercent color={false} hideSign percent={percent} />
      </div>
      <div className="value">
        <FormattedAmountInFiat amount={totalUsd} decimalPlaces={2} />
      </div>
    </div>
  ) : (
    <NetworkItemSkeleton />
  );
};
