import copy from 'copy-to-clipboard';
import React, { useCallback } from 'react';
import { ButtonProps } from 'rebass';

export interface BtnCopyProps extends Omit<ButtonProps, 'type' | 'color'> {
  text: string;
  copiedCallback?: (copied: boolean) => void;
  children: React.ReactNode;
}

export function BtnCopy({ text, copiedCallback, children, onClick, ...rest }: BtnCopyProps): JSX.Element {
  const onCopy = useCallback(
    e => {
      copy(text);
      if (copiedCallback) {
        copiedCallback(true);
      }
      onClick && onClick(e);
    },
    [copiedCallback, onClick, text],
  );

  const onMouseLeave = useCallback(() => {
    if (copiedCallback) {
      copiedCallback(false);
    }
  }, [copiedCallback]);

  return (
    <button type="button" onClick={onCopy} {...rest} onMouseLeave={() => onMouseLeave()}>
      {children}
    </button>
  );
}
