import { defaultAbiCoder, Interface } from '@ethersproject/abi';
import { getCreate2Address } from '@ethersproject/address';
import { MaxUint256 } from '@ethersproject/constants';
import { keccak256, pack } from '@ethersproject/solidity';
import { ChainId, Currency, JSBI, NATIVE, Price, toCurrencyAmount, Token, WNATIVE } from '@plasma/plasmaswap-sdk';
import { useMemo } from 'react';
import { PRICES_CONFIGURATION } from '../constants';
import { useMultipleContractSingleData } from '../state/multicall/multicall.hooks';
import { PriceConfiguration, PriceConfigurationVersion } from '../types';
import { tryParseAmount } from '../utils/try-parse-amount';
import { useActiveWeb3React } from './web3/use-active-web3-react';

const Q96 = JSBI.exponentiate(JSBI.BigInt(2), JSBI.BigInt(96));
const Q192 = JSBI.exponentiate(Q96, JSBI.BigInt(2));
const MAX_UINT_256 = JSBI.BigInt(MaxUint256);
const MIN_USD_AMOUNT_TO_USE_PRICE = '100000';

const POOL_ADDRESS_CACHE: { [chainId in ChainId]?: { [t0: string]: { [t1: string]: string } } } = {};

const POOL_INTERFACES: { [version in PriceConfigurationVersion]: Interface } = {
  [PriceConfigurationVersion.V2]: new Interface([
    {
      constant: true,
      inputs: [],
      name: 'getReserves',
      outputs: [
        {
          internalType: 'uint112',
          name: 'reserve0',
          type: 'uint112',
        },
        {
          internalType: 'uint112',
          name: 'reserve1',
          type: 'uint112',
        },
        {
          internalType: 'uint32',
          name: 'blockTimestampLast',
          type: 'uint32',
        },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
  ]),
  [PriceConfigurationVersion.V3]: new Interface([
    {
      inputs: [],
      name: 'slot0',
      outputs: [
        { internalType: 'uint160', name: 'sqrtPriceX96', type: 'uint160' },
        { internalType: 'int24', name: 'tick', type: 'int24' },
        { internalType: 'uint16', name: 'observationIndex', type: 'uint16' },
        { internalType: 'uint16', name: 'observationCardinality', type: 'uint16' },
        { internalType: 'uint16', name: 'observationCardinalityNext', type: 'uint16' },
        { internalType: 'uint8', name: 'feeProtocol', type: 'uint8' },
        { internalType: 'bool', name: 'unlocked', type: 'bool' },
      ],
      stateMutability: 'view',
      type: 'function',
    },
  ]),
};

/**
 * Generate liquidity pool address. V2 and V3 liquidity pools.
 * @param tA One of the tokens in the pool
 * @param tB The other token in the pool
 * @param conf Price configuration
 */
function getPoolAddress(tA: Token, tB: Token, conf: PriceConfiguration): string {
  const chainId: ChainId = tA.chainId;
  const [t0, t1] = tA.sortsBefore(tB) ? [tA, tB] : [tB, tA];
  const address0 = t0.address.toLowerCase();
  const address1 = t1.address.toLowerCase();

  if (POOL_ADDRESS_CACHE[chainId]?.[address0]?.[address1]) {
    return POOL_ADDRESS_CACHE[chainId]?.[address0]?.[address1] as string;
  }

  let poolAddress: string;
  if (conf.version === PriceConfigurationVersion.V2) {
    const salt = keccak256(['bytes'], [pack(['address', 'address'], [address0, address1])]);
    poolAddress = getCreate2Address(conf.factory, salt, conf.initCodeHash);
  } else {
    const salt = keccak256(['bytes'], [defaultAbiCoder.encode(['address', 'address', 'uint24'], [address0, address1, 3000])]);
    poolAddress = getCreate2Address(conf.factory, salt, conf.initCodeHash);
  }

  const lowerCasePoolAddress = poolAddress.toLowerCase();

  POOL_ADDRESS_CACHE[chainId] = {
    ...POOL_ADDRESS_CACHE[chainId],
    [address0]: {
      ...POOL_ADDRESS_CACHE[chainId]?.[address0],
      [address1]: lowerCasePoolAddress,
    },
  };

  return lowerCasePoolAddress;
}

function usePairPrices(pools: (string | undefined)[], pairs: [Token | undefined, Token | undefined][], conf?: PriceConfiguration): (Price | undefined | null)[] {
  const method = useMemo(() => {
    if (!conf) {
      return '';
    }
    return conf.version === PriceConfigurationVersion.V2 ? 'getReserves' : 'slot0';
  }, [conf]);
  const contractInterface = useMemo(() => (conf ? POOL_INTERFACES[conf.version] : new Interface([])), [conf]);

  const results = useMultipleContractSingleData(pools, contractInterface, method);

  const serializedResult = useMemo(() => {
    return results.reduce<string>((acc: string, { loading, result }, index) => {
      if (loading) {
        acc += 'loading';
      } else if (result?.sqrtPriceX96) {
        acc += result.sqrtPriceX96.toString();
      } else if (result?.reserve0 && result?.reserve1) {
        acc += `${result.reserve0.toString()}||${result.reserve1.toString()}`;
      } else {
        acc += 'error';
      }
      if (index < results.length - 1) {
        acc += '|||';
      }
      return acc;
    }, '');
  }, [results]);

  return useMemo(() => {
    if (!conf) {
      return pairs.map(() => undefined);
    }
    const results = serializedResult.split('|||');
    return results.map((result: string, index: number) => {
      if (result === 'loading') {
        return null;
      }
      const [t0, t1] = pairs[index];
      if (!t0 || !t1 || result === 'error') {
        return undefined;
      }

      const data = result.split('||');

      if (conf.version === PriceConfigurationVersion.V2) {
        const [reserve0, reserve1]: [string, string] = t0.sortsBefore(t1) ? [data[0], data[1]] : [data[1], data[0]];
        if (reserve0 && reserve1 && reserve0 !== '0' && reserve1 !== '0') {
          return new Price(t0, t1, reserve0, reserve1);
        }
      } else {
        const sqrtRatioX96 = JSBI.BigInt(data[0]);
        const ratioX96 = JSBI.exponentiate(sqrtRatioX96, JSBI.BigInt(2));

        if (JSBI.greaterThan(ratioX96, MAX_UINT_256)) {
          if (t0.sortsBefore(t1)) {
            return new Price(t0, t1, Q96, sqrtRatioX96);
          } else {
            return new Price(t0, t1, sqrtRatioX96, Q96);
          }
        }

        if (t0.sortsBefore(t1)) {
          return new Price(t0, t1, Q192, ratioX96);
        } else {
          return new Price(t0, t1, ratioX96, Q192);
        }
      }
      return undefined;
    });
  }, [serializedResult, conf, pairs]);
}

/**
 * Returns currencies exchange prices in USD
 * @param currencies
 */
export function useCurrencyPrices(currencies: (Currency | null | undefined)[]): (Price | null | undefined)[] {
  const { chainId } = useActiveWeb3React();
  const priceConfiguration = useMemo(() => chainId && PRICES_CONFIGURATION[chainId], [chainId]);
  const [wnative, usd] = useMemo(() => (chainId && priceConfiguration ? [WNATIVE[chainId], priceConfiguration.usdToken] : [undefined, undefined]), [chainId, priceConfiguration]);
  const minUsdAmountToUsePrice = useMemo(() => tryParseAmount(MIN_USD_AMOUNT_TO_USE_PRICE, usd), [usd]);
  const tokens = useMemo(() => currencies.map(currency => (currency ? currency.wrapped() : currency)), [currencies]);

  // Token lowercase addresses. Last index currency pair is WETH-USDC
  const pairs: [Token | undefined, Token | undefined][] = useMemo(() => {
    if (!wnative || !usd) {
      return [];
    }

    const pairs: [Token | undefined, Token | undefined][] = [];

    tokens.forEach(token => {
      pairs.push([token ? (!wnative.equals(token) ? token : undefined) : undefined, wnative]);
      pairs.push([token ? (!usd.equals(token) ? token : undefined) : undefined, usd]);
    });

    pairs.push([wnative, usd]);

    return pairs;
  }, [wnative, usd, tokens]);

  // Liquidity pools addresses
  const pools: (string | undefined)[] = useMemo(() => {
    if (!priceConfiguration) {
      return [];
    }

    return pairs.map(([t0, t1]) => {
      if (t0 && t1) {
        return getPoolAddress(t0, t1, priceConfiguration);
      } else {
        return undefined;
      }
    });
  }, [pairs, priceConfiguration]);

  const prices = usePairPrices(pools, pairs, priceConfiguration);

  return useMemo(() => {
    const usdEthPrice = prices[prices.length - 1];

    return currencies.map((currency, index) => {
      if (currency === null) {
        return null;
      }
      const token = tokens[index];
      if (!currency || !token || !wnative || !usd) {
        return undefined;
      }
      const ethPrice = prices[index * 2];
      const usdPrice = prices[index * 2 + 1];

      // Handle weth/eth
      if (token.equals(wnative)) {
        if (!usdPrice) {
          return usdPrice;
        }
        return new Price(currency, usd, usdPrice.denominator, usdPrice.numerator);
      }
      // Handle usd
      if (token.equals(usd)) {
        return new Price(usd, usd, '1', '1');
      }

      // Handle loading
      if (ethPrice === null || usdPrice === null) {
        return null;
      }

      // Direct token price in USD
      if (usdPrice && minUsdAmountToUsePrice) {
        const usdPriceUsdReserved = toCurrencyAmount(usd, usdPrice.numerator);
        if (usdPriceUsdReserved.greaterThan(minUsdAmountToUsePrice)) {
          return new Price(token, usd, usdPrice.denominator, usdPrice.numerator);
        }
      }
      // Token price via ETH
      else if (ethPrice && usdEthPrice) {
        const usdPrice = usdEthPrice.invert().multiply(ethPrice.invert()).invert();
        return new Price(token, usd, usdPrice.denominator, usdPrice.numerator);
      }

      return undefined;
    });
  }, [prices, currencies, tokens, wnative, usd, minUsdAmountToUsePrice]);
}

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export function useCurrencyPrice(currency: Currency | null | undefined): Price | null | undefined {
  const currencies = useMemo(() => [currency], [currency]);
  return useCurrencyPrices(currencies)?.[0];
}

/**
 * Return NATIVE (ETH, BSC, MATIC, etc.) currency price
 */
export function useEthPrice(): Price | null | undefined {
  const { chainId } = useActiveWeb3React();
  return useCurrencyPrice(chainId ? NATIVE[chainId] : undefined);
}
