import { ChainId, NETWORK_LABEL } from '@plasma/plasmaswap-sdk';
import React, { FC } from 'react';
import { NETWORK_ICON } from '../../constants';
import { useChainSelector } from '../../hooks/use-chain-selector';
import { useSupportedChains } from '../../hooks/use-supported-chains';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { FormControlSelect } from '../forms/form-control-select';
import './chain-selector.scss';

export const ChainSelector: FC = () => {
  const { chainId } = useActiveWeb3React();
  const changeWalletChain = useChainSelector();
  const chainIds: ChainId[] = useSupportedChains();

  const options = chainIds.map(chainId => ({
    value: `${chainId}`,
    label: NETWORK_LABEL[chainId],
    logo: NETWORK_ICON[chainId],
  }));

  return (
    <FormControlSelect
      options={options}
      value={`${chainId}`}
      onChange={value => {
        changeWalletChain(+value);
      }}
      className="chain-selector"
    />
  );
};
