import { Percent } from '@plasma/plasmaswap-sdk';
import Big from 'big.js';
import { useMemo } from 'react';
import { NEVER_RELOAD } from '../../state/multicall/multicall.helpers';
import { useSingleCallResult, useSingleContractMultipleData } from '../../state/multicall/multicall.hooks';
import { useReferrals } from '../../state/user/user.hooks';
import { CallState, CallStateResult } from '../../types';
import { useHyperDexRouterContract } from '../use-contract';

const FEE_REFERRALS_CALL_INPUTS = [[0], [1], [2], [3]];

export function useHyperDexFeeReferrals(): [string, string, string, string] | null | undefined {
  const routerContract = useHyperDexRouterContract();
  const feeReferralsResults = useSingleContractMultipleData(routerContract, 'feeReferrals', FEE_REFERRALS_CALL_INPUTS, NEVER_RELOAD);
  return useMemo(() => {
    if (feeReferralsResults.some(i => i.loading)) {
      return null;
    }

    if (feeReferralsResults.some(i => !i.result)) {
      return undefined;
    }

    return feeReferralsResults.map((result: CallState) => {
      return (result.result as CallStateResult)[0].toString();
    }) as [string, string, string, string];
  }, [feeReferralsResults]);
}

/**
 * Returns Hyper Dex fees, in percents
 */
export function useHyperDexFeePercent(): Percent | null | undefined {
  const referrals = useReferrals();
  const routerContract = useHyperDexRouterContract();
  const feeReferrals = useHyperDexFeeReferrals();

  const feeBeneficiaryResult = useSingleCallResult(routerContract, 'feeBeneficiary', undefined, NEVER_RELOAD);
  const feeBeneficiary = useMemo(() => (feeBeneficiaryResult.result ? feeBeneficiaryResult.result[0].toString() : undefined), [feeBeneficiaryResult]);

  const loading = useMemo(() => feeBeneficiaryResult.loading || feeReferrals === null, [feeReferrals, feeBeneficiaryResult.loading]);

  return useMemo(() => {
    if (loading) {
      return null;
    }
    if (!feeBeneficiary || !feeReferrals) {
      return undefined;
    }

    let totalFee = Big(0);
    for (let i = 0; i < referrals.length; i++) {
      if (!feeReferrals[i]) {
        break;
      }
      totalFee = totalFee.add(feeReferrals[i]);
    }
    totalFee = totalFee.add(feeBeneficiary);

    return new Percent(totalFee.toString(), '10000');
  }, [loading, feeBeneficiary, feeReferrals, referrals.length]);
}
