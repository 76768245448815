import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSlippageTolerance } from '../../state/user/user.hooks';
import { InfoHelper } from '../info-helper/info-helper';

enum ValidationError {
  VALID,
  INVALID_INPUT,
  RISKY_LOW,
  RISKY_HIGH,
}

export function SlippageTolerance(): JSX.Element {
  const [slippageTolerance, setSlippageTolerance] = useSlippageTolerance();
  const [slippageToleranceInput, setSlippageToleranceInput] = useState('');
  const { t } = useTranslation();
  // Slippage tolerance validation error (or warning)
  const validationError = useMemo<ValidationError>(() => {
    const isValid = !(slippageToleranceInput !== '' && (slippageTolerance / 100).toFixed(2) !== Number.parseFloat(slippageToleranceInput).toFixed(2));

    if (slippageToleranceInput !== '' && !isValid) {
      return ValidationError.INVALID_INPUT;
    } else if (slippageTolerance < 50 && isValid) {
      return ValidationError.RISKY_LOW;
    } else if (slippageTolerance > 500 && isValid) {
      return ValidationError.RISKY_HIGH;
    } else {
      return ValidationError.VALID;
    }
  }, [slippageTolerance, slippageToleranceInput]);

  // Parse the slippage tolerance input value (Remove additional characters)
  const parseCustomSlippageTolerance = useCallback(
    (value: string) => {
      // Filter invalid chars
      value = value.replace(/[^.,0-9]/gi, '');
      // Replace comma to dot
      value = value.replace(',', '.');

      setSlippageToleranceInput(value);

      try {
        const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString());
        if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat < 5000) {
          setSlippageTolerance(valueAsIntFromRoundedFloat);
        }
      } catch {}
    },
    [setSlippageTolerance],
  );

  return (
    <div className="settings-block">
      <div className="label">
        <span>{t('slippage_tolerance')}</span>
        <InfoHelper text={t('slippage_tolerance_tooltip')} backdrop="white" />
      </div>

      <div className="settings-buttons">
        <button
          className={`btn btn-fixed-value ${slippageTolerance === 10 ? 'active' : ''}`}
          onClick={() => {
            setSlippageToleranceInput('');
            setSlippageTolerance(10);
          }}
        >
          <span>0.1%</span>
        </button>
        <button
          className={`btn btn-fixed-value ${slippageTolerance === 50 ? 'active' : ''}`}
          onClick={() => {
            setSlippageToleranceInput('');
            setSlippageTolerance(50);
          }}
        >
          <span>0.5%</span>
        </button>
        <button
          className={`btn btn-fixed-value ${slippageTolerance === 100 ? 'active' : ''}`}
          onClick={() => {
            setSlippageToleranceInput('');
            setSlippageTolerance(100);
          }}
        >
          <span>1%</span>
        </button>

        <div className={`custom-value ${![10, 50, 100].includes(slippageTolerance) ? 'active' : ''} ${validationError === ValidationError.INVALID_INPUT ? 'invalid' : ''}`}>
          {!!slippageToleranceInput && (validationError === ValidationError.RISKY_LOW || validationError === ValidationError.RISKY_HIGH) ? (
            <i className="pi pi-warning warning-icon" />
          ) : null}

          <input
            maxLength={75}
            value={slippageToleranceInput}
            placeholder={(slippageTolerance / 100).toFixed(2)}
            onBlur={() => parseCustomSlippageTolerance((slippageTolerance / 100).toFixed(2))}
            onChange={e => parseCustomSlippageTolerance(e.target.value)}
          />

          <span>%</span>
        </div>
      </div>

      <div className="settings-errors">
        {validationError ? (
          <span className={validationError === ValidationError.INVALID_INPUT ? 'error' : 'warning'}>
            {validationError === ValidationError.INVALID_INPUT
              ? t('enter_valid_slippage_percentage')
              : validationError === ValidationError.RISKY_LOW
              ? t('your_transaction_may_fail')
              : t('your_transaction_may_be_frontrun')}
          </span>
        ) : null}
      </div>
    </div>
  );
}
