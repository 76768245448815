import { ChainId } from '@plasma/plasmaswap-sdk';
import { createReducer } from '@reduxjs/toolkit';
import { updateVersion } from '../global/global.actions';
import { addTokenToWatchBalance, fetchWalletTotal } from './wallets.actions';

export type WalletsState = {
  // A list of token addresses for checking balances.
  tokensToWatchBalances: { [chainId in ChainId]?: string[] };
  totalInUsd: {
    [account in string]?: {
      total: number;
      loading: boolean;
    };
  };
};

export const initialState: WalletsState = {
  tokensToWatchBalances: {},
  totalInUsd: {},
};

export default createReducer(initialState, builder => {
  builder
    .addCase(updateVersion, state => {
      if (!state.tokensToWatchBalances) {
        state.tokensToWatchBalances = initialState.tokensToWatchBalances;
      }
      return state;
    })

    .addCase(fetchWalletTotal.pending, (state, action) => {
      const { account } = action.payload;
      state = {
        ...state,
        totalInUsd: {
          ...state.totalInUsd,
          [account]: {
            total: 0,
            loading: true,
          },
        },
      };
      return state;
    })
    .addCase(fetchWalletTotal.resolved, (state, action) => {
      const { account, total } = action.payload;
      state = {
        ...state,
        totalInUsd: {
          ...state.totalInUsd,
          [account]: {
            total,
            loading: false,
          },
        },
      };
      return state;
    })
    .addCase(fetchWalletTotal.finished, (state, action) => {
      const { account } = action.payload;
      state = {
        ...state,
        totalInUsd: {
          ...state.totalInUsd,
          [account]: {
            total: state.totalInUsd[account]?.total || 0,
            loading: false,
          },
        },
      };
      return state;
    })
    // Watch addresses management
    .addCase(addTokenToWatchBalance, (state, { payload: { chainId, address } }) => {
      if (!state.tokensToWatchBalances[chainId]) {
        state.tokensToWatchBalances[chainId] = [];
      }
      (state.tokensToWatchBalances[chainId] as string[]).push(address);
      return state;
    });
});
