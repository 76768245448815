import { ChainId } from '@plasma/plasmaswap-sdk';
import { TFunction } from 'i18next';

export function getViewOnScanText(t: TFunction, chainId?: ChainId): string {
  if (!chainId) {
    return '';
  }

  switch (chainId) {
    case ChainId.BSC:
    case ChainId.BSC_TEST:
      return t('view_on_bscscan');
    case ChainId.MATIC:
      return t('view_on_polygonscan');
    case ChainId.MAINNET:
      return t('view_on_etherscan');
    default:
      return t('view_on_explorer');
  }
}
