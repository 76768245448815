import WalletConnectProvider from '@walletconnect/ethereum-provider';
import { providers } from 'ethers';
import utils from 'ethers/lib/utils';
import { Chain, Connector, ConnectorData } from 'wagmi';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { parseChainId } from '../utils/parse-chain-id';

export type MetaMaskMobileOptions = ConstructorParameters<typeof WalletConnectProvider>[0];

export class MetaMaskMobileConnector extends Connector<WalletConnectProvider, MetaMaskMobileOptions> {
  readonly id = 'metaMask';
  readonly name = 'MetaMask';
  readonly ready = true;

  private _innerConnector: WalletConnectConnector;

  constructor(config: { chains?: Chain[]; options: MetaMaskMobileOptions }) {
    super(config);
    this._innerConnector = new WalletConnectConnector({
      chains: config.chains,
      options: {
        qrcode: true,
        qrcodeModalOptions: {
          mobileLinks: ['metamask'],
          desktopLinks: [],
        },
      },
    });
  }

  connect(): Promise<Required<ConnectorData>> {
    return this._innerConnector.connect().then(connectorData => {
      this._innerConnector.addListener('disconnect', () => this.onDisconnect());
      this._innerConnector.addListener('change', ({ account, chain }) => {
        if (account) {
          this.onAccountsChanged([account]);
        }
        if (chain) {
          this.onChainChanged(chain.id);
        }
      });
      this._innerConnector.addListener('message', message => this.emit('message', message));

      return connectorData;
    });
  }

  disconnect(): Promise<void> {
    return this._innerConnector.disconnect().then(() => {
      this._innerConnector.removeAllListeners('disconnect');
      this._innerConnector.removeAllListeners('change');
      this._innerConnector.removeAllListeners('message');
    });
  }

  getAccount(): Promise<string> {
    return this._innerConnector.getAccount();
  }

  getChainId(): Promise<number> {
    return this._innerConnector.getChainId();
  }

  getProvider(config?: { chainId?: number; create?: boolean }): Promise<WalletConnectProvider> {
    return this._innerConnector.getProvider(config);
  }

  getSigner(): Promise<providers.JsonRpcSigner> {
    return this._innerConnector.getSigner();
  }

  isAuthorized(): Promise<boolean> {
    return this._innerConnector.isAuthorized();
  }

  protected onAccountsChanged(accounts: string[]): void {
    if (accounts.length === 0) {
      this.emit('disconnect');
    } else {
      this.emit('change', { account: utils.getAddress(accounts[0]) });
    }
  }

  protected onChainChanged(chain: number | string): void {
    const id = parseChainId(chain);
    const unsupported = this.isChainUnsupported(id);
    this.emit('change', {
      chain: {
        id,
        unsupported,
      },
    });
  }

  protected onDisconnect(): void {
    this.emit('disconnect');
  }
}
