import { Currency } from '@plasma/plasmaswap-sdk';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchTokensWithNative } from '../../hooks/search-tokens/use-search-tokens-with-native';
import { useDebounce } from '../../hooks/use-debounce';
import { InfoHelper } from '../info-helper/info-helper';
import { ModalProps, Modal } from '../modal/modal';
import { CurrencyListView } from './_currency-list';
import './modal-search-currency.scss';

interface ModalSearchCurrencyProps extends ModalProps {
  selectedCurrency?: Currency | null;
  onCurrencySelect: (currency: Currency) => void;
  otherSelectedCurrency?: Currency | null;
}

export function ModalSearchCurrency({ isOpen, onDismiss, ...props }: ModalSearchCurrencyProps): JSX.Element {
  return (
    <Modal padding="0" isOpen={isOpen} onDismiss={onDismiss}>
      <ModalSearchCurrencyContent onDismiss={onDismiss} {...props} />
    </Modal>
  );
}

function ModalSearchCurrencyContent({ onDismiss, onCurrencySelect, otherSelectedCurrency, selectedCurrency }: Omit<ModalSearchCurrencyProps, 'isOpen'>): JSX.Element {
  const { t } = useTranslation();
  const prevScrollTop = useRef<number>();
  const [page, setPage] = useState(0);

  const [typedValue, setTypedValue] = useState('');
  const search = useDebounce(typedValue?.trim().toLowerCase(), 500);
  const searchAndPage: [string, number] = useMemo(() => [search, page], [search, page]);
  const debouncedSearchAndPage = useDebounce(searchAndPage, 1);

  const [loading, currencies] = useSearchTokensWithNative(...debouncedSearchAndPage);

  const onCurrencySelectCallback = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency);
      onDismiss();
    },
    [onDismiss, onCurrencySelect],
  );

  const onScrollCallback = useCallback(
    event => {
      if (!event.target.scrollTop || loading) {
        return;
      }

      const scrollRatio = (event.target.scrollTop + event.target.offsetHeight) / event.target.scrollHeight;
      if (scrollRatio > 0.9 && event.target.scrollHeight !== prevScrollTop.current) {
        prevScrollTop.current = event.target.scrollHeight;
        setPage(page => (page < 10 ? ++page : page));
      }
    },
    [loading],
  );

  // After each entry in the search field, reset the page number to zero
  useEffect(() => setPage(0), [search]);

  return (
    <div className="modal search-currency-modal">
      <div className="modal-header">
        <div className="title">
          <span>{t('select_a_token')}</span>
          <InfoHelper text="select_a_token_tooltip" backdrop="white" />
        </div>
        <button className="btn" onClick={onDismiss}>
          <i className="pi pi-close" />
        </button>
      </div>
      <div className="modal-content currency-search-content">
        <div className="search-currency-input-wrap">
          <input type="text" className="search-currency-input" value={typedValue} onChange={e => setTypedValue(e.target.value)} placeholder={t('search_name_or_paste_address')} />
        </div>

        <CurrencyListView
          currencies={currencies}
          loading={loading}
          selected={selectedCurrency}
          disabled={otherSelectedCurrency}
          onSelect={onCurrencySelectCallback}
          onScroll={onScrollCallback}
        />
      </div>
    </div>
  );
}
