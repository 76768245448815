import { GasStationTxFeeQuery, GasStationEstimateGasQuery, GasPrices } from '../types';

// export async function getGasStationRelayInfo(url: string, abort?: AbortSignal): Promise<GasStationRelayInfo> {
//   const res = await fetch(`${url}/info`, {
//     method: 'GET',
//     mode: 'cors',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     signal: abort,
//   });

//   if (!res.ok) {
//     throw new Error(`getGsnRelayInfo: ERROR: "${res.statusText}"`);
//   }
//   const rawInfo = await res.json();
//   const chainId = parseInt(rawInfo.chainId, 10) as ChainId;

//   return {
//     chainId,
//     gasStation: rawInfo.gasStation,
//     feeTokens: rawInfo.feeTokens,
//     balance: toCurrencyAmount(NATIVE[chainId], rawInfo.balance),
//   };
// }

export async function getGasStationTxFee(url: string, tx: GasStationTxFeeQuery, abort?: AbortSignal): Promise<{ fee: string; currency: string }> {
  const res = await fetch(`${url}/tx/fee`, {
    method: 'POST',
    body: JSON.stringify(tx),
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    signal: abort,
  });

  if (!res.ok) {
    let errorMessage: string | undefined = undefined;
    try {
      const error = await res.json();
      if (error.message) {
        errorMessage = error.message;
      }
    } catch (e) {}
    errorMessage = errorMessage || `getGasStationTxFee: ERROR: "${res.statusText}"`;
    throw new Error(errorMessage);
  }

  return await res.json();
}

export async function getGasStationEstimateGas(url: string, tx: GasStationEstimateGasQuery, abort?: AbortSignal): Promise<string> {
  const res = await fetch(`${url}/tx/estimate-gas`, {
    method: 'POST',
    body: JSON.stringify(tx),
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    signal: abort,
  });

  if (!res.ok) {
    let errorMessage: string | undefined = undefined;
    try {
      const error = await res.json();
      if (error.message) {
        errorMessage = error.message;
      }
    } catch (e) {}
    errorMessage = errorMessage || `getGasStationEstimateGas: ERROR: "${res.statusText}"`;
    throw new Error(errorMessage);
  }

  const rawInfo = await res.json();
  if (!rawInfo.estimateGas) {
    throw new Error(`getGasStationEstimateGas: ERROR: Relay response error.`);
  }
  return rawInfo.estimateGas;
}

// export async function postGasStationSendTx(url: string, txData: GsnPostTransaction, abort?: AbortSignal): Promise<string> {
//   const res = await fetch(`${url}/tx/send`, {
//     method: 'POST',
//     body: JSON.stringify(txData),
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     mode: 'cors',
//     signal: abort,
//   });

//   if (!res.ok) {
//     const error = await res.json();
//     throw new Error(error.message);
//   }

//   const body = await res.json();
//   return body.txHash;
// }

export async function getGasPrices(url: string, abort?: AbortSignal): Promise<GasPrices> {
  const res = await fetch(`${url}/gas`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    signal: abort,
  });

  if (!res.ok) {
    throw new Error(`getGasPrices: ERROR: "${res.statusText}"`);
  }
  return await res.json();
}
