import { ChainId } from '@plasma/plasmaswap-sdk';
import { ApprovalState } from '../types';

/**
 * Used for token approval
 */
export const TOKENS_APPROVER_CONTRACTS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: '0xDa0A99D6ACFb64392331FCf95679Fc25A3a97B92',
  [ChainId.MATIC]: '0xdA4762A0149736A34bE2E9672Ad61786fCB7f5bb',
  [ChainId.BSC]: '0x3Dccff3f8b167403491909C8150411f945f43106',
  [ChainId.AVALANCHE]: '0xfCfb13B2bF7D367000e65642DE2e480b45694CdB',
  [ChainId.FANTOM]: '0x0F65738F4DF270d236197175577D6C0fE04f21dC',
};
/**
 * Whether to show the approval button or not
 * @param as
 */
export const APPROVE_FLOW_STATES: ApprovalState[] = [
  ApprovalState.NOT_APPROVED,
  ApprovalState.INSUFFICIENT_FUNDS,
  ApprovalState.TX_PENDING,
  ApprovalState.TX_SUCCESS,
  ApprovalState.SIGNING,
  ApprovalState.SIGNED,
];
/**
 * Is it possible for a user to send a transaction. TX_SUCCESS and APPROVED allowed flags
 * @param as
 */
export const SUCCESS_APPROVE_STATES: ApprovalState[] = [ApprovalState.TX_SUCCESS, ApprovalState.APPROVED, ApprovalState.SIGNED];
/**
 * States when the submit button will be disabled
 */
export const DISABLE_APPROVE_STATES: ApprovalState[] = [ApprovalState.INSUFFICIENT_FUNDS, ApprovalState.UNKNOWN];
/**
 * States when the loading indicator will be shown
 */
export const LOADING_APPROVE_STATES: ApprovalState[] = [ApprovalState.TX_PENDING, ApprovalState.LOADING, ApprovalState.SIGNING];
