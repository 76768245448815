import Lottie from 'lottie-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Error from '../../assets/animations/loader-rejected.json';
import './modal-tx-error-view.scss';
import { getEtherscanLink } from '../../utils';
import { ExternalLink } from '../external-link/external-link';
import { ViewOnScanLinkContent } from '../view-on-scan-link-content/view-on-scan-link-content';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';

export interface ModalTxErrorViewProps {
  caption?: string;
  info?: string;
  txHash?: string;
  onDismiss?: () => void;
}

export function ModalTxErrorView({ caption, info, txHash, onDismiss }: ModalTxErrorViewProps): JSX.Element {
  const { chainId } = useActiveWeb3React();
  const { t } = useTranslation();

  return (
    <div className="modal-content modal-tx-error-view">
      <div className="icon">
        <Lottie animationData={Error} loop={false} />
      </div>
      <div className="caption">
        <span>{caption ? t(caption) : t('transaction_rejected')}</span>
      </div>
      {info ? (
        <div className="info">
          <span>{t(info)}</span>
        </div>
      ) : null}
      {chainId && txHash && (
        <div className="link">
          <ExternalLink href={getEtherscanLink(chainId, txHash, 'transaction')}>
            <ViewOnScanLinkContent chainId={chainId} />
          </ExternalLink>
        </div>
      )}
      <div className="button-block">
        <button className="btn btn-primary btn-lg" onClick={onDismiss}>
          <span>{t('close')}</span>
        </button>
      </div>
    </div>
  );
}
