import React, { FC } from 'react';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';
import { AssetsList } from '../assets-list/assets-list';
import { ConnectWalletTab } from '../connect-wallet/connect-wallet';
import { NetworksTotal } from '../networks-total/networks-total';
import { WalletInfo } from '../wallet-info/wallet-info';
import './wallet-tab.scss';

export const WalletTab: FC = () => {
  const { account } = useActiveWeb3React();

  return (
    <div className="wallet-tab">
      {account ? (
        <>
          <WalletInfo />
          <NetworksTotal />
          <AssetsList />
        </>
      ) : (
        <ConnectWalletTab />
      )}
    </div>
  );
};
