import { FunctionFragment, Interface } from '@ethersproject/abi';
import { Call, CallListenerOptions, CallMethodArg, CallMethodArgs, CallResult, CallState, CallStateResult } from '../../types';

const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
const LOWER_HEX_REGEX = /^0x[a-f0-9]*$/;
const INVALID_CALL_STATE: CallState = { valid: false, result: undefined, loading: false, error: false };
const LOADING_CALL_STATE: CallState = { valid: true, result: undefined, loading: true, error: false };

// use this options object
export const NEVER_RELOAD: CallListenerOptions = {
  blocksPerFetch: Infinity,
};

// export function compareCallStores(s1?: CallStore | null, s2?: CallStore | null): boolean {
//   if (s1 === s2) {
//     return true;
//   }
//   if (!s1 || !s2) {
//     return false;
//   }

//   const hash1 = `${s1.data || ''}${s1.blockNumber || ''}${s1.fetchingBlockNumber || ''}`;
//   const hash2 = `${s2.data || ''}${s2.blockNumber || ''}${s2.fetchingBlockNumber || ''}`;
//   return hash1 === hash2;
// }

/**
 * Validate call and convert to string key
 * @param call
 */
export function call2Key(call: Call): string {
  if (!ADDRESS_REGEX.test(call.address)) {
    throw new Error(`Invalid address: ${call.address}`);
  }
  if (!LOWER_HEX_REGEX.test(call.callData)) {
    throw new Error(`Invalid hex: ${call.callData}`);
  }
  return `${call.address}-${call.callData}-${call.methodName}`;
}

/**
 * Convert key string ('0x0xf18A3af576c7871fEA9f8F3E54e8d0E71C98F206-0x0xf18A3af576c7') to call object
 * @param key
 */
export function key2Call(key: string): Call {
  const pcs = key.split('-');
  if (pcs.length !== 3) {
    throw new Error(`Invalid call key: ${key}`);
  }
  return {
    address: pcs[0],
    callData: pcs[1],
    methodName: pcs[2],
  };
}

/**
 * Checks call result and return state
 * @param callResult
 * @param contractInterface
 * @param fragment
 */
export function callResult2State(callResult?: CallResult, contractInterface?: Interface, fragment?: FunctionFragment): CallState {
  if (!callResult) {
    return INVALID_CALL_STATE;
  }
  const { valid, data, blockNumber } = callResult;
  if (!valid) {
    return INVALID_CALL_STATE;
  }
  if (valid && !blockNumber) {
    return LOADING_CALL_STATE;
  }
  if (!contractInterface || !fragment) {
    return LOADING_CALL_STATE;
  }
  const success = data && data.length > 2;
  let result: CallStateResult | undefined = undefined;
  if (success && data) {
    try {
      result = contractInterface.decodeFunctionResult(fragment, data);
    } catch (error) {
      // console.debug('Result data parsing failed', fragment, data);
      return {
        valid: true,
        loading: false,
        error: true,
        result,
      };
    }
  }
  return {
    valid: true,
    loading: false,
    result,
    error: !success,
  };
}

/**
 * Call method argument validation
 * @param x
 */
export function isCallMethodArg(x: unknown): x is CallMethodArg {
  return ['string', 'number'].indexOf(typeof x) !== -1;
}

/**
 * Accepts an array of methods args and validate it (undefined is valid argument)
 * @param x
 */
export function isCallMethodArgs(x: unknown): x is CallMethodArgs | undefined {
  return x === undefined || (Array.isArray(x) && x.every(xi => isCallMethodArg(xi) || (Array.isArray(xi) && xi.every(isCallMethodArg))));
}
