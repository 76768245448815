export const getDecimalsBySymbol = (symbol: string): number => {
  if (['USDC', 'USDT', 'EURT'].includes(symbol)) {
    return 6;
  }
  if (['BTC', 'DOGE', 'BCH'].includes(symbol)) {
    return 8;
  }
  if ('LUNA' === symbol) {
    return 9;
  }

  return 18;
};
