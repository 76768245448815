import { Token } from '@plasma/plasmaswap-sdk';
import { TokenInfo } from '@plasma-fi/token-lists';
import { TagInfo } from './tag-info';

/**
 * Token instances created from token info.
 */
export class WrappedTokenInfo<T extends TokenInfo = TokenInfo> extends Token {
  public readonly info: T;

  public readonly tags: TagInfo[];

  constructor(tokenInfo: T, tags: TagInfo[]) {
    super(tokenInfo.chainId, tokenInfo.address, tokenInfo.decimals, tokenInfo.symbol, tokenInfo.name);
    this.info = tokenInfo;
    this.tags = tags;
  }

  get logoURI(): string | undefined {
    return this.info.logoURI;
  }
}
