export enum BridgeRenNetworkId {
  Ethereum = 'Ethereum',
  BinanceSmartChain = 'BinanceSmartChain',
  Polygon = 'Polygon',
  Fantom = 'Fantom',
  Arbitrum = 'Arbitrum',
  Avalanche = 'Avalanche',
  Bitcoin = 'Bitcoin',
  BitcoinCash = 'BitcoinCash',
  Dogecoin = 'Dogecoin',
  Filecoin = 'Filecoin',
  Terra = 'Terra',
  Zcash = 'Zcash',
  DigiByte = 'DigiByte',
}
