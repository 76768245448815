import { Currency, CurrencyAmount, JSBI, NATIVE, NativeAmount, Token, TokenAmount } from '@plasma/plasmaswap-sdk';
import { useMemo } from 'react';
import { ERC20_INTERFACE } from '../../constants/abis/erc20';
import { useMulticallContract } from '../../hooks/use-contract';
import { useMultipleContractSingleData, useSingleContractMultipleData } from '../multicall/multicall.hooks';
import { isAddress } from '../../utils';
import { useActiveWeb3React } from '../../hooks/web3/use-active-web3-react';

/**
 * Returns ETH balance for current user or selected wallet
 */
export function useEthBalance(account?: string): CurrencyAmount | undefined {
  const { chainId } = useActiveWeb3React();
  const contract = useMulticallContract();
  const callStates = useSingleContractMultipleData(contract, 'getEthBalance', account ? [[account]] : []);

  return useMemo<CurrencyAmount | undefined>(() => {
    if (callStates?.[0]?.result?.[0] && chainId) {
      return NativeAmount.native(chainId, JSBI.BigInt(callStates?.[0]?.result?.[0].toString()));
    }
    return undefined;
  }, [chainId, callStates]);
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(address?: string, tokens?: (Token | undefined)[]): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const validTokens: Token[] = useMemo(() => tokens?.filter((t?: Token): t is Token => isAddress(t?.address) !== false) ?? [], [tokens]);

  const validTokenAddresses: string[] = useMemo(() => validTokens.map(vt => vt.address), [validTokens]);

  const balances = useMultipleContractSingleData(validTokenAddresses, ERC20_INTERFACE, 'balanceOf', [address]);

  const anyLoading: boolean = useMemo(() => balances.some(callState => callState.loading), [balances]);

  return [
    useMemo(
      () =>
        address && validTokens.length > 0
          ? validTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
              const value = balances?.[i]?.result?.[0];
              const amount = value ? JSBI.BigInt(value.toString()) : undefined;
              if (amount) {
                memo[token.address] = new TokenAmount(token, amount);
              }
              return memo;
            }, {})
          : {},
      [address, validTokens, balances],
    ),
    anyLoading,
  ];
}

/**
 * Returns balances list for each token of wallet address
 * @param address
 * @param tokens
 */
export function useTokenBalances(address?: string, tokens?: (Token | undefined)[]): { [tokenAddress: string]: TokenAmount | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0];
}

export function useCurrencyBalances(account?: string, currencies?: (Currency | undefined)[]): (CurrencyAmount | undefined)[] {
  const { chainId } = useActiveWeb3React();
  const tokens: Token[] = useMemo(() => currencies?.filter((currency): currency is Token => currency instanceof Token) ?? [], [currencies]);
  const tokenBalances = useTokenBalances(account, tokens);
  const ethBalance = useEthBalance(account);

  return useMemo(() => {
    if (!currencies || !chainId) {
      return [];
    }
    return currencies.map(currency => {
      if (!account || !currency) {
        return undefined;
      }
      if (currency instanceof Token) {
        return tokenBalances[currency.address];
      }
      if (currency === NATIVE[chainId]) {
        return ethBalance || undefined;
      }
      return undefined;
    });
  }, [currencies, chainId, account, tokenBalances, ethBalance]);
}

export function useCurrencyBalance(account?: string, currency?: Currency): CurrencyAmount | undefined {
  return useCurrencyBalances(account, [currency])[0];
}
