import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../loading/loading';
import './modal-loading-view.scss';

export interface ModalLoadingViewProps {
  message?: string;
}

export function ModalLoadingView({ message }: ModalLoadingViewProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="modal-content modal-loading-view">
      <div className="loading">
        <Loading size="lg" />
      </div>
      <div className="caption">{t('waiting_for_confirmation')}</div>
      {message ? <div className="sub-caption">{message}</div> : null}
      <div className="note-text">{t('confirm_this_transaction_in_your_wallet')}</div>
    </div>
  );
}
