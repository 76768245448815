import { MarketTokenListItem } from '../types';

const URL = 'https://raw.githubusercontent.com/plasmadlt/plasma-finance-market-tokenlist/main/plasma-finance-market-list.json';

export async function getPlasmaFinanceMarketTokenList(abort?: AbortSignal): Promise<MarketTokenListItem[]> {
  const res: Response = await fetch(`${URL}`, { signal: abort });

  if (!res.ok) {
    throw new Error(`Failed to fetch token list data`);
  }
  return res.json();
}
