import { createReducer } from '@reduxjs/toolkit';
import { CallStore } from '../../types';
import { addMulticallListeners, errorFetchingMulticallResults, fetchingMulticallResults, removeMulticallListeners, updateMulticallResults } from './multicall.actions';
import { call2Key } from './multicall.helpers';

export interface MulticallState {
  callListeners?: {
    // on a per-chain basis
    [chainId: number]: {
      // stores for each call key the listeners' preferences
      [callKey: string]: {
        // stores how many listeners there are per each blocks per fetch preference
        [blocksPerFetch: number]: number;
      };
    };
  };

  callResults: {
    [chainId: number]: {
      [callKey: string]: CallStore;
    };
  };

  lastCallsCount: number;

  state: 'standby' | 'process' | 'error';
}

const initialState: MulticallState = {
  callResults: {},
  lastCallsCount: 0,
  state: 'standby',
};

export default createReducer(initialState, builder =>
  builder
    .addCase(addMulticallListeners, (state, { payload: { calls, chainId, options: { blocksPerFetch = 1 } = {} } }) => {
      const listeners: MulticallState['callListeners'] = state.callListeners ? state.callListeners : (state.callListeners = {});
      listeners[chainId] = listeners[chainId] ?? {};

      calls.forEach(call => {
        const callKey = call2Key(call);
        listeners[chainId][callKey] = listeners[chainId][callKey] ?? {};
        listeners[chainId][callKey][blocksPerFetch] = (listeners[chainId][callKey][blocksPerFetch] ?? 0) + 1;
      });

      state.lastCallsCount = Object.keys(listeners[chainId]).length;
    })
    .addCase(removeMulticallListeners, (state, { payload: { chainId, calls, options: { blocksPerFetch = 1 } = {} } }) => {
      const listeners: MulticallState['callListeners'] = state.callListeners ? state.callListeners : (state.callListeners = {});

      if (!listeners[chainId]) {
        return;
      }
      calls.forEach(call => {
        const callKey = call2Key(call);
        if (!listeners[chainId][callKey]) {
          return;
        }
        if (!listeners[chainId][callKey][blocksPerFetch]) {
          return;
        }

        if (listeners[chainId][callKey][blocksPerFetch] === 1) {
          delete listeners[chainId][callKey][blocksPerFetch];
        } else {
          listeners[chainId][callKey][blocksPerFetch]--;
        }
      });
    })
    .addCase(fetchingMulticallResults, (state, { payload: { chainId, fetchingBlockNumber, calls } }) => {
      state.callResults[chainId] = state.callResults[chainId] ?? {};
      calls.forEach(call => {
        const callKey = call2Key(call);
        const current = state.callResults[chainId][callKey];
        if (!current) {
          state.callResults[chainId][callKey] = {
            fetchingBlockNumber,
          };
        } else {
          if ((current.fetchingBlockNumber ?? 0) >= fetchingBlockNumber) {
            return;
          }
          state.callResults[chainId][callKey].fetchingBlockNumber = fetchingBlockNumber;
        }
      });

      state.state = 'process';
      state.lastCallsCount = calls.length;
    })
    .addCase(errorFetchingMulticallResults, (state, { payload: { fetchingBlockNumber, chainId, calls } }) => {
      state.callResults[chainId] = state.callResults[chainId] ?? {};
      calls.forEach(call => {
        const callKey = call2Key(call);
        const current = state.callResults[chainId][callKey];
        if (!current) {
          return;
        } // only should be dispatched if we are already fetching
        if (current.fetchingBlockNumber === fetchingBlockNumber) {
          delete current.fetchingBlockNumber;
          current.data = null;
          current.blockNumber = fetchingBlockNumber;
        }
      });

      state.state = 'error';
    })
    .addCase(updateMulticallResults, (state, { payload: { chainId, results, blockNumber } }) => {
      state.callResults[chainId] = state.callResults[chainId] ?? {};
      Object.keys(results).forEach(callKey => {
        const current = state.callResults[chainId][callKey];
        if ((current?.blockNumber ?? 0) > blockNumber) {
          return;
        }
        state.callResults[chainId][callKey] = {
          data: results[callKey],
          blockNumber,
        };
      });
      state.state = 'standby';
    }),
);
