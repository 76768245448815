import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { load, save } from 'redux-localstorage-simple';
import { updateVersion } from './global/global.actions';
import user from './user/user.reducer';
import wallets from './wallets/wallets.reducer';
import multicall from './multicall/multicall.reducer';
import lists from './lists/lists.reducer';
import approval from './approval/approval.reducer';
import application from './application/application.reducer';
import gasStation from './gas-station/gas-station.reducer';
import portfolioAssets from './portfolio-assets/portfolio-assets.reducer';

const PERSISTED_KEYS: string[] = [
  'user.referrals',
  'user.firstVisit',
  'user.usedWallets',
  'user.timestamp',
  'user.exclude0xLiquiditySources',
  'user.currency',
  'user.slippageTolerance',
  'user.transactionExpiry',
];

const store = configureStore({
  reducer: {
    user,
    wallets,
    multicall,
    lists,
    approval,
    application,
    gasStation,
    portfolioAssets,
  },
  middleware: [...getDefaultMiddleware({ thunk: false, immutableCheck: false, serializableCheck: false }), save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS, disableWarnings: true }),
});

store.dispatch(updateVersion());

export default store;
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
