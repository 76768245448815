import { GsnPostTransaction } from '../types';

export async function postGasStationSendTx(url: string, txData: GsnPostTransaction, abort?: AbortSignal): Promise<string> {
  const res = await fetch(`${url}/send-tx`, {
    method: 'POST',
    body: JSON.stringify(txData),
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    signal: abort,
  });

  if (!res.ok) {
    throw new Error(`postGsnTransaction: ERROR: "${res.statusText}"`);
  }

  const rawInfo = await res.json();
  if (rawInfo.error) {
    throw new Error(`postGsnTransaction: ERROR: ${rawInfo.error}`);
  }
  return rawInfo.txHash;
}
