import RenJS, { Gateway } from '@renproject/ren';
import { useEffect, useState } from 'react';
import { REN_EVM_NETWORK_IDS } from '../../constants';
import { BridgeRenNetworkId } from '../../types';
import { useActiveWeb3React } from '../web3/use-active-web3-react';

export const useRenGateway = (
  exchanger?: RenJS,
  fromNetworkId?: BridgeRenNetworkId,
  toNetworkId?: BridgeRenNetworkId,
  fromAssetId?: string,
  recipient?: string,
): Gateway | null | undefined => {
  const { account } = useActiveWeb3React();
  const [gateway, setGateway] = useState<Gateway | null | undefined>();

  useEffect(() => {
    if (!account || !exchanger || !fromNetworkId || !toNetworkId || !exchanger.chains[fromNetworkId] || !exchanger.chains[toNetworkId] || !fromAssetId) {
      return setGateway(undefined);
    }
    const toEvm = REN_EVM_NETWORK_IDS.includes(toNetworkId);
    const fromNetwork = exchanger.chains[fromNetworkId];
    const toNetwork = exchanger.chains[toNetworkId];

    if (!toEvm && (!recipient || !toNetwork.validateAddress(recipient))) {
      return setGateway(undefined);
    }

    let from, to;
    try {
      from = 'GatewayAddress' in fromNetwork ? (fromNetwork as any).GatewayAddress() : (fromNetwork as any).Account({ account, amount: '0' });
      to = 'Account' in toNetwork ? (toNetwork as any).Account({ account }) : (toNetwork as any).Address(toEvm ? account : recipient);
    } catch (e) {
      console.error(e);
      return setGateway(undefined);
    }

    let canceled = false;

    setGateway(null);

    exchanger
      .gateway({ asset: fromAssetId, from, to })
      .then((gateway: Gateway) => {
        if (canceled) {
          return;
        }
        setGateway(gateway);
      })
      .catch(err => {
        if (canceled) {
          return;
        }
        console.error(err);
        setGateway(undefined);
      });

    return () => {
      canceled = true;
    };
  }, [account, exchanger, fromAssetId, fromNetworkId, recipient, toNetworkId]);

  return gateway;
};
