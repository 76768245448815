import React, { useState } from 'react';
import { ConnectWallet } from './_connect-wallet';
import { ConnectWalletProcess } from './_connect-wallet-process';
import './modal-wallet.scss';
import { Connector } from 'wagmi';

export enum WalletView {
  CONNECT_WALLET = 'CONNECT_WALLET',
  CONNECT_WALLET_PROCESS = 'CONNECT_WALLET_PROCESS',
}

export function ConnectWalletTab(): JSX.Element {
  const [walletView, setWalletView] = useState(WalletView.CONNECT_WALLET);
  const [connector, setConnector] = useState<Connector>();

  return walletView === WalletView.CONNECT_WALLET_PROCESS ? (
    <ConnectWalletProcess
      connector={connector}
      onReset={() => {
        setConnector(undefined);
        setWalletView(WalletView.CONNECT_WALLET);
      }}
    />
  ) : (
    <ConnectWallet
      onConnect={connector => {
        setWalletView(WalletView.CONNECT_WALLET_PROCESS);
        setConnector(connector);
      }}
    />
  );
}
