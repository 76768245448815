import { useMemo } from 'react';
import { isAddress } from '../utils';
import { useENSAddress } from './use-ens-address';
import { useENSName } from './use-ens-name';

/**
 * Given a name or address, does a lookup to resolve to an address and name
 * @param nameOrAddress ENS name or address
 */
export function useENS(nameOrAddress?: string | null): { loading: boolean; address: string | null; name: string | null } {
  const validated = isAddress(nameOrAddress || undefined);
  const reverseLookup = useENSName(validated ? validated : undefined);
  const lookup = useENSAddress(nameOrAddress);

  return useMemo(() => {
    return {
      loading: reverseLookup.loading || lookup.loading,
      address: validated ? validated : lookup.address,
      name: reverseLookup.ENSName ? reverseLookup.ENSName : !validated && lookup.address ? nameOrAddress || null : null,
    };
  }, [lookup.address, lookup.loading, nameOrAddress, reverseLookup.ENSName, reverseLookup.loading, validated]);
}
