import { FallbackProvider, JsonRpcSigner } from '@ethersproject/providers';
import { ChainId } from '@plasma/plasmaswap-sdk';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAccount, useConnect, useNetwork, useProvider, useSigner } from 'wagmi';
import { ALL_SUPPORTED_CHAIN_IDS, RPC_URLS, ZERO_ADDRESS } from '../../constants';
import { GsnWeb3Provider } from '../../gsn-web3-provider/gsn-web3-provider';
import { AppState } from '../../state';
import { isAddress } from '../../utils';
import { useGetQueryChainId } from '../../utils/get-query-params';
import { ApplicationContext } from './application-context';

export function ApplicationProvider({ children }: { children: JSX.Element }): JSX.Element {
  const defaultChainId = useSelector<AppState, AppState['application']['defaultChainId']>(state => state.application.defaultChainId);
  const { isConnected, isReconnecting } = useConnect();
  const { activeChain, switchNetwork } = useNetwork();
  const commonProvider = useProvider<GsnWeb3Provider | FallbackProvider>();
  const { data: accountInfo } = useAccount();
  const { data: signer } = useSigner();
  const [chainId, setChainId] = useState<ChainId>(defaultChainId);
  const firstLoad = useRef(true);
  const queryChainId = useGetQueryChainId();

  const account: string | undefined = useMemo(() => {
    if (!isConnected) {
      return undefined;
    }
    const account = isAddress(accountInfo?.address);
    return account && account !== ZERO_ADDRESS ? account : undefined;
  }, [accountInfo, isConnected]);

  useEffect(() => {
    if (activeChain && ALL_SUPPORTED_CHAIN_IDS.includes(activeChain.id)) {
      setChainId(activeChain.id);
    } else {
      setChainId(defaultChainId);
    }
  }, [activeChain, defaultChainId]);

  // useEffect(() => {
  //   if (activeChain) {
  //     setDefaultChainId(activeChain.id);
  //   }
  // }, [activeChain]);

  useEffect(() => {
    if (!queryChainId) {
      return;
    }
    if (!isConnected && !isReconnecting && firstLoad.current) {
      setChainId(queryChainId);
      firstLoad.current = false;
    } else if (isConnected && switchNetwork && firstLoad.current) {
      switchNetwork(queryChainId);
      firstLoad.current = false;
    }
  }, [isConnected, isReconnecting, queryChainId, switchNetwork]);

  const provider: GsnWeb3Provider = useMemo(() => {
    if (commonProvider instanceof GsnWeb3Provider) {
      return commonProvider;
    } else {
      return new GsnWeb3Provider(RPC_URLS[chainId as ChainId] as string);
    }
  }, [chainId, commonProvider]);

  // Set provider signer
  useEffect(() => {
    if (signer instanceof JsonRpcSigner) {
      provider.setSigner(signer);
    } else {
      provider.setSigner(undefined);
    }
  }, [provider, signer]);

  return <ApplicationContext.Provider value={{ chainId, account, provider }}>{children}</ApplicationContext.Provider>;
}
